import { Scope } from '@alamere/core';
import {
  BonusStructureGroupSaveRequest,
  BonusStructureGroupsDocument,
  BonusStructureSaveRequest,
  useBonusStructureGroupsSaveMutation,
  useBonusStructuresSaveMutation,
} from '@alamere/generated-graphql-types';
import { useApolloClient } from '@apollo/client';
import { Stack } from '@mui/material';
import { useState } from 'react';
import { EditButtons } from '../../components/EditButtons';
import { NewGroup } from './NewGroup';
import { Table } from './Table';
import { OnGroupChangeFunction, OnItemChangeFunction } from './types';

export default function BonusStructuresPage() {
  const client = useApolloClient();

  const [editing, setEditing] = useState(false);
  const [groupUpdates, setGroupUpdates] = useState<
    Record<string, BonusStructureGroupSaveRequest>
  >({});
  const [itemUpdates, setItemUpdates] = useState<
    Record<string, BonusStructureSaveRequest>
  >({});

  const [saveGroups] = useBonusStructureGroupsSaveMutation();
  const [saveItems] = useBonusStructuresSaveMutation();

  const handleSave = () => {
    Promise.all([
      Object.values(groupUpdates).length > 0 &&
        saveGroups({
          variables: {
            bonusStructureGroupSaveManyRequest: {
              items: Object.values(groupUpdates),
            },
          },
        }),
      Object.values(itemUpdates).length > 0 &&
        saveItems({
          variables: {
            bonusStructuresSaveRequest: {
              items: Object.values(itemUpdates),
            },
          },
        }),
    ]);
    setGroupUpdates({});
    setItemUpdates({});
    setEditing(false);
  };

  const handleCancel = () => {
    client.refetchQueries({
      include: [BonusStructureGroupsDocument],
    });
    setGroupUpdates({});
    setItemUpdates({});
    setEditing(false);
  };

  const handleGroupChange: OnGroupChangeFunction = (newGroup) => {
    setGroupUpdates((prev) => {
      if (!newGroup.id) {
        return prev;
      }
      return {
        ...prev,
        [newGroup.id]: {
          name: newGroup.name,
          id: newGroup.id,
        },
      };
    });
  };

  const handleItemChange: OnItemChangeFunction = (newItem) => {
    setItemUpdates((prev) => {
      if (!newItem.id) {
        return prev;
      }
      return {
        ...prev,
        [newItem.id]: {
          id: newItem.id,
          percent: newItem.percent,
          globalLevelLevel: newItem.globalLevelLevel,
          bonusStructureGroupId: newItem.bonusStructureGroupId,
          jobLevelId: newItem.jobLevelId,
        },
      };
    });
  };

  return (
    <>
      <EditButtons
        requiredScopes={[Scope.RANGE_WIDTHS_EDIT]}
        onEdit={() => setEditing(true)}
        onSave={() => {
          handleSave();
        }}
        onCancel={handleCancel}
        sx={{ mb: 2 }}
      />
      <Stack direction="row" spacing={1} sx={{ mt: 2 }}>
        <Table
          editing={editing}
          onGroupChange={handleGroupChange}
          onItemChange={handleItemChange}
        />
        <NewGroup editing={editing} />
      </Stack>
    </>
  );
}
