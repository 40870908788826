import { useApolloClient } from '@apollo/client';
import DeleteIcon from '@mui/icons-material/DeleteRounded';
import {
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { memo, useState } from 'react';
import {
  JobFragment,
  JobFragmentDoc,
} from '../../../graphql/_generated/generated-graphql-types';
import { OnItemChangeFunction } from './JobDefinitionsPage.page';

interface Props {
  job: JobFragment;
  editing: boolean;
  onItemChange: OnItemChangeFunction;
}
export default memo(({ job, editing, onItemChange }: Props) => {
  const { cache } = useApolloClient();
  const [hovered, setHovered] = useState(false);

  const handleChange = (val: string) => {
    const newItem = { ...job, title: val };
    cache.writeFragment({
      id: `Job:${job.id}`,
      data: newItem,
      fragment: JobFragmentDoc,
      fragmentName: 'Job',
    });
    onItemChange({ newItem });
  };

  const handleDelete = () => {
    cache.evict({ id: `Job:${job.id}` });
    onItemChange({ jobIdsToDelete: [job.id] });
  };

  return (
    <>
      {editing && (
        <TextField
          value={job.title || ''}
          size="small"
          variant="outlined"
          fullWidth
          onChange={(e) => handleChange(e.target.value)}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          InputProps={{
            endAdornment: (
              <InputAdornment
                position="end"
                sx={{ visibility: hovered ? 'visible' : 'hidden' }}
              >
                <IconButton size="small" onClick={handleDelete}>
                  <DeleteIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      )}
      {!editing && <Typography>{job.title}</Typography>}
    </>
  );
});
