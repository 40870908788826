export enum EquityValueType {
  NUM_SHARES = 'NUM_SHARES',
  USD = 'USD',
}

export interface NumSharesResult {
  rsus: number;
  options: number;
  total: number;
}

export interface NumSharesArgs {
  equityValue: number;
  stockPrice: number;
  strikePrice: number;
  percentRsu: number;
}

export function getNumShares({
  equityValue,
  stockPrice,
  strikePrice,
  percentRsu,
}: NumSharesArgs): NumSharesResult {
  // (rsus * sharePrice) + (options * (sharePrice - strikePrice)) = equityValue
  // options = (100 - percentRsu) * rsus
  // (rsus * sharePrice) + ((100 - percentRsu)rsu * (sharePrice - strikePrice)) = equityValue
  // rsus = equityValue / (sharePrice + ((100 - percentRsu) * (sharePrice - strikePrice)))
  const rsus =
    equityValue /
    (stockPrice + (100 - percentRsu) * (stockPrice - strikePrice));
  const options = (100 - percentRsu) * rsus;

  return { rsus, options, total: rsus + options };
}
