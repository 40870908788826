import * as amplitude from '@amplitude/analytics-browser';
import { useEffect, useState } from 'react';
import { useAuth } from '../providers/auth';

const AMPLIDUDE_PROD_KEY = '48406f121d84204a6a7645db51435126';
const AMPLITUDE_QA_KEY = '95f683d88f8a8ff98646438bc95ba0cd';
const amplitudeApiKey =
  location.hostname === 'app.alamere.io'
    ? AMPLIDUDE_PROD_KEY
    : AMPLITUDE_QA_KEY;

amplitude.init(amplitudeApiKey, undefined, {
  defaultTracking: {
    sessions: true,
    pageViews: true,
    formInteractions: true,
    fileDownloads: true,
  },
});

export enum AmplitudeEventType {
  SearchZipcode = 'Search Zipcode',
  EditGeoTierConfiguration = 'Edit Geo Tier Configuration',
}

export function useIdentifyAmplitudeUser() {
  const [userInitialized, setUserInitialized] = useState(false);
  const [workspaceInitialized, setWorkspaceInitialized] = useState(false);

  const { user, workspace } = useAuth();

  useEffect(() => {
    if (!userInitialized && user) {
      amplitude.setUserId(user.email);
      const identifyEvent = new amplitude.Identify();
      identifyEvent.set('userId', user.id);
      amplitude.identify(identifyEvent);

      setUserInitialized(true);
    }

    if (!workspaceInitialized && workspace) {
      const identifyEvent = new amplitude.Identify();
      identifyEvent.set('workspaceId', workspace.id);
      amplitude.identify(identifyEvent);
      setWorkspaceInitialized(true);
    }
  }, [user, userInitialized, workspaceInitialized, workspace]);
}

export default amplitude;
