import { Box, Container } from '@mui/material';
import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../providers/auth';
import JoinWaitlist from '../auth/SignUpPage/JoinWaitlist';
import DarkSection from './DarkSection';
import Features from './Features';
import Footer from './Footer';
import Hero from './Hero';

export default function HomePage() {
  const { user, workspace } = useAuth();
  const navigate = useNavigate();

  const joinWaitlistRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (workspace && user?.hasVerifiedEmail) {
      navigate(`/w/${workspace.id}/location-lookup`, { replace: true });
    }
  }, [workspace, navigate]);

  return (
    <Box>
      <Hero
        onCtaClick={() =>
          joinWaitlistRef.current?.scrollIntoView({ behavior: 'smooth' })
        }
      />
      <Container maxWidth="lg" sx={{ my: 14 }}>
        <Features />
      </Container>
      <DarkSection />
      <Box ref={joinWaitlistRef}>
        <JoinWaitlist email="" />
      </Box>
      <Footer />
    </Box>
  );
}
