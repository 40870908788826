import i18n, { t as transform } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import en from './locales/en.json';

const resources = {
  en: {
    translation: en,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    detection: {
      // https://github.com/i18next/i18next-browser-languageDetector
      lookupQuerystring: 'lang',
      order: [
        'querystring',
        'cookie',
        'localStorage',
        'sessionStorage',
        'navigator',
        'htmlTag',
        'path',
        'subdomain',
      ],
    },
  });

export default i18n;

export const tParent = (parentPath: string) => (key: string) =>
  transform(`${parentPath}.${key}`);
