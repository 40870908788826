import { Skeleton, TableCell, TableRow } from '@mui/material';

export const SkeletonTableRows = ({
  cols,
  rows,
  height,
}: {
  cols: number;
  rows: number;
  height?: number;
}) => {
  return (
    <>
      {Array(rows)
        .fill(null)
        .map((_, index) => (
          <TableRow key={index}>
            {Array(cols)
              .fill(null)
              .map((_, index2) => (
                <TableCell key={index2}>
                  <Skeleton height={height} />
                </TableCell>
              ))}
          </TableRow>
        ))}
    </>
  );
};
