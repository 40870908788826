import { useJoinWaitlistMutation } from '@alamere/generated-graphql-types';
import { Alert, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { t } from 'i18next';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export interface JoinWaitlistProps {
  email: string;
}

export default function JoinWaitlist({
  email: emailFromProps,
}: JoinWaitlistProps) {
  const [joinWaitlist, { loading }] = useJoinWaitlistMutation();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState(emailFromProps);
  const [joined, setJoined] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = async () => {
    await joinWaitlist({
      variables: {
        request: {
          firstName,
          lastName,
          email,
        },
      },
    });

    setJoined(true);
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          my: 12,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5" align="center">
          {t('signUpPage.joinWaitlist')}
        </Typography>
        <Stack sx={{ mt: 1 }} spacing={2} width="100%">
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="given-name"
            label="First Name"
            name="given-name"
            autoComplete="given-name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="family-name"
            label="Last Name"
            name="family-name"
            autoComplete="family-name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />

          {joined && (
            <Alert severity="success">{t('signUpPage.waitlistThankYou')}</Alert>
          )}
          <Button
            fullWidth
            variant="contained"
            size="large"
            sx={{ mt: 3, mb: 2 }}
            onClick={handleSubmit}
            disabled={loading || joined}
          >
            {t('signUpPage.joinWaitlist')}
          </Button>

          <Button onClick={() => navigate('/login')} variant="text" fullWidth>
            {'Have an account? Log In'}
          </Button>
        </Stack>
      </Box>
    </Container>
  );
}
