import { JOB_GROUPS } from '@alamere/core';
import {
  Table as MuiTable,
  Paper,
  TableBody,
  TableContainer,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
import { groupBy, uniqBy } from 'lodash';
import { useMemo } from 'react';
import {
  JobFamilyFragment,
  useGlobalLevelsQuery,
  useJobLevelsQuery,
  useJobsQuery,
} from '../../../graphql/_generated/generated-graphql-types';
import { Head } from './Head';
import { OnItemChangeFunction } from './JobDefinitionsPage.page';
import Row from './Row';

export interface Props {
  editing: boolean;
  onItemChange: OnItemChangeFunction;
  jobFamily: JobFamilyFragment;
}

export default function Table({ editing, onItemChange, jobFamily }: Props) {
  const { data: jobsData, loading: loadingJobs } = useJobsQuery({
    variables: {
      jobGetRequest: {
        jobFamilyIds: [jobFamily.id],
      },
    },
  });

  const { data: jobLevelsData, loading: loadingJobLevels } = useJobLevelsQuery({
    variables: {
      jobLevelsGetRequest: {
        onlyVisible: true,
      },
    },
  });

  const jobLevelsByLevel = useMemo(
    () => groupBy(jobLevelsData?.jobLevels, (jobLevel) => jobLevel?.level),
    [jobLevelsData?.jobLevels]
  );

  const { data: globalLevelsData, loading: loadingGlobalLevels } =
    useGlobalLevelsQuery();

  const jobsByLevel = useMemo(
    () => groupBy(jobsData?.jobs, (job) => job?.jobLevel.level),
    [jobsData?.jobs]
  );

  const globalLevelsByLevel = useMemo(
    () => groupBy(globalLevelsData?.globalLevels, (level) => level?.level),
    [globalLevelsData?.globalLevels]
  );

  const orderedJobGroups = useMemo(() => {
    const availableGroups = uniqBy(
      jobLevelsData?.jobLevels,
      (jobLevel) => jobLevel?.group
    );
    return JOB_GROUPS.filter((group) =>
      availableGroups.some((jobLevel) =>
        editing
          ? jobLevel.group === group && jobLevel.isVisible
          : jobsData?.jobs.some((job) => job?.jobLevel.group === group)
      )
    );
  }, [jobsData?.jobs, jobLevelsData?.jobLevels, editing]);

  if (orderedJobGroups.length === 0) {
    return <Typography>{t('jobDefinitionsPage.noJobsForFamily')}</Typography>;
  }

  return (
    <TableContainer component={Paper} sx={{ width: 'fit-content' }}>
      <MuiTable aria-label="Job Levels" size="small">
        {/* TODO GB: hide if no data in view mode */}
        <Head editing={editing} cols={orderedJobGroups} />
        <TableBody>
          {!loadingJobs &&
            Object.entries(jobLevelsByLevel)
              .reverse()
              .map(([level, jobLevels]) => {
                if (!globalLevelsByLevel[level]?.[0]) {
                  return null;
                }
                return (
                  <Row
                    key={level}
                    globalLevel={globalLevelsByLevel[level]?.[0]}
                    orderedJobGroups={orderedJobGroups}
                    jobLevels={jobLevels}
                    jobs={jobsByLevel[level]}
                    jobFamilyId={jobFamily.id}
                    editing={editing}
                    onItemChange={onItemChange}
                  />
                );
              })}
        </TableBody>
      </MuiTable>
    </TableContainer>
  );
}
