import { isBlessedUser } from '@alamere/core';
import { UserRole } from '@alamere/generated-graphql-types';
import { Chip } from '@mui/material';
import { formatDistanceToNow } from 'date-fns';
import { useEffect, useState } from 'react';
import { useAuth } from '../../providers/auth';

export default function JitaMenu() {
  const { user, workspaceMembership } = useAuth();
  const [timeRemaining, setTimeRemaining] = useState<string>('');

  useEffect(() => {
    if (workspaceMembership?.expiresAt) {
      const interval = setInterval(() => {
        setTimeRemaining(
          formatDistanceToNow(new Date(workspaceMembership?.expiresAt))
        );
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [workspaceMembership?.expiresAt]);
  if (!isBlessedUser(user?.email)) {
    return null;
  }

  if (workspaceMembership?.role !== UserRole.JITA_WRITE) {
    return null;
  }

  return (
    <Chip label={`JITA: ${timeRemaining}`} variant="outlined" color="warning" />
  );
}
