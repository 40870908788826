import { Paper, Stack, Typography } from '@mui/material';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';
export default function NoAccessPage() {
  const navigate = useNavigate();

  return (
    <Paper sx={{ alignItems: 'center', p: 3, width: 'fit-content' }}>
      <Stack spacing={2}>
        <Typography variant="h5">{t('noAccessPage.title')}</Typography>
        <Typography>{t('noAccessPage.subtitle')}</Typography>
      </Stack>
    </Paper>
  );
}
