import {
  useAcceptInvitationMutation,
  useAcceptInvitationWithGoogleMutation,
  useSignupFromInvitationMutation,
} from '@alamere/generated-graphql-types';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { Alert, Divider } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { GoogleLogin } from '@react-oauth/google';
import { useEffect, useState } from 'react';
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { WORKSPACE_ID } from '../../lib/constants';
import ifEnter from '../../lib/ifEnter';
import { useAuth } from '../../providers/auth';

export default function JoinPage() {
  const { user, onReset } = useAuth();
  const [params] = useSearchParams();
  const paramsWorkspaceId = params.get('workspaceId');
  const [workspaceId] = useState(
    paramsWorkspaceId !== null ? parseInt(paramsWorkspaceId) : undefined
  );

  const [code] = useState(decodeURI(params.get('code') || ''));
  const [workspaceName] = useState(params.get('workspaceName'));
  const [userExists] = useState(params.get('userExists') === 'true');
  const [email, setEmail] = useState(params.get('email') || '');

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');

  const [googleError, setGoogleError] = useState<Error>();
  const [error, setError] = useState<Error>();

  const [signUp] = useSignupFromInvitationMutation();
  const [acceptInvitation] = useAcceptInvitationMutation();
  const [acceptInvitationWithGoogle] = useAcceptInvitationWithGoogleMutation();
  const navigate = useNavigate();

  useEffect(() => {
    if (workspaceId) {
      localStorage.setItem(WORKSPACE_ID, workspaceId.toString());
    } else {
      localStorage.removeItem(WORKSPACE_ID);
      navigate('/');
    }
  }, [workspaceId]);

  useEffect(() => {
    if (user?.email !== email) {
      onReset();
    }
  }, [user, email]);

  useEffect(() => {
    if (userExists && workspaceId) {
      acceptInvitation({
        variables: {
          acceptInvitationRequest: {
            email,
            workspaceId,
            code,
          },
        },
      })
        .then(() => {
          navigate({
            pathname: '/login',
            search: createSearchParams({ email, password }).toString(),
          });
        })
        .catch(setError);
    }
  }, [userExists]);

  const onSignUpAndAccept = () => {
    if (!workspaceId) return;
    signUp({
      variables: {
        createUserRequest: {
          email,
          firstName,
          lastName,
          password,
          workspaceId,
          code,
        },
      },
    })
      .then(() => {
        navigate({
          pathname: '/login',
          search: createSearchParams({ email, password }).toString(),
        });
      })
      .catch(setError);
  };

  const onGoogleLogin = ({
    credential,
    clientId,
  }: {
    credential?: string;
    clientId?: string;
  }) => {
    if (!credential || !clientId || !workspaceId) {
      return;
    }

    acceptInvitationWithGoogle({
      variables: {
        createGoogleUserInWorkspaceRequest: {
          credential: credential,
          workspaceId,
          code,
        },
      },
    })
      .then(() => {
        navigate('/login');
      })
      .catch(setGoogleError);
  };

  if (!workspaceId) {
    return null;
  }

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 12,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <AutoAwesomeIcon sx={{ margin: 3 }} />
        <Typography component="h1" variant="h5" align="center">
          {workspaceName
            ? `Sign up and join ${workspaceName} on Alamere`
            : "Let's go!"}
        </Typography>
        <Box sx={{ mt: 5 }}>
          <GoogleLogin
            size="large"
            onSuccess={onGoogleLogin}
            width={500}
            logo_alignment="center"
            useOneTap
            text="signup_with"
          />
          {googleError && (
            <Alert sx={{ mt: 1 }} severity="error">
              {googleError.message}
            </Alert>
          )}
        </Box>
        <Divider sx={{ py: 2 }}>
          <Typography color="text.secondary">or</Typography>
        </Divider>
        <Box sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="given-name"
            label="First Name"
            name="given-name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="family-name"
            label="Last Name"
            name="family-name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            value={email}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            value={password}
            onKeyUp={ifEnter(onSignUpAndAccept)}
            onChange={(e) => setPassword(e.target.value)}
          />
          {error && <Alert severity="error">{error.message}</Alert>}
          <Button
            fullWidth
            variant="contained"
            size="large"
            sx={{ mt: 3, mb: 2 }}
            onClick={onSignUpAndAccept}
            disabled={!workspaceId || !firstName || !lastName || !password}
          >
            Sign Up and Accept Invite
          </Button>
          <Grid container direction="column" alignItems="center">
            <Grid item>
              <Button onClick={() => navigate('/login')} variant="text">
                {'Have an account? Sign In'}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}
