import { TableCell, TableHead, TableRow } from '@mui/material';
import { t } from 'i18next';

export default function Head({ editing }: { editing: boolean }) {
  return (
    <TableHead>
      <TableRow>
        <TableCell width={350}>
          {t('equityPage.globalMappings.table.country')}
        </TableCell>
        <TableCell align="center" width={120}>
          {t('equityPage.globalMappings.table.percentage')}
        </TableCell>
        <TableCell align="center">
          {t('equityPage.globalMappings.table.useGeoDiff')}
        </TableCell>
        {editing && <TableCell width={75}></TableCell>}
      </TableRow>
    </TableHead>
  );
}
