import { useAuth } from '../../providers/auth';
import { Grid, Box, Paper, Button, Stack } from '@mui/material';
import { TextareaAutosize } from '@mui/base';
import { useState } from 'react';
import {
  useMultiZipcodeSearchLazyQuery,
  type ZipcodeWithTierResponse,
} from '@alamere/generated-graphql-types';
import MultiZipcodeTable from './MultiZipcodeTable';
import { parseInput } from '../../utils';
import { tParent } from '../../vendor/i18n/i18n';
const tPage = tParent('multiZipLookupPage');

export function MultiZipLookupPage() {
  const { workspace } = useAuth();
  const [zipCodes, setZipCodes] = useState<string>('');
  const [search] = useMultiZipcodeSearchLazyQuery();
  const [loading, setLoading] = useState<boolean>(false);
  const [results, setResults] = useState<ZipcodeWithTierResponse[]>([]);


  const onChange = (newZipcodes: string) => {
    const parsedLines = parseInput(newZipcodes, true);
    setZipCodes(parsedLines.join('\n'));
  };

  const onSubmit = async () => {
    setLoading(true);
    const zips = parseInput(zipCodes, false);
    const zipArray: string[] = [];
    for (let i = 0; i < zips.length; i++) {
      zipArray.push(zips[i]);
    }

    const res = await search({
      variables: {
        multiZipcodeSearchRequest: {
          zipcodes: zipArray,
        },
      },
    });
    setLoading(false);
    setResults(res.data?.searchMultiZipcodes as ZipcodeWithTierResponse[]);
  };

  if (!workspace) {
    return null;
  }

  return (
      <Grid container minHeight={'100%'} spacing={1} alignItems={'stretch'}>
        <Grid item xs={12} md={2} lg={2}>
          <Paper sx={{ p: 2, height: '100%' }}>
            <Stack direction="column" gap={1}>
              <TextareaAutosize
                placeholder={tPage('fields.multiZipcodeInput')}
                aria-label={tPage('fields.multiZipcodeInput')}
                minRows={3}
                maxRows={40}
                value={zipCodes}
                onChange={(e) => onChange(e.target.value)}
              />
              <Button
                disabled={zipCodes.length < 5 || loading}
                onClick={() => onSubmit()}
              >
                {tPage('fields.submit')}
              </Button>
            </Stack>
          </Paper>
        </Grid>
        <Grid item xs={12} md={10} lg={10}>
          <Paper sx={{ p: 2, height: '100%'  }}>
            <MultiZipcodeTable rows={results} />
          </Paper>
        </Grid>
      </Grid>
  );
}

export const MULTI_ZIP_LOOKUP_PAGE_SLUG: string = 'multi-zipcode-lookup';
