import { JobGroup, SurveyCode, SurveyProvider } from './job-levels';

export interface RadfordJob {
  comment: string;
  module: string;
  code: string;
  title: string;
  function: string;
  area: string;
  focus: string;
  level: string;
  moduleCode: ModuleCode;
  functionCode: FunctionCode;
  areaCode: AreaCode;
  focusCode: FocusCode;
  levelCode: SurveyCode;
  moduleDefinition: string;
  functionDefinition: string;
  areaDefinition: string;
  focusDefinition: string;
  detailedDefinition: string;
  surveyProvider: SurveyProvider;
}
export type RadfordJobs = {
  [key in FunctionCode]?: {
    [key in AreaCode]?: {
      [key in FocusCode]?: { [key in SurveyCode]: { data: RadfordJob } };
    };
  };
};

export interface RadfordJobFamilyCode {
  functionCode: FunctionCode;
  areaCode: AreaCode;
  focusCode: FocusCode;
}

export function getFamilyCode(code: RadfordJobFamilyCode): string {
  return `${code.functionCode}.${code.areaCode}${code.focusCode}`;
}

export enum ModuleCode {
  BPO = 'BPO',
  ENE = 'ENE',
  EXM = 'EXM',
  INF = 'INF',
  LSC = 'LSC',
  MFG = 'MFG',
  MNG = 'MNG',
  RTL = 'RTL',
  SLS = 'SLS',
  TEC = 'TEC',
}

export enum FunctionCode {
  BC = 'BC',
  BO = 'BO',
  BP = 'BP',
  CA = 'CA',
  CB = 'CB',
  CR = 'CR',
  CS = 'CS',
  DO = 'DO',
  EH = 'EH',
  EN = 'EN',
  EO = 'EO',
  FI = 'FI',
  FO = 'FO',
  GA = 'GA',
  GS = 'GS',
  HR = 'HR',
  LE = 'LE',
  LG = 'LG',
  ME = 'ME',
  MF = 'MF',
  MK = 'MK',
  MO = 'MO',
  MR = 'MR',
  OM = 'OM',
  OT = 'OT',
  PD = 'PD',
  PO = 'PO',
  PS = 'PS',
  QU = 'QU',
  RD = 'RD',
  SA = 'SA',
  SC = 'SC',
  SK = 'SK',
  SO = 'SO',
  SP = 'SP',
  TE = 'TE',
  TO = 'TO',
}

export enum AreaCode {
  AA = 'AA',
  AC = 'AC',
  AD = 'AD',
  AG = 'AG',
  AM = 'AM',
  AP = 'AP',
  AR = 'AR',
  AS = 'AS',
  AT = 'AT',
  AV = 'AV',
  AY = 'AY',
  BD = 'BD',
  BE = 'BE',
  BF = 'BF',
  BH = 'BH',
  BO = 'BO',
  BP = 'BP',
  BR = 'BR',
  BU = 'BU',
  CA = 'CA',
  CB = 'CB',
  CC = 'CC',
  CD = 'CD',
  CE = 'CE',
  CH = 'CH',
  CL = 'CL',
  CM = 'CM',
  CO = 'CO',
  CP = 'CP',
  CR = 'CR',
  CS = 'CS',
  DA = 'DA',
  DC = 'DC',
  DE = 'DE',
  DF = 'DF',
  DI = 'DI',
  DM = 'DM',
  DO = 'DO',
  DP = 'DP',
  DR = 'DR',
  DT = 'DT',
  DV = 'DV',
  EC = 'EC',
  ED = 'ED',
  EE = 'EE',
  EG = 'EG',
  EH = 'EH',
  EL = 'EL',
  EM = 'EM',
  EN = 'EN',
  EP = 'EP',
  ER = 'ER',
  ES = 'ES',
  ET = 'ET',
  FA = 'FA',
  FH = 'FH',
  FM = 'FM',
  FO = 'FO',
  FR = 'FR',
  FS = 'FS',
  GA = 'GA',
  GD = 'GD',
  GE = 'GE',
  GL = 'GL',
  GM = 'GM',
  GO = 'GO',
  GP = 'GP',
  GR = 'GR',
  GT = 'GT',
  HD = 'HD',
  HL = 'HL',
  HS = 'HS',
  IA = 'IA',
  IC = 'IC',
  ID = 'ID',
  IF = 'IF',
  IM = 'IM',
  IN = 'IN',
  IR = 'IR',
  IS = 'IS',
  LA = 'LA',
  LD = 'LD',
  LE = 'LE',
  LG = 'LG',
  LM = 'LM',
  LP = 'LP',
  LS = 'LS',
  MA = 'MA',
  MD = 'MD',
  ME = 'ME',
  MI = 'MI',
  MN = 'MN',
  MP = 'MP',
  MR = 'MR',
  MS = 'MS',
  MT = 'MT',
  MU = 'MU',
  NA = 'NA',
  NE = 'NE',
  NU = 'NU',
  OO = 'OO',
  OP = 'OP',
  OW = 'OW',
  PA = 'PA',
  PC = 'PC',
  PD = 'PD',
  PE = 'PE',
  PG = 'PG',
  PH = 'PH',
  PL = 'PL',
  PM = 'PM',
  PN = 'PN',
  PO = 'PO',
  PP = 'PP',
  PR = 'PR',
  PS = 'PS',
  PT = 'PT',
  QA = 'QA',
  QC = 'QC',
  RA = 'RA',
  RC = 'RC',
  RE = 'RE',
  RK = 'RK',
  RL = 'RL',
  RM = 'RM',
  RN = 'RN',
  RS = 'RS',
  SA = 'SA',
  SD = 'SD',
  SG = 'SG',
  SM = 'SM',
  SO = 'SO',
  SP = 'SP',
  SS = 'SS',
  TD = 'TD',
  TE = 'TE',
  TH = 'TH',
  TM = 'TM',
  TO = 'TO',
  TP = 'TP',
  TR = 'TR',
  TS = 'TS',
  TV = 'TV',
  TX = 'TX',
  TY = 'TY',
  UU = 'UU',
  VM = 'VM',
  WI = 'WI',
  WO = 'WO',
  ZZ = 'ZZ',
}

export enum FocusCode {
  AA = 'AA',
  AC = 'AC',
  AD = 'AD',
  AG = 'AG',
  AH = 'AH',
  AI = 'AI',
  AM = 'AM',
  AN = 'AN',
  AO = 'AO',
  AP = 'AP',
  AR = 'AR',
  AS = 'AS',
  AT = 'AT',
  AU = 'AU',
  AV = 'AV',
  AX = 'AX',
  BA = 'BA',
  BC = 'BC',
  BD = 'BD',
  BE = 'BE',
  BF = 'BF',
  BI = 'BI',
  BL = 'BL',
  BM = 'BM',
  BN = 'BN',
  BO = 'BO',
  BP = 'BP',
  BR = 'BR',
  BS = 'BS',
  BT = 'BT',
  BU = 'BU',
  BX = 'BX',
  BY = 'BY',
  CA = 'CA',
  CB = 'CB',
  CC = 'CC',
  CD = 'CD',
  CE = 'CE',
  CF = 'CF',
  CG = 'CG',
  CH = 'CH',
  CI = 'CI',
  CL = 'CL',
  CM = 'CM',
  CN = 'CN',
  CO = 'CO',
  CP = 'CP',
  CQ = 'CQ',
  CR = 'CR',
  CS = 'CS',
  CT = 'CT',
  CU = 'CU',
  CV = 'CV',
  CW = 'CW',
  CX = 'CX',
  CY = 'CY',
  DA = 'DA',
  DB = 'DB',
  DC = 'DC',
  DD = 'DD',
  DE = 'DE',
  DF = 'DF',
  DG = 'DG',
  DH = 'DH',
  DI = 'DI',
  DL = 'DL',
  DM = 'DM',
  DN = 'DN',
  DO = 'DO',
  DP = 'DP',
  DQ = 'DQ',
  DR = 'DR',
  DS = 'DS',
  DT = 'DT',
  DV = 'DV',
  DX = 'DX',
  DY = 'DY',
  EA = 'EA',
  EB = 'EB',
  EC = 'EC',
  ED = 'ED',
  EE = 'EE',
  EI = 'EI',
  EL = 'EL',
  EM = 'EM',
  EN = 'EN',
  EP = 'EP',
  EQ = 'EQ',
  ER = 'ER',
  ES = 'ES',
  ET = 'ET',
  EV = 'EV',
  EX = 'EX',
  FA = 'FA',
  FB = 'FB',
  FC = 'FC',
  FD = 'FD',
  FE = 'FE',
  FG = 'FG',
  FI = 'FI',
  FL = 'FL',
  FM = 'FM',
  FO = 'FO',
  FP = 'FP',
  FR = 'FR',
  FS = 'FS',
  FT = 'FT',
  FX = 'FX',
  GA = 'GA',
  GC = 'GC',
  GD = 'GD',
  GE = 'GE',
  GF = 'GF',
  GI = 'GI',
  GK = 'GK',
  GL = 'GL',
  GM = 'GM',
  GN = 'GN',
  GO = 'GO',
  GP = 'GP',
  GQ = 'GQ',
  GR = 'GR',
  GS = 'GS',
  GT = 'GT',
  GV = 'GV',
  GW = 'GW',
  GX = 'GX',
  GY = 'GY',
  HB = 'HB',
  HC = 'HC',
  HD = 'HD',
  HE = 'HE',
  HG = 'HG',
  HI = 'HI',
  HL = 'HL',
  HM = 'HM',
  HN = 'HN',
  HO = 'HO',
  HP = 'HP',
  HR = 'HR',
  HS = 'HS',
  HT = 'HT',
  HW = 'HW',
  HY = 'HY',
  IA = 'IA',
  IB = 'IB',
  IC = 'IC',
  ID = 'ID',
  IE = 'IE',
  IL = 'IL',
  IM = 'IM',
  IN = 'IN',
  IO = 'IO',
  IP = 'IP',
  IR = 'IR',
  IS = 'IS',
  IT = 'IT',
  JR = 'JR',
  KA = 'KA',
  KC = 'KC',
  KE = 'KE',
  KM = 'KM',
  KS = 'KS',
  LA = 'LA',
  LB = 'LB',
  LC = 'LC',
  LD = 'LD',
  LE = 'LE',
  LF = 'LF',
  LG = 'LG',
  LI = 'LI',
  LK = 'LK',
  LL = 'LL',
  LM = 'LM',
  LN = 'LN',
  LO = 'LO',
  LP = 'LP',
  LQ = 'LQ',
  LR = 'LR',
  LS = 'LS',
  LT = 'LT',
  MA = 'MA',
  MB = 'MB',
  MC = 'MC',
  MD = 'MD',
  ME = 'ME',
  MF = 'MF',
  MG = 'MG',
  MI = 'MI',
  ML = 'ML',
  MM = 'MM',
  MN = 'MN',
  MO = 'MO',
  MP = 'MP',
  MR = 'MR',
  MS = 'MS',
  MT = 'MT',
  MU = 'MU',
  MW = 'MW',
  MX = 'MX',
  NA = 'NA',
  NC = 'NC',
  ND = 'ND',
  NE = 'NE',
  NG = 'NG',
  NM = 'NM',
  NO = 'NO',
  NP = 'NP',
  NR = 'NR',
  NS = 'NS',
  NT = 'NT',
  NU = 'NU',
  NV = 'NV',
  NX = 'NX',
  NY = 'NY',
  OC = 'OC',
  OD = 'OD',
  OE = 'OE',
  OG = 'OG',
  OM = 'OM',
  ON = 'ON',
  OO = 'OO',
  OP = 'OP',
  OQ = 'OQ',
  OR = 'OR',
  OS = 'OS',
  OT = 'OT',
  OW = 'OW',
  OX = 'OX',
  PA = 'PA',
  PB = 'PB',
  PC = 'PC',
  PD = 'PD',
  PE = 'PE',
  PF = 'PF',
  PG = 'PG',
  PH = 'PH',
  PI = 'PI',
  PL = 'PL',
  PM = 'PM',
  PN = 'PN',
  PO = 'PO',
  PP = 'PP',
  PR = 'PR',
  PS = 'PS',
  PT = 'PT',
  PV = 'PV',
  PW = 'PW',
  PX = 'PX',
  QA = 'QA',
  QP = 'QP',
  QR = 'QR',
  QT = 'QT',
  QU = 'QU',
  RA = 'RA',
  RB = 'RB',
  RC = 'RC',
  RD = 'RD',
  RE = 'RE',
  RF = 'RF',
  RG = 'RG',
  RI = 'RI',
  RL = 'RL',
  RM = 'RM',
  RN = 'RN',
  RO = 'RO',
  RP = 'RP',
  RR = 'RR',
  RS = 'RS',
  RT = 'RT',
  RV = 'RV',
  RW = 'RW',
  RX = 'RX',
  SA = 'SA',
  SB = 'SB',
  SC = 'SC',
  SD = 'SD',
  SE = 'SE',
  SF = 'SF',
  SG = 'SG',
  SH = 'SH',
  SI = 'SI',
  SL = 'SL',
  SM = 'SM',
  SN = 'SN',
  SO = 'SO',
  SP = 'SP',
  SR = 'SR',
  SS = 'SS',
  ST = 'ST',
  SU = 'SU',
  SV = 'SV',
  SX = 'SX',
  SY = 'SY',
  TA = 'TA',
  TC = 'TC',
  TD = 'TD',
  TE = 'TE',
  TF = 'TF',
  TG = 'TG',
  TH = 'TH',
  TI = 'TI',
  TL = 'TL',
  TM = 'TM',
  TN = 'TN',
  TO = 'TO',
  TP = 'TP',
  TR = 'TR',
  TS = 'TS',
  TT = 'TT',
  TU = 'TU',
  TW = 'TW',
  TX = 'TX',
  TY = 'TY',
  UA = 'UA',
  UD = 'UD',
  UE = 'UE',
  UN = 'UN',
  UP = 'UP',
  US = 'US',
  UT = 'UT',
  VA = 'VA',
  VB = 'VB',
  VD = 'VD',
  VE = 'VE',
  VF = 'VF',
  VG = 'VG',
  VI = 'VI',
  VM = 'VM',
  VO = 'VO',
  VR = 'VR',
  VS = 'VS',
  VX = 'VX',
  WA = 'WA',
  WB = 'WB',
  WC = 'WC',
  WD = 'WD',
  WE = 'WE',
  WL = 'WL',
  WP = 'WP',
  WQ = 'WQ',
  WS = 'WS',
  WT = 'WT',
  WU = 'WU',
  WX = 'WX',
  XC = 'XC',
  XS = 'XS',
  XY = 'XY',
  YJ = 'YJ',
}

export const LEVELS_BY_JOB_GROUP: {
  [key in Exclude<SurveyCode, SurveyCode.Intern>]: JobGroup;
} = {
  [SurveyCode.E1]: JobGroup.Executive,
  [SurveyCode.E2]: JobGroup.Executive,
  [SurveyCode.E3]: JobGroup.Executive,
  [SurveyCode.E4]: JobGroup.Executive,
  [SurveyCode.E5]: JobGroup.Executive,
  [SurveyCode.E6]: JobGroup.Executive,
  [SurveyCode.M1]: JobGroup.Management,
  [SurveyCode.M2]: JobGroup.Management,
  [SurveyCode.M3]: JobGroup.Management,
  [SurveyCode.M4]: JobGroup.Management,
  [SurveyCode.M5]: JobGroup.Management,
  [SurveyCode.M6]: JobGroup.Management,
  [SurveyCode.P1]: JobGroup.Professional,
  [SurveyCode.P2]: JobGroup.Professional,
  [SurveyCode.P3]: JobGroup.Professional,
  [SurveyCode.P4]: JobGroup.Professional,
  [SurveyCode.P5]: JobGroup.Professional,
  [SurveyCode.P6]: JobGroup.Professional,
  [SurveyCode.P7]: JobGroup.Professional,
  [SurveyCode.T1]: JobGroup.TechnicalScientific,
  [SurveyCode.T2]: JobGroup.TechnicalScientific,
  [SurveyCode.T3]: JobGroup.TechnicalScientific,
  [SurveyCode.T4]: JobGroup.TechnicalScientific,
  [SurveyCode.T5]: JobGroup.TechnicalScientific,
  [SurveyCode.T6]: JobGroup.TechnicalScientific,
  [SurveyCode.T7]: JobGroup.TechnicalScientific,
  [SurveyCode.S1]: JobGroup.Support,
  [SurveyCode.S2]: JobGroup.Support,
  [SurveyCode.S3]: JobGroup.Support,
  [SurveyCode.S4]: JobGroup.Support,
  [SurveyCode.S5]: JobGroup.Support,
};
