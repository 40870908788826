import QuoteImage from '@images/quote.jpg';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { t } from 'i18next';

const DarkSection = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingY: 16,
        backgroundColor: '#021C2F',
        color: '#fff',
        gap: 4,
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          display: 'flex',
          gap: 10,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <img
          src={QuoteImage}
          style={{ width: '160px', height: '160px', borderRadius: 100 }}
        />
        <Box>
          <Typography variant="h3">
            {t('homePage.darkSection.quote')}
          </Typography>
          {/* <Typography variant="body1" sx={{ lineHeight: '1.5em' }}>
            {t('homePage.darkSection.author')}
          </Typography> */}
        </Box>
      </Container>
    </Box>
  );
};

export default DarkSection;
