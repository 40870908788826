import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import * as React from 'react';
import { Outlet } from 'react-router-dom';
import UnauthedMenu from './UnauthedMenu';

export default function UnauthedLayout() {
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <UnauthedMenu />
      <Box component="main" width="100%">
        <Outlet />
      </Box>
    </Box>
  );
}
