import { Box, CircularProgress } from '@mui/material';
import { useAuth } from '../providers/auth';

export default function NotFound404Page() {
  const { loading } = useAuth();

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress color="success" />
      </Box>
    );
  }
  return <div>Oops, something went wrong</div>;
}
