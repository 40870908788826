import { styled } from '@mui/material';
import { Theme } from '@mui/material/styles/createTheme';
import {
  MaterialDesignContent,
  SnackbarProvider as NotistackSnackbarProvider,
} from 'notistack';
import { useCallback } from 'react';

const StyledNotistackComponent = (theme: Theme) =>
  styled(MaterialDesignContent)(() => ({
    '&.notistack-MuiContent': {
      color: theme.palette.background.default,
      svg: {
        color: theme.palette.background.default,
      },
    },
    '&.notistack-MuiContent-success': {
      color: theme.palette.background.default,
      background: theme.palette.success.main,
    },
    '&.notistack-MuiContent-info': {
      color: theme.palette.background.default,
      background: theme.palette.info.main,
    },
    '&.notistack-MuiContent-warning': {
      color: theme.palette.background.default,
      background: theme.palette.warning.main,
    },
    '&.notistack-MuiContent-error': {
      color: theme.palette.background.default,
      background: theme.palette.error.main,
    },
  }));

export default function SnackbarProvider({
  theme,
  children,
}: {
  theme: Theme;
  children: any;
}) {
  return (
    <NotistackSnackbarProvider
      maxSnack={5}
      Components={{
        success: useCallback(StyledNotistackComponent(theme), [theme]),
        error: useCallback(StyledNotistackComponent(theme), [theme]),
        info: useCallback(StyledNotistackComponent(theme), [theme]),
        warning: useCallback(StyledNotistackComponent(theme), [theme]),
      }}
    >
      {children}
    </NotistackSnackbarProvider>
  );
}
