import { useApolloClient } from '@apollo/client';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { IconButton, TableCell, Tooltip } from '@mui/material';
import { t } from 'i18next';
import { memo } from 'react';
import {
  JobFamilyFragment,
  useJobFamilyDeleteMutation,
} from '../../../graphql/_generated/generated-graphql-types';
import { OnItemChangeFunction } from '../types';

interface Props {
  editing: boolean;
  jobFamily: JobFamilyFragment;
  hovered: boolean;
  onItemChange: OnItemChangeFunction;
}

export const Delete = memo(
  ({ editing, jobFamily, hovered, onItemChange }: Props) => {
    const [deleteFamily] = useJobFamilyDeleteMutation();
    const { cache } = useApolloClient();
    const onDelete = () => {
      onItemChange({ jobFamilyIdToDelete: jobFamily.id });
      cache.evict({ id: `JobFamily:${jobFamily.id}` });
    };
    if (!editing) {
      return null;
    }

    return (
      <TableCell align="center">
        <Tooltip title={`${t('global.delete')} ${jobFamily.name}`}>
          <IconButton
            onClick={onDelete}
            size="small"
            sx={{ visibility: hovered ? 'visible' : 'hidden' }}
          >
            <DeleteRoundedIcon />
          </IconButton>
        </Tooltip>
      </TableCell>
    );
  }
);
