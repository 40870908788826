import { grey } from '@mui/material/colors';
import { Theme, ThemeProvider, createTheme } from '@mui/material/styles';
import { ReactNode, createContext, useMemo, useState } from 'react';
import { THEME_MODE } from '../lib/constants';
import SnackbarProvider from './SnackbarProvider';
import { black, blue, green, red, white, yellow } from './colors';

declare module '@mui/material/styles' {
  interface TypeBackground {
    secondary: string;
    error: string;
    success: string;
  }
  // allow configuration using `createTheme`
}

declare module '@mui/material/styles/createTypography' {
  interface TypographyOptions {
    title?: {
      fontSize: string;
      fontWeight: number;
      lineHeight?: number;
      letterSpacing?: string;
    };
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    title: true;
  }
}

export const PRIMARY_COLOR = green;
export const SECONDARY_COLOR = black;

export const TIER_COLORS: { [key: string]: any } = {
  '1': green,
  '2': blue,
  '3': yellow,
  '4': red,
  '5': green,
  allOtherLocations: grey,
};

export const tableBorder = (theme: Theme) =>
  `1px solid ${theme.palette.divider}`;

export const ColorModeContext = createContext({
  toggleColorMode: () => {},
  activeMode: 'light' as 'light' | 'dark',
});

const createCustomTheme = (mode: 'light' | 'dark') => () =>
  createTheme({
    mixins: {},
    components: {
      MuiCssBaseline: {
        styleOverrides: (theme) => `
          .MuiTableContainer-root.MuiPaper-root {
            border-color: ${mode === 'light' ? green[700] : green[900]};
          }
        `,
      },
      MuiDrawer: {
        styleOverrides: {
          paper: {
            // backgroundColor: mode === 'light' ? grey[100] : grey[900],
            backgroundColor: green[400],
            color: white,
          },
        },
      },
      MuiButton: {
        defaultProps: {
          sx: {
            borderRadius: 30,
          },
        },
      },
      MuiListItemIcon: {
        defaultProps: {
          style: {
            color: green[700],
          },
        },
      },
      MuiPaper: {
        defaultProps: {
          variant: 'outlined',
        },
      },
      MuiTableRow: {
        defaultProps: {
          sx: { '&:last-child td, &:last-child th': { borderBottom: 0 } },
        },
      },
      MuiTableHead: {
        defaultProps: {
          sx: {
            'tr th, input, label, svg': {
              backgroundColor: green[400] + ' !important',
              color: white,
            },
          },
        },
      },
    },
    shape: {
      borderRadius: 10,
    },
    palette: {
      mode,
      primary: PRIMARY_COLOR,
      secondary: SECONDARY_COLOR,
      success: {
        main: green[700],
      },
      info: { main: blue[700] },
      warning: { main: yellow[500] },
      error: {
        main: red[600],
      },
      background: {
        default: mode === 'light' ? white : black[400],
        secondary: mode === 'light' ? grey[100] : grey[900],
        error: mode === 'light' ? red[50] : red[900],
        success: mode === 'light' ? green[50] : green[900],
      },
    },
    typography: {
      h1: {
        fontSize: '3rem',
        fontWeight: 700,
      },
      h2: {
        fontSize: '2.5rem',
        fontWeight: 500,
      },
      h3: {
        fontSize: '2rem',
        fontWeight: 500,
      },
      h4: {
        fontSize: '1.5rem',
        fontWeight: 500,
      },
      h5: {
        fontSize: '1.25rem',
        fontWeight: 500,
      },
      h6: {
        fontSize: '1rem',
      },
      title: {
        fontSize: '1.5rem',
        fontWeight: 400,
        lineHeight: 1.5,
        letterSpacing: '0.00938em',
      },
    },
  });

export default function CustomTheme({ children }: { children: ReactNode }) {
  const [mode, setMode] = useState<'light' | 'dark'>(
    (localStorage.getItem(THEME_MODE) as 'light' | 'dark') || 'light'
  );

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => {
          const newMode = prevMode === 'light' ? 'dark' : 'light';
          localStorage.setItem(THEME_MODE, newMode);
          return newMode;
        });
      },
      activeMode: mode,
    }),
    [mode]
  );

  const theme = useMemo(createCustomTheme(mode), [mode]);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <SnackbarProvider theme={theme}>
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
      </SnackbarProvider>
    </ColorModeContext.Provider>
  );
}
