export enum UserRole {
  // WARNING:
  // Only add new items. DO NOT DELETE EXISTING.
  // Used as pg column.
  ADMIN = 'ADMIN',
  MEMBER = 'MEMBER',
  JITA_WRITE = 'JITA_WRITE',
  CUSTOM = 'CUSTOM',
}

export interface UserRoleMetadata {
  id: UserRole;
  name: string;
  isInternal?: boolean;
}

export const USER_ROLES_WITH_METADATA: Record<UserRole, UserRoleMetadata> = {
  [UserRole.ADMIN]: {
    id: UserRole.ADMIN,
    name: 'Super Admin',
  },
  [UserRole.MEMBER]: {
    id: UserRole.MEMBER,
    name: 'Member',
  },
  [UserRole.JITA_WRITE]: {
    id: UserRole.JITA_WRITE,
    name: 'Jita Write',
    isInternal: true,
  },
  [UserRole.CUSTOM]: {
    id: UserRole.CUSTOM,
    name: 'Custom Role',
    isInternal: true,
  },
};

export const INTERNAL_ROLES = new Set(
  Object.keys(USER_ROLES_WITH_METADATA).filter(
    (k) => USER_ROLES_WITH_METADATA[k as UserRole]?.isInternal
  )
);
