import styled from '@emotion/styled';
import BackgroundImage from '@images/hero-background.jpg';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { t } from 'i18next';

const HeroContainer = styled(Box)({
  backgroundImage: `url("${BackgroundImage}")`,
  backgroundPosition: 'center center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundOrigin: 'padding-box',
  height: '800px',
  position: 'relative',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',

  '&::before': {
    content: "''",
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    background: 'rgba(0,0,0,0.7)',
    zIndex: 1,
  },
});

const BoxContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  width: '60%',
});

const TagLine = styled(Typography)({
  color: '#fff',
  fontWeight: 500,
  zIndex: 2,
  fontSize: '3rem',
});

const SubTagLine = styled(Typography)(({ theme }) => ({
  color: '#fff',
  fontWeight: 200,
  zIndex: 2,
  fontSize: '1.5rem',
}));

const ContractorLink = styled(Link)({
  color: '#fff',
  zIndex: 2,
  fontSize: '1rem',
  textDecoration: 'none',
  position: 'absolute',
  bottom: '6px',
});

const Hero = ({ onCtaClick }: { onCtaClick?: () => void }) => {
  return (
    <HeroContainer>
      <BoxContainer>
        <TagLine variant="h2" gutterBottom>
          {t('homePage.hero.title')}
        </TagLine>
        <SubTagLine variant="h5" gutterBottom>
          {t('homePage.hero.description')}
        </SubTagLine>
        <Button
          variant="contained"
          size="large"
          sx={{ zIndex: 3, mt: 8 }}
          onClick={onCtaClick}
        >
          {t('homePage.hero.cta')}
        </Button>
        <Button
          variant="text"
          size="small"
          sx={{ zIndex: 3, mt: 2 }}
          href="/login"
        >
          {t('homePage.hero.cta2')}
        </Button>
      </BoxContainer>
    </HeroContainer>
  );
};

export default Hero;
