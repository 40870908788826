import { useSignupAndCreateNewWorkspaceMutation } from '@alamere/generated-graphql-types';
import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded';
import { Alert } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

export default function SignUp() {
  const [params] = useSearchParams();
  const [firstName, setFirstName] = useState(params.get('firstName') || params.get('given-name') || '');
  const [lastName, setLastName] = useState(params.get('lastName') || params.get('family-name') || '');
  const [email, setEmail] = useState(params.get('email') || '');
  const [password, setPassword] = useState('');
  const [workspaceName, setWorkspaceName] = useState(params.get('workspaceName') || params.get('workspace-name') || '');
  const [loading, setLoading] = useState(false);

  const [signup, { error }] = useSignupAndCreateNewWorkspaceMutation();
  const navigate = useNavigate();
  const onSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (e.currentTarget.form && e.currentTarget.form.checkValidity()) {
      e.preventDefault();
      setLoading(true);
      signup({
        variables: {
          createUserRequest: {
            firstName,
            lastName,
            email,
            password,
            workspaceName,
          },
        },
      }).then(() =>
        navigate({
          pathname: '/login',
          search: createSearchParams({ email, password }).toString(),
        })
      ).catch(() => {
        setLoading(false);
      });
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 10,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <AutoAwesomeRoundedIcon />

        <Typography component="h4">Create a new account</Typography>
        <Box sx={{ mt: 1 }}>
          <form>
            <TextField
              margin="normal"
              required
              fullWidth
              id="given-name"
              label="First Name"
              name="given-name"
              autoComplete="given-name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              disabled={loading}
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="family-name"
              label="Last Name"
              name="family-name"
              autoComplete="family-name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              disabled={loading}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled={loading}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              disabled={loading}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="workspace-name"
              label="Workspace Name"
              name="workspace-name"
              placeholder="My Company's Workspace!"
              value={workspaceName}
              onChange={(e) => setWorkspaceName(e.target.value)}
              disabled={loading}
            />
            {error && <Alert severity="error">{error.message}</Alert>}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              size="large"
              sx={{ mt: 3, mb: 2 }}
              onClick={onSubmit}
              disabled={loading}
            >
              Create Account
            </Button>
            <Grid container direction="column" alignItems="center">
              <Grid item>
                <Button onClick={() => navigate('/login')} variant="text">
                  {'Already have an account? Sign In'}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Box>
    </Container>
  );
}
