import {
  BonusStructureGroupFragment,
  BonusStructureGroupFragmentDoc,
  useBonusStructureGroupDeleteMutation,
} from '@alamere/generated-graphql-types';
import { useApolloClient } from '@apollo/client';
import DeleteIcon from '@mui/icons-material/DeleteRounded';
import {
  IconButton,
  InputAdornment,
  OutlinedInput,
  Stack,
  TableCell,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
import { memo, useCallback } from 'react';
import { OnGroupChangeFunction } from './types';

interface Props {
  isHighlighted: boolean;
  editing: boolean;
  group: BonusStructureGroupFragment;
  onGroupChange: OnGroupChangeFunction;
}

export const HeaderCell = memo(
  ({ isHighlighted, editing, group, onGroupChange }: Props) => {
    const { cache } = useApolloClient();
    const [deleteGroup] = useBonusStructureGroupDeleteMutation({
      update(cache, { data }) {
        cache.modify({
          fields: {
            bonusStructureGroups(existingGroups = [], { readField }) {
              return existingGroups.filter(
                (groupRef: any) =>
                  data?.bonusStructureGroupDelete !== readField('id', groupRef)
              );
            },
          },
        });
      },
    });

    const handleGroupDelete = async (id: number) => {
      await deleteGroup({
        variables: { bonusStructureGroupDeleteId: group.id },
      });
    };

    const handleGroupChange = useCallback<OnGroupChangeFunction>(
      (newGroup) => {
        cache.writeFragment({
          id: `BonusStructureGroup:${newGroup.id}`,
          fragmentName: 'BonusStructureGroup',
          fragment: BonusStructureGroupFragmentDoc,
          data: newGroup,
        });
        onGroupChange(newGroup);
      },
      [cache.writeFragment, onGroupChange]
    );

    return (
      <>
        <TableCell
          key={group.id}
          align="center"
          className={isHighlighted ? 'highlighted-column' : ''}
        >
          <Typography
            sx={{ display: editing ? 'none' : 'block' }}
            variant="subtitle1"
          >
            {group.name}
          </Typography>
          <Stack direction="row" sx={{ display: editing ? 'block' : 'none' }}>
            <OutlinedInput
              size="small"
              defaultValue={group.name}
              sx={{ maxWidth: 'fit-content' }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label={t('rangeWidthsPage.deleteGroup')}
                    size="small"
                    title={t('rangeWidthsPage.deleteGroup')}
                    onClick={() => handleGroupDelete(group.id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </InputAdornment>
              }
              onChange={(e) =>
                handleGroupChange({
                  name: e.target.value,
                  id: group.id,
                })
              }
            />
          </Stack>
        </TableCell>
      </>
    );
  }
);
