import {
  BonusStructureGroupFragmentDoc,
  useBonusStructureGroupCreateMutation,
} from '@alamere/generated-graphql-types';
import AddIcon from '@mui/icons-material/AddRounded';
import { IconButton } from '@mui/material';
import { t } from 'i18next';

interface Props {
  editing: boolean;
}

export const NewGroup = ({ editing }: Props) => {
  const [createNewGroup] = useBonusStructureGroupCreateMutation({
    update: (cache, { data }) => {
      const group = data?.bonusStructureGroupCreate;
      if (!group) return;

      cache.modify({
        fields: {
          bonusStructureGroups(existingGroups = []) {
            const newGroupRef = cache.writeFragment({
              id: `BonusStructureGroup:${group.id}`,
              data: group,
              fragment: BonusStructureGroupFragmentDoc,
              fragmentName: 'BonusStructureGroup',
            });
            return [...existingGroups, newGroupRef];
          },
        },
      });
    },
  });
  const handleCreateNewGroup = () => {
    createNewGroup({
      variables: {
        request: {
          name: t('bonusStructuresPage.newGroup'),
        },
      },
    });
  };

  if (!editing) {
    return null;
  }

  return (
    <IconButton
      aria-label={t('bonusStructuresPage.createNewGroup')}
      sx={{ height: 'fit-content' }}
      title={t('bonusStructuresPage.createNewGroup')}
      onClick={handleCreateNewGroup}
    >
      <AddIcon />
    </IconButton>
  );
};
