import {
  AlamereLocationIF,
  AlamereLocationId,
  AlamereLocationWithChildren,
  AlamereLocations,
  LocationType,
  TierConfigParsed,
  TierManager,
} from '@alamere/core';
import ApartmentRoundedIcon from '@mui/icons-material/ApartmentRounded';
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import GiteRoundedIcon from '@mui/icons-material/GiteRounded';
import HouseRoundedIcon from '@mui/icons-material/HouseRounded';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Looks3RoundedIcon from '@mui/icons-material/Looks3Rounded';
import Looks4RoundedIcon from '@mui/icons-material/Looks4Rounded';
import Looks5RoundedIcon from '@mui/icons-material/Looks5Rounded';
import Looks6RoundedIcon from '@mui/icons-material/Looks6Rounded';
import LooksOneRoundedIcon from '@mui/icons-material/LooksOneRounded';
import LooksTwoRoundedIcon from '@mui/icons-material/LooksTwoRounded';
import PlaylistAddRoundedIcon from '@mui/icons-material/PlaylistAddRounded';
import {
  Box,
  Collapse,
  IconButton,
  Input,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { capitalize, debounce } from 'lodash';
import { Fragment, useState } from 'react';
import { TIER_COLORS } from '../../layout/theme';
import { Exclusions } from './Exclusions';
export type ConfigurationProps = {
  tierManager: TierManager;
  onConfigChange: (newTierConfig: TierConfigParsed) => void;
  editing: boolean;
};
const MAX_TIERS = 5;

export default function Configuration({
  onConfigChange,
  editing,
  tierManager,
}: ConfigurationProps) {
  const onDeleteTier = (tierNumber: string) => {
    const newTierConfig = { ...tierManager.getTierConfig() };
    const targetTierNumber =
      tierNumber === Object.keys(tierManager.getTierConfig()).length.toString()
        ? (parseInt(tierNumber) - 1).toString()
        : parseInt(tierNumber) + 1;

    newTierConfig[targetTierNumber].alamereLocationIds.push(
      ...newTierConfig[tierNumber].alamereLocationIds
    );

    delete newTierConfig[tierNumber];

    const newConfigArray = Object.keys(newTierConfig).map((tierNumber) => {
      return newTierConfig[tierNumber];
    });

    const updatedTierConfig = newConfigArray.reduce((acc, tierConfig, i) => {
      acc[i + 1] = tierConfig;
      return acc;
    }, {} as TierConfigParsed);

    onConfigChange(updatedTierConfig);
  };

  const onAddTier = () => {
    const newTierConfig = { ...tierManager.getTierConfig() };
    const newTierNumber = Object.keys(tierManager.getTierConfig()).length + 1;
    newTierConfig[newTierNumber] = {
      percentile: tierManager.getTierConfig()[newTierNumber - 1].percentile,
      alamereLocationIds: [],
      label: `Tier ${newTierNumber}`,
    };
    onConfigChange(newTierConfig);
  };

  const onChangeLocationTier = (
    newTierNumber: string,
    oldTierNumber: string,
    id: AlamereLocationId
  ) => {
    const newConfig = {
      ...tierManager.getTierConfig(),
    };

    const idsToMove = [id].concat(
      tierManager.findChildrenInTier(id, oldTierNumber)
    );

    idsToMove.forEach((id) => {
      // remove from old tier
      newConfig[oldTierNumber].alamereLocationIds.splice(
        newConfig[oldTierNumber].alamereLocationIds.indexOf(id),
        1
      );
      // add to new tier
      newConfig[newTierNumber].alamereLocationIds.push(id);
    });

    onConfigChange(newConfig);
  };

  const renderChangeTierButtons = (
    currentTierNumber: string,
    id: AlamereLocationId
  ) => {
    return Object.keys(tierManager.getTierConfig()).map((tierNumber) => {
      if (!editing || tierNumber === currentTierNumber) return null;
      return (
        <IconButton
          key={tierNumber}
          edge="start"
          onClick={(e) =>
            onChangeLocationTier(tierNumber, currentTierNumber, id)
          }
        >
          {tierNumber === '1' && <LooksOneRoundedIcon />}
          {tierNumber === '2' && <LooksTwoRoundedIcon />}
          {tierNumber === '3' && <Looks3RoundedIcon />}
          {tierNumber === '4' && <Looks4RoundedIcon />}
          {tierNumber === '5' && <Looks5RoundedIcon />}
          {tierNumber === '6' && <Looks6RoundedIcon />}
        </IconButton>
      );
    });
  };

  const renderTier = (tierNumber: string) => {
    const tier = tierManager.getTierConfig()[tierNumber];
    const nestedTier = tierManager.getNestedTier(tierNumber);
    const containsAllOtherLocations =
      tierManager.tierContainsAllOtherLocations(tierNumber);

    const renderLocations = (
      alamereLocationIdsWithChildren: AlamereLocationWithChildren[],
      depth: number = 0
    ) => {
      return alamereLocationIdsWithChildren.map(
        ({ id, parentId, children }) => {
          const [open, setOpen] = useState(true);

          return (
            <Fragment key={id}>
              <ListItem
                sx={{ pl: depth * 2 + 2 }}
                secondaryAction={
                  children.length > 0 &&
                  editing && (
                    <IconButton onClick={() => setOpen(!open)}>
                      {open ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                  )
                }
              >
                <Tooltip title={capitalize(AlamereLocations[id].type)}>
                  <ListItemIcon sx={{ minWidth: 0, mr: 2 }}>
                    {getLocationIcon(AlamereLocations[id].type)}
                  </ListItemIcon>
                </Tooltip>
                <ListItemText
                  primary={
                    <Stack direction="row">
                      {AlamereLocations[id].label}
                      <HelpText
                        location={AlamereLocations[id]}
                        childrenInTier={children}
                        tierManager={tierManager}
                      />
                    </Stack>
                  }
                  sx={{ pr: 4 }}
                />
                {renderChangeTierButtons(tierNumber, id)}
              </ListItem>
              {children.length > 0 && (
                <Collapse in={open && editing} timeout="auto" unmountOnExit>
                  <List disablePadding>
                    {renderLocations(children, depth + 1)}
                  </List>
                </Collapse>
              )}
            </Fragment>
          );
        }
      );
    };

    return (
      <Box key={tierNumber} sx={{ minWidth: 275 }}>
        <Paper
          sx={{
            marginBottom: 1,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'baseline',
            padding: 2,
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {editing ? (
              <TextField
                id={`tier-label-${tierNumber}`}
                variant="standard"
                defaultValue={tier.label}
                onChange={debounce((e) => {
                  const newTierConfig = { ...tierManager.getTierConfig() };
                  newTierConfig[tierNumber] = {
                    ...tier,
                    label: e.target.value,
                  };
                  onConfigChange(newTierConfig);
                }, 300)}
              />
            ) : (
              <Typography display="inline" variant="h5">
                {tier.label || `Tier ${tierNumber}`}
              </Typography>
            )}

            <CircleRoundedIcon
              sx={{
                marginLeft: 1,
                color: containsAllOtherLocations
                  ? TIER_COLORS['allOtherLocations'][600]
                  : TIER_COLORS[tierNumber][600],
                height: 15,
              }}
            />
            {editing && Object.keys(tierManager.getTierConfig()).length > 2 && (
              <IconButton onClick={() => onDeleteTier(tierNumber)}>
                <DeleteRoundedIcon />
              </IconButton>
            )}
          </Box>
          {!editing ? (
            <Typography display="inline">{tier.percentile}%</Typography>
          ) : (
            <Input
              size="small"
              sx={{ maxWidth: 50 }}
              value={tier.percentile}
              endAdornment={<InputAdornment position="end">%</InputAdornment>}
              onChange={(e) => {
                const newTierConfig = { ...tierManager.getTierConfig() };
                newTierConfig[tierNumber] = {
                  ...tier,
                  percentile: parseInt(e.target.value) || 0,
                };
                onConfigChange(newTierConfig);
              }}
            />
          )}
        </Paper>
        <Paper elevation={0} sx={{ marginBottom: 3, minHeight: 70 }}>
          <List>
            {renderLocations(nestedTier.alamereLocationsWithChildren)}
          </List>
        </Paper>
      </Box>
    );
  };

  return (
    <Box sx={{ mt: 3 }}>
      <Stack direction="row" alignItems="start" spacing={1} flexWrap="wrap">
        {Object.keys(tierManager.getTierConfig()).map(renderTier)}

        {editing &&
          Object.keys(tierManager.getTierConfig()).length < MAX_TIERS && (
            <Tooltip title="Add New Tier">
              <IconButton
                color="primary"
                onClick={onAddTier}
                sx={{ alignSelf: 'baseline' }}
              >
                <PlaylistAddRoundedIcon />
              </IconButton>
            </Tooltip>
          )}
      </Stack>
    </Box>
  );
}

function getLocationIcon(locationType?: LocationType) {
  switch (locationType) {
    case LocationType.METRO:
      return <ApartmentRoundedIcon />;
    case LocationType.STATE:
      return <GiteRoundedIcon />;
    default:
      return <HouseRoundedIcon />;
  }
}

function HelpText({
  location,
  childrenInTier,
  tierManager,
}: {
  location: AlamereLocationIF;
  childrenInTier: AlamereLocationWithChildren[];
  tierManager: TierManager;
}) {
  if (!location.parentId) return null;

  const exclusions = tierManager.getExclusions(location.id);
  if (exclusions.length === 0) return null;

  return (
    <Tooltip
      componentsProps={{ tooltip: { sx: { maxWidth: 350 } } }}
      title={<Exclusions id={location.id} tierManager={tierManager} />}
    >
      <IconButton size="small">
        <InfoOutlinedIcon fontSize="small" color="disabled" />
      </IconButton>
    </Tooltip>
  );
}
