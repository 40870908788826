import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  from,
  InMemoryCache,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';

import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { setContext } from '@apollo/client/link/context';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { IconButton } from '@mui/material';
import { closeSnackbar, EnqueueSnackbar, useSnackbar } from 'notistack';
import { WORKSPACE_ID } from '../lib/constants';
import { deleteToken, getToken } from '../providers/auth';
import {env} from '../../environments/env';

const graphqlEndpoint =
  env.env === 'development'
    ? 'http://localhost:3333/graphql'
    : '/graphql'

const cache = new InMemoryCache({
  typePolicies: {
    RangeWidth: {
      keyFields: ['rangeWidthGroupId', 'level'],
    },
    EquityBand: {
      keyFields: ['equityBandGroupId', 'globalLevelLevel'],
    },
    BonusStructure: {
      keyFields: ['bonusStructureGroupId', 'globalLevelLevel'],
    },
    GlobalLevel: {
      keyFields: ['level'],
    },
    Setting: {
      keyFields: ['property', 'type'],
    },
  },
});

const retryLink = new RetryLink({
  delay: {
    initial: 200,
    max: 5000,
    jitter: true,
  },
  attempts: {
    max: 3,
    retryIf: (error, _operation) => {
      return !!error && error.statusCode !== 401;
    },
  },
});

const authLink = setContext((request, { headers }) => {
  const token = getToken();
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const workspaceIdLink = setContext((_, { headers }) => {
  const workspaceId = localStorage.getItem(WORKSPACE_ID);
  return {
    headers: {
      ...headers,

      workspaceid: workspaceId || '',
    },
  };
});

const batchHttpLink = new BatchHttpLink({
  uri: graphqlEndpoint,
  batchMax: 10, // No more than 5 operations per batch
  batchInterval: 10, // Wait no more than 20ms after first batched operation
});

const errorLink = (enqueueSnackbar: EnqueueSnackbar) =>
  onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.map(({ message, path, extensions }) => {
        console.warn(
          `[GraphQL error]: Code: ${extensions.code} Message: ${message},  Path: ${path}`
        );

        if (
          extensions?.code === 'FORBIDDEN' ||
          extensions?.code === 'UNAUTHENTICATED'
        ) {
          if (getToken()) {
            deleteToken();
            window.location.reload();
          }
        } else {
          enqueueSnackbar(message, {
            variant: 'error',
            preventDuplicate: true,
            autoHideDuration: 7000,
            action: (key) => (
              <IconButton
                color="primary"
                aria-label="dismiss notification"
                component="label"
                onClick={() => {
                  closeSnackbar(key);
                }}
              >
                <CloseRoundedIcon />
              </IconButton>
            ),
          });
        }
      });

    if (networkError) {
      console.warn(`[Network error]: ${networkError}`);
    }
  });

export const apolloClient = ({
  enqueueSnackbar,
}: {
  enqueueSnackbar: EnqueueSnackbar;
}) =>
  new ApolloClient({
    connectToDevTools: new URL(window.location.href).host === 'localhost:4200',
    cache: cache,
    link: from([
      errorLink(enqueueSnackbar),
      retryLink,
      authLink,
      workspaceIdLink,
      batchHttpLink,
    ] as ApolloLink[]),
  });

export const WrappedAppolloProvider = ({ children }: { children: any }) => {
  const { enqueueSnackbar } = useSnackbar();
  return (
    <ApolloProvider client={apolloClient({ enqueueSnackbar })}>
      {children}
    </ApolloProvider>
  );
};
