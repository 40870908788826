import { Scope, canAccessAllScopes, isBlessedUser } from '@alamere/core';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../providers/auth';

export function useScopes(): {
  checkScopes: (...scopes: Scope[]) => boolean;
} {
  const { user, workspace, workspaceMembership, loading } = useAuth();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const checkScopes = (...scopes: Scope[]) => {
    if (user && isBlessedUser(user.email)) return true;
    if (loading || !workspace || !workspaceMembership) return false;
    return canAccessAllScopes(scopes, workspaceMembership);
  };

  return { checkScopes };
}
