import {
  JobFamilyFragment,
  JobFamilyFragmentDoc,
  JobFamilySaveRequest,
} from '@alamere/generated-graphql-types';
import { useApolloClient } from '@apollo/client';
import { OutlinedInput, TableCell, Typography } from '@mui/material';
import { debounce } from 'lodash';
import { memo, useRef } from 'react';
import { DEBOUNCE_MS } from '../../../lib/constants';
import { OnItemChangeFunction } from '../types';

interface Props {
  editing: boolean;
  jobFamily: JobFamilyFragment;
  onItemChange: OnItemChangeFunction;
}
export const Code = memo(({ editing, jobFamily, onItemChange }: Props) => {
  const debouncedOnItemChange = debounce(onItemChange, DEBOUNCE_MS);
  const { cache } = useApolloClient();
  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = (value: string) => {
    const data = { ...jobFamily, code: value };

    cache.writeFragment<JobFamilySaveRequest>({
      id: `JobFamily:${jobFamily.id}`,
      fragmentName: 'JobFamily',
      fragment: JobFamilyFragmentDoc,
      data,
    });

    debouncedOnItemChange({ newItem: data });
  };

  return (
    <TableCell>
      <OutlinedInput
        value={jobFamily.code || ''}
        sx={{ display: editing ? undefined : 'none' }}
        size="small"
        fullWidth
        onChange={(e) => handleChange(e.target.value)}
        inputRef={inputRef}
      />
      <Typography sx={{ display: !editing ? undefined : 'none' }}>
        {jobFamily?.code}
      </Typography>
    </TableCell>
  );
});
