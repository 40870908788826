import { PermissionSets, ScopesByGroup, UserRoleMetadata } from '@alamere/core';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Alert, Box, IconButton, Stack, Typography } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { t } from 'i18next';

export interface DefaultRoleDetailProps {
  role: UserRoleMetadata;
  onCancel: () => void;
}

export function DefaultRoleDetail({ role, onCancel }: DefaultRoleDetailProps) {
  const roleScopes = PermissionSets[role.id];

  return (
    <Box sx={{ p: 4 }}>
      <Stack gap={4}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="title" mr={4}>
            {role.name}
          </Typography>
          <IconButton onClick={onCancel}>
            <CloseRoundedIcon />
          </IconButton>
        </Stack>
        <Alert severity="info">
          This is a default role and is not editable
        </Alert>
        {Object.keys(ScopesByGroup).map((groupName) => (
          <Box key={t(groupName)}>
            <Stack gap={1}>
              <Typography variant="title">{t(groupName)}</Typography>
              {ScopesByGroup[groupName].map((scope) => (
                <FormControlLabel
                  key={scope.id}
                  sx={{
                    '& .MuiFormControlLabel-label.Mui-disabled': {
                      color: '#c5c5c5',
                    },
                  }}
                  control={
                    <Switch disabled checked={roleScopes.has(scope.id)} />
                  }
                  label={t(scope.label)}
                />
              ))}
            </Stack>
          </Box>
        ))}
      </Stack>
    </Box>
  );
}
