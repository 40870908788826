import { Box, CircularProgress } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../providers/auth';

export function LoadingPage() {
  const { user, workspace } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (workspace && user?.hasVerifiedEmail) {
      navigate(`/w/${workspace.id}/location-lookup`, { replace: true });
    }
  }, [workspace]);

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress color="success" />
    </Box>
  );
}
