import {
  EquityBandFragment,
  EquityBandFragmentDoc,
  EquityBandGroupFragment,
  GlobalLevelFragment,
  SettingProperty,
  SettingType,
  useRangeWidthGroupsQuery,
} from '@alamere/generated-graphql-types';
import { useFragment } from '@apollo/client';
import ArrowDropUpRoundedIcon from '@mui/icons-material/ArrowDropUpRounded';
import {
  Chip,
  OutlinedInput,
  Skeleton,
  Stack,
  TableCell,
  Typography,
} from '@mui/material';
import { isEqual } from 'lodash';
import { memo, useEffect, useMemo, useState } from 'react';
import { OnBandChangeFunction } from './types';
export interface EquityBandsCellsProps {
  globalLevel: GlobalLevelFragment;
  previousGlobalLevel?: GlobalLevelFragment;
  group: EquityBandGroupFragment;
  editing: boolean;
  loading: boolean;
  isHighlighted: boolean;
  onBandChange: OnBandChangeFunction;
}

export default memo(function EquityBandsCells({
  globalLevel,
  previousGlobalLevel,
  group,
  editing,
  loading,
  isHighlighted,
  onBandChange,
}: EquityBandsCellsProps) {
  const { value: showMinMax } = useSetting(
    SettingProperty.EQUITY_BANDS_PAGE_SHOW_MIN_MAX,
    SettingType.WORKSPACE_USER
  );
  const { value: showDeltas } = useSetting(
    SettingProperty.EQUITY_BANDS_PAGE_SHOW_DELTA_FROM_PREVIOUS_BAND,
    SettingType.WORKSPACE_USER
  );
  const { value: showAlternateUnit } = useSetting(
    SettingProperty.EQUITY_BANDS_PAGE_SHOW_ALTERNATE_EQUITY_UNIT,
    SettingType.WORKSPACE_USER
  );
  const { value: stockPrice } = useSetting(
    SettingProperty.PREFERRED_STOCK_PRICE,
    SettingType.WORKSPACE
  );
  const { value: strikePrice } = useSetting(
    SettingProperty.STRIKE_PRICE,
    SettingType.WORKSPACE
  );

  const { data: rangeWidthGroupsData, loading: loadingRangeWidth } =
    useRangeWidthGroupsQuery();

  const rangeWidth = useMemo(() => {
    return rangeWidthGroupsData?.rangeWidthGroups
      .find((grp) => grp.id === group.rangeWidthGroupId)
      ?.rangeWidths.find((rw) => rw.level === globalLevel.level);
  }, [rangeWidthGroupsData, globalLevel, group]);

  const equityBandFromProps = useMemo(() => {
    return group.equityBands.find(
      (eb) => eb.globalLevelLevel === globalLevel.level
    );
  }, [group, globalLevel]);

  const [equityBand, setEquityBand] = useState(equityBandFromProps);

  const { data: parentBand } = useFragment<EquityBandFragment>({
    fragment: EquityBandFragmentDoc,
    fragmentName: 'EquityBand',
    from: {
      equityBandGroupId: group.dynamicParentGroupId,
      globalLevelLevel: globalLevel?.level,
      __typename: 'EquityBand',
    },
  });

  const { data: previousBand } = useFragment<EquityBandFragment>({
    fragment: EquityBandFragmentDoc,
    fragmentName: 'EquityBand',
    from: {
      equityBandGroupId: group.id,
      globalLevelLevel: previousGlobalLevel?.level,
      __typename: 'EquityBand',
    },
  });

  const doUpdate = (newEquityBand: EquityBandFragment) => {
    setEquityBand(newEquityBand);
    onBandChange({
      id: newEquityBand.id,
      value: newEquityBand.value,
      globalLevelLevel: newEquityBand.globalLevelLevel,
      equityBandGroupId: newEquityBand.equityBandGroupId,
      dynamicPercentOverride: newEquityBand.dynamicPercentOverride,
      jobLevelId: newEquityBand.jobLevelId || null,
    });
  };

  const handleValueChange = (value: string) => {
    if (!equityBand) {
      return;
    }
    const newEquityBand = {
      ...equityBand,
      value: Number(value) || 0,
    };

    doUpdate(newEquityBand);
  };

  useEffect(() => {
    // Update from cache changes
    if (equityBandFromProps && !isEqual(equityBandFromProps, equityBand)) {
      doUpdate(equityBandFromProps);
    }
  }, [equityBandFromProps]);

  useEffect(() => {
    // Update from parent changes
    if (editing && parentBand.value && equityBand) {
      const newEquityBand = {
        ...equityBand,
        value: parentBand.value * ((group.dynamicPercent || 0) / 100),
      };
      doUpdate(newEquityBand);
    }
  }, [parentBand]);

  const mid = equityBand?.value || 0;
  const percentBelow = rangeWidth?.percentBelowMid;
  const percentAbove = rangeWidth?.percentAboveMid;
  const min = percentBelow ? (mid * (100 - percentBelow)) / 100 : 0;
  const max = percentAbove ? (mid * (100 + percentAbove)) / 100 : 0;
  const delta = previousBand?.value
    ? ((mid - previousBand.value) / mid) * 100
    : 0;
  const numSharesMid = getNumShares({
    equityValue: mid,
    stockPrice,
    strikePrice,
    percentRsu: globalLevel.percentRsu,
  });
  const numSharesMax = getNumShares({
    equityValue: max,
    stockPrice,
    strikePrice,
    percentRsu: globalLevel.percentRsu,
  });
  const numSharesMin = getNumShares({
    equityValue: min,
    stockPrice,
    strikePrice,
    percentRsu: globalLevel.percentRsu,
  });

  return (
    <>
      {showMinMax && (
        <TableCell
          align="center"
          className={isHighlighted ? 'highlighted-column' : ''}
        >
          {loadingRangeWidth ? (
            <Skeleton />
          ) : (
            <Typography>
              {' '}
              {min
                ? min.toLocaleString(undefined, { maximumFractionDigits: 0 })
                : '-'}{' '}
            </Typography>
          )}
          {showAlternateUnit && !!numSharesMin && (
            <NumSharesCaption numShares={numSharesMax} />
          )}
        </TableCell>
      )}
      <TableCell
        align="center"
        className={isHighlighted ? 'highlighted-column' : ''}
      >
        <OutlinedInput
          value={mid.toLocaleString(undefined, { maximumFractionDigits: 0 })}
          sx={{ display: editing ? undefined : 'none' }}
          inputProps={{ style: { textAlign: 'right' } }}
          size="small"
          fullWidth
          // startAdornment={<InputAdornment position="start">$</InputAdornment>}
          onChange={(e) =>
            handleValueChange(e.target.value.split(',').join(''))
          }
        />
        {!editing &&
          (loading ? (
            <Skeleton />
          ) : (
            <Typography>
              {mid.toLocaleString(undefined, { maximumFractionDigits: 0 })}
            </Typography>
          ))}
        {showAlternateUnit && !!numSharesMid && (
          <NumSharesCaption numShares={numSharesMax} />
        )}
      </TableCell>
      {showMinMax && (
        <TableCell
          align="center"
          className={isHighlighted ? 'highlighted-column' : ''}
        >
          {loadingRangeWidth ? (
            <Skeleton />
          ) : (
            <Typography>
              {max
                ? max.toLocaleString(undefined, { maximumFractionDigits: 0 })
                : '-'}
            </Typography>
          )}
          {showAlternateUnit && !!numSharesMax && (
            <NumSharesCaption numShares={numSharesMax} />
          )}
        </TableCell>
      )}
      {showDeltas && (
        <TableCell
          align="center"
          className={isHighlighted ? 'highlighted-column' : ''}
          sx={{ flexDirection: 'row' }}
        >
          {delta ? (
            <Chip
              icon={
                delta > 0 ? (
                  <ArrowDropUpRoundedIcon color="success" />
                ) : delta < 0 ? (
                  <ArrowDropDownRoundedIcon color="error" />
                ) : undefined
              }
              label={
                delta.toLocaleString(undefined, { maximumFractionDigits: 0 }) +
                '%'
              }
              variant="outlined"
              size="small"
            />
          ) : (
            '-'
          )}
        </TableCell>
      )}
    </>
  );
});

function NumSharesCaption({ numShares }: { numShares: NumSharesResult }) {
  if (!numShares) return <Typography variant="caption">-</Typography>;

  const showUnits = numShares.options > 0 && numShares.rsus > 0;
  return (
    <Stack>
      {numShares.rsus > 0 && (
        <Typography variant="caption" color="text.secondary">
          {numShares.rsus.toLocaleString(undefined, {
            maximumFractionDigits: 0,
          })}
          {showUnits && ' ' + t('global.rsu')}
        </Typography>
      )}
      {numShares.options > 0 && (
        <Typography variant="caption" color="text.secondary">
          {numShares.options.toLocaleString(undefined, {
            maximumFractionDigits: 0,
          })}
          {showUnits && ' ' + t('global.optionsAbbreviated')}
        </Typography>
      )}
    </Stack>
  );

  // {numShares.options > 0 && numShares.rsus > 0 && }
}

import { NumSharesResult, getNumShares } from '@alamere/core';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import { t } from 'i18next';
import { useSetting } from '../../../hooks/useSetting';
