import Feature1 from '@images/feature1.png';
import Feature2 from '@images/feature2.png';
import Feature3 from '@images/feature3.png';
import { Paper, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { t } from 'i18next';
import React, { SyntheticEvent, useState } from 'react';

interface LinkTabProps {
  label: string;
  id: string;
  'aria-controls': string;
}

interface ItemType {
  label: string;
  title: string;
  description: string;
  image: string;
}

const ITEMS: ItemType[] = [
  {
    label: 'homePage.features.label1',
    title: 'homePage.features.title1',
    description: 'homePage.features.description1',
    image: Feature1,
  },
  {
    label: 'homePage.features.label2',
    title: 'homePage.features.title2',
    description: 'homePage.features.description2',
    image: Feature2,
  },
  {
    label: 'homePage.features.label3',
    title: 'homePage.features.title3',
    description: 'homePage.features.description3',
    image: Feature3,
  },
];

const LinkTab = (props: LinkTabProps) => {
  const { label, ...rest } = props;
  return (
    <Tab
      component="a"
      onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
      }}
      disableRipple={true}
      sx={{
        justifyContent: 'flex-start',
        textAlign: 'left',
        paddingLeft: 3,
        minHeight: '44px',
        borderLeft: `1px solid #BEC3CA`,
        marginLeft: '6px',
        overflow: 'visible',
        fontWeight: 600,
        color: '#BEC3CA',

        '&:before': {
          content: "''",
          position: 'absolute',
          left: '-1px',
          top: 0,
          width: '14px',
          borderRadius: '50%',
          height: '14px',
          border: '1px solid #BEC3CA',
          transform: 'translate(-6px, 1em)',
          backgroundColor: '#fff',
        },
        '&.Mui-selected': {
          backgroundColor: '#fff',
          color: '#000',
        },
      }}
      iconPosition="start"
      label={t(props.label)}
      {...rest}
    />
  );
};

interface TabPanelProps {
  index: number;
  label: string;
  title: string;
  description: string;
  image: string;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { index, value, title, description } = props;
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
    >
      <Typography variant="h5" sx={{ mb: 2 }}>
        {t(title)}
      </Typography>
      <Typography>{t(description)}</Typography>
    </Box>
  );
};

interface TabImageProps {
  src: string;
  index: number;
  value: number;
}

const TabImage = (props: TabImageProps) => {
  const { value, index, src } = props;

  return (
    <Paper
      elevation={5}
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      sx={{
        width: 'auto',
        height: 340,
        backgroundImage: `url(${src})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
    />
  );
};

const a11yProps = (index: number) => {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
};

export default function Features() {
  const [value, setValue] = useState(0);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Grid container>
      <Grid item xs={12} md={6} sx={{ flexDirection: 'column', mb: 4 }}>
        <Typography variant="h4" fontWeight={500} sx={{ mb: 8 }}>
          {t('homePage.features.title')}
        </Typography>
        <Box sx={{ display: 'flex', gap: '5%' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="Solutions"
            orientation="vertical"
            TabIndicatorProps={{
              style: {
                position: 'absolute',
                left: '1px',
                width: '12px',
                borderRadius: '50%',
                transform: 'translate(0, calc(1em - 1px))',
                height: '12px',
                backgroundColor: '#BEC3CA',
              },
            }}
          >
            {ITEMS.map((item, index) => (
              <LinkTab key={index} {...item} {...a11yProps(index)} />
            ))}
          </Tabs>
          <Box sx={{ flexBasis: '55%' }}>
            {ITEMS.map((item, index) => (
              <TabPanel key={index} index={index} {...item} value={value} />
            ))}
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        {ITEMS.map((item, index) => (
          <TabImage key={index} index={index} value={value} src={item.image} />
        ))}
      </Grid>
    </Grid>
  );
}
