import {
  JobFamilyFragment,
  JobFamilyFragmentDoc,
  JobFamilySaveRequest,
  JobFunctionFragment,
  useJobFunctionSaveMutation,
  useJobFunctionsQuery,
} from '@alamere/generated-graphql-types';
import { useApolloClient } from '@apollo/client';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import {
  Autocomplete,
  List,
  Paper,
  TableCell,
  TextField,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
import { memo, useEffect, useState } from 'react';
import { OnItemChangeFunction } from '../types';

interface Props {
  editing: boolean;
  jobFamily: JobFamilyFragment;
  onItemChange: OnItemChangeFunction;
}

export const JobFunction = memo(
  ({ editing, jobFamily, onItemChange }: Props) => {
    const { cache } = useApolloClient();
    const [value, setValue] = useState<JobFunctionFragment | null | undefined>(
      jobFamily.jobFunction
    );
    const [open, setOpen] = useState(false);
    const [inputValue, setInputValue] = useState('');

    const { data: jobFunctionData, loading, refetch } = useJobFunctionsQuery();
    const [saveJobFunction, { loading: saving }] = useJobFunctionSaveMutation();

    useEffect(() => {
      setValue(jobFamily.jobFunction);
    }, [jobFamily]);

    const handleChange = (value?: JobFunctionFragment | null) => {
      setValue(value);
      const data = {
        ...jobFamily,
        jobFunction: value,
      };
      cache.writeFragment<JobFamilySaveRequest>({
        id: `JobFamily:${jobFamily.id}`,
        fragmentName: 'JobFamily',
        fragment: JobFamilyFragmentDoc,
        data,
      });
      onItemChange({ newItem: data });
    };

    const handleCreateJobFunction = async () => {
      const newJobFunction = await saveJobFunction({
        variables: {
          request: {
            name: inputValue,
          },
        },
      });
      await refetch();
      handleChange(newJobFunction.data?.jobFunctionSave);
      setOpen(false);
    };

    const showCreateButton =
      inputValue !== '' &&
      !jobFunctionData?.jobFunctions.some(
        (jobFunction) => jobFunction.name === inputValue
      );

    return (
      <TableCell width="fit-content">
        <Autocomplete
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          autoComplete
          blurOnSelect
          value={value}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          size="small"
          id="job-function-autocomplete"
          options={jobFunctionData?.jobFunctions ?? []}
          getOptionLabel={(option) => option.name}
          onChange={(e, v) => handleChange(v)}
          onInputChange={(e, value) => setInputValue(value)}
          sx={{ minWidth: 200, display: editing ? undefined : 'none' }}
          isOptionEqualToValue={(option, value) => option.id === value?.id}
          PaperComponent={({ children }) => (
            <Paper>
              <List>{children}</List>
              {showCreateButton && (
                <LoadingButton
                  loading={saving || loading}
                  fullWidth
                  variant="text"
                  sx={{ textTransform: 'none' }}
                  onMouseDown={handleCreateJobFunction}
                  onTouchStart={handleCreateJobFunction}
                >
                  {`${t(
                    'jobFamiliesPage.table.createJobFunction'
                  )} "${inputValue}"`}
                </LoadingButton>
              )}
            </Paper>
          )}
          renderInput={(params) => <TextField {...params} />}
        />
        <Typography sx={{ display: editing ? 'none' : undefined }}>
          {jobFamily?.jobFunction?.name}
        </Typography>
      </TableCell>
    );
  }
);
