import { Autocomplete, SxProps, TextField } from '@mui/material';
import { t } from 'i18next';
import { orderBy } from 'lodash';
import { useEffect, useState } from 'react';
import {
  JobFamilyFragment,
  useJobFamiliesQuery,
} from '../graphql/_generated/generated-graphql-types';
import { CHACHED_PICKER_JOB_FAMILY_ID } from '../lib/constants';

export interface Props {
  onChange: (jobFamily: JobFamilyFragment | null) => void;
  sx?: SxProps;
}

export default function JobFamilyPicker({ onChange, sx }: Props) {
  const [value, setValue] = useState<JobFamilyFragment | null>(null);
  const { data, loading } = useJobFamiliesQuery();

  useEffect(() => {
    if (!data?.jobFamilies) {
      return;
    }

    const defaultId = localStorage.getItem(CHACHED_PICKER_JOB_FAMILY_ID);
    const defaultFamily =
      defaultId && data?.jobFamilies.find((j) => j.id === parseInt(defaultId))
      || data.jobFamilies[0];

    if (defaultFamily) {
      handleChange(defaultFamily);
      return;
    }
  }, [data]);

  const handleChange = (v: JobFamilyFragment | null) => {
    v
      ? localStorage.setItem(CHACHED_PICKER_JOB_FAMILY_ID, v?.id.toString())
      : localStorage.removeItem(CHACHED_PICKER_JOB_FAMILY_ID);

    setValue(v);
    onChange(v);
  };
  const options = orderBy(data?.jobFamilies ?? [], ['name'], ['asc']);

  return (
    <Autocomplete
      sx={sx}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      autoComplete
      blurOnSelect
      value={value}
      size="small"
      options={options}
      getOptionLabel={(option) => option.name}
      noOptionsText={loading ? t('global.loading') : t('global.noOptions')}
      onChange={(e, v) => handleChange(v)}
      isOptionEqualToValue={(option, value) => option.id === value?.id}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.id}>
            {option.name}
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField
          label={
            value
              ? t('components.JobFamilyPicker.inputLabelSelected')
              : t('components.JobFamilyPicker.inputLabel')
          }
          {...params}
        />
      )}
    />
  );
}
