import { JobGroup } from '@alamere/core';
import {
  JobFamilyFragment,
  JobFamilyFragmentDoc,
  RadfordJobFamilyFragment,
  useRadfordJobFamiliesSearchLazyQuery,
} from '@alamere/generated-graphql-types';
import { useApolloClient } from '@apollo/client';
import {
  Autocomplete,
  Box,
  Card,
  CardContent,
  Skeleton,
  TableCell,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
import { debounce } from 'lodash';
import { memo, useCallback, useEffect, useState } from 'react';
import { OnItemChangeFunction } from '../types';

interface Props {
  loading: boolean;
  editing: boolean;
  jobFamily: JobFamilyFragment;
  onItemChange: OnItemChangeFunction;
  families: RadfordJobFamilyFragment[];
  jobGroup: JobGroup;
}

export const RadfordMatch = memo(
  ({
    editing,
    loading,
    jobFamily,
    jobGroup,
    families,
    onItemChange,
  }: Props) => {
    const { cache } = useApolloClient();

    const [value, setValue] = useState<RadfordJobFamilyFragment | null>(
      getRadfordMatch(jobFamily, jobGroup, families)
    );
    const [open, setOpen] = useState(false);
    const [inputValue, setInputValue] = useState('');

    const [
      searchUnDebounced,
      { data: familiesData, loading: loadingFamilies },
    ] = useRadfordJobFamiliesSearchLazyQuery();

    const search = useCallback(debounce(searchUnDebounced, 500), [
      searchUnDebounced,
    ]);

    useEffect(() => {
      setValue(getRadfordMatch(jobFamily, jobGroup, families));
    }, [jobFamily, jobGroup, families]);

    useEffect(() => {
      if (inputValue.length > 1) {
        search({
          variables: {
            request: { query: inputValue, jobGroup },
          },
        });
      }
    }, [inputValue]);

    const handleChange = (value: RadfordJobFamilyFragment | null) => {
      setValue(value);

      const data = {
        ...jobFamily,
        ...toJobFamilyProperty(value?.code, jobGroup),
      };

      cache.writeFragment<JobFamilyFragment>({
        id: `JobFamily:${jobFamily.id}`,
        fragmentName: 'JobFamily',
        fragment: JobFamilyFragmentDoc,
        data,
      });

      onItemChange({
        newItem: data,
      });
    };

    if (loading) {
      return (
        <TableCell>
          <Skeleton />
        </TableCell>
      );
    }

    return (
      <TableCell>
        <Autocomplete
          options={
            inputValue.length > 1
              ? familiesData?.radfordJobFamiliesSearch ?? []
              : []
          }
          selectOnFocus
          handleHomeEndKeys
          autoComplete
          blurOnSelect
          value={value ?? null}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => {
            setOpen(false);
            setInputValue('');
          }}
          noOptionsText={
            inputValue.length > 1
              ? loadingFamilies
                ? t('jobFamiliesPage.table.radfordMatch.loading')
                : t('jobFamiliesPage.table.radfordMatch.noResults')
              : t('jobFamiliesPage.table.radfordMatch.searchForResults')
          }
          size="small"
          id="equity-band-group-autocomplete"
          onChange={(e, v) => handleChange(v)}
          onInputChange={(e, v) => {
            setInputValue(v);
          }}
          sx={{ minWidth: 200, display: editing ? undefined : 'none' }}
          isOptionEqualToValue={(option, value) => option.code === value?.code}
          renderInput={(params) => (
            <TextField
              title={value ? [value.code, value.label].join(' - ') : undefined}
              {...params}
            />
          )}
          getOptionLabel={(option) => [option.code, option.label].join(' - ')}
          renderOption={(props, option, ownerState) => (
            <Tooltip
              componentsProps={{
                tooltip: {
                  sx: {
                    background: 'none',
                  },
                },
              }}
              placement="right-start"
              title={<TooltipCard option={option} />}
            >
              <Box component="li" {...props}>
                {[option.code, option.label].join(' - ')}
              </Box>
            </Tooltip>
          )}
        />
        <Typography
          sx={{
            display: editing ? 'none' : undefined,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
          title={value?.label}
        >
          {value && [value?.code, value?.label].join(' - ')}
        </Typography>
      </TableCell>
    );
  }
);

function getMatch(
  jobFamily: JobFamilyFragment,
  jobGroup: JobGroup
): {
  code: string | null | undefined;
  value: {
    radfordExecutiveMatchCode?: string | null | undefined;
    radfordManagementMatchCode?: string | null | undefined;
    radfordProfessionalMatchCode?: string | null | undefined;
    radfordScientificMatchCode?: string | null | undefined;
    radfordSupportMatchCode?: string | null | undefined;
  };
} {
  switch (jobGroup) {
    case JobGroup.Executive:
      return {
        code: jobFamily.radfordExecutiveMatchCode,
        value: toJobFamilyProperty(
          jobFamily.radfordExecutiveMatchCode,
          jobGroup
        ),
      };
    case JobGroup.Management:
      return {
        code: jobFamily.radfordManagementMatchCode,
        value: toJobFamilyProperty(
          jobFamily.radfordManagementMatchCode,
          jobGroup
        ),
      };
    case JobGroup.Professional:
      return {
        code: jobFamily.radfordProfessionalMatchCode,
        value: toJobFamilyProperty(
          jobFamily.radfordProfessionalMatchCode,
          jobGroup
        ),
      };
    case JobGroup.TechnicalScientific:
      return {
        code: jobFamily.radfordScientificMatchCode,
        value: toJobFamilyProperty(
          jobFamily.radfordScientificMatchCode,
          jobGroup
        ),
      };
    case JobGroup.Support:
      return {
        code: jobFamily.radfordSupportMatchCode,
        value: toJobFamilyProperty(jobFamily.radfordSupportMatchCode, jobGroup),
      };
    default:
      throw new Error(`Unknown job group: ${jobGroup}`);
  }
}

function toJobFamilyProperty(
  code: string | undefined | null,
  jobGroup: JobGroup
) {
  switch (jobGroup) {
    case JobGroup.Executive:
      return {
        radfordExecutiveMatchCode: code ?? null,
      };
    case JobGroup.Management:
      return {
        radfordManagementMatchCode: code ?? null,
      };
    case JobGroup.Professional:
      return {
        radfordProfessionalMatchCode: code ?? null,
      };
    case JobGroup.TechnicalScientific:
      return {
        radfordScientificMatchCode: code ?? null,
      };
    case JobGroup.Support:
      return {
        radfordSupportMatchCode: code ?? null,
      };
    default:
      throw new Error(`Unknown job group: ${jobGroup}`);
  }
}

function getRadfordMatch(
  jobFamily: JobFamilyFragment,
  jobGroup: JobGroup,
  radfordJobFamilies?: RadfordJobFamilyFragment[]
): RadfordJobFamilyFragment | null {
  const match = getMatch(jobFamily, jobGroup);
  if (!match.code || !radfordJobFamilies) {
    return null;
  }
  return (
    radfordJobFamilies.find(
      (r) => r.code === match.code && r.jobGroup === jobGroup
    ) ?? null
  );
}

function TooltipCard({ option }: { option: RadfordJobFamilyFragment }) {
  return (
    <Card
      sx={{
        minWidth: 275,
        maxHeight: 400,
        overflow: 'scroll',
      }}
    >
      <CardContent>
        <Typography variant="h6" color="text.secondary">
          {option.code}
        </Typography>
        <Typography variant="h5">{option.area}</Typography>
        <Typography variant="h5">{option.focus}</Typography>
        <Typography sx={{ my: 1 }} color="text.secondary" variant="body2">
          {option.detailedDefinition}
        </Typography>
      </CardContent>
    </Card>
  );
}
