import { EquityValueType, SettingProperty, SettingType } from '@alamere/core';
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  InputAdornment,
  OutlinedInput,
  Paper,
  Stack,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { t } from 'i18next';
import { useSetting } from '../../../hooks/useSetting';

export interface EquityBandsSettingsProps {
  editing: boolean;
}

export default function EquityBandsSettings({
  editing,
}: EquityBandsSettingsProps) {
  const { value: showAlternateEquityUnitSetting, save: saveAlternateUnit } =
    useSetting(
      SettingProperty.EQUITY_BANDS_PAGE_SHOW_ALTERNATE_EQUITY_UNIT,
      SettingType.WORKSPACE_USER
    );
  const { value: showMinMax, save: saveMinMax } = useSetting(
    SettingProperty.EQUITY_BANDS_PAGE_SHOW_MIN_MAX,
    SettingType.WORKSPACE_USER
  );
  const { value: showDeltas, save: saveShowDelta } = useSetting(
    SettingProperty.EQUITY_BANDS_PAGE_SHOW_DELTA_FROM_PREVIOUS_BAND,
    SettingType.WORKSPACE_USER
  );
  const { value: preferredStockPriceValue, update: updateStockPrice } =
    useSetting(SettingProperty.PREFERRED_STOCK_PRICE, SettingType.WORKSPACE);

  const { value: strikePrice, update: updateStrikePrice } = useSetting(
    SettingProperty.STRIKE_PRICE,
    SettingType.WORKSPACE
  );

  return (
    <Stack spacing={2}>
      <Paper sx={{ p: 2, width: 'fit-content' }}>
        <FormGroup row sx={{ gap: 2, alignItems: 'flex-end' }}>
          <FormControl disabled={!editing}>
            <FormLabel>{t('global.preferredStockPrice')}</FormLabel>

            <OutlinedInput
              sx={{ maxWidth: 200 }}
              value={preferredStockPriceValue}
              onChange={({ target: { value } }) => {
                updateStockPrice(value);
              }}
              startAdornment={
                <InputAdornment position="start">$</InputAdornment>
              }
              size="small"
              type="number"
            />
          </FormControl>
          <FormControl disabled={!editing}>
            <FormLabel>{t('global.strikePrice')}</FormLabel>

            <OutlinedInput
              sx={{ maxWidth: 200 }}
              value={strikePrice}
              onChange={({ target: { value } }) => {
                updateStrikePrice(value);
              }}
              startAdornment={
                <InputAdornment position="start">$</InputAdornment>
              }
              size="small"
              type="number"
            />
          </FormControl>
          <FormControl disabled={!editing}>
            <FormLabel>{t('global.stockUnits')}</FormLabel>
            <ToggleButtonGroup
              disabled={!editing}
              color="primary"
              value={EquityValueType.USD}
              exclusive
              // onChange={handleChange}
              aria-label={t('global.stockUnits')}
              size="small"
            >
              <ToggleButton value={EquityValueType.USD}>
                {t('global.USD')}
              </ToggleButton>
              <ToggleButton value={EquityValueType.NUM_SHARES}>
                {t('global.numShares')}
              </ToggleButton>
            </ToggleButtonGroup>
          </FormControl>
        </FormGroup>
      </Paper>
      <Paper sx={{ px: 2, py: 1, width: 'fit-content' }}>
        <FormGroup row sx={{ gap: 2 }}>
          <FormControlLabel
            control={
              <Switch
                checked={showAlternateEquityUnitSetting}
                onChange={({ target: { checked } }) => {
                  saveAlternateUnit(JSON.stringify(checked));
                }}
              />
            }
            label={t('equityPage.settings.showAlternateUnit')}
          />
          <FormControlLabel
            control={
              <Switch
                checked={showMinMax}
                onChange={({ target: { checked } }) => {
                  saveMinMax(JSON.stringify(checked));
                }}
              />
            }
            label={t('equityPage.settings.showMinMax')}
          />
          <FormControlLabel
            control={
              <Switch
                checked={showDeltas}
                onChange={({ target: { checked } }) => {
                  saveShowDelta(JSON.stringify(checked));
                }}
              />
            }
            label={t('equityPage.settings.showDelta')}
          />
        </FormGroup>
      </Paper>
    </Stack>
  );
}
