import { Scope } from '@alamere/core';
import { Flag } from '../../../../core/src/lib/flags';
import { useScopes } from '../hooks/useScopes';
import NoAccessPage from '../pages/auth/NoAccessPage.page';
import { useIsUngated } from '../vendor/flagsmith';

export default function CheckAccess({
  children,
  requiredScopes = [],
  requiredFlags = [],
}: {
  children: React.ReactNode;
  requiredScopes?: Scope[];
  requiredFlags?: Flag[];
}) {
  const isUngated = useIsUngated(...requiredFlags);

  const { checkScopes } = useScopes();

  if (!checkScopes(...requiredScopes) || !isUngated) {
    return <NoAccessPage />;
  }
  return <>{children}</>;
}
