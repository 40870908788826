import { INTERNAL_ROLES, USER_ROLES_WITH_METADATA } from '@alamere/core';
import {
  UserRole,
  useGetCustomRolesQuery,
} from '@alamere/generated-graphql-types';
import {
  FormControl,
  FormControlProps,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from '@mui/material';
import { useMemo } from 'react';

export interface RolePickerProps {
  onChange?: (role: CustomizableRole) => void;
  disabled?: boolean;
  value: CustomizableRole;
  SelectProps?: SelectProps;
  FormControlProps?: FormControlProps;
}

export interface CustomizableRole {
  userRole: UserRole;
  customRole?: { id: number; name: string } | null;
}

export function RolePicker({
  onChange = () => {},
  disabled,
  value,
  SelectProps = {},
  FormControlProps = {},
}: RolePickerProps) {
  const { data, loading } = useGetCustomRolesQuery();

  const allRoles = useMemo(() => {
    const defaultroles = Object.values(UserRole)
      .filter((role) => !INTERNAL_ROLES.has(role)) // don't show internal roles
      .map<CustomizableRole>((role) => {
        return {
          userRole: role,
          customRole: undefined,
        };
      });
    const customRoles =
      data?.getCustomRoles?.map<CustomizableRole>((customRole) => {
        return {
          userRole: UserRole.CUSTOM,
          customRole,
        };
      }) || [];

    return defaultroles.concat(customRoles).reduce((acc, role) => {
      const key = getCustomizableRoleKey(role);
      acc[key] = role;
      return acc;
    }, {} as Record<string, CustomizableRole>);
  }, [data]);

  return (
    <FormControl {...FormControlProps}>
      <InputLabel id="role">Role</InputLabel>
      <Select
        fullWidth
        labelId="role"
        size="small"
        label="Role"
        value={getCustomizableRoleKey(value)}
        disabled={disabled}
        onChange={(e) => onChange(allRoles[e.target.value as string])}
        {...SelectProps}
      >
        {Object.keys(allRoles).map((roleKey) => {
          const role = allRoles[roleKey];
          return (
            <MenuItem value={roleKey} key={roleKey}>
              {role.userRole === UserRole.CUSTOM && role.customRole
                ? role.customRole.name
                : USER_ROLES_WITH_METADATA[role.userRole].name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

function getCustomizableRoleKey(role: CustomizableRole) {
  return [role.userRole.valueOf(), role.customRole?.id || ''].join('::');
}
