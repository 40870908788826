import {
  BonusStructureFragmentDoc,
  BonusStructureGroupFragment,
  BonusStructureSaveRequest,
  GlobalLevelFragment,
} from '@alamere/generated-graphql-types';
import { useApolloClient } from '@apollo/client';
import ContentPasteGoRoundedIcon from '@mui/icons-material/ContentPasteGoRounded';
import {
  IconButton,
  InputAdornment,
  OutlinedInput,
  Skeleton,
  TableCell,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
import { debounce } from 'lodash';
import { memo, useMemo, useState } from 'react';
import { DEBOUNCE_MS } from '../../lib/constants';
import { OnItemChangeFunction } from './types';

interface Props {
  globalLevel: GlobalLevelFragment;
  group: BonusStructureGroupFragment;
  editing: boolean;
  loading: boolean;
  isHighlighted: boolean;
  onItemChange: OnItemChangeFunction;
}

export const Cell = memo(
  ({
    globalLevel,
    group,
    editing,
    loading,
    isHighlighted,
    onItemChange,
  }: Props) => {
    const { cache } = useApolloClient();
    const [hovered, setHovered] = useState(false);
    const debouncedOnItemChange = debounce(onItemChange, DEBOUNCE_MS);

    const bonusStructure = useMemo(
      () =>
        group.bonusStructures?.find(
          (bonusStructure) =>
            bonusStructure.globalLevelLevel === globalLevel.level
        ),
      [globalLevel.level, group.bonusStructures]
    );

    const handleValueChange = (value: string) => {
      const data = {
        bonusStructureGroupId: group.id,
        globalLevelLevel: globalLevel.level,
        id: bonusStructure?.id,
        percent: parseFloat(value || '0'),
      };

      cache.writeFragment<BonusStructureSaveRequest>({
        id: cache.identify({
          bonusStructureGroupId: group.id,
          globalLevelLevel: globalLevel.level,
          __typename: 'BonusStructure',
        }),
        fragmentName: 'BonusStructure',
        fragment: BonusStructureFragmentDoc,
        data,
      });
      debouncedOnItemChange(data);
    };

    const handleCopyOver = () => {
      const updates = group.bonusStructures?.map((old) => {
        return {
          ...old,
          percent: bonusStructure?.percent ?? 0,
        };
      });

      cache.performTransaction((cache) => {
        updates?.forEach((update) => {
          cache.writeFragment<BonusStructureSaveRequest>({
            id: cache.identify({
              bonusStructureGroupId: update.bonusStructureGroupId,
              globalLevelLevel: update.globalLevelLevel,
              __typename: 'BonusStructure',
            }),
            fragmentName: 'BonusStructure',
            fragment: BonusStructureFragmentDoc,
            data: update,
          });
        });
      });
      updates.forEach(onItemChange);
    };

    if (loading) {
      return (
        <TableCell className={isHighlighted ? 'highlighted-column' : ''}>
          <Skeleton />
        </TableCell>
      );
    }

    return (
      <TableCell
        align="center"
        className={isHighlighted ? 'highlighted-column' : ''}
        onPointerEnter={() => setHovered(true)}
        onPointerLeave={() => setHovered(false)}
      >
        <OutlinedInput
          value={bonusStructure?.percent}
          sx={{ display: editing ? undefined : 'none' }}
          type="number"
          inputProps={{
            style: { textAlign: 'right' },
          }}
          size="small"
          fullWidth
          startAdornment={
            <InputAdornment
              position="start"
              sx={{ visibility: hovered ? 'visible' : 'hidden' }}
            >
              <IconButton
                onClick={handleCopyOver}
                title={t('bonusStructurePage.table.copyOver')}
              >
                <ContentPasteGoRoundedIcon />
              </IconButton>
            </InputAdornment>
          }
          endAdornment={<InputAdornment position="end">%</InputAdornment>}
          onChange={(e) => handleValueChange(e.target.value)}
        />
        <Typography sx={{ display: !editing ? undefined : 'none' }}>
          {bonusStructure?.percent.toLocaleString() || '0'}
        </Typography>
      </TableCell>
    );
  }
);
