import MenuIcon from '@mui/icons-material/Menu';
import { useScrollTrigger } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import { t } from 'i18next';
import * as React from 'react';
import { Logo } from '../components/Logo';

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

const ElevationScroll = (props: any) => {
  const { children } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });
  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
    sx: {
      transition: 'all 0.3s ease-in-out',
      // backgroundColor: trigger
      //   ? alpha(white.main, 0.8)
      //   : alpha(white.main, 0.9),
    },
  });
};

const drawerWidth = 240;
const navItems: {
  transKey: string;
  path: string;
  variant?: 'outlined' | 'contained' | 'text';
}[] = [
  {
    transKey: 'unauthedNav.login',
    path: '/login',
    variant: 'outlined',
  },
  {
    transKey: 'unauthedNav.consulting',
    path: 'https://www.alamere.io/',
  },
  {
    transKey: 'unauthedNav.contact',
    path: 'https://www.alamere.io/contact',
  },
];

export default function UnauthedMenu(props: Props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const mainMenu = (
    <>
      {navItems.map((item, index) => (
        <Button
          key={index}
          sx={{ color: 'inherit', height: 'fit-content' }}
          href={item.path}
          variant={item.variant ?? 'text'}
          color="secondary"
        >
          {t(item.transKey)}
        </Button>
      ))}
    </>
  );

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Logo />
      <Divider />
      <List>
        {navItems.map((item, index) => (
          <ListItem key={index} disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }} href={item.path}>
              <ListItemText>{t(item.transKey)}</ListItemText>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      <ElevationScroll>
        <AppBar component="nav" variant="outlined">
          <Toolbar sx={{ backdropFilter: 'blur(4px)' }}>
            <Box
              sx={{
                display: { xs: 'flex', sm: 'none' },
                flexGrow: 1,
                justifyContent: 'space-between',
              }}
            >
              <Logo />
              <IconButton
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
              >
                <MenuIcon />
              </IconButton>
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                display: {
                  xs: 'none',
                  sm: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                },
              }}
            >
              <Logo />
              <Box sx={{ display: { xs: 'none', sm: 'flex', gap: 10 } }}>
                {mainMenu}
              </Box>
            </Box>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </>
  );
}
