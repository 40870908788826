import {
  GlobalLevelFragment,
  useBonusStructureGroupsQuery,
} from '@alamere/generated-graphql-types';
import { TableRow } from '@mui/material';
import { orderBy } from 'lodash';
import { memo } from 'react';
import GlobalLevelCell from '../../components/GlobalLevelCell';
import { Cell } from './Cell';
import { OnItemChangeFunction } from './types';

interface Props {
  editing: boolean;
  globalLevel: GlobalLevelFragment;
  onItemChange: OnItemChangeFunction;
}
export const Row = memo(({ editing, globalLevel, onItemChange }: Props) => {
  const { data: groupsData, loading: loadingGroupData } =
    useBonusStructureGroupsQuery();

  const groups = orderBy(
    groupsData?.bonusStructureGroups ?? [],
    ['id'],
    ['asc']
  );

  return (
    <TableRow>
      <GlobalLevelCell globalLevel={globalLevel} />
      {groups.map((group, index) => (
        <Cell
          key={group.id}
          group={group}
          globalLevel={globalLevel}
          editing={editing}
          loading={loadingGroupData}
          isHighlighted={index % 2 === 0}
          onItemChange={onItemChange}
        />
      ))}
    </TableRow>
  );
});
