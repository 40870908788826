import {
  JobGroup,
  RadfordJobFamilyFragment,
  useJobFamiliesQuery,
  useRadfordJobFamiliesQuery,
} from '@alamere/generated-graphql-types';
import Paper from '@mui/material/Paper';
import MuiTable from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { t } from 'i18next';
import { groupBy, orderBy } from 'lodash';
import { memo, useMemo } from 'react';
import { SkeletonTableRows } from '../../components/SkeletonTableRows';
import { Header } from './Header';
import { Row } from './Row';
import { OnItemChangeFunction } from './types';

export interface TableProps {
  editing: boolean;
  onItemChange: OnItemChangeFunction;
}

export const Table = memo(({ editing, onItemChange }: TableProps) => {
  const { data, loading } = useJobFamiliesQuery();
  const orderedFamilies = orderBy(data?.jobFamilies ?? [], ['code'], ['asc']);

  const { data: radfordData, loading: loadingRadford } =
    useRadfordJobFamiliesQuery();

  const familiesByJobGroup = useMemo(
    () => groupBy(radfordData?.radfordJobFamilies ?? [], (fam) => fam.jobGroup),
    [radfordData?.radfordJobFamilies]
  ) as Record<JobGroup, RadfordJobFamilyFragment[]>;

  return (
    <TableContainer
      component={Paper}
      sx={{ maxHeight: 'calc(100vh - 140px)', overflow: 'auto' }}
    >
      <MuiTable
        stickyHeader
        sx={(theme) => ({
          tableLayout: 'auto',
          '& .highlighted-column': {
            backgroundColor: theme.palette.background.secondary,
          },
          'tr > :nth-of-type(3) ': {
            borderRight: '1px solid',
            borderRightColor: 'grey.300',
          },
          'tr > :nth-of-type(9) ': {
            borderRight: '1px solid',
            borderRightColor: 'grey.300',
          },
        })}
        size="small"
        aria-label={t('bonusStructuresPage.table.name')}
      >
        <Header editing={editing} />
        <TableBody>
          {loading && <SkeletonTableRows rows={5} cols={13} height={30} />}
          {orderedFamilies.map((fam) => (
            <Row
              key={fam.id}
              jobFamily={fam}
              editing={editing}
              onItemChange={onItemChange}
              familiesByJobGroup={familiesByJobGroup}
              loadingRadford={loadingRadford}
            />
          ))}
        </TableBody>
      </MuiTable>
    </TableContainer>
  );
});
