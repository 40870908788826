import React, { useEffect } from 'react';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import MuiTable from '@mui/material/Table';
import { Head } from './Head';
import TableBody from '@mui/material/TableBody';
import { Row } from './Row';
import { Job, JobsQuery } from '@alamere/generated-graphql-types';
import { useSnackbar } from 'notistack';
import { JOB_GROUPS, Tier } from '@alamere/core';
import { groupBy, orderBy } from 'lodash';

interface Props {
  jobFamily: number | null;
  levels: number[];
  combinedJobFamilyData: any;
  loadingJobs: boolean;
  jobsData: JobsQuery | undefined;
  tier: Tier | null;
}

function processJobData(jobFamily: number | null, levels: number[], combinedJobFamilyData: any, jobsData: JobsQuery) {
  if (!jobsData || !jobsData.jobs || !jobFamily) {
    return { data: null, error: false };
  }

  const jobFamilyData = combinedJobFamilyData.find((jf: any) => jf.id === jobFamily);
  if (!jobFamilyData) return { data: null, error: false };

  const filteredJobs = jobsData.jobs.filter(job =>
    job.jobFamilyId === jobFamily && levels.includes(job.jobLevel.level)
  );

  let error = false;

  const processedJobs = filteredJobs.map((job) => {
    const bonus = jobFamilyData.bonusStructureGroup.bonusStructures.find((bonus: any) => bonus.globalLevelLevel === job.jobLevel.level);
    const compBand = jobFamilyData.compBandGroup.compBands.find((compBand: any) => compBand.globalLevelLevel === job.jobLevel.level);
    const rangeWidth = jobFamilyData.rangeWidthGroup.rangeWidths.find((rangeWidth: any) => rangeWidth.level === job.jobLevel.level);
    const equityBand = jobFamilyData.equityBandGroup.equityBands.find((equityBand: any) => equityBand.globalLevelLevel === job.jobLevel.level);

    if (!bonus || !compBand || !rangeWidth || !equityBand) {
      error = true;
    }

    return {
      ...job,
      bonus,
      compBand,
      rangeWidth,
      equityBand,
      compBandMultiplier: jobFamilyData.compBandMultiplier
    };
  });

  return {
    data: {
      ...jobFamilyData,
      jobs: processedJobs
    },
    error
  };
}

export function Table({ jobFamily, levels, combinedJobFamilyData, jobsData, loadingJobs, tier }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    if ((jobFamily && levels.length > 0) && (!combinedJobFamilyData || combinedJobFamilyData.length === 0)) {
      enqueueSnackbar('No data found for some levels', { variant: 'error' });
    }
  }, [jobFamily, levels, combinedJobFamilyData, jobsData]);
  const { data: processedData, error } = React.useMemo(() => {
    if (loadingJobs || !jobsData || !jobFamily) return { data: null, error: false };
    return processJobData(jobFamily, levels, combinedJobFamilyData, jobsData);
  }, [jobFamily, levels, combinedJobFamilyData, jobsData, loadingJobs]);

  useEffect(() => {
    if (error) {
      enqueueSnackbar('No data found for some levels', { variant: 'error' });
    }
  }, [error, enqueueSnackbar]);

  if (!processedData) {
    return null;
  }

  const getRangePointsByGroup = (jobs: Array<{
      compBand: {onTargetEarnings: number; absoluteVariable: number | null; percentVariable: number;};
      rangeWidth: {percentBelowMid: number; percentAboveMid: number;},
      equityBand: {value: number}
      compBandMultiplier: number;
      bonus: { percent: number };
    }>) => {
    let salaryRangeMin = Infinity;
    let salaryRangeMax = -Infinity;
    let equityRangeMin = Infinity;
    let equityRangeMax = -Infinity;
    jobs.forEach((job) => {
      if (tier && job && job.bonus && job.rangeWidth && job.compBand && job.compBandMultiplier && job.equityBand) {
        const { compBand, rangeWidth, equityBand, compBandMultiplier } = job;
        const salaryMid = compBand.onTargetEarnings * (compBandMultiplier / 100) * (tier.percentile / 100);
        const currentSalaryMin = salaryMid * (1 - rangeWidth.percentBelowMid / 100);
        const currentSalaryMax = salaryMid * (1 + rangeWidth.percentAboveMid / 100);
        const equityMid = equityBand.value;
        const currentEquityMin = equityMid * (1 - rangeWidth.percentBelowMid / 100);
        const currentEquityMax = equityMid * (1 + rangeWidth.percentAboveMid / 100);
        salaryRangeMin = Math.min(salaryRangeMin, currentSalaryMin);
        salaryRangeMax = Math.max(salaryRangeMax, currentSalaryMax);
        equityRangeMin = Math.min(equityRangeMin, currentEquityMin);
        equityRangeMax = Math.max(equityRangeMax, currentEquityMax);
      }
    })
    return {
      salaryRangeMin,
      salaryRangeMax,
      equityRangeMin,
      equityRangeMax
    }
  }

  const orderedJobs = orderBy(
    processedData?.jobs,
    [(j) => j.jobLevel.level],
    ['desc']
  );
  const groupedJobs = groupBy(orderedJobs, (j) => j.jobLevel.group);
  const renderRows = () => {
    return JOB_GROUPS.flatMap((group) => {
      const jobsInGroup = groupedJobs[group] || [];
      const rangePoints = getRangePointsByGroup(jobsInGroup);
      let isFirstValidRowInGroup = true;

      return jobsInGroup.map((job: any, index) => {
        const isValidRow = job && job.bonus && job.rangeWidth && job.compBand && job.compBandMultiplier && job.equityBand;

        if (!isValidRow) {
          return null;
        }
        const row = (
          <Row
            key={job.id}
            job={job}
            bonus={job.bonus}
            compBand={job.compBand}
            rangePoints={rangePoints}
            rangeWidth={job.rangeWidth}
            equityBand={job.equityBand}
            compBandMultiplier={job.compBandMultiplier}
            tier={tier}
            itemIndex={index}
            isFirstInGroup={isFirstValidRowInGroup}
          />
        );

        if (isFirstValidRowInGroup) {
          isFirstValidRowInGroup = false;
        }

        return row;
      }).filter(Boolean);
    });
  };

  return (
    <TableContainer component={Paper} sx={{minWidth: '1440px'}}>
      <MuiTable size="small">
        <Head />
        <TableBody>
          {renderRows()}
        </TableBody>
      </MuiTable>
    </TableContainer>
  );
}
