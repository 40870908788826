import { Scopes, ScopesByGroup } from '@alamere/core';
import {
  CustomRoleFragment,
  Scope,
  useCreateCustomRoleMutation,
  useDeleteCustomRoleMutation,
  useUpdateCustomRoleMutation,
} from '@alamere/generated-graphql-types';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import DeleteIconRounded from '@mui/icons-material/DeleteRounded';
import {
  Box,
  Button,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { useFlagsmith } from 'flagsmith/react';
import { t } from 'i18next';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';
import * as React from 'react';
import { ColorModeContext } from '../../layout/theme';

export interface CustomRoleDetailProps {
  customRole?: CustomRoleFragment;
  onComplete: () => void;
  onCancel: () => void;
}

export function CustomRoleDetail({
  customRole,
  onCancel,
  onComplete,
}: CustomRoleDetailProps) {
  const colorMode = React.useContext(ColorModeContext);
  const [create, { loading: loadingCreate }] = useCreateCustomRoleMutation();
  const [update, { loading: loadingUpdate }] = useUpdateCustomRoleMutation();
  const [deleteRole, { loading: loadingDelete }] =
    useDeleteCustomRoleMutation();

  const [name, setName] = useState(customRole?.name || '');
  const [scopes, setScopes] = useState<Record<Scope, Boolean>>(
    customRole?.scopes?.reduce((acc, scope) => {
      acc[scope] = true;
      return acc;
    }, {} as Record<Scope, boolean>) ?? ({} as Record<Scope, boolean>)
  );
  const flagsmith = useFlagsmith();

  const handleToggleScope = (scope: Scope) => () => {
    const toggledDependencies = Scopes[scope]?.requires?.reduce(
      (acc, alsoRequiredScope) => {
        acc[alsoRequiredScope] = !scopes[scope];
        return acc;
      },
      {} as Record<Scope, boolean>
    );
    const toggledDependents = Scopes[scope]?.isDependencyTo?.reduce(
      (acc, dependentScope) => {
        acc[dependentScope] = !scopes[scope];
        return acc;
      },
      {} as Record<Scope, boolean>
    );

    setScopes((prev) => {
      const newValue = !prev[scope];
      const alsoToggle = newValue ? toggledDependencies : toggledDependents;
      return {
        ...prev,
        [scope]: newValue,
        ...alsoToggle,
      };
    });
  };

  const handleSubmit = async () => {
    customRole?.id ? await handleUpdate() : await handleCreate();
  };

  const handleCreate = async () => {
    await create({
      variables: {
        createCustomRoleRequest: {
          name,
          scopes: Object.keys(scopes).filter(
            (scope) => scopes[scope as Scope]
          ) as Scope[],
        },
      },
    });
    onComplete();
  };

  const handleUpdate = async () => {
    if (!customRole) return;
    await update({
      variables: {
        updateCustomRoleRequest: {
          id: customRole.id,
          name,
          scopes: Object.keys(scopes).filter(
            (scope) => scopes[scope as Scope]
          ) as Scope[],
        },
      },
    });
    onComplete();
  };

  const handleDelete = async () => {
    if (!customRole) return;
    if (customRole.userCount > 0) {
      enqueueSnackbar('Cannot delete a custom role with users', {
        variant: 'error',
      });
    }
    await deleteRole({
      variables: {
        deleteCustomRoleRequest: {
          id: customRole.id,
        },
      },
    });
    onComplete();
  };

  const loading = loadingCreate || loadingUpdate || loadingDelete;

  return (
    <Box sx={{ p: 4 }}>
      <Stack gap={4}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="title" mr={4}>
            {customRole?.id ? 'Update Custom Role' : 'Create Custom Role'}
          </Typography>
          <IconButton onClick={onCancel}>
            <CloseRoundedIcon />
          </IconButton>
        </Stack>

        <TextField
          label="Name"
          variant="outlined"
          value={name}
          sx={{
            '& .MuiInputBase-input': {
              color: colorMode.activeMode === 'light' ? 'white' : '',
            },
            '& .MuiInputLabel-root': {
              color: colorMode.activeMode === 'light' ? 'white' : '',
            },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: colorMode.activeMode === 'light' ? 'white' : '',
              },
              '&:hover fieldset': {
                borderColor: colorMode.activeMode === 'light' ? 'white' : '',
              },
              '&.Mui-focused fieldset': {
                borderColor: colorMode.activeMode === 'light' ? 'white' : '',
              },
            },
          }}
          onChange={(e) => setName(e.target.value)}
        />
        {Object.keys(ScopesByGroup).map((groupName) => {
          const scopesInGroup = ScopesByGroup[groupName].filter(
            (scope) =>
              !scope.flags ||
              scope.flags.every((requiredFlag) =>
                flagsmith.hasFeature(requiredFlag)
              )
          );
          if (scopesInGroup.length === 0) return null;

          return (
            <Box key={groupName}>
              <Stack gap={1}>
                <Typography variant="title">{t(groupName)}</Typography>
                {scopesInGroup.map((scope) => (
                  <FormControlLabel
                    key={scope.id}
                    control={
                      <Switch
                        checked={!!scopes[scope.id]}
                        onChange={handleToggleScope(scope.id)}
                      />
                    }
                    label={t(scope.label)}
                  />
                ))}
              </Stack>
            </Box>
          );
        })}
        <Stack gap={2}>
          <Button
            variant="contained"
            size="large"
            onClick={handleSubmit}
            disabled={loading}
          >
            {customRole?.id ? 'Update' : 'Create'}
          </Button>
          <Button
            variant="outlined"
            size="small"
            onClick={handleDelete}
            disabled={loading}
            endIcon={<DeleteIconRounded />}
          >
            Delete
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
}
