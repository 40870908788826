import { isBlessedUser } from '@alamere/core';
import {
  UpdateWorkspaceRequest,
  UserRole,
  useUpdateWorkspaceMutation,
} from '@alamere/generated-graphql-types';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Box,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useAuth } from '../../providers/auth';

export default function WorkspaceSettingsGeneral() {
  const { user, workspace, refetch } = useAuth();
  const [workspaceFormData, setWorkspaceFormData] =
    useState<UpdateWorkspaceRequest>({
      name: workspace?.name || '',
      domain: workspace?.domain || null,
      enableInviteByDomain: workspace?.enableInviteByDomain || false,
      inviteByDomainRole: workspace?.inviteByDomainRole || UserRole.MEMBER,
      inviteByDomainCustomRoleId: workspace?.inviteByDomainCustomRole?.id,
    });
  const [loadingUpdateWorkspace, setLoadingWorkspace] =
    useState<boolean>(false);

  const [updateWorkspace] = useUpdateWorkspaceMutation();

  useEffect(() => {
    setWorkspaceFormData({
      name: workspace?.name || '',
      domain: workspace?.domain || null,
      enableInviteByDomain: workspace?.enableInviteByDomain || false,
      inviteByDomainRole: workspace?.inviteByDomainRole || UserRole.MEMBER,
      inviteByDomainCustomRoleId: workspace?.inviteByDomainCustomRole?.id,
    });
  }, [workspace]);

  const onUpdateWorkspace = (
    updateWorkspaceRequest: UpdateWorkspaceRequest
  ) => {
    setLoadingWorkspace(true);
    return updateWorkspace({
      variables: {
        updateWorkspaceRequest,
      },
    })
      .then(refetch)
      .finally(() => setLoadingWorkspace(false));
  };

  const hasPendingWorkspaceChanges = () => {
    for (const key of Object.keys(
      workspaceFormData
    ) as (keyof UpdateWorkspaceRequest)[]) {
      if (key === 'inviteByDomainCustomRoleId') {
        if (
          workspaceFormData.inviteByDomainCustomRoleId !==
          workspace?.inviteByDomainCustomRole?.id
        ) {
          return true;
        }
        continue;
      }
      if (workspaceFormData[key] !== workspace?.[key]) {
        return true;
      }
    }
    return false;
  };

  if (!workspace) {
    return null;
  }

  return (
    <Box sx={{ ml: 0 }}>
      <Stack spacing={2}>
        <Typography variant="h5" sx={{ pt: 2 }}>
          General settings
        </Typography>
        <Paper sx={{ p: 2 }}>
          <Stack width="fit-content" minWidth={250} gap={2}>
            <TextField
              id="workspaceId"
              label="Workspace ID"
              value={workspace.id}
              disabled
              variant="standard"
            />
            <TextField
              id="workspace-name"
              label="Name"
              value={workspaceFormData.name}
              variant="standard"
              onChange={(e) => {
                setWorkspaceFormData({
                  ...workspaceFormData,
                  name: e.target.value,
                });
              }}
            />
            <TextField
              id="domain"
              label="Domain"
              value={workspaceFormData.domain || ''}
              variant="standard"
              disabled={!isBlessedUser(user?.email)}
              onChange={(e) => {
                setWorkspaceFormData({
                  ...workspaceFormData,
                  domain: e.target.value,
                });
              }}
            />
            <LoadingButton
              variant="contained"
              loading={loadingUpdateWorkspace}
              disabled={!hasPendingWorkspaceChanges() || loadingUpdateWorkspace}
              onClick={() => onUpdateWorkspace(workspaceFormData)}
            >
              {hasPendingWorkspaceChanges() && !loadingUpdateWorkspace
                ? 'Save'
                : 'Saved'}
            </LoadingButton>
          </Stack>
        </Paper>
      </Stack>
    </Box>
  );
}
