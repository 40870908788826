import { CountryCode, Tier } from '@alamere/core';
import { Stack } from '@mui/material';
import { useMemo, useState, useEffect } from 'react';
import { t } from 'i18next';
import {
  EquityGlobalMappingFragment, useBonusStructureGroupsQuery, useCompBandGroupsQuery, useEquityBandGroupsQuery,
  useJobFamiliesQuery, useJobLevelsQuery, useJobsQuery, useRangeWidthGroupsQuery, JobFamilyFragment, JobsQuery
} from '../../graphql/_generated/generated-graphql-types';
import JobFamilyPicker from '../../components/JobFamilyPicker';
import { MultiSelect, SelectOption } from '../../components/MultiSelect';
import { TierPicker } from '../../components/TierPicker';
import { Table } from './Table';


function processJobData(jobFamily: number | null, levels: number[], combinedJobFamilyData: any, jobsData: JobsQuery) {
  if (!jobsData || !jobsData.jobs || !jobFamily) {
    return { data: null };
  }

  const jobFamilyData = combinedJobFamilyData.find((jf: any) => jf.id === jobFamily);
  if (!jobFamilyData) {
    return { data: null };
  }

  const filteredJobs = jobsData.jobs.filter(job =>
    job.jobFamilyId === jobFamily && levels.includes(job.jobLevel.level)
  );

  const processedJobs = filteredJobs.map((job) => {
    const bonus = jobFamilyData.bonusStructureGroup.bonusStructures.find((bonus: any) => bonus.globalLevelLevel === job.jobLevel.level);
    const compBand = jobFamilyData.compBandGroup.compBands.find((compBand: any) => compBand.globalLevelLevel === job.jobLevel.level);
    const rangeWidth = jobFamilyData.rangeWidthGroup.rangeWidths.find((rangeWidth: any) => rangeWidth.level === job.jobLevel.level);
    const equityBand = jobFamilyData.equityBandGroup.equityBands.find((equityBand: any) => equityBand.globalLevelLevel === job.jobLevel.level);


    return {
      ...job,
      bonus,
      compBand,
      rangeWidth,
      equityBand,
      compBandMultiplier: jobFamilyData.compBandMultiplier,
      missingRequiredData: Boolean(!bonus || !compBand || !rangeWidth || !equityBand),
    };
  });

  return {
    data: {
      ...jobFamilyData,
      jobs: processedJobs
    }
  };
}

export default function CompRangesPage() {
  const [jobFamily, setJobFamily] = useState<JobFamilyFragment | null>(null);
  const [options, setOptions] = useState<SelectOption[]>([]);
  const [tier, setTier] = useState<Tier | null>(null);
  const [equityGlobalMapping, setEquityGlobalMapping] =
    useState<EquityGlobalMappingFragment | null>({ countryCode: CountryCode.US, id: 1, percent: 2, useGeoDiff: true });
  const { data: jobFamiliesData } = useJobFamiliesQuery();
  const { data: jobsData, loading: loadingJobs } = useJobsQuery({
    variables: {
      jobGetRequest: {
        jobFamilyIds: jobFamily ? [jobFamily.id] : [],
      },
    },
  });
  const { data: rangeWidthGroupsData} = useRangeWidthGroupsQuery();
  const { data: compBandGroupsData} = useCompBandGroupsQuery();
  const { data: groupsData } = useBonusStructureGroupsQuery();
  const { data: equityBandGroupsData, loading: loadingEquityBandGroups } =
    useEquityBandGroupsQuery();

  const levelOptions = useMemo(() => {
    if (!jobsData?.jobs) return [];

    const levelMap = new Map();

    jobsData.jobs.forEach(job => {
      const level = job.jobLevel.level;
      const title = job.title;

      if (levelMap.has(level)) {
        levelMap.get(level).titles.push(title);
      } else {
        levelMap.set(level, {
          level,
          titles: [title],
          name: job.jobLevel.name
        });
      }
    });

    return Array.from(levelMap.values())
      .map(({ level, titles }) => ({
        title: `${titles.join(' / ')}`,
        value: level
      }))
      .sort((a, b) => b.value - a.value);
  }, [jobsData]);

  const combinedJobFamilyData = useMemo(() => {
    if (!jobFamiliesData || !rangeWidthGroupsData || !compBandGroupsData || !groupsData || !equityBandGroupsData || !jobFamily) {
      return [];
    }

    return jobFamiliesData.jobFamilies
      .filter(family => family.id === jobFamily.id)
      .map(jobFamily => {
        const rangeWidthGroup = rangeWidthGroupsData.rangeWidthGroups.find(
          group => group.id === jobFamily.rangeWidthGroup?.id
        );

        const compBandGroup = compBandGroupsData.compBandsGroups.find(
          group => group.id === jobFamily.compBandGroup?.id
        );

        const bonusStructureGroup = groupsData.bonusStructureGroups.find(
          group => group.id === jobFamily.bonusStructureGroup?.id
        );

        const equityBandGroup = equityBandGroupsData.equityBandGroups.find(
          group => group.id === jobFamily.equityBandGroup?.id
        );

        const filteredRangeWidths = rangeWidthGroup?.rangeWidths.filter(rw =>
          levelOptions.some(level => level.value === rw.level)
        ) || [];

        const filteredCompBands = compBandGroup?.compBands.filter(cb =>
          levelOptions.some(level => level.value === cb.globalLevelLevel)
        ) || [];

        const filteredBonusStructures = bonusStructureGroup?.bonusStructures.filter(bs =>
          levelOptions.some(level => level.value === bs.globalLevelLevel)
        ) || [];

        const filteredEquityBands = equityBandGroup?.equityBands.filter(eb =>
          levelOptions.some(level => level.value === eb.globalLevelLevel)
        ) || [];

        if (!rangeWidthGroup || !compBandGroup || !bonusStructureGroup || !equityBandGroup ||
          filteredRangeWidths.length === 0 || filteredCompBands.length === 0 ||
          filteredBonusStructures.length === 0 || filteredEquityBands.length === 0) {
          return null;
        }

        return {
          ...jobFamily,
          rangeWidthGroup: {
            ...rangeWidthGroup,
            rangeWidths: filteredRangeWidths
          },
          compBandGroup: {
            ...compBandGroup,
            compBands: filteredCompBands
          },
          bonusStructureGroup: {
            ...bonusStructureGroup,
            bonusStructures: filteredBonusStructures
          },
          equityBandGroup: {
            ...equityBandGroup,
            equityBands: filteredEquityBands
          }
        };
      })
      .filter(Boolean);

  }, [jobFamiliesData, rangeWidthGroupsData, compBandGroupsData, groupsData, equityBandGroupsData, jobFamily, levelOptions]);

  const { data: processedData } = useMemo(() => {
    if (loadingJobs || !jobsData || !jobFamily) return { data: null };
    return processJobData(jobFamily.id, levelOptions.flatMap((level) => level.value), combinedJobFamilyData, jobsData);
  }, [jobFamily, combinedJobFamilyData, jobsData, loadingJobs]);

  useEffect(() => {
    if (processedData?.jobs) {
      const disabledLevelIDOptions = processedData.jobs.filter((job: any) => {
        return job.missingRequiredData === true;
      }).map((job : any) => {
        return job.jobLevel.level;
      });
      const newLevelOptions = levelOptions.map(item => {
        return {
          ...item,
          disabled: disabledLevelIDOptions.includes(item.value),
          checked: !disabledLevelIDOptions.includes(item.value),
        }
      });
      setOptions(newLevelOptions)
    }
  }, [processedData])

  const handleJobFamilyChange = (jobFamily: JobFamilyFragment | null) => {
    setJobFamily(jobFamily);
  };

  const handleLevelChange  = (options: SelectOption[]) => {
    setOptions(options);
  }

  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={2}>
        <JobFamilyPicker onChange={handleJobFamilyChange} sx={{minWidth: 285}} />
        <MultiSelect
          disabled={!jobFamily}
          label={t('compRangesPage.select.level')}
          options={options}
          sx={{minWidth: 285}}
          onChange={handleLevelChange}
        />
        <TierPicker
          countryCode={CountryCode.US}
          useGeoDiff={equityGlobalMapping?.useGeoDiff ?? false}
          onChange={setTier}
          sx={{ width: 150 }}
        />
      </Stack>
      <Table
        processedData={processedData}
        selectedLevels={options.filter(option => option.checked).map(option => option.value)}
        tier={tier}
      />
    </Stack>
  );
}
