import { isBlessedUser } from '@alamere/core';
import BoltRoundedIcon from '@mui/icons-material/BoltRounded';
import { Avatar, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../providers/auth';

export default function HqMenu() {
  const navigate = useNavigate();
  const { user, workspace } = useAuth();

  const onClick = () => {
    navigate({
      pathname: `/hq/w/${workspace?.id || ''}`,
    });
  };

  if (!isBlessedUser(user?.email)) {
    return null;
  }

  return (
    <IconButton
      color="primary"
      aria-label="Open HQ"
      id="avatar-button"
      onClick={onClick}
    >
      <Avatar
        sx={{
          width: 32,
          height: 32,
          filter: 'drop-shadow(2px 2px 2px rgb(0 0 0 / 0.2))',
          backgroundColor: (theme) => theme.palette.warning.main,
        }}
      >
        <BoltRoundedIcon />
      </Avatar>
    </IconButton>
  );
}
