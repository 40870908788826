import {
  EquityBandGroupPropertiesFragment,
  JobFamilyFragment,
  JobFamilyFragmentDoc,
  useEquityBandGroupsQuery,
} from '@alamere/generated-graphql-types';
import { useApolloClient } from '@apollo/client';
import { Autocomplete, TableCell, TextField, Typography } from '@mui/material';
import { memo, useEffect, useState } from 'react';
import { OnItemChangeFunction } from '../types';

interface Props {
  editing: boolean;
  jobFamily: JobFamilyFragment;
  onItemChange: OnItemChangeFunction;
}

export const EquityBandGroup = memo(
  ({ editing, jobFamily, onItemChange }: Props) => {
    const { cache } = useApolloClient();
    const [value, setValue] = useState<
      EquityBandGroupPropertiesFragment | null | undefined
    >(jobFamily.equityBandGroup);
    const [open, setOpen] = useState(false);

    const { data } = useEquityBandGroupsQuery();

    useEffect(() => {
      setValue(jobFamily.equityBandGroup);
    }, [jobFamily]);

    const handleChange = (value?: EquityBandGroupPropertiesFragment | null) => {
      setValue(value);
      const data = {
        ...jobFamily,
        equityBandGroup: value,
      };
      cache.writeFragment<JobFamilyFragment>({
        id: `JobFamily:${jobFamily.id}`,
        fragmentName: 'JobFamily',
        fragment: JobFamilyFragmentDoc,
        data,
      });
      onItemChange({ newItem: data });
    };

    return (
      <TableCell align="center">
        <Autocomplete
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          autoComplete
          blurOnSelect
          value={value}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          size="small"
          id="equity-band-group-autocomplete"
          options={data?.equityBandGroups ?? []}
          getOptionLabel={(option) => option.name}
          onChange={(e, v) => handleChange(v)}
          sx={{ minWidth: 200, display: editing ? undefined : 'none' }}
          isOptionEqualToValue={(option, value) => option.id === value?.id}
          renderInput={(params) => <TextField {...params} />}
        />
        <Typography sx={{ display: editing ? 'none' : undefined }}>
          {jobFamily?.equityBandGroup?.name}
        </Typography>
      </TableCell>
    );
  }
);
