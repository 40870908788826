import { useBonusStructureGroupsQuery } from '@alamere/generated-graphql-types';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { t } from 'i18next';
import { orderBy } from 'lodash';
import { memo } from 'react';
import { HeaderCell } from './HeaderCell';
import { OnGroupChangeFunction } from './types';

interface Props {
  editing: boolean;
  onGroupChange: OnGroupChangeFunction;
}

export const Header = memo(({ editing, onGroupChange }: Props) => {
  const { data: groupsData, loading } = useBonusStructureGroupsQuery();

  const groups = orderBy(
    groupsData?.bonusStructureGroups ?? [],
    ['id'],
    ['asc']
  );
  return (
    <TableHead>
      <TableRow>
        <TableCell /* intentionally empty */ />

        {groups.map((group, index) => {
          return (
            <HeaderCell
              key={group.id}
              group={group}
              editing={editing}
              isHighlighted={index % 2 === 0}
              onGroupChange={onGroupChange}
            />
          );
        })}
      </TableRow>
      <TableRow>
        <TableCell align="center">{t('global.globalLevel')}</TableCell>
        {groups.map((group, groupIndex) => (
          <TableCell
            key={group.id}
            align="center"
            className={groupIndex % 2 === 0 ? 'highlighted-column' : ''}
          >
            {t('bonusStructurePage.table.header.bonus')}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
});
