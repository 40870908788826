export enum CountryCode {
  AF = 'AF',
  AX = 'AX',
  AL = 'AL',
  DZ = 'DZ',
  AS = 'AS',
  AD = 'AD',
  AO = 'AO',
  AI = 'AI',
  AQ = 'AQ',
  AG = 'AG',
  AR = 'AR',
  AM = 'AM',
  AW = 'AW',
  AU = 'AU',
  AT = 'AT',
  AZ = 'AZ',
  BS = 'BS',
  BH = 'BH',
  BD = 'BD',
  BB = 'BB',
  BY = 'BY',
  BE = 'BE',
  BZ = 'BZ',
  BJ = 'BJ',
  BM = 'BM',
  BT = 'BT',
  BO = 'BO',
  BQ = 'BQ',
  BA = 'BA',
  BW = 'BW',
  BV = 'BV',
  BR = 'BR',
  IO = 'IO',
  BN = 'BN',
  BG = 'BG',
  BF = 'BF',
  BI = 'BI',
  CV = 'CV',
  KH = 'KH',
  CM = 'CM',
  CA = 'CA',
  KY = 'KY',
  CF = 'CF',
  TD = 'TD',
  CL = 'CL',
  CN = 'CN',
  CX = 'CX',
  CC = 'CC',
  CO = 'CO',
  KM = 'KM',
  CG = 'CG',
  CD = 'CD',
  CK = 'CK',
  CR = 'CR',
  CI = 'CI',
  HR = 'HR',
  CU = 'CU',
  CW = 'CW',
  CY = 'CY',
  CZ = 'CZ',
  DK = 'DK',
  DJ = 'DJ',
  DM = 'DM',
  DO = 'DO',
  EC = 'EC',
  EG = 'EG',
  SV = 'SV',
  GQ = 'GQ',
  ER = 'ER',
  EE = 'EE',
  SZ = 'SZ',
  ET = 'ET',
  FK = 'FK',
  FO = 'FO',
  FJ = 'FJ',
  FI = 'FI',
  FR = 'FR',
  GF = 'GF',
  PF = 'PF',
  TF = 'TF',
  GA = 'GA',
  GM = 'GM',
  GE = 'GE',
  DE = 'DE',
  GH = 'GH',
  GI = 'GI',
  GR = 'GR',
  GL = 'GL',
  GD = 'GD',
  GP = 'GP',
  GU = 'GU',
  GT = 'GT',
  GG = 'GG',
  GN = 'GN',
  GW = 'GW',
  GY = 'GY',
  HT = 'HT',
  HM = 'HM',
  VA = 'VA',
  HN = 'HN',
  HK = 'HK',
  HU = 'HU',
  IS = 'IS',
  IN = 'IN',
  ID = 'ID',
  IR = 'IR',
  IQ = 'IQ',
  IE = 'IE',
  IM = 'IM',
  IL = 'IL',
  IT = 'IT',
  JM = 'JM',
  JP = 'JP',
  JE = 'JE',
  JO = 'JO',
  KZ = 'KZ',
  KE = 'KE',
  KI = 'KI',
  KP = 'KP',
  KR = 'KR',
  KW = 'KW',
  KG = 'KG',
  LA = 'LA',
  LV = 'LV',
  LB = 'LB',
  LS = 'LS',
  LR = 'LR',
  LY = 'LY',
  LI = 'LI',
  LT = 'LT',
  LU = 'LU',
  MO = 'MO',
  MG = 'MG',
  MW = 'MW',
  MY = 'MY',
  MV = 'MV',
  ML = 'ML',
  MT = 'MT',
  MH = 'MH',
  MQ = 'MQ',
  MR = 'MR',
  MU = 'MU',
  YT = 'YT',
  MX = 'MX',
  FM = 'FM',
  MD = 'MD',
  MC = 'MC',
  MN = 'MN',
  ME = 'ME',
  MS = 'MS',
  MA = 'MA',
  MZ = 'MZ',
  MM = 'MM',
  NA = 'NA',
  NR = 'NR',
  NP = 'NP',
  NL = 'NL',
  NC = 'NC',
  NZ = 'NZ',
  NI = 'NI',
  NE = 'NE',
  NG = 'NG',
  NU = 'NU',
  NF = 'NF',
  MK = 'MK',
  MP = 'MP',
  NO = 'NO',
  OM = 'OM',
  PK = 'PK',
  PW = 'PW',
  PS = 'PS',
  PA = 'PA',
  PG = 'PG',
  PY = 'PY',
  PE = 'PE',
  PH = 'PH',
  PN = 'PN',
  PL = 'PL',
  PT = 'PT',
  PR = 'PR',
  QA = 'QA',
  RE = 'RE',
  RO = 'RO',
  RU = 'RU',
  RW = 'RW',
  BL = 'BL',
  SH = 'SH',
  KN = 'KN',
  LC = 'LC',
  MF = 'MF',
  PM = 'PM',
  VC = 'VC',
  WS = 'WS',
  SM = 'SM',
  ST = 'ST',
  SA = 'SA',
  SN = 'SN',
  RS = 'RS',
  SC = 'SC',
  SL = 'SL',
  SG = 'SG',
  SX = 'SX',
  SK = 'SK',
  SI = 'SI',
  SB = 'SB',
  SO = 'SO',
  ZA = 'ZA',
  GS = 'GS',
  SS = 'SS',
  ES = 'ES',
  LK = 'LK',
  SD = 'SD',
  SR = 'SR',
  SJ = 'SJ',
  SE = 'SE',
  CH = 'CH',
  SY = 'SY',
  TW = 'TW',
  TJ = 'TJ',
  TZ = 'TZ',
  TH = 'TH',
  TL = 'TL',
  TG = 'TG',
  TK = 'TK',
  TO = 'TO',
  TT = 'TT',
  TN = 'TN',
  TR = 'TR',
  TM = 'TM',
  TC = 'TC',
  TV = 'TV',
  UG = 'UG',
  UA = 'UA',
  AE = 'AE',
  GB = 'GB',
  US = 'US',
  UM = 'UM',
  UY = 'UY',
  UZ = 'UZ',
  VU = 'VU',
  VE = 'VE',
  VN = 'VN',
  VG = 'VG',
  VI = 'VI',
  WF = 'WF',
  EH = 'EH',
  YE = 'YE',
  ZM = 'ZM',
  ZW = 'ZW',
}

export interface CountryMetadata {
  name: string;
  alpha2: string;
  alpha3: string;
  countryCode: string;
  region: string;
  subRegion: string;
  intermediateRegion: string;
  regionCode: string;
  subRegionCode: string;
  intermediateRegionCode: string;
}

export class Countries {
  static getByCode(code: CountryCode): CountryMetadata {
    return COUNTRIES_BY_CODE[code];
  }
}

export const COUNTRIES_BY_CODE: Record<CountryCode, CountryMetadata> = {
  AF: {
    name: 'Afghanistan',
    alpha2: 'AF',
    alpha3: 'AFG',
    countryCode: '004',
    region: 'Asia',
    subRegion: 'Southern Asia',
    intermediateRegion: '',
    regionCode: '142',
    subRegionCode: '034',
    intermediateRegionCode: '',
  },
  AX: {
    name: 'Åland Islands',
    alpha2: 'AX',
    alpha3: 'ALA',
    countryCode: '248',
    region: 'Europe',
    subRegion: 'Northern Europe',
    intermediateRegion: '',
    regionCode: '150',
    subRegionCode: '154',
    intermediateRegionCode: '',
  },
  AL: {
    name: 'Albania',
    alpha2: 'AL',
    alpha3: 'ALB',
    countryCode: '008',
    region: 'Europe',
    subRegion: 'Southern Europe',
    intermediateRegion: '',
    regionCode: '150',
    subRegionCode: '039',
    intermediateRegionCode: '',
  },
  DZ: {
    name: 'Algeria',
    alpha2: 'DZ',
    alpha3: 'DZA',
    countryCode: '012',
    region: 'Africa',
    subRegion: 'Northern Africa',
    intermediateRegion: '',
    regionCode: '002',
    subRegionCode: '015',
    intermediateRegionCode: '',
  },
  AS: {
    name: 'American Samoa',
    alpha2: 'AS',
    alpha3: 'ASM',
    countryCode: '016',
    region: 'Oceania',
    subRegion: 'Polynesia',
    intermediateRegion: '',
    regionCode: '009',
    subRegionCode: '061',
    intermediateRegionCode: '',
  },
  AD: {
    name: 'Andorra',
    alpha2: 'AD',
    alpha3: 'AND',
    countryCode: '020',
    region: 'Europe',
    subRegion: 'Southern Europe',
    intermediateRegion: '',
    regionCode: '150',
    subRegionCode: '039',
    intermediateRegionCode: '',
  },
  AO: {
    name: 'Angola',
    alpha2: 'AO',
    alpha3: 'AGO',
    countryCode: '024',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Middle Africa',
    regionCode: '002',
    subRegionCode: '202',
    intermediateRegionCode: '017',
  },
  AI: {
    name: 'Anguilla',
    alpha2: 'AI',
    alpha3: 'AIA',
    countryCode: '660',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'Caribbean',
    regionCode: '019',
    subRegionCode: '419',
    intermediateRegionCode: '029',
  },
  AQ: {
    name: 'Antarctica',
    alpha2: 'AQ',
    alpha3: 'ATA',
    countryCode: '010',
    region: '',
    subRegion: '',
    intermediateRegion: '',
    regionCode: '',
    subRegionCode: '',
    intermediateRegionCode: '',
  },
  AG: {
    name: 'Antigua and Barbuda',
    alpha2: 'AG',
    alpha3: 'ATG',
    countryCode: '028',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'Caribbean',
    regionCode: '019',
    subRegionCode: '419',
    intermediateRegionCode: '029',
  },
  AR: {
    name: 'Argentina',
    alpha2: 'AR',
    alpha3: 'ARG',
    countryCode: '032',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'South America',
    regionCode: '019',
    subRegionCode: '419',
    intermediateRegionCode: '005',
  },
  AM: {
    name: 'Armenia',
    alpha2: 'AM',
    alpha3: 'ARM',
    countryCode: '051',
    region: 'Asia',
    subRegion: 'Western Asia',
    intermediateRegion: '',
    regionCode: '142',
    subRegionCode: '145',
    intermediateRegionCode: '',
  },
  AW: {
    name: 'Aruba',
    alpha2: 'AW',
    alpha3: 'ABW',
    countryCode: '533',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'Caribbean',
    regionCode: '019',
    subRegionCode: '419',
    intermediateRegionCode: '029',
  },
  AU: {
    name: 'Australia',
    alpha2: 'AU',
    alpha3: 'AUS',
    countryCode: '036',
    region: 'Oceania',
    subRegion: 'Australia and New Zealand',
    intermediateRegion: '',
    regionCode: '009',
    subRegionCode: '053',
    intermediateRegionCode: '',
  },
  AT: {
    name: 'Austria',
    alpha2: 'AT',
    alpha3: 'AUT',
    countryCode: '040',
    region: 'Europe',
    subRegion: 'Western Europe',
    intermediateRegion: '',
    regionCode: '150',
    subRegionCode: '155',
    intermediateRegionCode: '',
  },
  AZ: {
    name: 'Azerbaijan',
    alpha2: 'AZ',
    alpha3: 'AZE',
    countryCode: '031',
    region: 'Asia',
    subRegion: 'Western Asia',
    intermediateRegion: '',
    regionCode: '142',
    subRegionCode: '145',
    intermediateRegionCode: '',
  },
  BS: {
    name: 'Bahamas',
    alpha2: 'BS',
    alpha3: 'BHS',
    countryCode: '044',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'Caribbean',
    regionCode: '019',
    subRegionCode: '419',
    intermediateRegionCode: '029',
  },
  BH: {
    name: 'Bahrain',
    alpha2: 'BH',
    alpha3: 'BHR',
    countryCode: '048',
    region: 'Asia',
    subRegion: 'Western Asia',
    intermediateRegion: '',
    regionCode: '142',
    subRegionCode: '145',
    intermediateRegionCode: '',
  },
  BD: {
    name: 'Bangladesh',
    alpha2: 'BD',
    alpha3: 'BGD',
    countryCode: '050',
    region: 'Asia',
    subRegion: 'Southern Asia',
    intermediateRegion: '',
    regionCode: '142',
    subRegionCode: '034',
    intermediateRegionCode: '',
  },
  BB: {
    name: 'Barbados',
    alpha2: 'BB',
    alpha3: 'BRB',
    countryCode: '052',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'Caribbean',
    regionCode: '019',
    subRegionCode: '419',
    intermediateRegionCode: '029',
  },
  BY: {
    name: 'Belarus',
    alpha2: 'BY',
    alpha3: 'BLR',
    countryCode: '112',
    region: 'Europe',
    subRegion: 'Eastern Europe',
    intermediateRegion: '',
    regionCode: '150',
    subRegionCode: '151',
    intermediateRegionCode: '',
  },
  BE: {
    name: 'Belgium',
    alpha2: 'BE',
    alpha3: 'BEL',
    countryCode: '056',
    region: 'Europe',
    subRegion: 'Western Europe',
    intermediateRegion: '',
    regionCode: '150',
    subRegionCode: '155',
    intermediateRegionCode: '',
  },
  BZ: {
    name: 'Belize',
    alpha2: 'BZ',
    alpha3: 'BLZ',
    countryCode: '084',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'Central America',
    regionCode: '019',
    subRegionCode: '419',
    intermediateRegionCode: '013',
  },
  BJ: {
    name: 'Benin',
    alpha2: 'BJ',
    alpha3: 'BEN',
    countryCode: '204',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Western Africa',
    regionCode: '002',
    subRegionCode: '202',
    intermediateRegionCode: '011',
  },
  BM: {
    name: 'Bermuda',
    alpha2: 'BM',
    alpha3: 'BMU',
    countryCode: '060',
    region: 'Americas',
    subRegion: 'Northern America',
    intermediateRegion: '',
    regionCode: '019',
    subRegionCode: '021',
    intermediateRegionCode: '',
  },
  BT: {
    name: 'Bhutan',
    alpha2: 'BT',
    alpha3: 'BTN',
    countryCode: '064',
    region: 'Asia',
    subRegion: 'Southern Asia',
    intermediateRegion: '',
    regionCode: '142',
    subRegionCode: '034',
    intermediateRegionCode: '',
  },
  BO: {
    name: 'Bolivia (Plurinational State of)',
    alpha2: 'BO',
    alpha3: 'BOL',
    countryCode: '068',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'South America',
    regionCode: '019',
    subRegionCode: '419',
    intermediateRegionCode: '005',
  },
  BQ: {
    name: 'Bonaire, Sint Eustatius and Saba',
    alpha2: 'BQ',
    alpha3: 'BES',
    countryCode: '535',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'Caribbean',
    regionCode: '019',
    subRegionCode: '419',
    intermediateRegionCode: '029',
  },
  BA: {
    name: 'Bosnia and Herzegovina',
    alpha2: 'BA',
    alpha3: 'BIH',
    countryCode: '070',
    region: 'Europe',
    subRegion: 'Southern Europe',
    intermediateRegion: '',
    regionCode: '150',
    subRegionCode: '039',
    intermediateRegionCode: '',
  },
  BW: {
    name: 'Botswana',
    alpha2: 'BW',
    alpha3: 'BWA',
    countryCode: '072',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Southern Africa',
    regionCode: '002',
    subRegionCode: '202',
    intermediateRegionCode: '018',
  },
  BV: {
    name: 'Bouvet Island',
    alpha2: 'BV',
    alpha3: 'BVT',
    countryCode: '074',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'South America',
    regionCode: '019',
    subRegionCode: '419',
    intermediateRegionCode: '005',
  },
  BR: {
    name: 'Brazil',
    alpha2: 'BR',
    alpha3: 'BRA',
    countryCode: '076',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'South America',
    regionCode: '019',
    subRegionCode: '419',
    intermediateRegionCode: '005',
  },
  IO: {
    name: 'British Indian Ocean Territory',
    alpha2: 'IO',
    alpha3: 'IOT',
    countryCode: '086',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Eastern Africa',
    regionCode: '002',
    subRegionCode: '202',
    intermediateRegionCode: '014',
  },
  BN: {
    name: 'Brunei Darussalam',
    alpha2: 'BN',
    alpha3: 'BRN',
    countryCode: '096',
    region: 'Asia',
    subRegion: 'South-eastern Asia',
    intermediateRegion: '',
    regionCode: '142',
    subRegionCode: '035',
    intermediateRegionCode: '',
  },
  BG: {
    name: 'Bulgaria',
    alpha2: 'BG',
    alpha3: 'BGR',
    countryCode: '100',
    region: 'Europe',
    subRegion: 'Eastern Europe',
    intermediateRegion: '',
    regionCode: '150',
    subRegionCode: '151',
    intermediateRegionCode: '',
  },
  BF: {
    name: 'Burkina Faso',
    alpha2: 'BF',
    alpha3: 'BFA',
    countryCode: '854',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Western Africa',
    regionCode: '002',
    subRegionCode: '202',
    intermediateRegionCode: '011',
  },
  BI: {
    name: 'Burundi',
    alpha2: 'BI',
    alpha3: 'BDI',
    countryCode: '108',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Eastern Africa',
    regionCode: '002',
    subRegionCode: '202',
    intermediateRegionCode: '014',
  },
  CV: {
    name: 'Cabo Verde',
    alpha2: 'CV',
    alpha3: 'CPV',
    countryCode: '132',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Western Africa',
    regionCode: '002',
    subRegionCode: '202',
    intermediateRegionCode: '011',
  },
  KH: {
    name: 'Cambodia',
    alpha2: 'KH',
    alpha3: 'KHM',
    countryCode: '116',
    region: 'Asia',
    subRegion: 'South-eastern Asia',
    intermediateRegion: '',
    regionCode: '142',
    subRegionCode: '035',
    intermediateRegionCode: '',
  },
  CM: {
    name: 'Cameroon',
    alpha2: 'CM',
    alpha3: 'CMR',
    countryCode: '120',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Middle Africa',
    regionCode: '002',
    subRegionCode: '202',
    intermediateRegionCode: '017',
  },
  CA: {
    name: 'Canada',
    alpha2: 'CA',
    alpha3: 'CAN',
    countryCode: '124',
    region: 'Americas',
    subRegion: 'Northern America',
    intermediateRegion: '',
    regionCode: '019',
    subRegionCode: '021',
    intermediateRegionCode: '',
  },
  KY: {
    name: 'Cayman Islands',
    alpha2: 'KY',
    alpha3: 'CYM',
    countryCode: '136',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'Caribbean',
    regionCode: '019',
    subRegionCode: '419',
    intermediateRegionCode: '029',
  },
  CF: {
    name: 'Central African Republic',
    alpha2: 'CF',
    alpha3: 'CAF',
    countryCode: '140',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Middle Africa',
    regionCode: '002',
    subRegionCode: '202',
    intermediateRegionCode: '017',
  },
  TD: {
    name: 'Chad',
    alpha2: 'TD',
    alpha3: 'TCD',
    countryCode: '148',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Middle Africa',
    regionCode: '002',
    subRegionCode: '202',
    intermediateRegionCode: '017',
  },
  CL: {
    name: 'Chile',
    alpha2: 'CL',
    alpha3: 'CHL',
    countryCode: '152',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'South America',
    regionCode: '019',
    subRegionCode: '419',
    intermediateRegionCode: '005',
  },
  CN: {
    name: 'China',
    alpha2: 'CN',
    alpha3: 'CHN',
    countryCode: '156',
    region: 'Asia',
    subRegion: 'Eastern Asia',
    intermediateRegion: '',
    regionCode: '142',
    subRegionCode: '030',
    intermediateRegionCode: '',
  },
  CX: {
    name: 'Christmas Island',
    alpha2: 'CX',
    alpha3: 'CXR',
    countryCode: '162',
    region: 'Oceania',
    subRegion: 'Australia and New Zealand',
    intermediateRegion: '',
    regionCode: '009',
    subRegionCode: '053',
    intermediateRegionCode: '',
  },
  CC: {
    name: 'Cocos (Keeling) Islands',
    alpha2: 'CC',
    alpha3: 'CCK',
    countryCode: '166',
    region: 'Oceania',
    subRegion: 'Australia and New Zealand',
    intermediateRegion: '',
    regionCode: '009',
    subRegionCode: '053',
    intermediateRegionCode: '',
  },
  CO: {
    name: 'Colombia',
    alpha2: 'CO',
    alpha3: 'COL',
    countryCode: '170',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'South America',
    regionCode: '019',
    subRegionCode: '419',
    intermediateRegionCode: '005',
  },
  KM: {
    name: 'Comoros',
    alpha2: 'KM',
    alpha3: 'COM',
    countryCode: '174',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Eastern Africa',
    regionCode: '002',
    subRegionCode: '202',
    intermediateRegionCode: '014',
  },
  CG: {
    name: 'Congo',
    alpha2: 'CG',
    alpha3: 'COG',
    countryCode: '178',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Middle Africa',
    regionCode: '002',
    subRegionCode: '202',
    intermediateRegionCode: '017',
  },
  CD: {
    name: 'Congo, Democratic Republic of the',
    alpha2: 'CD',
    alpha3: 'COD',
    countryCode: '180',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Middle Africa',
    regionCode: '002',
    subRegionCode: '202',
    intermediateRegionCode: '017',
  },
  CK: {
    name: 'Cook Islands',
    alpha2: 'CK',
    alpha3: 'COK',
    countryCode: '184',
    region: 'Oceania',
    subRegion: 'Polynesia',
    intermediateRegion: '',
    regionCode: '009',
    subRegionCode: '061',
    intermediateRegionCode: '',
  },
  CR: {
    name: 'Costa Rica',
    alpha2: 'CR',
    alpha3: 'CRI',
    countryCode: '188',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'Central America',
    regionCode: '019',
    subRegionCode: '419',
    intermediateRegionCode: '013',
  },
  CI: {
    name: "Côte d'Ivoire",
    alpha2: 'CI',
    alpha3: 'CIV',
    countryCode: '384',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Western Africa',
    regionCode: '002',
    subRegionCode: '202',
    intermediateRegionCode: '011',
  },
  HR: {
    name: 'Croatia',
    alpha2: 'HR',
    alpha3: 'HRV',
    countryCode: '191',
    region: 'Europe',
    subRegion: 'Southern Europe',
    intermediateRegion: '',
    regionCode: '150',
    subRegionCode: '039',
    intermediateRegionCode: '',
  },
  CU: {
    name: 'Cuba',
    alpha2: 'CU',
    alpha3: 'CUB',
    countryCode: '192',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'Caribbean',
    regionCode: '019',
    subRegionCode: '419',
    intermediateRegionCode: '029',
  },
  CW: {
    name: 'Curaçao',
    alpha2: 'CW',
    alpha3: 'CUW',
    countryCode: '531',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'Caribbean',
    regionCode: '019',
    subRegionCode: '419',
    intermediateRegionCode: '029',
  },
  CY: {
    name: 'Cyprus',
    alpha2: 'CY',
    alpha3: 'CYP',
    countryCode: '196',
    region: 'Asia',
    subRegion: 'Western Asia',
    intermediateRegion: '',
    regionCode: '142',
    subRegionCode: '145',
    intermediateRegionCode: '',
  },
  CZ: {
    name: 'Czechia',
    alpha2: 'CZ',
    alpha3: 'CZE',
    countryCode: '203',
    region: 'Europe',
    subRegion: 'Eastern Europe',
    intermediateRegion: '',
    regionCode: '150',
    subRegionCode: '151',
    intermediateRegionCode: '',
  },
  DK: {
    name: 'Denmark',
    alpha2: 'DK',
    alpha3: 'DNK',
    countryCode: '208',
    region: 'Europe',
    subRegion: 'Northern Europe',
    intermediateRegion: '',
    regionCode: '150',
    subRegionCode: '154',
    intermediateRegionCode: '',
  },
  DJ: {
    name: 'Djibouti',
    alpha2: 'DJ',
    alpha3: 'DJI',
    countryCode: '262',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Eastern Africa',
    regionCode: '002',
    subRegionCode: '202',
    intermediateRegionCode: '014',
  },
  DM: {
    name: 'Dominica',
    alpha2: 'DM',
    alpha3: 'DMA',
    countryCode: '212',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'Caribbean',
    regionCode: '019',
    subRegionCode: '419',
    intermediateRegionCode: '029',
  },
  DO: {
    name: 'Dominican Republic',
    alpha2: 'DO',
    alpha3: 'DOM',
    countryCode: '214',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'Caribbean',
    regionCode: '019',
    subRegionCode: '419',
    intermediateRegionCode: '029',
  },
  EC: {
    name: 'Ecuador',
    alpha2: 'EC',
    alpha3: 'ECU',
    countryCode: '218',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'South America',
    regionCode: '019',
    subRegionCode: '419',
    intermediateRegionCode: '005',
  },
  EG: {
    name: 'Egypt',
    alpha2: 'EG',
    alpha3: 'EGY',
    countryCode: '818',
    region: 'Africa',
    subRegion: 'Northern Africa',
    intermediateRegion: '',
    regionCode: '002',
    subRegionCode: '015',
    intermediateRegionCode: '',
  },
  SV: {
    name: 'El Salvador',
    alpha2: 'SV',
    alpha3: 'SLV',
    countryCode: '222',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'Central America',
    regionCode: '019',
    subRegionCode: '419',
    intermediateRegionCode: '013',
  },
  GQ: {
    name: 'Equatorial Guinea',
    alpha2: 'GQ',
    alpha3: 'GNQ',
    countryCode: '226',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Middle Africa',
    regionCode: '002',
    subRegionCode: '202',
    intermediateRegionCode: '017',
  },
  ER: {
    name: 'Eritrea',
    alpha2: 'ER',
    alpha3: 'ERI',
    countryCode: '232',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Eastern Africa',
    regionCode: '002',
    subRegionCode: '202',
    intermediateRegionCode: '014',
  },
  EE: {
    name: 'Estonia',
    alpha2: 'EE',
    alpha3: 'EST',
    countryCode: '233',
    region: 'Europe',
    subRegion: 'Northern Europe',
    intermediateRegion: '',
    regionCode: '150',
    subRegionCode: '154',
    intermediateRegionCode: '',
  },
  SZ: {
    name: 'Eswatini',
    alpha2: 'SZ',
    alpha3: 'SWZ',
    countryCode: '748',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Southern Africa',
    regionCode: '002',
    subRegionCode: '202',
    intermediateRegionCode: '018',
  },
  ET: {
    name: 'Ethiopia',
    alpha2: 'ET',
    alpha3: 'ETH',
    countryCode: '231',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Eastern Africa',
    regionCode: '002',
    subRegionCode: '202',
    intermediateRegionCode: '014',
  },
  FK: {
    name: 'Falkland Islands (Malvinas)',
    alpha2: 'FK',
    alpha3: 'FLK',
    countryCode: '238',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'South America',
    regionCode: '019',
    subRegionCode: '419',
    intermediateRegionCode: '005',
  },
  FO: {
    name: 'Faroe Islands',
    alpha2: 'FO',
    alpha3: 'FRO',
    countryCode: '234',
    region: 'Europe',
    subRegion: 'Northern Europe',
    intermediateRegion: '',
    regionCode: '150',
    subRegionCode: '154',
    intermediateRegionCode: '',
  },
  FJ: {
    name: 'Fiji',
    alpha2: 'FJ',
    alpha3: 'FJI',
    countryCode: '242',
    region: 'Oceania',
    subRegion: 'Melanesia',
    intermediateRegion: '',
    regionCode: '009',
    subRegionCode: '054',
    intermediateRegionCode: '',
  },
  FI: {
    name: 'Finland',
    alpha2: 'FI',
    alpha3: 'FIN',
    countryCode: '246',
    region: 'Europe',
    subRegion: 'Northern Europe',
    intermediateRegion: '',
    regionCode: '150',
    subRegionCode: '154',
    intermediateRegionCode: '',
  },
  FR: {
    name: 'France',
    alpha2: 'FR',
    alpha3: 'FRA',
    countryCode: '250',
    region: 'Europe',
    subRegion: 'Western Europe',
    intermediateRegion: '',
    regionCode: '150',
    subRegionCode: '155',
    intermediateRegionCode: '',
  },
  GF: {
    name: 'French Guiana',
    alpha2: 'GF',
    alpha3: 'GUF',
    countryCode: '254',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'South America',
    regionCode: '019',
    subRegionCode: '419',
    intermediateRegionCode: '005',
  },
  PF: {
    name: 'French Polynesia',
    alpha2: 'PF',
    alpha3: 'PYF',
    countryCode: '258',
    region: 'Oceania',
    subRegion: 'Polynesia',
    intermediateRegion: '',
    regionCode: '009',
    subRegionCode: '061',
    intermediateRegionCode: '',
  },
  TF: {
    name: 'French Southern Territories',
    alpha2: 'TF',
    alpha3: 'ATF',
    countryCode: '260',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Eastern Africa',
    regionCode: '002',
    subRegionCode: '202',
    intermediateRegionCode: '014',
  },
  GA: {
    name: 'Gabon',
    alpha2: 'GA',
    alpha3: 'GAB',
    countryCode: '266',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Middle Africa',
    regionCode: '002',
    subRegionCode: '202',
    intermediateRegionCode: '017',
  },
  GM: {
    name: 'Gambia',
    alpha2: 'GM',
    alpha3: 'GMB',
    countryCode: '270',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Western Africa',
    regionCode: '002',
    subRegionCode: '202',
    intermediateRegionCode: '011',
  },
  GE: {
    name: 'Georgia',
    alpha2: 'GE',
    alpha3: 'GEO',
    countryCode: '268',
    region: 'Asia',
    subRegion: 'Western Asia',
    intermediateRegion: '',
    regionCode: '142',
    subRegionCode: '145',
    intermediateRegionCode: '',
  },
  DE: {
    name: 'Germany',
    alpha2: 'DE',
    alpha3: 'DEU',
    countryCode: '276',
    region: 'Europe',
    subRegion: 'Western Europe',
    intermediateRegion: '',
    regionCode: '150',
    subRegionCode: '155',
    intermediateRegionCode: '',
  },
  GH: {
    name: 'Ghana',
    alpha2: 'GH',
    alpha3: 'GHA',
    countryCode: '288',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Western Africa',
    regionCode: '002',
    subRegionCode: '202',
    intermediateRegionCode: '011',
  },
  GI: {
    name: 'Gibraltar',
    alpha2: 'GI',
    alpha3: 'GIB',
    countryCode: '292',
    region: 'Europe',
    subRegion: 'Southern Europe',
    intermediateRegion: '',
    regionCode: '150',
    subRegionCode: '039',
    intermediateRegionCode: '',
  },
  GR: {
    name: 'Greece',
    alpha2: 'GR',
    alpha3: 'GRC',
    countryCode: '300',
    region: 'Europe',
    subRegion: 'Southern Europe',
    intermediateRegion: '',
    regionCode: '150',
    subRegionCode: '039',
    intermediateRegionCode: '',
  },
  GL: {
    name: 'Greenland',
    alpha2: 'GL',
    alpha3: 'GRL',
    countryCode: '304',
    region: 'Americas',
    subRegion: 'Northern America',
    intermediateRegion: '',
    regionCode: '019',
    subRegionCode: '021',
    intermediateRegionCode: '',
  },
  GD: {
    name: 'Grenada',
    alpha2: 'GD',
    alpha3: 'GRD',
    countryCode: '308',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'Caribbean',
    regionCode: '019',
    subRegionCode: '419',
    intermediateRegionCode: '029',
  },
  GP: {
    name: 'Guadeloupe',
    alpha2: 'GP',
    alpha3: 'GLP',
    countryCode: '312',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'Caribbean',
    regionCode: '019',
    subRegionCode: '419',
    intermediateRegionCode: '029',
  },
  GU: {
    name: 'Guam',
    alpha2: 'GU',
    alpha3: 'GUM',
    countryCode: '316',
    region: 'Oceania',
    subRegion: 'Micronesia',
    intermediateRegion: '',
    regionCode: '009',
    subRegionCode: '057',
    intermediateRegionCode: '',
  },
  GT: {
    name: 'Guatemala',
    alpha2: 'GT',
    alpha3: 'GTM',
    countryCode: '320',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'Central America',
    regionCode: '019',
    subRegionCode: '419',
    intermediateRegionCode: '013',
  },
  GG: {
    name: 'Guernsey',
    alpha2: 'GG',
    alpha3: 'GGY',
    countryCode: '831',
    region: 'Europe',
    subRegion: 'Northern Europe',
    intermediateRegion: 'Channel Islands',
    regionCode: '150',
    subRegionCode: '154',
    intermediateRegionCode: '830',
  },
  GN: {
    name: 'Guinea',
    alpha2: 'GN',
    alpha3: 'GIN',
    countryCode: '324',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Western Africa',
    regionCode: '002',
    subRegionCode: '202',
    intermediateRegionCode: '011',
  },
  GW: {
    name: 'Guinea-Bissau',
    alpha2: 'GW',
    alpha3: 'GNB',
    countryCode: '624',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Western Africa',
    regionCode: '002',
    subRegionCode: '202',
    intermediateRegionCode: '011',
  },
  GY: {
    name: 'Guyana',
    alpha2: 'GY',
    alpha3: 'GUY',
    countryCode: '328',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'South America',
    regionCode: '019',
    subRegionCode: '419',
    intermediateRegionCode: '005',
  },
  HT: {
    name: 'Haiti',
    alpha2: 'HT',
    alpha3: 'HTI',
    countryCode: '332',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'Caribbean',
    regionCode: '019',
    subRegionCode: '419',
    intermediateRegionCode: '029',
  },
  HM: {
    name: 'Heard Island and McDonald Islands',
    alpha2: 'HM',
    alpha3: 'HMD',
    countryCode: '334',
    region: 'Oceania',
    subRegion: 'Australia and New Zealand',
    intermediateRegion: '',
    regionCode: '009',
    subRegionCode: '053',
    intermediateRegionCode: '',
  },
  VA: {
    name: 'Holy See',
    alpha2: 'VA',
    alpha3: 'VAT',
    countryCode: '336',
    region: 'Europe',
    subRegion: 'Southern Europe',
    intermediateRegion: '',
    regionCode: '150',
    subRegionCode: '039',
    intermediateRegionCode: '',
  },
  HN: {
    name: 'Honduras',
    alpha2: 'HN',
    alpha3: 'HND',
    countryCode: '340',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'Central America',
    regionCode: '019',
    subRegionCode: '419',
    intermediateRegionCode: '013',
  },
  HK: {
    name: 'Hong Kong',
    alpha2: 'HK',
    alpha3: 'HKG',
    countryCode: '344',
    region: 'Asia',
    subRegion: 'Eastern Asia',
    intermediateRegion: '',
    regionCode: '142',
    subRegionCode: '030',
    intermediateRegionCode: '',
  },
  HU: {
    name: 'Hungary',
    alpha2: 'HU',
    alpha3: 'HUN',
    countryCode: '348',
    region: 'Europe',
    subRegion: 'Eastern Europe',
    intermediateRegion: '',
    regionCode: '150',
    subRegionCode: '151',
    intermediateRegionCode: '',
  },
  IS: {
    name: 'Iceland',
    alpha2: 'IS',
    alpha3: 'ISL',
    countryCode: '352',
    region: 'Europe',
    subRegion: 'Northern Europe',
    intermediateRegion: '',
    regionCode: '150',
    subRegionCode: '154',
    intermediateRegionCode: '',
  },
  IN: {
    name: 'India',
    alpha2: 'IN',
    alpha3: 'IND',
    countryCode: '356',
    region: 'Asia',
    subRegion: 'Southern Asia',
    intermediateRegion: '',
    regionCode: '142',
    subRegionCode: '034',
    intermediateRegionCode: '',
  },
  ID: {
    name: 'Indonesia',
    alpha2: 'ID',
    alpha3: 'IDN',
    countryCode: '360',
    region: 'Asia',
    subRegion: 'South-eastern Asia',
    intermediateRegion: '',
    regionCode: '142',
    subRegionCode: '035',
    intermediateRegionCode: '',
  },
  IR: {
    name: 'Iran (Islamic Republic of)',
    alpha2: 'IR',
    alpha3: 'IRN',
    countryCode: '364',
    region: 'Asia',
    subRegion: 'Southern Asia',
    intermediateRegion: '',
    regionCode: '142',
    subRegionCode: '034',
    intermediateRegionCode: '',
  },
  IQ: {
    name: 'Iraq',
    alpha2: 'IQ',
    alpha3: 'IRQ',
    countryCode: '368',
    region: 'Asia',
    subRegion: 'Western Asia',
    intermediateRegion: '',
    regionCode: '142',
    subRegionCode: '145',
    intermediateRegionCode: '',
  },
  IE: {
    name: 'Ireland',
    alpha2: 'IE',
    alpha3: 'IRL',
    countryCode: '372',
    region: 'Europe',
    subRegion: 'Northern Europe',
    intermediateRegion: '',
    regionCode: '150',
    subRegionCode: '154',
    intermediateRegionCode: '',
  },
  IM: {
    name: 'Isle of Man',
    alpha2: 'IM',
    alpha3: 'IMN',
    countryCode: '833',
    region: 'Europe',
    subRegion: 'Northern Europe',
    intermediateRegion: '',
    regionCode: '150',
    subRegionCode: '154',
    intermediateRegionCode: '',
  },
  IL: {
    name: 'Israel',
    alpha2: 'IL',
    alpha3: 'ISR',
    countryCode: '376',
    region: 'Asia',
    subRegion: 'Western Asia',
    intermediateRegion: '',
    regionCode: '142',
    subRegionCode: '145',
    intermediateRegionCode: '',
  },
  IT: {
    name: 'Italy',
    alpha2: 'IT',
    alpha3: 'ITA',
    countryCode: '380',
    region: 'Europe',
    subRegion: 'Southern Europe',
    intermediateRegion: '',
    regionCode: '150',
    subRegionCode: '039',
    intermediateRegionCode: '',
  },
  JM: {
    name: 'Jamaica',
    alpha2: 'JM',
    alpha3: 'JAM',
    countryCode: '388',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'Caribbean',
    regionCode: '019',
    subRegionCode: '419',
    intermediateRegionCode: '029',
  },
  JP: {
    name: 'Japan',
    alpha2: 'JP',
    alpha3: 'JPN',
    countryCode: '392',
    region: 'Asia',
    subRegion: 'Eastern Asia',
    intermediateRegion: '',
    regionCode: '142',
    subRegionCode: '030',
    intermediateRegionCode: '',
  },
  JE: {
    name: 'Jersey',
    alpha2: 'JE',
    alpha3: 'JEY',
    countryCode: '832',
    region: 'Europe',
    subRegion: 'Northern Europe',
    intermediateRegion: 'Channel Islands',
    regionCode: '150',
    subRegionCode: '154',
    intermediateRegionCode: '830',
  },
  JO: {
    name: 'Jordan',
    alpha2: 'JO',
    alpha3: 'JOR',
    countryCode: '400',
    region: 'Asia',
    subRegion: 'Western Asia',
    intermediateRegion: '',
    regionCode: '142',
    subRegionCode: '145',
    intermediateRegionCode: '',
  },
  KZ: {
    name: 'Kazakhstan',
    alpha2: 'KZ',
    alpha3: 'KAZ',
    countryCode: '398',
    region: 'Asia',
    subRegion: 'Central Asia',
    intermediateRegion: '',
    regionCode: '142',
    subRegionCode: '143',
    intermediateRegionCode: '',
  },
  KE: {
    name: 'Kenya',
    alpha2: 'KE',
    alpha3: 'KEN',
    countryCode: '404',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Eastern Africa',
    regionCode: '002',
    subRegionCode: '202',
    intermediateRegionCode: '014',
  },
  KI: {
    name: 'Kiribati',
    alpha2: 'KI',
    alpha3: 'KIR',
    countryCode: '296',
    region: 'Oceania',
    subRegion: 'Micronesia',
    intermediateRegion: '',
    regionCode: '009',
    subRegionCode: '057',
    intermediateRegionCode: '',
  },
  KP: {
    name: "Korea (Democratic People's Republic of)",
    alpha2: 'KP',
    alpha3: 'PRK',
    countryCode: '408',
    region: 'Asia',
    subRegion: 'Eastern Asia',
    intermediateRegion: '',
    regionCode: '142',
    subRegionCode: '030',
    intermediateRegionCode: '',
  },
  KR: {
    name: 'Korea, Republic of',
    alpha2: 'KR',
    alpha3: 'KOR',
    countryCode: '410',
    region: 'Asia',
    subRegion: 'Eastern Asia',
    intermediateRegion: '',
    regionCode: '142',
    subRegionCode: '030',
    intermediateRegionCode: '',
  },
  KW: {
    name: 'Kuwait',
    alpha2: 'KW',
    alpha3: 'KWT',
    countryCode: '414',
    region: 'Asia',
    subRegion: 'Western Asia',
    intermediateRegion: '',
    regionCode: '142',
    subRegionCode: '145',
    intermediateRegionCode: '',
  },
  KG: {
    name: 'Kyrgyzstan',
    alpha2: 'KG',
    alpha3: 'KGZ',
    countryCode: '417',
    region: 'Asia',
    subRegion: 'Central Asia',
    intermediateRegion: '',
    regionCode: '142',
    subRegionCode: '143',
    intermediateRegionCode: '',
  },
  LA: {
    name: "Lao People's Democratic Republic",
    alpha2: 'LA',
    alpha3: 'LAO',
    countryCode: '418',
    region: 'Asia',
    subRegion: 'South-eastern Asia',
    intermediateRegion: '',
    regionCode: '142',
    subRegionCode: '035',
    intermediateRegionCode: '',
  },
  LV: {
    name: 'Latvia',
    alpha2: 'LV',
    alpha3: 'LVA',
    countryCode: '428',
    region: 'Europe',
    subRegion: 'Northern Europe',
    intermediateRegion: '',
    regionCode: '150',
    subRegionCode: '154',
    intermediateRegionCode: '',
  },
  LB: {
    name: 'Lebanon',
    alpha2: 'LB',
    alpha3: 'LBN',
    countryCode: '422',
    region: 'Asia',
    subRegion: 'Western Asia',
    intermediateRegion: '',
    regionCode: '142',
    subRegionCode: '145',
    intermediateRegionCode: '',
  },
  LS: {
    name: 'Lesotho',
    alpha2: 'LS',
    alpha3: 'LSO',
    countryCode: '426',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Southern Africa',
    regionCode: '002',
    subRegionCode: '202',
    intermediateRegionCode: '018',
  },
  LR: {
    name: 'Liberia',
    alpha2: 'LR',
    alpha3: 'LBR',
    countryCode: '430',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Western Africa',
    regionCode: '002',
    subRegionCode: '202',
    intermediateRegionCode: '011',
  },
  LY: {
    name: 'Libya',
    alpha2: 'LY',
    alpha3: 'LBY',
    countryCode: '434',
    region: 'Africa',
    subRegion: 'Northern Africa',
    intermediateRegion: '',
    regionCode: '002',
    subRegionCode: '015',
    intermediateRegionCode: '',
  },
  LI: {
    name: 'Liechtenstein',
    alpha2: 'LI',
    alpha3: 'LIE',
    countryCode: '438',
    region: 'Europe',
    subRegion: 'Western Europe',
    intermediateRegion: '',
    regionCode: '150',
    subRegionCode: '155',
    intermediateRegionCode: '',
  },
  LT: {
    name: 'Lithuania',
    alpha2: 'LT',
    alpha3: 'LTU',
    countryCode: '440',
    region: 'Europe',
    subRegion: 'Northern Europe',
    intermediateRegion: '',
    regionCode: '150',
    subRegionCode: '154',
    intermediateRegionCode: '',
  },
  LU: {
    name: 'Luxembourg',
    alpha2: 'LU',
    alpha3: 'LUX',
    countryCode: '442',
    region: 'Europe',
    subRegion: 'Western Europe',
    intermediateRegion: '',
    regionCode: '150',
    subRegionCode: '155',
    intermediateRegionCode: '',
  },
  MO: {
    name: 'Macao',
    alpha2: 'MO',
    alpha3: 'MAC',
    countryCode: '446',
    region: 'Asia',
    subRegion: 'Eastern Asia',
    intermediateRegion: '',
    regionCode: '142',
    subRegionCode: '030',
    intermediateRegionCode: '',
  },
  MG: {
    name: 'Madagascar',
    alpha2: 'MG',
    alpha3: 'MDG',
    countryCode: '450',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Eastern Africa',
    regionCode: '002',
    subRegionCode: '202',
    intermediateRegionCode: '014',
  },
  MW: {
    name: 'Malawi',
    alpha2: 'MW',
    alpha3: 'MWI',
    countryCode: '454',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Eastern Africa',
    regionCode: '002',
    subRegionCode: '202',
    intermediateRegionCode: '014',
  },
  MY: {
    name: 'Malaysia',
    alpha2: 'MY',
    alpha3: 'MYS',
    countryCode: '458',
    region: 'Asia',
    subRegion: 'South-eastern Asia',
    intermediateRegion: '',
    regionCode: '142',
    subRegionCode: '035',
    intermediateRegionCode: '',
  },
  MV: {
    name: 'Maldives',
    alpha2: 'MV',
    alpha3: 'MDV',
    countryCode: '462',
    region: 'Asia',
    subRegion: 'Southern Asia',
    intermediateRegion: '',
    regionCode: '142',
    subRegionCode: '034',
    intermediateRegionCode: '',
  },
  ML: {
    name: 'Mali',
    alpha2: 'ML',
    alpha3: 'MLI',
    countryCode: '466',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Western Africa',
    regionCode: '002',
    subRegionCode: '202',
    intermediateRegionCode: '011',
  },
  MT: {
    name: 'Malta',
    alpha2: 'MT',
    alpha3: 'MLT',
    countryCode: '470',
    region: 'Europe',
    subRegion: 'Southern Europe',
    intermediateRegion: '',
    regionCode: '150',
    subRegionCode: '039',
    intermediateRegionCode: '',
  },
  MH: {
    name: 'Marshall Islands',
    alpha2: 'MH',
    alpha3: 'MHL',
    countryCode: '584',
    region: 'Oceania',
    subRegion: 'Micronesia',
    intermediateRegion: '',
    regionCode: '009',
    subRegionCode: '057',
    intermediateRegionCode: '',
  },
  MQ: {
    name: 'Martinique',
    alpha2: 'MQ',
    alpha3: 'MTQ',
    countryCode: '474',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'Caribbean',
    regionCode: '019',
    subRegionCode: '419',
    intermediateRegionCode: '029',
  },
  MR: {
    name: 'Mauritania',
    alpha2: 'MR',
    alpha3: 'MRT',
    countryCode: '478',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Western Africa',
    regionCode: '002',
    subRegionCode: '202',
    intermediateRegionCode: '011',
  },
  MU: {
    name: 'Mauritius',
    alpha2: 'MU',
    alpha3: 'MUS',
    countryCode: '480',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Eastern Africa',
    regionCode: '002',
    subRegionCode: '202',
    intermediateRegionCode: '014',
  },
  YT: {
    name: 'Mayotte',
    alpha2: 'YT',
    alpha3: 'MYT',
    countryCode: '175',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Eastern Africa',
    regionCode: '002',
    subRegionCode: '202',
    intermediateRegionCode: '014',
  },
  MX: {
    name: 'Mexico',
    alpha2: 'MX',
    alpha3: 'MEX',
    countryCode: '484',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'Central America',
    regionCode: '019',
    subRegionCode: '419',
    intermediateRegionCode: '013',
  },
  FM: {
    name: 'Micronesia (Federated States of)',
    alpha2: 'FM',
    alpha3: 'FSM',
    countryCode: '583',
    region: 'Oceania',
    subRegion: 'Micronesia',
    intermediateRegion: '',
    regionCode: '009',
    subRegionCode: '057',
    intermediateRegionCode: '',
  },
  MD: {
    name: 'Moldova, Republic of',
    alpha2: 'MD',
    alpha3: 'MDA',
    countryCode: '498',
    region: 'Europe',
    subRegion: 'Eastern Europe',
    intermediateRegion: '',
    regionCode: '150',
    subRegionCode: '151',
    intermediateRegionCode: '',
  },
  MC: {
    name: 'Monaco',
    alpha2: 'MC',
    alpha3: 'MCO',
    countryCode: '492',
    region: 'Europe',
    subRegion: 'Western Europe',
    intermediateRegion: '',
    regionCode: '150',
    subRegionCode: '155',
    intermediateRegionCode: '',
  },
  MN: {
    name: 'Mongolia',
    alpha2: 'MN',
    alpha3: 'MNG',
    countryCode: '496',
    region: 'Asia',
    subRegion: 'Eastern Asia',
    intermediateRegion: '',
    regionCode: '142',
    subRegionCode: '030',
    intermediateRegionCode: '',
  },
  ME: {
    name: 'Montenegro',
    alpha2: 'ME',
    alpha3: 'MNE',
    countryCode: '499',
    region: 'Europe',
    subRegion: 'Southern Europe',
    intermediateRegion: '',
    regionCode: '150',
    subRegionCode: '039',
    intermediateRegionCode: '',
  },
  MS: {
    name: 'Montserrat',
    alpha2: 'MS',
    alpha3: 'MSR',
    countryCode: '500',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'Caribbean',
    regionCode: '019',
    subRegionCode: '419',
    intermediateRegionCode: '029',
  },
  MA: {
    name: 'Morocco',
    alpha2: 'MA',
    alpha3: 'MAR',
    countryCode: '504',
    region: 'Africa',
    subRegion: 'Northern Africa',
    intermediateRegion: '',
    regionCode: '002',
    subRegionCode: '015',
    intermediateRegionCode: '',
  },
  MZ: {
    name: 'Mozambique',
    alpha2: 'MZ',
    alpha3: 'MOZ',
    countryCode: '508',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Eastern Africa',
    regionCode: '002',
    subRegionCode: '202',
    intermediateRegionCode: '014',
  },
  MM: {
    name: 'Myanmar',
    alpha2: 'MM',
    alpha3: 'MMR',
    countryCode: '104',
    region: 'Asia',
    subRegion: 'South-eastern Asia',
    intermediateRegion: '',
    regionCode: '142',
    subRegionCode: '035',
    intermediateRegionCode: '',
  },
  NA: {
    name: 'Namibia',
    alpha2: 'NA',
    alpha3: 'NAM',
    countryCode: '516',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Southern Africa',
    regionCode: '002',
    subRegionCode: '202',
    intermediateRegionCode: '018',
  },
  NR: {
    name: 'Nauru',
    alpha2: 'NR',
    alpha3: 'NRU',
    countryCode: '520',
    region: 'Oceania',
    subRegion: 'Micronesia',
    intermediateRegion: '',
    regionCode: '009',
    subRegionCode: '057',
    intermediateRegionCode: '',
  },
  NP: {
    name: 'Nepal',
    alpha2: 'NP',
    alpha3: 'NPL',
    countryCode: '524',
    region: 'Asia',
    subRegion: 'Southern Asia',
    intermediateRegion: '',
    regionCode: '142',
    subRegionCode: '034',
    intermediateRegionCode: '',
  },
  NL: {
    name: 'Netherlands',
    alpha2: 'NL',
    alpha3: 'NLD',
    countryCode: '528',
    region: 'Europe',
    subRegion: 'Western Europe',
    intermediateRegion: '',
    regionCode: '150',
    subRegionCode: '155',
    intermediateRegionCode: '',
  },
  NC: {
    name: 'New Caledonia',
    alpha2: 'NC',
    alpha3: 'NCL',
    countryCode: '540',
    region: 'Oceania',
    subRegion: 'Melanesia',
    intermediateRegion: '',
    regionCode: '009',
    subRegionCode: '054',
    intermediateRegionCode: '',
  },
  NZ: {
    name: 'New Zealand',
    alpha2: 'NZ',
    alpha3: 'NZL',
    countryCode: '554',
    region: 'Oceania',
    subRegion: 'Australia and New Zealand',
    intermediateRegion: '',
    regionCode: '009',
    subRegionCode: '053',
    intermediateRegionCode: '',
  },
  NI: {
    name: 'Nicaragua',
    alpha2: 'NI',
    alpha3: 'NIC',
    countryCode: '558',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'Central America',
    regionCode: '019',
    subRegionCode: '419',
    intermediateRegionCode: '013',
  },
  NE: {
    name: 'Niger',
    alpha2: 'NE',
    alpha3: 'NER',
    countryCode: '562',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Western Africa',
    regionCode: '002',
    subRegionCode: '202',
    intermediateRegionCode: '011',
  },
  NG: {
    name: 'Nigeria',
    alpha2: 'NG',
    alpha3: 'NGA',
    countryCode: '566',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Western Africa',
    regionCode: '002',
    subRegionCode: '202',
    intermediateRegionCode: '011',
  },
  NU: {
    name: 'Niue',
    alpha2: 'NU',
    alpha3: 'NIU',
    countryCode: '570',
    region: 'Oceania',
    subRegion: 'Polynesia',
    intermediateRegion: '',
    regionCode: '009',
    subRegionCode: '061',
    intermediateRegionCode: '',
  },
  NF: {
    name: 'Norfolk Island',
    alpha2: 'NF',
    alpha3: 'NFK',
    countryCode: '574',
    region: 'Oceania',
    subRegion: 'Australia and New Zealand',
    intermediateRegion: '',
    regionCode: '009',
    subRegionCode: '053',
    intermediateRegionCode: '',
  },
  MK: {
    name: 'North Macedonia',
    alpha2: 'MK',
    alpha3: 'MKD',
    countryCode: '807',
    region: 'Europe',
    subRegion: 'Southern Europe',
    intermediateRegion: '',
    regionCode: '150',
    subRegionCode: '039',
    intermediateRegionCode: '',
  },
  MP: {
    name: 'Northern Mariana Islands',
    alpha2: 'MP',
    alpha3: 'MNP',
    countryCode: '580',
    region: 'Oceania',
    subRegion: 'Micronesia',
    intermediateRegion: '',
    regionCode: '009',
    subRegionCode: '057',
    intermediateRegionCode: '',
  },
  NO: {
    name: 'Norway',
    alpha2: 'NO',
    alpha3: 'NOR',
    countryCode: '578',
    region: 'Europe',
    subRegion: 'Northern Europe',
    intermediateRegion: '',
    regionCode: '150',
    subRegionCode: '154',
    intermediateRegionCode: '',
  },
  OM: {
    name: 'Oman',
    alpha2: 'OM',
    alpha3: 'OMN',
    countryCode: '512',
    region: 'Asia',
    subRegion: 'Western Asia',
    intermediateRegion: '',
    regionCode: '142',
    subRegionCode: '145',
    intermediateRegionCode: '',
  },
  PK: {
    name: 'Pakistan',
    alpha2: 'PK',
    alpha3: 'PAK',
    countryCode: '586',
    region: 'Asia',
    subRegion: 'Southern Asia',
    intermediateRegion: '',
    regionCode: '142',
    subRegionCode: '034',
    intermediateRegionCode: '',
  },
  PW: {
    name: 'Palau',
    alpha2: 'PW',
    alpha3: 'PLW',
    countryCode: '585',
    region: 'Oceania',
    subRegion: 'Micronesia',
    intermediateRegion: '',
    regionCode: '009',
    subRegionCode: '057',
    intermediateRegionCode: '',
  },
  PS: {
    name: 'Palestine, State of',
    alpha2: 'PS',
    alpha3: 'PSE',
    countryCode: '275',
    region: 'Asia',
    subRegion: 'Western Asia',
    intermediateRegion: '',
    regionCode: '142',
    subRegionCode: '145',
    intermediateRegionCode: '',
  },
  PA: {
    name: 'Panama',
    alpha2: 'PA',
    alpha3: 'PAN',
    countryCode: '591',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'Central America',
    regionCode: '019',
    subRegionCode: '419',
    intermediateRegionCode: '013',
  },
  PG: {
    name: 'Papua New Guinea',
    alpha2: 'PG',
    alpha3: 'PNG',
    countryCode: '598',
    region: 'Oceania',
    subRegion: 'Melanesia',
    intermediateRegion: '',
    regionCode: '009',
    subRegionCode: '054',
    intermediateRegionCode: '',
  },
  PY: {
    name: 'Paraguay',
    alpha2: 'PY',
    alpha3: 'PRY',
    countryCode: '600',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'South America',
    regionCode: '019',
    subRegionCode: '419',
    intermediateRegionCode: '005',
  },
  PE: {
    name: 'Peru',
    alpha2: 'PE',
    alpha3: 'PER',
    countryCode: '604',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'South America',
    regionCode: '019',
    subRegionCode: '419',
    intermediateRegionCode: '005',
  },
  PH: {
    name: 'Philippines',
    alpha2: 'PH',
    alpha3: 'PHL',
    countryCode: '608',
    region: 'Asia',
    subRegion: 'South-eastern Asia',
    intermediateRegion: '',
    regionCode: '142',
    subRegionCode: '035',
    intermediateRegionCode: '',
  },
  PN: {
    name: 'Pitcairn',
    alpha2: 'PN',
    alpha3: 'PCN',
    countryCode: '612',
    region: 'Oceania',
    subRegion: 'Polynesia',
    intermediateRegion: '',
    regionCode: '009',
    subRegionCode: '061',
    intermediateRegionCode: '',
  },
  PL: {
    name: 'Poland',
    alpha2: 'PL',
    alpha3: 'POL',
    countryCode: '616',
    region: 'Europe',
    subRegion: 'Eastern Europe',
    intermediateRegion: '',
    regionCode: '150',
    subRegionCode: '151',
    intermediateRegionCode: '',
  },
  PT: {
    name: 'Portugal',
    alpha2: 'PT',
    alpha3: 'PRT',
    countryCode: '620',
    region: 'Europe',
    subRegion: 'Southern Europe',
    intermediateRegion: '',
    regionCode: '150',
    subRegionCode: '039',
    intermediateRegionCode: '',
  },
  PR: {
    name: 'Puerto Rico',
    alpha2: 'PR',
    alpha3: 'PRI',
    countryCode: '630',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'Caribbean',
    regionCode: '019',
    subRegionCode: '419',
    intermediateRegionCode: '029',
  },
  QA: {
    name: 'Qatar',
    alpha2: 'QA',
    alpha3: 'QAT',
    countryCode: '634',
    region: 'Asia',
    subRegion: 'Western Asia',
    intermediateRegion: '',
    regionCode: '142',
    subRegionCode: '145',
    intermediateRegionCode: '',
  },
  RE: {
    name: 'Réunion',
    alpha2: 'RE',
    alpha3: 'REU',
    countryCode: '638',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Eastern Africa',
    regionCode: '002',
    subRegionCode: '202',
    intermediateRegionCode: '014',
  },
  RO: {
    name: 'Romania',
    alpha2: 'RO',
    alpha3: 'ROU',
    countryCode: '642',
    region: 'Europe',
    subRegion: 'Eastern Europe',
    intermediateRegion: '',
    regionCode: '150',
    subRegionCode: '151',
    intermediateRegionCode: '',
  },
  RU: {
    name: 'Russian Federation',
    alpha2: 'RU',
    alpha3: 'RUS',
    countryCode: '643',
    region: 'Europe',
    subRegion: 'Eastern Europe',
    intermediateRegion: '',
    regionCode: '150',
    subRegionCode: '151',
    intermediateRegionCode: '',
  },
  RW: {
    name: 'Rwanda',
    alpha2: 'RW',
    alpha3: 'RWA',
    countryCode: '646',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Eastern Africa',
    regionCode: '002',
    subRegionCode: '202',
    intermediateRegionCode: '014',
  },
  BL: {
    name: 'Saint Barthélemy',
    alpha2: 'BL',
    alpha3: 'BLM',
    countryCode: '652',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'Caribbean',
    regionCode: '019',
    subRegionCode: '419',
    intermediateRegionCode: '029',
  },
  SH: {
    name: 'Saint Helena, Ascension and Tristan da Cunha',
    alpha2: 'SH',
    alpha3: 'SHN',
    countryCode: '654',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Western Africa',
    regionCode: '002',
    subRegionCode: '202',
    intermediateRegionCode: '011',
  },
  KN: {
    name: 'Saint Kitts and Nevis',
    alpha2: 'KN',
    alpha3: 'KNA',
    countryCode: '659',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'Caribbean',
    regionCode: '019',
    subRegionCode: '419',
    intermediateRegionCode: '029',
  },
  LC: {
    name: 'Saint Lucia',
    alpha2: 'LC',
    alpha3: 'LCA',
    countryCode: '662',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'Caribbean',
    regionCode: '019',
    subRegionCode: '419',
    intermediateRegionCode: '029',
  },
  MF: {
    name: 'Saint Martin (French part)',
    alpha2: 'MF',
    alpha3: 'MAF',
    countryCode: '663',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'Caribbean',
    regionCode: '019',
    subRegionCode: '419',
    intermediateRegionCode: '029',
  },
  PM: {
    name: 'Saint Pierre and Miquelon',
    alpha2: 'PM',
    alpha3: 'SPM',
    countryCode: '666',
    region: 'Americas',
    subRegion: 'Northern America',
    intermediateRegion: '',
    regionCode: '019',
    subRegionCode: '021',
    intermediateRegionCode: '',
  },
  VC: {
    name: 'Saint Vincent and the Grenadines',
    alpha2: 'VC',
    alpha3: 'VCT',
    countryCode: '670',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'Caribbean',
    regionCode: '019',
    subRegionCode: '419',
    intermediateRegionCode: '029',
  },
  WS: {
    name: 'Samoa',
    alpha2: 'WS',
    alpha3: 'WSM',
    countryCode: '882',
    region: 'Oceania',
    subRegion: 'Polynesia',
    intermediateRegion: '',
    regionCode: '009',
    subRegionCode: '061',
    intermediateRegionCode: '',
  },
  SM: {
    name: 'San Marino',
    alpha2: 'SM',
    alpha3: 'SMR',
    countryCode: '674',
    region: 'Europe',
    subRegion: 'Southern Europe',
    intermediateRegion: '',
    regionCode: '150',
    subRegionCode: '039',
    intermediateRegionCode: '',
  },
  ST: {
    name: 'Sao Tome and Principe',
    alpha2: 'ST',
    alpha3: 'STP',
    countryCode: '678',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Middle Africa',
    regionCode: '002',
    subRegionCode: '202',
    intermediateRegionCode: '017',
  },
  SA: {
    name: 'Saudi Arabia',
    alpha2: 'SA',
    alpha3: 'SAU',
    countryCode: '682',
    region: 'Asia',
    subRegion: 'Western Asia',
    intermediateRegion: '',
    regionCode: '142',
    subRegionCode: '145',
    intermediateRegionCode: '',
  },
  SN: {
    name: 'Senegal',
    alpha2: 'SN',
    alpha3: 'SEN',
    countryCode: '686',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Western Africa',
    regionCode: '002',
    subRegionCode: '202',
    intermediateRegionCode: '011',
  },
  RS: {
    name: 'Serbia',
    alpha2: 'RS',
    alpha3: 'SRB',
    countryCode: '688',
    region: 'Europe',
    subRegion: 'Southern Europe',
    intermediateRegion: '',
    regionCode: '150',
    subRegionCode: '039',
    intermediateRegionCode: '',
  },
  SC: {
    name: 'Seychelles',
    alpha2: 'SC',
    alpha3: 'SYC',
    countryCode: '690',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Eastern Africa',
    regionCode: '002',
    subRegionCode: '202',
    intermediateRegionCode: '014',
  },
  SL: {
    name: 'Sierra Leone',
    alpha2: 'SL',
    alpha3: 'SLE',
    countryCode: '694',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Western Africa',
    regionCode: '002',
    subRegionCode: '202',
    intermediateRegionCode: '011',
  },
  SG: {
    name: 'Singapore',
    alpha2: 'SG',
    alpha3: 'SGP',
    countryCode: '702',
    region: 'Asia',
    subRegion: 'South-eastern Asia',
    intermediateRegion: '',
    regionCode: '142',
    subRegionCode: '035',
    intermediateRegionCode: '',
  },
  SX: {
    name: 'Sint Maarten (Dutch part)',
    alpha2: 'SX',
    alpha3: 'SXM',
    countryCode: '534',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'Caribbean',
    regionCode: '019',
    subRegionCode: '419',
    intermediateRegionCode: '029',
  },
  SK: {
    name: 'Slovakia',
    alpha2: 'SK',
    alpha3: 'SVK',
    countryCode: '703',
    region: 'Europe',
    subRegion: 'Eastern Europe',
    intermediateRegion: '',
    regionCode: '150',
    subRegionCode: '151',
    intermediateRegionCode: '',
  },
  SI: {
    name: 'Slovenia',
    alpha2: 'SI',
    alpha3: 'SVN',
    countryCode: '705',
    region: 'Europe',
    subRegion: 'Southern Europe',
    intermediateRegion: '',
    regionCode: '150',
    subRegionCode: '039',
    intermediateRegionCode: '',
  },
  SB: {
    name: 'Solomon Islands',
    alpha2: 'SB',
    alpha3: 'SLB',
    countryCode: '090',
    region: 'Oceania',
    subRegion: 'Melanesia',
    intermediateRegion: '',
    regionCode: '009',
    subRegionCode: '054',
    intermediateRegionCode: '',
  },
  SO: {
    name: 'Somalia',
    alpha2: 'SO',
    alpha3: 'SOM',
    countryCode: '706',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Eastern Africa',
    regionCode: '002',
    subRegionCode: '202',
    intermediateRegionCode: '014',
  },
  ZA: {
    name: 'South Africa',
    alpha2: 'ZA',
    alpha3: 'ZAF',
    countryCode: '710',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Southern Africa',
    regionCode: '002',
    subRegionCode: '202',
    intermediateRegionCode: '018',
  },
  GS: {
    name: 'South Georgia and the South Sandwich Islands',
    alpha2: 'GS',
    alpha3: 'SGS',
    countryCode: '239',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'South America',
    regionCode: '019',
    subRegionCode: '419',
    intermediateRegionCode: '005',
  },
  SS: {
    name: 'South Sudan',
    alpha2: 'SS',
    alpha3: 'SSD',
    countryCode: '728',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Eastern Africa',
    regionCode: '002',
    subRegionCode: '202',
    intermediateRegionCode: '014',
  },
  ES: {
    name: 'Spain',
    alpha2: 'ES',
    alpha3: 'ESP',
    countryCode: '724',
    region: 'Europe',
    subRegion: 'Southern Europe',
    intermediateRegion: '',
    regionCode: '150',
    subRegionCode: '039',
    intermediateRegionCode: '',
  },
  LK: {
    name: 'Sri Lanka',
    alpha2: 'LK',
    alpha3: 'LKA',
    countryCode: '144',
    region: 'Asia',
    subRegion: 'Southern Asia',
    intermediateRegion: '',
    regionCode: '142',
    subRegionCode: '034',
    intermediateRegionCode: '',
  },
  SD: {
    name: 'Sudan',
    alpha2: 'SD',
    alpha3: 'SDN',
    countryCode: '729',
    region: 'Africa',
    subRegion: 'Northern Africa',
    intermediateRegion: '',
    regionCode: '002',
    subRegionCode: '015',
    intermediateRegionCode: '',
  },
  SR: {
    name: 'Suriname',
    alpha2: 'SR',
    alpha3: 'SUR',
    countryCode: '740',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'South America',
    regionCode: '019',
    subRegionCode: '419',
    intermediateRegionCode: '005',
  },
  SJ: {
    name: 'Svalbard and Jan Mayen',
    alpha2: 'SJ',
    alpha3: 'SJM',
    countryCode: '744',
    region: 'Europe',
    subRegion: 'Northern Europe',
    intermediateRegion: '',
    regionCode: '150',
    subRegionCode: '154',
    intermediateRegionCode: '',
  },
  SE: {
    name: 'Sweden',
    alpha2: 'SE',
    alpha3: 'SWE',
    countryCode: '752',
    region: 'Europe',
    subRegion: 'Northern Europe',
    intermediateRegion: '',
    regionCode: '150',
    subRegionCode: '154',
    intermediateRegionCode: '',
  },
  CH: {
    name: 'Switzerland',
    alpha2: 'CH',
    alpha3: 'CHE',
    countryCode: '756',
    region: 'Europe',
    subRegion: 'Western Europe',
    intermediateRegion: '',
    regionCode: '150',
    subRegionCode: '155',
    intermediateRegionCode: '',
  },
  SY: {
    name: 'Syrian Arab Republic',
    alpha2: 'SY',
    alpha3: 'SYR',
    countryCode: '760',
    region: 'Asia',
    subRegion: 'Western Asia',
    intermediateRegion: '',
    regionCode: '142',
    subRegionCode: '145',
    intermediateRegionCode: '',
  },
  TW: {
    name: 'Taiwan, Province of China',
    alpha2: 'TW',
    alpha3: 'TWN',
    countryCode: '158',
    region: 'Asia',
    subRegion: 'Eastern Asia',
    intermediateRegion: '',
    regionCode: '142',
    subRegionCode: '030',
    intermediateRegionCode: '',
  },
  TJ: {
    name: 'Tajikistan',
    alpha2: 'TJ',
    alpha3: 'TJK',
    countryCode: '762',
    region: 'Asia',
    subRegion: 'Central Asia',
    intermediateRegion: '',
    regionCode: '142',
    subRegionCode: '143',
    intermediateRegionCode: '',
  },
  TZ: {
    name: 'Tanzania, United Republic of',
    alpha2: 'TZ',
    alpha3: 'TZA',
    countryCode: '834',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Eastern Africa',
    regionCode: '002',
    subRegionCode: '202',
    intermediateRegionCode: '014',
  },
  TH: {
    name: 'Thailand',
    alpha2: 'TH',
    alpha3: 'THA',
    countryCode: '764',
    region: 'Asia',
    subRegion: 'South-eastern Asia',
    intermediateRegion: '',
    regionCode: '142',
    subRegionCode: '035',
    intermediateRegionCode: '',
  },
  TL: {
    name: 'Timor-Leste',
    alpha2: 'TL',
    alpha3: 'TLS',
    countryCode: '626',
    region: 'Asia',
    subRegion: 'South-eastern Asia',
    intermediateRegion: '',
    regionCode: '142',
    subRegionCode: '035',
    intermediateRegionCode: '',
  },
  TG: {
    name: 'Togo',
    alpha2: 'TG',
    alpha3: 'TGO',
    countryCode: '768',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Western Africa',
    regionCode: '002',
    subRegionCode: '202',
    intermediateRegionCode: '011',
  },
  TK: {
    name: 'Tokelau',
    alpha2: 'TK',
    alpha3: 'TKL',
    countryCode: '772',
    region: 'Oceania',
    subRegion: 'Polynesia',
    intermediateRegion: '',
    regionCode: '009',
    subRegionCode: '061',
    intermediateRegionCode: '',
  },
  TO: {
    name: 'Tonga',
    alpha2: 'TO',
    alpha3: 'TON',
    countryCode: '776',
    region: 'Oceania',
    subRegion: 'Polynesia',
    intermediateRegion: '',
    regionCode: '009',
    subRegionCode: '061',
    intermediateRegionCode: '',
  },
  TT: {
    name: 'Trinidad and Tobago',
    alpha2: 'TT',
    alpha3: 'TTO',
    countryCode: '780',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'Caribbean',
    regionCode: '019',
    subRegionCode: '419',
    intermediateRegionCode: '029',
  },
  TN: {
    name: 'Tunisia',
    alpha2: 'TN',
    alpha3: 'TUN',
    countryCode: '788',
    region: 'Africa',
    subRegion: 'Northern Africa',
    intermediateRegion: '',
    regionCode: '002',
    subRegionCode: '015',
    intermediateRegionCode: '',
  },
  TR: {
    name: 'Turkey',
    alpha2: 'TR',
    alpha3: 'TUR',
    countryCode: '792',
    region: 'Asia',
    subRegion: 'Western Asia',
    intermediateRegion: '',
    regionCode: '142',
    subRegionCode: '145',
    intermediateRegionCode: '',
  },
  TM: {
    name: 'Turkmenistan',
    alpha2: 'TM',
    alpha3: 'TKM',
    countryCode: '795',
    region: 'Asia',
    subRegion: 'Central Asia',
    intermediateRegion: '',
    regionCode: '142',
    subRegionCode: '143',
    intermediateRegionCode: '',
  },
  TC: {
    name: 'Turks and Caicos Islands',
    alpha2: 'TC',
    alpha3: 'TCA',
    countryCode: '796',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'Caribbean',
    regionCode: '019',
    subRegionCode: '419',
    intermediateRegionCode: '029',
  },
  TV: {
    name: 'Tuvalu',
    alpha2: 'TV',
    alpha3: 'TUV',
    countryCode: '798',
    region: 'Oceania',
    subRegion: 'Polynesia',
    intermediateRegion: '',
    regionCode: '009',
    subRegionCode: '061',
    intermediateRegionCode: '',
  },
  UG: {
    name: 'Uganda',
    alpha2: 'UG',
    alpha3: 'UGA',
    countryCode: '800',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Eastern Africa',
    regionCode: '002',
    subRegionCode: '202',
    intermediateRegionCode: '014',
  },
  UA: {
    name: 'Ukraine',
    alpha2: 'UA',
    alpha3: 'UKR',
    countryCode: '804',
    region: 'Europe',
    subRegion: 'Eastern Europe',
    intermediateRegion: '',
    regionCode: '150',
    subRegionCode: '151',
    intermediateRegionCode: '',
  },
  AE: {
    name: 'United Arab Emirates',
    alpha2: 'AE',
    alpha3: 'ARE',
    countryCode: '784',
    region: 'Asia',
    subRegion: 'Western Asia',
    intermediateRegion: '',
    regionCode: '142',
    subRegionCode: '145',
    intermediateRegionCode: '',
  },
  GB: {
    name: 'United Kingdom of Great Britain and Northern Ireland',
    alpha2: 'GB',
    alpha3: 'GBR',
    countryCode: '826',
    region: 'Europe',
    subRegion: 'Northern Europe',
    intermediateRegion: '',
    regionCode: '150',
    subRegionCode: '154',
    intermediateRegionCode: '',
  },
  US: {
    name: 'United States of America',
    alpha2: 'US',
    alpha3: 'USA',
    countryCode: '840',
    region: 'Americas',
    subRegion: 'Northern America',
    intermediateRegion: '',
    regionCode: '019',
    subRegionCode: '021',
    intermediateRegionCode: '',
  },
  UM: {
    name: 'United States Minor Outlying Islands',
    alpha2: 'UM',
    alpha3: 'UMI',
    countryCode: '581',
    region: 'Oceania',
    subRegion: 'Micronesia',
    intermediateRegion: '',
    regionCode: '009',
    subRegionCode: '057',
    intermediateRegionCode: '',
  },
  UY: {
    name: 'Uruguay',
    alpha2: 'UY',
    alpha3: 'URY',
    countryCode: '858',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'South America',
    regionCode: '019',
    subRegionCode: '419',
    intermediateRegionCode: '005',
  },
  UZ: {
    name: 'Uzbekistan',
    alpha2: 'UZ',
    alpha3: 'UZB',
    countryCode: '860',
    region: 'Asia',
    subRegion: 'Central Asia',
    intermediateRegion: '',
    regionCode: '142',
    subRegionCode: '143',
    intermediateRegionCode: '',
  },
  VU: {
    name: 'Vanuatu',
    alpha2: 'VU',
    alpha3: 'VUT',
    countryCode: '548',
    region: 'Oceania',
    subRegion: 'Melanesia',
    intermediateRegion: '',
    regionCode: '009',
    subRegionCode: '054',
    intermediateRegionCode: '',
  },
  VE: {
    name: 'Venezuela (Bolivarian Republic of)',
    alpha2: 'VE',
    alpha3: 'VEN',
    countryCode: '862',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'South America',
    regionCode: '019',
    subRegionCode: '419',
    intermediateRegionCode: '005',
  },
  VN: {
    name: 'Viet Nam',
    alpha2: 'VN',
    alpha3: 'VNM',
    countryCode: '704',
    region: 'Asia',
    subRegion: 'South-eastern Asia',
    intermediateRegion: '',
    regionCode: '142',
    subRegionCode: '035',
    intermediateRegionCode: '',
  },
  VG: {
    name: 'Virgin Islands (British)',
    alpha2: 'VG',
    alpha3: 'VGB',
    countryCode: '092',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'Caribbean',
    regionCode: '019',
    subRegionCode: '419',
    intermediateRegionCode: '029',
  },
  VI: {
    name: 'Virgin Islands (U.S.)',
    alpha2: 'VI',
    alpha3: 'VIR',
    countryCode: '850',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'Caribbean',
    regionCode: '019',
    subRegionCode: '419',
    intermediateRegionCode: '029',
  },
  WF: {
    name: 'Wallis and Futuna',
    alpha2: 'WF',
    alpha3: 'WLF',
    countryCode: '876',
    region: 'Oceania',
    subRegion: 'Polynesia',
    intermediateRegion: '',
    regionCode: '009',
    subRegionCode: '061',
    intermediateRegionCode: '',
  },
  EH: {
    name: 'Western Sahara',
    alpha2: 'EH',
    alpha3: 'ESH',
    countryCode: '732',
    region: 'Africa',
    subRegion: 'Northern Africa',
    intermediateRegion: '',
    regionCode: '002',
    subRegionCode: '015',
    intermediateRegionCode: '',
  },
  YE: {
    name: 'Yemen',
    alpha2: 'YE',
    alpha3: 'YEM',
    countryCode: '887',
    region: 'Asia',
    subRegion: 'Western Asia',
    intermediateRegion: '',
    regionCode: '142',
    subRegionCode: '145',
    intermediateRegionCode: '',
  },
  ZM: {
    name: 'Zambia',
    alpha2: 'ZM',
    alpha3: 'ZMB',
    countryCode: '894',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Eastern Africa',
    regionCode: '002',
    subRegionCode: '202',
    intermediateRegionCode: '014',
  },
  ZW: {
    name: 'Zimbabwe',
    alpha2: 'ZW',
    alpha3: 'ZWE',
    countryCode: '716',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Eastern Africa',
    regionCode: '002',
    subRegionCode: '202',
    intermediateRegionCode: '014',
  },
};
