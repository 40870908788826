import { useCallback } from 'react';
import { authClient } from '../../api/authClient';
import { gql } from '@apollo/client';

const loginGql = gql`
  fragment Payload on REST {
    email: String
    password: String
  }
  query Login($input: Payload!) {
    login(input: $input) @rest(path: "login", method: "POST") {
      access_token
    }
  }
`;

export function usePasswordLogin() {
  const execute = useCallback(
    ({ email, password }: { email: string; password: string }) => {
      return authClient.query({
        query: loginGql,
        variables: {
          input: { email, password },
        },
      });
    },
    []
  );
  return [execute];
}
