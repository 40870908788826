// eslint-disable-next-line @typescript-eslint/no-unused-vars

import { CssBaseline } from '@mui/material';
import { GoogleOAuthProvider } from '@react-oauth/google';
import flagsmith from 'flagsmith';
import { FlagsmithProvider } from 'flagsmith/react';
import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { RouterProvider } from 'react-router-dom';
import { env } from '../environments/env';
import { WrappedAppolloProvider } from './api/apolloClient';
import { useSilenceResizeLoopError } from './hooks/useSilenceResizeLoopError';
import CustomTheme from './layout/theme';
import { GOOGLE_OAUTH_CLIENT_ID } from './lib/constants';
import reactRouter from './reactRouter';
import './vendor/i18n/i18n';

export function App() {
  useSilenceResizeLoopError();

  return (
    <React.StrictMode>
      <HelmetProvider>
        <CustomTheme>
          <FlagsmithProvider
            options={{
              environmentID: env.flagsmith.environmentId,
              cacheFlags: env.flagsmith.cacheFlags,
              cacheOptions: {
                ttl: env.flagsmith.cacheTTL, // to clean flag cache: localStorage.removeItem('BULLET_TRAIN_DB')
                skipAPI: true,
              },
            }}
            flagsmith={flagsmith}
          >
            <GoogleOAuthProvider clientId={GOOGLE_OAUTH_CLIENT_ID}>
              <WrappedAppolloProvider>
                <CssBaseline />
                <RouterProvider router={reactRouter} />
              </WrappedAppolloProvider>
            </GoogleOAuthProvider>
          </FlagsmithProvider>
        </CustomTheme>
      </HelmetProvider>
    </React.StrictMode>
  );
}

export default App;
