import { SurveyProvider, defaultGlobalLevels } from '@alamere/core';
import {
  useGlobalLevelsSaveMutation,
  useJobLevelSaveMutation,
} from '@alamere/generated-graphql-types';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { tParent } from '../../vendor/i18n/i18n';
import { radfordDefaultLevels } from './radfordDefaultLevels';

const t = tParent('jobArchitecturePage.onboarding');

export interface SurveyProviderSelectProps {
  onSave: () => void;
  saving: boolean;
}

export default function SurveyProviderSelect({
  onSave,
  saving,
}: SurveyProviderSelectProps) {
  const [value, setValue] = useState<SurveyProvider | ''>('');
  const [saveJobLevels, { loading: savingJobLevels }] =
    useJobLevelSaveMutation();

  const [saveGlobalLevel, { loading: savingGlobalLevel }] =
    useGlobalLevelsSaveMutation();

  const handleSave = async () => {
    if (value === '') {
      return;
    }
    const saveJobLevelsPromise = saveJobLevels({
      variables: {
        request: {
          items: radfordDefaultLevels,
        },
      },
    });

    const saveGlobalLevelPromise = saveGlobalLevel({
      variables: {
        request: {
          items: Object.values(defaultGlobalLevels),
        },
      },
    });

    await Promise.all([saveJobLevelsPromise, saveGlobalLevelPromise]);

    onSave();
  };
  return (
    <Paper sx={{ width: 'fit-content', p: 2 }}>
      <Stack spacing={3}>
        <Typography variant="h5">{t('title')}</Typography>
        <FormControl fullWidth>
          <InputLabel id="survey-provider">{t('selectLabel')}</InputLabel>
          <Select
            labelId="survey-provider"
            id="survey-provider"
            value={value}
            label={t('selectLabel')}
            onChange={(event) => setValue(event.target.value as SurveyProvider)}
          >
            {Object.values(SurveyProvider).map((value) => {
              return (
                <MenuItem key={value} value={value}>
                  {value}
                </MenuItem>
              );
            })}
            <Typography
              variant="caption"
              color="text.secondary"
              textAlign="center"
              p={2}
            >
              {t('caption')}
            </Typography>
          </Select>
        </FormControl>
        <Button
          variant="contained"
          disabled={value === '' || saving || savingJobLevels}
          onClick={handleSave}
        >
          {t('save')}
        </Button>
      </Stack>
    </Paper>
  );
}
