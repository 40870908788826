import {
  GlobalLevelFragment,
  GlobalLevelSaveRequest,
} from '@alamere/generated-graphql-types';
import {
  InputAdornment,
  OutlinedInput,
  Stack,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
import { memo, useState } from 'react';

export type OnGlobalLevelChangeFunction = (
  globalLevel: GlobalLevelSaveRequest
) => void;

export interface RsuOptionsMixProps {
  globalLevel: GlobalLevelFragment;
  editing: boolean;
  onGlobalLevelChange: OnGlobalLevelChangeFunction;
}

export default memo(function RsuOptionsMix({
  globalLevel: globalLevelFromProps,
  editing,
  onGlobalLevelChange,
}: RsuOptionsMixProps) {
  const [globalLevel, setGlobalLevel] = useState(globalLevelFromProps);

  const handleRsuChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (Number(e.target.value) > 100 || isNaN(Number(e.target.value))) {
      return;
    }
    const newGlobalLevel = {
      ...globalLevel,
      percentRsu: Number(e.target.value) || 0,
    };
    setGlobalLevel(newGlobalLevel);
    onGlobalLevelChange(newGlobalLevel);
  };

  const handleOptionsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (Number(e.target.value) > 100 || isNaN(Number(e.target.value))) {
      return;
    }
    const newGlobalLevel = {
      ...globalLevel,
      percentRsu: 100 - (Number(e.target.value) || 0),
    };
    setGlobalLevel(newGlobalLevel);
    onGlobalLevelChange(newGlobalLevel);
  };

  const percentRsu = globalLevel.percentRsu;

  if (!editing) {
    return (
      <Typography>
        {percentRsu}:{100 - percentRsu}
      </Typography>
    );
  }

  return (
    <Stack
      direction="row"
      spacing={0.5}
      alignItems="center"
      width="fit-content"
      minWidth={editing ? 180 : 125}
      // maxWidth={240}
    >
      <OutlinedInput
        inputProps={{ style: { textAlign: 'center' } }}
        size="small"
        value={percentRsu}
        endAdornment={
          <InputAdornment position="end">
            <Stack alignItems="center" spacing={-1}>
              <Typography>%</Typography>
              <Typography fontSize={10}>{t('global.rsu')}</Typography>
            </Stack>
          </InputAdornment>
        }
        onChange={handleRsuChange}
      />

      <OutlinedInput
        inputProps={{ style: { textAlign: 'center' } }}
        size="small"
        value={100 - percentRsu}
        endAdornment={
          <InputAdornment position="end">
            <Stack alignItems="center" spacing={-1}>
              <Typography>%</Typography>
              <Typography fontSize={10}>
                {' '}
                {t('global.optionsAbbreviated')}
              </Typography>
            </Stack>
          </InputAdornment>
        }
        onChange={handleOptionsChange}
      />
    </Stack>
  );
});
