import { Scope } from '@alamere/core';
import {
  EquityBandFragmentDoc,
  EquityBandGroupFragment,
  EquityBandGroupFragmentDoc,
  EquityBandGroupSaveRequest,
  EquityBandGroupsDocument,
  EquityBandSaveRequest,
  GlobalLevelFragmentDoc,
  GlobalLevelSaveRequest,
  SettingProperty,
  SettingType,
  SettingsDocument,
  useEquityBandGroupCreateMutation,
  useEquityBandGroupsQuery,
  useEquityBandGroupsSaveMutation,
  useEquityBandSaveMutation,
  useGlobalLevelsQuery,
  useGlobalLevelsSaveMutation,
  useRangeWidthGroupsQuery,
} from '@alamere/generated-graphql-types';
import { useApolloClient } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import {
  Alert,
  Button,
  IconButton,
  Stack,
  Typography,
  debounce,
} from '@mui/material';
import { t } from 'i18next';
import { useCallback, useState } from 'react';
import { EditButtons } from '../../../components/EditButtons';
import { useScopes } from '../../../hooks/useScopes';
import { useSetting } from '../../../hooks/useSetting';
import { useWorkspaceNavigate } from '../../../hooks/useWorkspaceNavigate';
import { DEBOUNCE_MS } from '../../../lib/constants';
import EquityBandsSettings from './EquityBandsSettings';
import EquityBandsTable from './EquityBandsTable';
import { OnGlobalLevelChangeFunction } from './RsuOptionsMix';
import { OnBandChangeFunction, OnGroupChangeFunction } from './types';

export default function EquityBandsPage() {
  const wNavigate = useWorkspaceNavigate();
  const client = useApolloClient();
  const { checkScopes } = useScopes();

  const [editing, setEditing] = useState(false);
  const [groupUpdates, setGroupUpdates] = useState<
    Record<string, EquityBandGroupSaveRequest>
  >({});
  const [bandUpdates, setBandUpdates] = useState<
    Record<string, EquityBandSaveRequest>
  >({});
  const [globalLevelUpdates, setGlobalLevelUpdates] = useState<
    Record<string, GlobalLevelSaveRequest>
  >({});

  const { data: equityBandGroupsData, loading: loadingEquityBandGroups } =
    useEquityBandGroupsQuery();
  const { data: globalLevelsData, loading: loadingGlobalLevels } =
    useGlobalLevelsQuery();
  const { data: rangeWidthGroupsData, loading: loadingRangeWidthGroups } =
    useRangeWidthGroupsQuery();
  const [saveGroupUpdates] = useEquityBandGroupsSaveMutation();
  const [saveBandUpdates] = useEquityBandSaveMutation();
  const [saveGlobalLevelUpdates] = useGlobalLevelsSaveMutation();
  const [createNewGroup] = useEquityBandGroupCreateMutation({
    update: (cache, { data }) => {
      const group = data?.equityBandGroupCreate;
      if (!group) return;

      cache.modify({
        fields: {
          equityBandGroups(existingGroups = []) {
            const newGroupRef = cache.writeFragment({
              id: `EquityBandGroup:${group.id}`,
              data: group,
              fragment: EquityBandGroupFragmentDoc,
              fragmentName: 'EquityBandGroup',
            });
            return [...existingGroups, newGroupRef];
          },
        },
      });
    },
  });

  const { save: saveStockPrice, cancel: cancelStockPrice } = useSetting(
    SettingProperty.PREFERRED_STOCK_PRICE,
    SettingType.WORKSPACE
  );

  const { save: saveStrikePrice, cancel: cancelStrikePrice } = useSetting(
    SettingProperty.STRIKE_PRICE,
    SettingType.WORKSPACE
  );

  const handleSave = async () => {
    Promise.all([
      saveStockPrice(),
      saveStrikePrice(),
      saveGroupUpdates({
        variables: {
          equityBandGroupSaveManyRequest: {
            items: Object.values(groupUpdates),
          },
        },
      }),
      saveBandUpdates({
        variables: {
          equityBandSaveManyRequest: {
            items: Object.values(bandUpdates),
          },
        },
      }),
      saveGlobalLevelUpdates({
        variables: {
          request: {
            items: Object.values(globalLevelUpdates),
          },
        },
      }),
    ]);

    setGroupUpdates({});
    setBandUpdates({});
    setGlobalLevelUpdates({});
    setEditing(false);
  };

  const handleCancel = async () => {
    client.refetchQueries({
      include: [SettingsDocument, EquityBandGroupsDocument],
    });
    setGroupUpdates({});
    setBandUpdates({});
    setGlobalLevelUpdates({});
    setEditing(false);
  };

  const handleCreateNewGroup = async () => {
    await createNewGroup({
      variables: {
        request: {
          name: t('equityPage.bands.newGroupName'),
        },
      },
    });
  };

  const handleGroupChange = useCallback<OnGroupChangeFunction>(
    debounce((newGroup) => {
      setGroupUpdates((prev) => {
        if (!newGroup.id) {
          return prev;
        }
        return {
          ...prev,
          [newGroup.id]: {
            name: newGroup.name,
            id: newGroup.id,
            dynamicPercent: newGroup.dynamicPercent,
            dynamicParentGroupId: newGroup.dynamicParentGroupId,
            rangeWidthGroupId: newGroup.rangeWidthGroupId,
          },
        };
      });

      client.cache.writeFragment({
        id: `EquityBandGroup:${newGroup.id}`,
        fragmentName: 'EquityBandGroup',
        fragment: EquityBandGroupFragmentDoc,
        data: newGroup,
      });

      if (
        shouldUpdateDynamicValues(
          equityBandGroupsData?.equityBandGroups,
          newGroup
        )
      ) {
        // update bands
        // TODO: Should/can we resolve this on the cell level for each band and let the band decide if it needs updating?

        const dynamicParentGroup = equityBandGroupsData?.equityBandGroups.find(
          (grp) => grp.id === newGroup.dynamicParentGroupId
        );
        if (dynamicParentGroup) {
          globalLevelsData?.globalLevels.forEach((globalLevel) => {
            const parentBand = dynamicParentGroup.equityBands.find(
              (parentBand) => parentBand.globalLevelLevel === globalLevel.level
            );
            const band = newGroup.equityBands.find(
              (band) => band.globalLevelLevel === globalLevel.level
            );
            const newBand = {
              ...band,
              value:
                (parentBand?.value || 0) *
                ((newGroup.dynamicPercent || 0) / 100),
            };

            client.cache.writeFragment({
              id: client.cache.identify({
                equityBandGroupId: newGroup.id,
                globalLevelLevel: globalLevel.level,
                __typename: 'EquityBand',
              }),
              fragmentName: 'EquityBand',
              fragment: EquityBandFragmentDoc,
              data: newBand,
            });
          });
        }
      }
    }, DEBOUNCE_MS),
    [client, setGroupUpdates, equityBandGroupsData]
  );

  const handleBandChange = useCallback<OnBandChangeFunction>(
    (update: EquityBandSaveRequest) => {
      setBandUpdates((prev) => {
        if (!update.id) {
          return prev;
        }
        return {
          ...prev,
          [update.id]: update,
        };
      });

      client.cache.writeFragment({
        id: client.cache.identify({
          equityBandGroupId: update.equityBandGroupId,
          globalLevelLevel: update.globalLevelLevel,
          __typename: 'EquityBand',
        }),
        fragmentName: 'EquityBand',
        fragment: EquityBandFragmentDoc,
        data: update,
      });
    },
    [client, setBandUpdates]
  );

  const handleGlobalLevelChange = useCallback<OnGlobalLevelChangeFunction>(
    (newGlobalLevel) => {
      setGlobalLevelUpdates((prev) => {
        if (!newGlobalLevel.level) {
          return prev;
        }
        return {
          ...prev,
          [newGlobalLevel.level]: {
            level: newGlobalLevel.level,
            percentRsu: newGlobalLevel.percentRsu,
            name: newGlobalLevel.name,
          },
        };
      });

      client.cache.writeFragment({
        id: client.cache.identify({
          level: newGlobalLevel.level,
          __typename: 'GlobalLevel',
        }),
        fragmentName: 'GlobalLevel',
        fragment: GlobalLevelFragmentDoc,
        data: newGlobalLevel,
      });
    },
    [setGlobalLevelUpdates, client.cache.writeFragment, client.cache.identify]
  );

  const notConfigured =
    !loadingEquityBandGroups &&
    equityBandGroupsData?.equityBandGroups.length === 0;

  const rangesNotConfigured =
    !loadingRangeWidthGroups &&
    rangeWidthGroupsData?.rangeWidthGroups.length === 0;
  const globalLevelsNotConfigured =
    !loadingGlobalLevels && globalLevelsData?.globalLevels.length === 0;

  if (globalLevelsNotConfigured || rangesNotConfigured) {
    return (
      <Stack spacing={2} maxWidth="sm">
        {globalLevelsNotConfigured && (
          <Alert
            severity="warning"
            action={
              <Button
                color="inherit"
                size="small"
                onClick={() => wNavigate('/job-architecture')}
              >
                {t('equityPage.bands.configureGlobalLevels')}
              </Button>
            }
          >
            {t('equityPage.bands.globalLevelsNotConfigured')}
          </Alert>
        )}
        {rangesNotConfigured && (
          <Alert
            severity="warning"
            action={
              <Button
                color="inherit"
                size="small"
                onClick={() => wNavigate('/range-widths')}
              >
                {t('equityPage.bands.configureRangeWidthGroups')}
              </Button>
            }
          >
            {t('equityPage.bands.rangeWidthGroupsNotConfigured')}
          </Alert>
        )}
      </Stack>
    );
  }

  return (
    <Stack spacing={2}>
      {checkScopes(Scope.EQUITY_BANDS_EDIT) && (
        <EditButtons
          onEdit={() => setEditing(true)}
          onSave={() => {
            handleSave();
          }}
          onCancel={handleCancel}
        />
      )}
      {!notConfigured && (
        <Typography>{t('equityPage.bands.equityUnitMessage')}</Typography>
      )}
      {notConfigured && !editing ? (
        <Stack width="fit-content" spacing={2}>
          <Typography>{t('equityPage.bands.noData')}</Typography>
          {editing && (
            <Button
              variant="contained"
              aria-label={t('equityPage.bands.createNewGroup')}
              title={t('equityPage.bands.createNewGroup')}
              onClick={handleCreateNewGroup}
            >
              {t('equityPage.bands.createNewGroup')}
            </Button>
          )}
        </Stack>
      ) : (
        <Stack spacing={2}>
          <EquityBandsSettings editing={editing} />
          <Stack direction="row" spacing={1}>
            <EquityBandsTable
              editing={editing}
              onGroupChange={handleGroupChange}
              onBandChange={handleBandChange}
              onGlobalLevelChange={handleGlobalLevelChange}
            />
            {editing && (
              <IconButton
                aria-label={t('equityPage.bands.createNewGroup')}
                sx={{ height: 'fit-content' }}
                title={t('equityPage.bands.createNewGroup')}
                onClick={handleCreateNewGroup}
              >
                <AddIcon />
              </IconButton>
            )}
          </Stack>
        </Stack>
      )}
    </Stack>
  );
}

function shouldUpdateDynamicValues(
  equityBandGroups: EquityBandGroupFragment[] | undefined,
  newGroup: EquityBandGroupFragment
) {
  if (!equityBandGroups) {
    return false;
  }
  const oldGroup = equityBandGroups.find((grp) => grp.id === newGroup.id);
  const dynamicParentChanged =
    oldGroup?.dynamicParentGroupId !== newGroup.dynamicParentGroupId;
  const dynamicPercentChanged =
    oldGroup?.dynamicPercent !== newGroup.dynamicPercent;

  return dynamicParentChanged || dynamicPercentChanged;
}
