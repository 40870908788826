import { groupBy } from 'lodash';

export enum JobGroup {
  Executive = 'Executive',
  Management = 'Management',
  Professional = 'Professional',
  TechnicalScientific = 'TechnicalScientific',
  Support = 'Support',
}
export const JOB_GROUPS: JobGroup[] = Object.values(JobGroup);
export interface RadfordLevel {
  surveyCode: SurveyCode;
  defaultLevel: number;
  group: JobGroup;
  defaultLabel: string;
  defaultCode: string;
  code: string;
  label: string;
  analogy: string;
  levelDescription: string;
  typicalRole: string;
  typicalTitles: string;
}

export enum SurveyProvider {
  Radford = 'Radford',
}

export enum SurveyCode {
  E6 = 'E6',
  E5 = 'E5',
  E4 = 'E4',
  E3 = 'E3',
  E2 = 'E2',
  E1 = 'E1',
  M6 = 'M6',
  M5 = 'M5',
  M4 = 'M4',
  M3 = 'M3',
  M2 = 'M2',
  M1 = 'M1',
  P7 = 'P7',
  P6 = 'P6',
  P5 = 'P5',
  P4 = 'P4',
  P3 = 'P3',
  P2 = 'P2',
  P1 = 'P1',
  T7 = 'T7',
  T6 = 'T6',
  T5 = 'T5',
  T4 = 'T4',
  T3 = 'T3',
  T2 = 'T2',
  T1 = 'T1',
  S5 = 'S5',
  S4 = 'S4',
  S3 = 'S3',
  S2 = 'S2',
  S1 = 'S1',
  Intern = 'Intern',
}

export const radfordLevelDefinitions: RadfordLevel[] = [
  {
    surveyCode: SurveyCode.E6,
    group: JobGroup.Executive,
    defaultLevel: 19,
    defaultLabel: 'CEO',
    defaultCode: 'E6',
    code: 'E6',
    label: 'Executive 6 (E6)',
    analogy: 'Chairman, CEO',
    levelDescription:
      'The senior leader(s) of the corporate entity.\nRole is a member of the Board of Directors/Main Board and the most senior position in the organization. Full accountability to stakeholders for the results of the ‘parent’ organization and all entities reporting into it.\nDevelops the strategic long–term goals for the organization aligned to its mission, vision, and values. Responsible for ensuring strategic priorities and objectives are effectively cascaded down through the senior management tiers of the organization.',
    typicalRole:
      'Chief Executive Officer-Corporate, Executive Chairman-Corporate',
    typicalTitles:
      'Chairman of the Board, Chairman and Chief Executive Officer, or Chairman, President and Chief Executive Officer',
  },
  {
    surveyCode: SurveyCode.E5,
    group: JobGroup.Executive,
    defaultLevel: 18,
    defaultLabel: 'CXO',
    defaultCode: 'E5',
    code: 'E5',
    label: 'Executive 5 (E5)',
    analogy: 'Major Function Leaders, often CXOs, EVPs',
    levelDescription:
      'Full responsibility for a global corporate business line typically across a diverse geographic area that represents a significant proportion of parent company revenues or has full responsibility for a major and strategic corporate function contributing to the overall strategy of the parent company.\nDevelops corporate plans and strategies. Focuses on setting corporate strategy translating that into corporate objectives and business plans for the firm and their own function. Strategic decisions are generally long-term and long-lasting. Role has a significant impact on company financials. \nTypically, direct reports to the Chief Executive Officer. Is a member of the Corporations’ Executive Committee and usually an executive officer. May also interface with the Board of Directors and represent the company to outside stakeholders.',
    typicalRole:
      'Will vary depending on the strategic focus of the organization but may include: Head of Major Functions, Group Presidents (Multi-Profit Center), among other roles',
    typicalTitles:
      'Executive Vice President, Chief Operating Officer, President-Group, Chief Financial Officer',
  },
  {
    surveyCode: SurveyCode.E4,
    group: JobGroup.Executive,
    defaultLevel: 17,
    defaultLabel: 'SVP II',
    defaultCode: 'E4',
    code: 'E4',
    label: 'Executive 4 (E4)',
    analogy: 'Highly Complex / Large Function Leaders, often CXOs, SVPs',
    levelDescription:
      'Direct report to senior leadership, Executive Committee member, or functional leadership committee. Responsible for overall operations of a division (single profit center), a corporate area, or a function at the group level (multi–profit center).\nTypically has E3 level direct reports in addition to E1 and E2 roles if they exist. Focuses on translating corporate objectives into strategic business plans for the business segment/function/area based on knowledge of market and competitive trends. Sets and is accountable for financial plans and goals. Strategic decisions have medium to long-term impact on operations. In global firms, will likely have worldwide responsibilities. \nIncumbent may be a member of the corporate executive management team (below office of Chief Executive Officer). Relative to an E3 resides in a business unit or function with greater complexity, impact, revenue or staff. Not all organizations will have this level. ',
    typicalRole:
      'Corporate Heads of Areas, Group Heads of Functions, and Division Presidents (Single-Profit Centers)',
    typicalTitles:
      'Senior Vice President, less likely to be Executive Vice President',
  },
  {
    surveyCode: SurveyCode.E3,
    group: JobGroup.Executive,
    defaultLevel: 16,
    defaultLabel: 'SVP',
    defaultCode: 'E3',
    code: 'E3',
    label: 'Executive 3 (E3)',
    analogy: 'Group Function Leaders, Division Presidents, often SVPs',
    levelDescription:
      'Direct report to senior leadership, Executive Committee member, or functional leadership committee. Responsible for overall operations of a division (single profit center), a corporate area, or a function at the group level (multi–profit center).\nTypically has E1 level direct reports and E2 roles, if they exist. Almost all organizations will have this level while not all will have an E4 level.\nFocuses on translating corporate objectives into strategic business plans for the business segment/function/area based on knowledge of market and competitive trends. Sets and is accountable for financial plans and goals. Strategic decisions have medium to long-term impact on operations. In global firms, will likely have worldwide responsibilities. \nIncumbent may be a member of the corporate executive management team (below office of Chief Executive Officer).',
    typicalRole:
      'Corporate Heads of Areas, Group Heads of Functions, and Division Presidents (Single-Profit Centers)',
    typicalTitles: 'Senior Vice President',
  },
  {
    surveyCode: SurveyCode.E2,
    group: JobGroup.Executive,
    defaultLevel: 15,
    defaultLabel: 'VP II',
    defaultCode: 'E2',
    code: 'E2',
    label: 'Executive 2 (E2)',
    analogy: 'Medium Complexity Functional Leaders, often VPs',
    levelDescription:
      'Generally direct reports to Division Presidents, Group function heads, or Corporate area heads. \nTypically has E1 direct reports and/or M6 and/or M5 (director level) direct reports.\nResponsible for a major function at a division (single-profit center), a major area at group level (multi-profit center), or a major focus at corporate. Establishes strategic plans for functions/areas owned based on corporate strategy. Strategic decisions are critical to functional/area performance and likely have short-term impact (<2 years). Manages change and develops risk mitigation plans. Responsibilities may be regional or local rather than global.\nRelative to an E1 resides in a business unit or function with greater complexity, impact, revenue or staff. Not all organizations will have this level.',
    typicalRole:
      'Corporate Focus Heads, Group Heads of Areas, and Division Heads of Functions',
    typicalTitles:
      'Vice President, less likely to be Senior Vice President or Senior Director',
  },
  {
    surveyCode: SurveyCode.E1,
    group: JobGroup.Executive,
    defaultLevel: 14,
    defaultLabel: 'VP',
    defaultCode: 'E1',
    code: 'E1',
    label: 'Executive 1 (E1)',
    analogy: 'Smaller Complexity Functional / Divisional leaders, often VPs',
    levelDescription:
      'Generally direct reports to Division Presidents, Group function heads, or Corporate area heads. \nTypically has M6 and/or M5 (director level) direct reports. Almost all organizations will have this level while not all will have an E2 level.\nResponsible for a major function at a division (single-profit center), a major area at group level (multi-profit center), or a major focus at corporate. Establishes strategic plans for functions/areas owned based on corporate strategy. Strategic decisions are critical to functional/area performance and likely have short-term impact (<2 years). Manages change and develops risk mitigation plans. Responsibilities may be regional or local rather than global.',
    typicalRole:
      'Corporate Focus Heads, Group Heads of Areas, and Division Heads of Functions',
    typicalTitles:
      'Vice President, may also be Senior Director in some organizations',
  },

  {
    surveyCode: SurveyCode.M6,
    group: JobGroup.Management,
    defaultLevel: 13,
    defaultLabel: 'Senior Director',
    defaultCode: 'M6',
    code: 'M6',
    label: 'Senior Director (M6)',
    analogy:
      'Directs efforts of both air and ground search parties (Inspires action, coordinates simultaneous initiatives)',
    levelDescription:
      'Develops and provides strategy, vision and direction to multiple functions. Implements company wide policies and practices. Provides input to executive management. Accountable for function financial results which impact organization performance.',
    typicalRole:
      'Works consistently with abstract ideas or situations across functional areas of the business. Through assessment of intangible variables, identifies and evaluates fundamental issues, providing strategy and direction for multiple functional areas. Anticipates factors that could influence strategies and company position in the market. ',
    typicalTitles:
      "Establishes and develops broader company strategy and translates into multi functional objectives. Erroneous decisions will effect the company's success, five years or longer.",
  },
  {
    surveyCode: SurveyCode.M5,
    group: JobGroup.Management,
    defaultLevel: 12,
    defaultLabel: 'Director',
    defaultCode: 'M5',
    code: 'M5',
    label: 'Director (M5)',
    analogy:
      'Controls search efforts to find missing hikers. (Strategic battle-tested leader, directs operational units).',
    levelDescription:
      'Participates with senior management to establish strategic plans and objectives. Makes final decisions on implementation and ensures operational effectiveness. Effectively cascades functional strategy and contributes to development of organizational policies. Accountable for actions that impact function or multiple department budgets.',
    typicalRole:
      'Works on complex issues where problems are not clearly defined and where fundamental principles do not fully apply. Translates business segment strategy into functional plans and guides execution. Participates in corporate development of methods, techniques and evaluation criteria for projects, programs, and people. Ensures budgets and schedules meet corporate requirements.',
    typicalTitles:
      'Contributes to development of strategies of a function or multi departments. Erroneous decisions will have a critical long term (typically up to five years) impact on the overall success of function or multi departments.',
  },
  {
    surveyCode: SurveyCode.M4,
    group: JobGroup.Management,
    defaultLevel: 11,
    defaultLabel: 'Senior Manager / Associate Director',
    defaultCode: 'M4',
    code: 'M4',
    label: 'Senior Manager / Associate Director (M4)',
    analogy:
      'Pilots expedition through unexpected snow storm. (Strong tactical decision-maker, handles unforeseen issues).',
    levelDescription:
      'Establishes operational objectives, policies, procedures and work plans and delegates assignments to subordinates. Develops, modifies and executes company policies that affect immediate operations and may also have company-wide effect. Accountable for department budget which may be broad and have far reaching impact on the business segment.',
    typicalRole:
      'Works on issues where analysis of situations or data requires conceptual thinking and an in-depth knowledge of organizational objectives. Problems are not clearly defined and lack strategic direction. Implements policies and selects methods, techniques, and evaluation criteria for obtaining results. Drives the strategy and success of larger projects which contribute to multiple areas of the organization.',
    typicalTitles:
      'Erroneous decisions will result in critical delay(s) in schedules and/or unit operations and may jeopardize overall business activities. Decisions directly impact short-term less than one-year results of the team/ program/ group.',
  },
  {
    surveyCode: SurveyCode.M3,
    group: JobGroup.Management,
    defaultLevel: 10,
    defaultLabel: 'Manager',
    defaultCode: 'M3',
    code: 'M3',
    label: 'Manager (M3)',
    analogy:
      'Leads extended alpine mountain-climbing trips (Experienced leadership required for multi-faceted environment)',
    levelDescription:
      'Receives objective based assignments, and determines resources to meet schedules and goals. Provides guidance to subordinates within the latitude of established company policies. Recommends changes to policies and establishes procedures that affect section or multiple disciplines. Decisions impact others in the immediate team and influences methods and techniques. Executes financials, business planning, organizational priorities and workforce.',
    typicalRole:
      'Works on issues of diverse scope where analysis of situation or data requires evaluation of a variety of factors. Follows processes and operational policies in selecting methods and techniques for obtaining solutions. Develops and manages operational initiatives to deliver tactical results. ',
    typicalTitles:
      'Translates functional plans into operational processes and guides execution. Erroneous decisions or failure to achieve results will add to costs and may have up to a one year impact.',
  },
  {
    surveyCode: SurveyCode.M2,
    group: JobGroup.Management,
    defaultLevel: 9,
    defaultLabel: 'Senior Supervisor',
    defaultCode: 'M2',
    code: 'M2',
    label: 'Senior Supervisor (M2)',
    analogy:
      'Guides team while rock climbing (Skills/training needed, risk-taking support systems in place)',
    levelDescription:
      'Receives assignments based on incremental objectives and defined processes. Provides direction to employees according to established policies and management guidance. Administers company policies that directly affect subordinate employees. Recommends changes to sub-unit, unit or discipline policies. Takes actions to monitor and manage costs related to a section.',
    typicalRole:
      'Works on issues requiring the analysis of data, relevant factors and resolves problems. Exercises judgment and interpretation to help define procedures and policies to determine appropriate action.',
    typicalTitles:
      'Develops and manages routine operational plans. Erroneous decisions or failure to achieve results will cause delays in schedules. Meets targets typically against a monthly plan.',
  },
  {
    surveyCode: SurveyCode.M1,
    group: JobGroup.Management,
    defaultLevel: 7,
    defaultLabel: 'Supervisor',
    defaultCode: 'M1',
    code: 'M1',
    label: 'Supervisor (M1)',
    analogy:
      'Escorts hikers on day trips (Routine situations, emergency prepared)',
    levelDescription:
      'Receives predetermined work assignments that are process-driven, and subject to a moderate level of control and review. Directs subordinates on a course of action based on knowledge of established policies and procedures. Provides input and contributes to managing costs related to a unit or sub unit.',
    typicalRole:
      'Works on issues of limited scope. Follows established practices and procedures in analyzing situations or data from which answers can be readily obtained. Monitors daily/weekly operations of a unit or sub-unit. ',
    typicalTitles:
      'Executes specified routine tasks. Erroneous decisions or failure to achieve results will cause delays in schedules. Meets unit targets typically against a weekly plan.',
  },
  {
    surveyCode: SurveyCode.P7,
    group: JobGroup.Professional,
    defaultLevel: 13,
    defaultLabel: 'Distinguished',
    defaultCode: 'P7',
    code: 'P7',
    label: 'Distinguished (P7)',
    analogy: 'Invented the zipper.',
    levelDescription:
      'Applies deep technical expertise of current industry and emerging technologies to provide insight and technical leadership to promote new ideas, innovative concepts and resolving critical issues. Uses extensive knowledge of theories, practices and design and is a key influencer in product research, product innovation and growth strategies.',
    typicalRole:
      'Consistently works with abstract ideas. Through assessment of intangible variables, identifies and evaluates fundamental issues, providing strategy and direction. Requires in-depth knowledge of industry trends,  business strategies and the company’s goals. ',
    typicalTitles:
      "Oversees creative engineering-oriented research or advanced product development.  Erroneous decisions will have a long-term effect on the company's success.  Consults with and provides guidance on advanced technical issues to leadership. Recognized as a technical leader whose knowledge, ideas and critical thinking impact the organization.",
  },
  {
    surveyCode: SurveyCode.P6,
    group: JobGroup.Professional,
    defaultLevel: 12,
    defaultLabel: 'Principal / Consultant',
    defaultCode: 'P6',
    code: 'P6',
    label: 'Principal / Consultant (P6)',
    analogy: 'Invented nylon.',
    levelDescription:
      'Serves as an expert in the field and principal advisor. Provides, guides and uses professional concepts in developing resolution to critical issues. Applies extensive knowledge of theories, practices and design matters. Leads the application of existing principles and guides development of new ideas. ',
    typicalRole:
      'Works on issues that impact program success or addresses future concepts and products. Solutions are devised based on limited information and requires judgment. Implementation of solutions requires a longer term view which impacts strategic goals and objectives. Results impact multiple functions.',
    typicalTitles:
      'Exercises wide latitude in determining objectives and approaches to critical assignments. Creates formal networks with key decision makers and widely recognized as expert and thought leader by both internal and external community. Serves as external spokesperson for the organization.',
  },
  {
    surveyCode: SurveyCode.P5,
    group: JobGroup.Professional,
    defaultLevel: 11,
    defaultLabel: 'Expert',
    defaultCode: 'P5',
    code: 'P5',
    label: 'Expert (P5)',
    analogy: 'Knows more about rope than you ever will.',
    levelDescription:
      'Applies broad expertise and knowledge in highly specialized fields or several related disciplines. Leads and contributes to development of company objectives and principles to achieve goals in creative and effective ways. ',
    typicalRole:
      'Works on significant and unique issues where analysis of situations or data requires an evaluation of intangibles. Requires conceptual thinking to understand advanced issues and implications. Exercises independent judgment in methods, techniques and evaluation criteria for obtaining results. Accountable for results, which may impact the entire function.',
    typicalTitles:
      'Creates formal networks involving coordination among groups. Focuses on providing thought leadership and works on broader organization projects, which requires understanding of wider business. Requires conveying advanced information and persuading several diverse stakeholders/audiences. Recognized internally as a subject matter expert. May direct the activities of others.',
  },
  {
    surveyCode: SurveyCode.P4,
    group: JobGroup.Professional,
    defaultLevel: 10,
    defaultLabel: 'Advanced',
    defaultCode: 'P4',
    code: 'P4',
    label: 'Advanced (P4)',
    analogy: 'Understands rope making.',
    levelDescription:
      'Exhibits advanced wide-ranging experience, using in-depth professional knowledge, acumen, concepts and company objectives to develop, resolve complex models and procedures. Provides solutions to issues in creative and effective ways. Understands the interrelationships of different disciplines. Directs the application of existing principles and guides development of new policies and ideas. ',
    typicalRole:
      'Understands and works on complex issues where analysis of situations or data requires an in-depth evaluation of variable factors. Determines methods and procedures on new assignments. Exercises judgment in selecting methods, evaluating, adapting of complex techniques and evaluation criteria for obtaining results. Work is reviewed upon completion and is consistent with departmental objectives.',
    typicalTitles:
      'Networks with key contacts outside own area of expertise. Adapts style and uses persuasion in delivering messages that relate to the wider firm business. Frequently advises others on complex matters. May be accountable through team for delivery of tactical business targets. ',
  },
  {
    surveyCode: SurveyCode.P3,
    group: JobGroup.Professional,
    defaultLevel: 9,
    defaultLabel: 'Career',
    defaultCode: 'P3',
    code: 'P3',
    label: 'Career (P3)',
    analogy: 'Calculates rope strength. Knows a lot about knots.',
    levelDescription:
      'Complete knowledge and full understanding of area of specialization, principles and practices within a professional discipline. Assesses unusual circumstances and uses sophisticated analytical and problem solving techniques to identify cause. Resolves and assesses a wide range of issues in creative ways and suggests variations in approach. This job is the fully qualified, experienced professional, journey-level position. ',
    typicalRole:
      'Works on problems of diverse scope where analysis of information requires evaluation of identifiable factors. Devises solutions based on limited information and precedent and adapts existing approaches to resolve issues. Uses evaluation, judgment, and interpretation to select right course of action. Work is done independently and is reviewed at critical points.',
    typicalTitles:
      'Enhances relationships and networks with senior internal/external partners who are not familiar with the subject matter often requiring persuasion. Adapts style to differing audiences and often advises others on difficult matters.',
  },
  {
    surveyCode: SurveyCode.P2,
    group: JobGroup.Professional,
    defaultLevel: 7,
    defaultLabel: 'Developing',
    defaultCode: 'P2',
    code: 'P2',
    label: 'Developing (P2)',
    analogy: 'Can tie basic knots. Shown complex knots.',
    levelDescription:
      'Develops professional expertise, applies company policies and procedures to resolve a variety of issues. Determines a course of action based on guidelines and modifies processes and methods as required. ',
    typicalRole:
      'Works on problems of moderate scope which are often varied and routine where analysis of situations or data requires a review of a variety of factors. Exercises judgment within defined procedures and practices to determine appropriate action. Applies learned techniques and contributes to analysis and investigation to solve problems. Normally receives little instruction on day-to-day work, general instructions on new assignments.',
    typicalTitles:
      'Builds productive internal/external working relationships to resolve mutual problems by collaborating on procedures or transactions. Focuses on providing standard professional advice and creating initial reports/analyzes for review by experienced team professionals.',
  },
  {
    surveyCode: SurveyCode.P1,
    group: JobGroup.Professional,

    defaultLevel: 6,
    defaultLabel: 'Entry',
    defaultCode: 'P1',
    code: 'P1',
    label: 'Entry (P1)',
    analogy: 'Learns about rope.',
    levelDescription:
      'Learns to use professional concepts. Applies knowledge and basic problem solving techniques to define and resolve problems. Determines a course of action based on guidelines. ',
    typicalRole:
      'Works on problems of limited scope. Follows standard practices and procedures in analyzing situations or data from which answers can be readily obtained. Work is reviewed periodically based on a set of defined procedures or precedence. Normally receives general instruction on work and new assignments. ',
    typicalTitles:
      'Develops stable working relationships internally. Exchanges standard/basic information with colleagues and immediate supervisor, project leaders and other professionals in the team./group.',
  },
  {
    surveyCode: SurveyCode.T7,
    group: JobGroup.TechnicalScientific,
    defaultLevel: 14,
    defaultLabel: 'Fellow / Distinguished Fellow',
    defaultCode: 'T7',
    code: 'T7',
    label: 'Fellow / Distinguished Fellow (T7)',
    analogy:
      'Wins national/global recognition for innovative discoveries that address vital issues/concerns.',
    levelDescription:
      'Oversees applied research or advanced development of new products, materials, or processes. Uses leading-edge and broad knowledge of other disciplines principles, concepts and practices and/or techniques to champion highly complex projects or programs involving multi-disciplinary. ',
    typicalRole:
      'Directs and develops strategy, direction and activities necessary to complete major projects, requiring a wide variety of technical skills that impacts the business. Erroneous decisions or unsound recommendations could risk achievement of critical organizational objectives and affect the organization’s public image or value. Is recognized as a leader and an expert in the scientific arena, both nationally and typically internationally as well.',
    typicalTitles:
      'Consults and interacts with and provides guidance to executive management and several departments on advanced technical issues. Influences policymaking efforts, consistent with governing practices and regulations. ',
  },
  {
    surveyCode: SurveyCode.T6,
    group: JobGroup.TechnicalScientific,
    defaultLevel: 13,
    defaultLabel: 'Senior Principal',
    defaultCode: 'T6',
    code: 'T6',
    label: 'Senior Principal (T6)',
    analogy:
      'Makes notable and substantial contribution to a particular field of science.',
    levelDescription:
      'Initiates, directs and executes the scientific research and/or development direction for a company through a research staff (or independent study). which are critical and have a high impact on corporate strategies and image. Investigates the feasibility of applying a wide variety of engineering/scientific principles and concepts to potential inventions, products and problems. ',
    typicalRole:
      'Participates in the development of critical applications. Maintains broad knowledge of state-of-the-art principles and theory. Makes major contributions at a national or multi national level to engineering/scientific literature and conferences. This position is recognized as a published expert within a discipline. ',
    typicalTitles:
      'Acts as an advisor to top management. Influences senior management to adopt a different point of view, when appropriate, and make decisions that have an impact on business direction. Participates in management and professional development of research group. ',
  },
  {
    surveyCode: SurveyCode.T5,
    group: JobGroup.TechnicalScientific,
    defaultLevel: 12,
    defaultLabel: 'Principal',
    defaultCode: 'T5',
    code: 'T5',
    label: 'Principal (T5)',
    analogy: 'Executes and implements significant scientific research.',
    levelDescription:
      "Applies leading edge knowledge and professional concepts to contribute to development of company's concepts and principles and to achieve objectives in creative and effective ways. ",
    typicalRole:
      'Creates formal networks with key decision makers and serves as external spokesperson for the organization. Works on significant and unique issues where analysis of situations or data requires an evaluation of intangible variables and may impact future concepts, products or technologies. Exercises latitude in determining objectives and approaches to critical assignments. Barriers to entry such as committee review exist at this level. ',
    typicalTitles:
      'Acts independently to determine methods and procedures on new assignments. A strategic leader within the organization. Oversee the activities of other lower level personnel.',
  },
  {
    surveyCode: SurveyCode.T4,
    group: JobGroup.TechnicalScientific,
    defaultLevel: 11,
    defaultLabel: 'Advanced',
    defaultCode: 'T4',
    code: 'T4',
    label: 'Advanced (T4)',
    analogy:
      'Draws conclusions from new/novel research to create new solutions to critical new problems.',
    levelDescription:
      "Applies professional concepts to contribute to development of company's concepts and principles and to achieve objectives in creative and effective ways. Expert knowledge of scientific principles and concepts. Reputation as emerging leader in field with sustained performance and accomplishment. ",
    typicalRole:
      'Presents technical and functional knowledge to design experiments/ projects that contribute to overall direction of department/ discipline. Works on extremely complex problems in which analysis of situations or data requires an evaluation of intangible variables. Exercises independent judgment in developing methods, techniques and evaluation criteria for obtaining results.',
    typicalTitles:
      'Explains difficult concepts and influences others. Interacts with team members and other managers and may include suppliers, vendors and/or customers. May oversee and manage the activities of lower level personnel - primary role is an individual contributor.',
  },
  {
    surveyCode: SurveyCode.T3,
    group: JobGroup.TechnicalScientific,
    defaultLevel: 10,
    defaultLabel: 'Career',
    defaultCode: 'T3',
    code: 'T3',
    label: 'Career (T3)',
    analogy:
      'Exploits/modifies existing solutions to develop alternatives for solving new problems.',
    levelDescription:
      'Uses professional concepts in accordance with company objectives to solve complex problems in creative and effective ways. ',
    typicalRole:
      'Applies technical and functional knowledge to assist in experiment/ project design that will enable department/group/team to meet goals. Works on complex problems in which analysis of situations or data requires an in-depth evaluation of various factors. Exercises judgment within broadly defined practices and policies in selecting methods, techniques and evaluation criteria for obtaining results.',
    typicalTitles:
      'Explains complex concepts and influences others to adopt a different point of view. Networks with senior internal and external representatives in own area of expertise. May determine methods and procedures on new assignments and may provide guidance to other lower level personnel. ',
  },
  {
    surveyCode: SurveyCode.T2,
    group: JobGroup.TechnicalScientific,
    defaultLevel: 9,
    defaultLabel: 'Developing',
    defaultCode: 'T2',
    code: 'T2',
    label: 'Developing (T2)',
    analogy:
      'Applies results of previous studies to studies that solve new/different problems.',
    levelDescription:
      "Uses professional concepts and company's policies and procedures to solve moderate range of difficult problems in imaginative and practical ways.",
    typicalRole:
      'Demonstrated success in technical proficiency, scientific creativity, collaboration with others and independent thought. Works on problems of diverse scope in which analysis of data requires evaluation of identifiable factors. Exercises judgment within generally defined practices and policies in selecting methods and techniques for obtaining solutions.',
    typicalTitles:
      'Solves difficult technical issues and works to build alignment around a complex situation. Normally receives no instructions on routine work, general instructions on new assignments.',
  },
  {
    surveyCode: SurveyCode.T1,
    group: JobGroup.TechnicalScientific,
    defaultLevel: 8,
    defaultLabel: 'Entry',
    defaultCode: 'T1',
    code: 'T1',
    label: 'Entry (T1)',
    analogy:
      'Designs and conducts studies, applying findings to draw conclusions',
    levelDescription:
      "Uses professional concepts and company's policies and procedures to solve a variety of problems.",
    typicalRole:
      'Demonstrates potential for technical proficiency, scientific creativity, collaboration with others and independent thought. Works on problems of moderate scope in which analysis of situation or data requires a review of identifiable factors. Exercises judgment within defined procedures and practices to determine appropriate action.',
    typicalTitles:
      'Explains moderate technical issues and works to build alignment around a straightforward situation. Normally receives general instructions on routine and new assignments.',
  },
  {
    surveyCode: SurveyCode.S5,
    group: JobGroup.Support,
    defaultLevel: 6,
    defaultLabel: 'Specialist',
    defaultCode: 'S5',
    code: 'S5',
    label: 'Specialist (S5)',
    analogy:
      'As a highly skilled specialist, contributes to the development of concepts and techniques. Completes complex tasks in creative and effective ways. ',
    levelDescription:
      'Consistently works on complex assignments requiring independent action and a high degree of initiative to resolve issues. Makes recommendations for new procedures.',
    typicalRole:
      'Acts independently to determine methods and procedures on new assignments. Often acts as a facilitator and team leader.',
    typicalTitles:
      'Typically requires a minimum of 6+ years of related experience.',
  },
  {
    surveyCode: SurveyCode.S4,
    group: JobGroup.Support,
    defaultLevel: 5,
    defaultLabel: 'Highly Skilled',
    defaultCode: 'S4',
    code: 'S4',
    label: 'Highly Skilled (S4)',
    analogy:
      'As a skilled specialist, completes tasks in resourceful and effective ways.',
    levelDescription:
      'Works on assignments requiring considerable judgment and initiative. Understands implications of work and makes recommendations for solutions. ',
    typicalRole:
      'Determines methods and procedures on new assignments. May be informal team leader.',
    typicalTitles:
      'Typically requires a minimum of 4 - 6 years of related experience.',
  },
  {
    surveyCode: SurveyCode.S3,
    group: JobGroup.Support,
    defaultLevel: 4,
    defaultLabel: 'Senior',
    defaultCode: 'S3',
    code: 'S3',
    label: 'Senior (S3)',
    analogy:
      'Has substantial understanding of the job and applies knowledge and skills to complete a wide range of tasks.',
    levelDescription:
      'Works on assignments that are moderately difficult, requiring judgment in resolving issues or in making recommendations.',
    typicalRole:
      'Normally receives little instruction on daily work, general instructions on newly introduced assignments.',
    typicalTitles:
      'Typically requires a minimum of 2 - 4 years of related experience.',
  },
  {
    surveyCode: SurveyCode.S2,
    group: JobGroup.Support,
    defaultLevel: 3,
    defaultLabel: 'Intermediate',
    defaultCode: 'S2',
    code: 'S2',
    label: 'Intermediate (S2)',
    analogy:
      'Applies acquired job skills and company policies and procedures to complete assigned tasks.',
    levelDescription:
      'Works on assignments that are semi-routine in nature but recognizes the need for occasional deviation from accepted practice.',
    typicalRole:
      'Normally follows established procedures on routine work, requires instructions only on new assignments. ',
    typicalTitles:
      'Typically requires a minimum of 1 - 2 years of related experience.',
  },
  {
    surveyCode: SurveyCode.S1,
    group: JobGroup.Support,
    defaultLevel: 2,
    defaultLabel: 'Entry',
    defaultCode: 'S1',
    code: 'S1',
    label: 'Entry (S1)',
    analogy:
      'Acquires job skills and learns company policies and procedures to complete routine tasks.',
    levelDescription:
      'Works on assignments that are routine in nature, requiring limited judgment. Has little or no role in decision-making.',
    typicalRole:
      'Normally receives detailed instructions on all work. Works under close supervision.',
    typicalTitles: 'Typically requires less than 1 year of related experience.',
  },
  {
    surveyCode: SurveyCode.Intern,
    group: JobGroup.Support,
    defaultLevel: 1,
    defaultLabel: 'Intern',
    defaultCode: 'Intern',
    code: 'Intern',
    label: 'Intern',
    analogy: '',
    levelDescription: '',
    typicalRole: '',
    typicalTitles: 'Intern, Co-op, Apprentice',
  },
];

export const radfordLevelDefinitionsByGroup = groupBy(
  radfordLevelDefinitions,
  'group'
) as Record<JobGroup, RadfordLevel[]>;

export const defaultGlobalLevels = {
  20: {
    level: 20,
    name: 'L20',
  },
  19: {
    level: 19,
    name: 'L19',
  },
  18: {
    level: 18,
    name: 'L18',
  },
  17: {
    level: 17,
    name: 'L17',
  },
  16: {
    level: 16,
    name: 'L16',
  },
  15: {
    level: 15,
    name: 'L15',
  },
  14: {
    level: 14,
    name: 'L14',
  },
  13: {
    level: 13,
    name: 'L13',
  },
  12: {
    level: 12,
    name: 'L12',
  },
  11: {
    level: 11,
    name: 'L11',
  },
  10: {
    level: 10,
    name: 'L10',
  },
  9: {
    level: 9,
    name: 'L9',
  },
  8: {
    level: 8,
    name: 'L8',
  },
  7: {
    level: 7,
    name: 'L7',
  },
  6: {
    level: 6,
    name: 'L6',
  },
  5: {
    level: 5,
    name: 'L5',
  },
  4: {
    level: 4,
    name: 'L4',
  },
  3: {
    level: 3,
    name: 'L3',
  },
  2: {
    level: 2,
    name: 'L2',
  },
  1: {
    level: 1,
    name: 'L1',
  },
  0: {
    level: 0,
    name: 'INT',
  },
};
