import { isBlessedUser } from '@alamere/core';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import { Avatar, IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Toolbar from '@mui/material/Toolbar';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Logo } from '../../components/Logo';
import AvatarMenu from '../../layout/menus/AvatarMenu';
import { getToken, useAuth } from '../../providers/auth';
import { HqSidebarMenu } from './HqSidebarMenu';

const drawerWidth = 240;

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    }),
  },
}));
function HqLayout() {
  const { loading, user } = useAuth();
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const { pathname } = useLocation();

  useEffect(() => {
    if (!loading && !user && !getToken()) {
      navigate('/login', { replace: true });
    }
    if (user && !isBlessedUser(user.email)) {
      navigate('/', { replace: true });
    }
  }, [loading, user]);

  if (loading) return null;

  return (
    <Box sx={{ display: 'flex' }}>
      <Box
        sx={{
          position: 'absolute',
          right: 10,
          top: 3,
          zIndex: 10,
          gap: 1,
          display: 'flex',
        }}
      >
        <IconButton color="primary" onClick={() => navigate('/')}>
          <Avatar
            sx={{
              width: 32,
              height: 32,
              filter: 'drop-shadow(2px 2px 2px rgb(0 0 0 / 0.2))',
              backgroundColor: (theme) => theme.palette.primary.main,
            }}
          >
            <HomeRoundedIcon />
          </Avatar>
        </IconButton>
        <AvatarMenu />
      </Box>
      <Drawer variant="permanent" open={open}>
        <Toolbar
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            px: [1],
          }}
        >
          <Logo />
        </Toolbar>
        <List component="nav">
          <Box>
            <HqSidebarMenu currentPath={pathname} />
          </Box>
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
        }}
      >
        {/*  to push content down */}
        <Toolbar />
        <Box sx={{ mx: 2 }}>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
}

export default HqLayout;
