import { JobGroup, Scope } from '@alamere/core';
import {
  GlobalLevelFragment,
  GlobalLevelSaveRequest,
  JobLevelFragment,
  JobLevelSaveRequest,
  JobLevelsQuery,
  useGlobalLevelsQuery,
  useGlobalLevelsSaveMutation,
  useJobLevelSaveMutation,
  useJobLevelsQuery,
} from '@alamere/generated-graphql-types';
import { Box, CircularProgress } from '@mui/material';
import { cloneDeep } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import {
  Suspense,
  lazy,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { EditButtons } from '../../components/EditButtons';
import { useScopes } from '../../hooks/useScopes';
import SurveyProviderSelect from './SurveyProviderSelect';

export type OnJobLevelChangeFunction = ({
  jobLevel,
  updates,
}: {
  jobLevel: JobLevelSaveRequest;
  updates: Partial<JobLevelSaveRequest>;
}) => void;

export type OnGlobalLevelChangeFunction = ({
  globalLevel,
  updates,
}: {
  globalLevel: GlobalLevelSaveRequest;
  updates: Partial<GlobalLevelSaveRequest>;
}) => void;

interface RowState {
  [level: number]: {
    [group in JobGroup]?: JobLevelFragment;
  };
}
interface UpdatesState {
  [level: number]: {
    [group in JobGroup]?: JobLevelSaveRequest;
  };
}

interface GlobalLevelUpdatesState {
  [level: number]: GlobalLevelSaveRequest;
}

export interface GlobalLevelIndex {
  [level: number]: GlobalLevelFragment | undefined;
}

export default function JobArchitecturePage() {
  const { checkScopes } = useScopes();
  const canEdit = checkScopes(Scope.JOB_ARCHITECTURE_EDIT);
  const [rowState, setRowState] = useState<RowState>({});
  const [rows, setRows] = useState<(JobLevelFragment | undefined)[][]>([]);
  const [rowLevels, setRowLevels] = useState<number[]>([]);
  const [editing, setEditing] = useState<boolean>(false);
  const [globalLevel, setGlobalLevel] = useState<GlobalLevelIndex>({});
  const [saving, setSaving] = useState<boolean>(false);

  const [updates, setUpdates] = useState<UpdatesState>({});
  const [globalLevelUpdates, setGlobalLevelUpdates] =
    useState<GlobalLevelUpdatesState>({});

  const { data, loading, refetch } = useJobLevelsQuery({
    variables: {
      jobLevelsGetRequest: {
        onlyVisible: false,
      },
    },
  });

  const [save, { loading: savingJobLevels }] = useJobLevelSaveMutation();

  const {
    data: globalLevelData,
    loading: globalLevelLoading,
    refetch: refetchGlobalLevel,
  } = useGlobalLevelsQuery();
  const [saveGlobalLevel, { loading: savingGlobalLevel }] =
    useGlobalLevelsSaveMutation();

  const handleSave = async () => {
    const items = Object.keys(updates)
      .flatMap((level) => Object.values(updates[parseInt(level)]))
      .filter((fragment) => fragment !== undefined)
      .map(
        ({
          id,
          surveyCode,
          surveyProvider,
          level,
          name,
          code,
          isVisible,
          group,
        }) => {
          return {
            id,
            surveyCode,
            surveyProvider,
            level,
            name,
            code,
            isVisible,
            group,
          };
        }
      );

    if (items.length === 0) {
      setEditing(false);
      return;
    }

    await save({
      variables: {
        request: {
          items,
        },
      },
    });
    setUpdates({});
    setEditing(false);
    enqueueSnackbar('Saved', { variant: 'success' });
    await refetch();
  };

  const handleSaveGlobalLevel = async () => {
    const items = Object.values(globalLevelUpdates)
      .filter((update) => update !== undefined)
      .map(({ id, level, name }) => {
        return {
          id,
          level,
          name,
        };
      });

    if (items.length === 0) {
      setEditing(false);
      return;
    }

    await saveGlobalLevel({
      variables: {
        request: {
          items,
        },
      },
    });
    setGlobalLevelUpdates({});
    setEditing(false);
    enqueueSnackbar('Saved', { variant: 'success' });
    await refetchGlobalLevel();
  };

  useEffect(() => {
    if (data?.jobLevels) {
      const rowState = toRowState(data);
      setRowState(rowState);
    }
  }, [data]);

  useEffect(() => {
    if (globalLevelData?.globalLevels) {
      const index = globalLevelData.globalLevels.reduce((acc, metadata) => {
        acc[metadata.level] = metadata;
        return acc;
      }, {} as GlobalLevelIndex);
      setGlobalLevel(index);
    }
  }, [globalLevelData]);

  useEffect(() => {
    setRows(toRows(rowState, editing));
    setRowLevels(toRowLevels(rowState));
  }, [rowState, editing]);

  const handleJobLevelChange: OnJobLevelChangeFunction = useCallback(
    ({ jobLevel, updates: jobLevelUpdates }) => {
      const { level: newLevel, group: newGroup } = jobLevelUpdates;
      const { level, group } = jobLevel;

      if (newGroup || newLevel) {
        enqueueSnackbar('Cannot move this job', { variant: 'error' });
        return;
      }

      setUpdates((oldState) => {
        const newState = cloneDeep(oldState);

        newState[level] = newState[level] || {};
        newState[level][group] = {
          ...jobLevel,
          ...newState[level][group],
          ...jobLevelUpdates,
        };
        return newState;
      });
    },
    []
  );

  const handleGlobalLevelChange: OnGlobalLevelChangeFunction = useCallback(
    ({ globalLevel, updates: globalLevelUpdates }) => {
      const { level: newLevel } = globalLevelUpdates;
      const { level } = globalLevel;

      if (newLevel) {
        enqueueSnackbar('Cannot move this level', { variant: 'error' });
        return;
      }

      setGlobalLevelUpdates((oldState) => {
        const newState = cloneDeep(oldState);

        newState[level] = {
          ...globalLevel,
          ...newState[level],
          ...globalLevelUpdates,
        };
        return newState;
      });
    },
    []
  );

  const LazyTable = lazy(() => import('./JobLevelTable'));

  const tableComponent = useMemo(() => {
    if (saving) return null;
    return (
      <LazyTable
        rows={rows}
        rowLevels={rowLevels}
        editing={editing}
        globalLevel={globalLevel}
        onJobLevelChange={handleJobLevelChange}
        onGlobalLevelChange={handleGlobalLevelChange}
      />
    );
  }, [rows, editing, handleJobLevelChange, globalLevel, rowLevels, saving]);

  if (loading || globalLevelLoading) return null;
  if (!loading && data?.jobLevels.length === 0)
    return (
      <SurveyProviderSelect
        onSave={async () =>
          await Promise.all([refetch(), refetchGlobalLevel()])
        }
        saving={savingJobLevels || savingGlobalLevel}
      />
    );

  return (
    <Box sx={{ mb: 10 }}>
      {canEdit && (
        <Box paddingBottom={1}>
          <EditButtons
            onEdit={() => setEditing(true)}
            onCancel={() => {
              setUpdates({});
              setEditing(false);
            }}
            onSave={async () => {
              setSaving(true);
              await Promise.all([handleSaveGlobalLevel(), handleSave()]);
              setSaving(false);
            }}
          />
        </Box>
      )}
      <Suspense fallback={<CircularProgress />}>{tableComponent}</Suspense>
    </Box>
  );
}

function toRowState(data: JobLevelsQuery | undefined): RowState {
  if (!data?.jobLevels) return {};

  // group by level and then by group
  return data.jobLevels.reduce((acc, jobLevel) => {
    if (!acc[jobLevel.level]) {
      acc[jobLevel.level] = {};
    }
    acc[jobLevel.level][jobLevel.group] = jobLevel;
    return acc;
  }, {} as RowState);
}

function toRowLevels(rowState: RowState): number[] {
  return Object.keys(rowState)
    .map((level) => parseFloat(level))
    .reverse();
}

function toRows(
  rowState: RowState,
  editing: boolean
): (JobLevelFragment | undefined)[][] {
  const levels = Object.keys(rowState).map((level) => parseFloat(level));
  const groups = Object.keys(JobGroup) as Array<keyof typeof JobGroup>;

  return levels.reverse().map((level) => {
    return groups.map((group) => {
      const item = rowState[level]?.[group];
      if (!editing && !item?.isVisible) return undefined;
      return item;
    });
  });
}
