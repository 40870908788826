import { CountryCode, Scope } from '@alamere/core';
import {
  useEquityGlobalMappingSaveMutation,
  useEquityGlobalMappingsQuery,
} from '@alamere/generated-graphql-types';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { Box, Button, Paper, Stack } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { EditButtons } from '../../../components/EditButtons';
import { SkeletonTableRows } from '../../../components/SkeletonTableRows';
import { useScopes } from '../../../hooks/useScopes';
import Head from './Head';
import Row, { RowData } from './Row';

export default function EquityGlobalMappingsPage() {
  const { checkScopes } = useScopes();
  const canEdit = checkScopes(Scope.EQUITY_BANDS_EDIT);
  const [editing, setEditing] = useState(false);
  const [rows, setRows] = useState<RowData[]>([]);

  const { data, loading, refetch } = useEquityGlobalMappingsQuery({
    fetchPolicy: 'network-only',
  });

  const [save] = useEquityGlobalMappingSaveMutation();

  useEffect(() => {
    if (data?.equityGlobalMappings) {
      setRows(
        data.equityGlobalMappings
          .map((mapping) => ({
            mapping,
            edited: false,
          }))
          .sort((a, b) => b.mapping.percent - a.mapping.percent)
      );
    }
  }, [data, editing]);

  const onRowChange = (newRow: RowData, index: number) => {
    if (newRow.deleted && newRow.mapping.id) {
      save({
        variables: {
          request: {
            items: [],
            idsToDelete: [newRow.mapping.id],
          },
        },
      });
    }

    setRows((oldRows) =>
      oldRows.flatMap((oldRow, i) => {
        return i === index
          ? newRow.deleted
            ? []
            : [{ ...newRow, edited: true }]
          : [oldRow];
      })
    );
  };

  const handleCancel = async () => {
    setEditing(false);
    refetch();
  };

  const handleSave = async () => {
    setEditing(false);
    const items = rows.flatMap((row) => {
      if (row.edited && !row.deleted) {
        return [
          {
            id: row.mapping.id,
            percent: row.mapping.percent,
            countryCode: row.mapping.countryCode,
            useGeoDiff: row.mapping.useGeoDiff,
          },
        ];
      }
      return [];
    });

    const idsToDelete = rows.flatMap((row) => {
      if (row.deleted && row.mapping.id) {
        return [row.mapping.id];
      }
      return [];
    });

    if (items.length === 0 && idsToDelete.length === 0) return;

    await save({
      variables: {
        request: {
          items,
          idsToDelete,
        },
      },
    });
    await refetch();
  };

  const handleAddMore = () => {
    setRows(
      rows.concat([
        {
          mapping: { countryCode: CountryCode.US, percent: 0 },
          edited: true,
        },
      ])
    );
  };

  const hasChanges = rows.some((row) => row.edited);

  return (
    <Box>
      <Stack gap={2}>
        {canEdit && (
          <EditButtons
            onCancel={handleCancel}
            onEdit={() => setEditing(true)}
            onSave={handleSave}
          />
        )}
        <TableContainer component={Paper} sx={{ width: 'fit-content' }}>
          <Table>
            <Head editing={editing} />
            <TableBody>
              {loading ? (
                <SkeletonTableRows rows={5} cols={3} height={40} />
              ) : (
                rows?.map((row, index) => (
                  <Row
                    row={row}
                    key={index}
                    editing={editing}
                    index={index}
                    onChange={onRowChange}
                  />
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {editing && (
          <Button
            endIcon={<AddRoundedIcon />}
            onClick={handleAddMore}
            sx={{ width: 'fit-content' }}
          >
            {rows.length > 0
              ? t('equityPage.globalMappings.table.addMore')
              : t('equityPage.globalMappings.table.addFirst')}
          </Button>
        )}
      </Stack>
    </Box>
  );
}
