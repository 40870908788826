import {
  GetSettingType,
  SettingConfigs,
  SettingProperty,
  SettingType,
} from '@alamere/core';
import {
  SettingFragment,
  SettingFragmentDoc,
  useSettingsQuery,
  useSettingsSaveMutation,
} from '@alamere/generated-graphql-types';
import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';

export interface UseSettingsReturnType<T extends SettingProperty> {
  value: GetSettingType<T>;
  setting: SettingFragment | undefined;
  loading: boolean;
  saving: boolean;
  save: (stringValue?: string) => void;
  update: (stringValue: string) => void;
  cancel: () => void;
}

export function useSetting<T extends SettingProperty>(
  property: T,
  type: SettingType
): UseSettingsReturnType<T> {
  const { cache } = useApolloClient();
  const [doSave, { loading: saving }] = useSettingsSaveMutation();
  const { data, loading, refetch } = useSettingsQuery({
    variables: { request: { items: [{ property, type }] } },
  });

  const cachedSetting = data?.settings[0];

  const value = cachedSetting
    ? cachedSetting.stringValue
      ? SettingConfigs[property].parse(cachedSetting.stringValue)
      : SettingConfigs[property].defaultValue
    : SettingConfigs[property].defaultValue;

  const update = useCallback(
    (stringValue: string) => {
      const newSetting = { property, type, stringValue };

      cache.writeFragment({
        id: cache.identify({
          property,
          type,
          __typename: 'Setting',
        }),
        fragmentName: 'Setting',
        fragment: SettingFragmentDoc,
        data: newSetting,
      });
    },
    [cachedSetting, cache, property, type]
  );

  const save = useCallback(
    (stringValue?: string) => {
      if (stringValue !== undefined) {
        update(stringValue);
        return doSave({
          variables: {
            settingsSaveRequest: { items: [{ property, type, stringValue }] },
          },
        });
      }

      if (cachedSetting?.stringValue !== undefined) {
        // save the cached value - works when a parent component tries to call save
        return doSave({
          variables: {
            settingsSaveRequest: {
              items: [
                {
                  property: cachedSetting.property,
                  type: cachedSetting.type,
                  stringValue: cachedSetting.stringValue,
                },
              ],
            },
          },
        });
      }

      return Promise.resolve();
    },
    [doSave, cachedSetting, update, property, type]
  );

  const cancel = useCallback(() => {
    refetch();
  }, []);

  return {
    setting: cachedSetting,
    value,
    loading,
    saving,
    save,
    update,
    cancel,
  };
}
