const ALL_STATES = [
  { id: 'AL', val: '01' },
  { id: 'AK', val: '02' },
  // { id: 'AS', val: '60' },
  { id: 'AZ', val: '04' },
  { id: 'AR', val: '05' },
  { id: 'CA', val: '06' },
  { id: 'CO', val: '08' },
  { id: 'CT', val: '09' },
  { id: 'DE', val: '10' },
  { id: 'DC', val: '11' },
  { id: 'FL', val: '12' },
  { id: 'FM', val: '64' },
  { id: 'GA', val: '13' },
  // { id: 'GU', val: '66' },
  { id: 'HI', val: '15' },
  { id: 'ID', val: '16' },
  { id: 'IL', val: '17' },
  { id: 'IN', val: '18' },
  { id: 'IA', val: '19' },
  { id: 'KS', val: '20' },
  { id: 'KY', val: '21' },
  { id: 'LA', val: '22' },
  { id: 'ME', val: '23' },
  { id: 'MH', val: '68' },
  { id: 'MD', val: '24' },
  { id: 'MA', val: '25' },
  { id: 'MI', val: '26' },
  { id: 'MN', val: '27' },
  { id: 'MO', val: '29' },
  // { id: 'MP', val: '69' },
  { id: 'MS', val: '28' },
  { id: 'MT', val: '30' },
  { id: 'NE', val: '31' },
  { id: 'NV', val: '32' },
  { id: 'NH', val: '33' },
  { id: 'NJ', val: '34' },
  { id: 'NM', val: '35' },
  { id: 'NY', val: '36' },
  { id: 'NC', val: '37' },
  { id: 'ND', val: '38' },
  { id: 'OH', val: '39' },
  { id: 'OK', val: '40' },
  { id: 'OR', val: '41' },
  { id: 'PW', val: '70' },
  { id: 'PA', val: '42' },
  // { id: 'PR', val: '72' },
  { id: 'RI', val: '44' },
  { id: 'SC', val: '45' },
  { id: 'SD', val: '46' },
  { id: 'TN', val: '47' },
  { id: 'TX', val: '48' },
  { id: 'UM', val: '74' },
  { id: 'UT', val: '49' },
  { id: 'VT', val: '50' },
  { id: 'VA', val: '51' },
  // { id: 'VI', val: '78' },
  { id: 'WA', val: '53' },
  { id: 'WV', val: '54' },
  { id: 'WI', val: '55' },
  { id: 'WY', val: '56' },
];

export default ALL_STATES;
