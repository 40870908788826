import { Scope } from '@alamere/core';
import { useState } from 'react'
import ArchitectureRoundedIcon from '@mui/icons-material/ArchitectureRounded';
import Diversity1Icon from '@mui/icons-material/Diversity1';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import ElevatorOutlinedIcon from '@mui/icons-material/ElevatorOutlined';
import RedeemIcon from '@mui/icons-material/Redeem';
import BalanceIcon from '@mui/icons-material/Balance';
import TravelExploreRoundedIcon from '@mui/icons-material/TravelExploreRounded';
import { List, ListItemButton } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useFlags } from 'flagsmith/react';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';
import { Flag } from '../../../../../core/src/lib/flags';
import { useScopes } from '../../hooks/useScopes';
import { useAuth } from '../../providers/auth';
import { WorkspacePicker } from './WorkspacePicker';
import { green, white } from '../colors';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LayersIcon from '@mui/icons-material/Layers';

export interface MainMenuProps {
  currentPath: string;
}

export interface MenuItemProps {
  currentPath: string;
  item: MenuItem;
  depth: number;
}

interface MenuItem {
  path: string;
  transKey: string;
  icon?: React.ReactNode;
  requiredFlags?: Flag[];
  requiredScopes?: Scope[];
  children?: MenuItem[];
}

type ExpansionKeys = 'tools' | 'inputs';

export const TOOLS_MENU_ITEMS: MenuItem[] = [
  {
    path: `location-lookup`,
    transKey: 'locationLookupPage.menu',
    icon: <TravelExploreRoundedIcon />,
    requiredScopes: [Scope.VIEW_LOCATION_CALCULATOR],
  },
  {
    path: `comp-ranges`,
    transKey: 'compRangesPage.menu',
    icon: <ElevatorOutlinedIcon />,
    requiredFlags: [Flag.JOB_CATALOG],
    requiredScopes: [Scope.EQUITY_BANDS_VIEW],
  },
  {
    path: `job-architecture`,
    transKey: 'jobArchitecturePage.menu',
    icon: <ArchitectureRoundedIcon />,
    requiredFlags: [Flag.JOB_CATALOG],
    requiredScopes: [Scope.JOB_ARCHITECTURE_VIEW],
  },
]

export const MAIN_MENU_ITEMS: MenuItem[] = [
  {
    path: `global-level`,
    transKey: 'global.globalLevel',
    icon: <LayersIcon />,
    requiredFlags: [Flag.JOB_CATALOG],
    requiredScopes: [Scope.JOB_FAMILIES_VIEW],
  },
  {
    path: `job-families`,
    transKey: 'jobFamiliesPage.menu',
    icon: <Diversity1Icon />,
    requiredFlags: [Flag.JOB_CATALOG],
    requiredScopes: [Scope.JOB_FAMILIES_VIEW],
  },
  {
    path: `cash`,
    transKey: 'global.cash',
    icon: <LocalAtmIcon />,
    requiredFlags: [Flag.JOB_CATALOG],
    requiredScopes: [Scope.JOB_FAMILIES_VIEW],
  },
  {
    path: `bonus-structures`,
    transKey: 'bonusStructuresPage.menu',
    icon: <RedeemIcon />,
    requiredFlags: [Flag.JOB_CATALOG],
    requiredScopes: [Scope.EQUITY_BANDS_VIEW],
  },
  {
    path: `equity`,
    transKey: 'equityPage.menu',
    icon: <BalanceIcon />,
    requiredFlags: [Flag.JOB_CATALOG],
    requiredScopes: [Scope.EQUITY_BANDS_VIEW],
  },
  {
    path: `merit-matrices`,
    transKey: 'meritMatrices.menu',
    icon: <RedeemIcon />,
  },
  {
    path: `merit-ratings`,
    transKey: 'meritRatings.menu',
    icon: <RedeemIcon />,
  },
];

interface MenuItemsMapType {
  'tools': MenuItem[];
  'inputs': MenuItem[];
}

const MenuItemsMap: MenuItemsMapType = {
  'tools': TOOLS_MENU_ITEMS,
  'inputs': MAIN_MENU_ITEMS,
}

export function MainMenu({ currentPath }: MainMenuProps) {
  const [expanded, setExpanded] = useState<Record<ExpansionKeys, boolean>>({
    tools: true,
    inputs: false,
  });

  const handleExpansion = (key: ExpansionKeys) => {
    setExpanded((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  const renderItems = (items: MenuItem[]) =>
    items.flatMap((item: MenuItem) => (
      <MenuItem
        key={item.path}
        item={item}
        currentPath={currentPath}
        depth={1}
      />
    ));

  const accordionStyles = {
    border: 'none',
    margin: '0 !important',
    '& .MuiAccordion-region': { height: expanded ? 'auto' : 0 },
    '& .MuiAccordionSummary-root': {
      background: green[400],
      color: white,
      '& svg': { color: white }
    },
    '& .MuiAccordionDetails-root': {
      display: expanded ? 'block' : 'none',
      padding: 0,
      background: green[400],
    },
    '&::before': {
      opacity: 0,
    }
  };

  return (
    <>
      <WorkspacePicker />
      {(Object.keys(MenuItemsMap) as Array<'tools' | 'inputs'>).map((key) => {
        return (
          <Accordion
            key={key}
            expanded={expanded[key]}
            onChange={() => handleExpansion(key)}
            sx={accordionStyles}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>{t(`global.${key}`)}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {renderItems(MenuItemsMap[key])}
            </AccordionDetails>
          </Accordion >
        )
      })}
    </>
  );
}

const MenuItem = ({ item, currentPath, depth }: MenuItemProps) => {
  const { checkScopes } = useScopes();
  const flags = useFlags(item.requiredFlags || []);
  const navigate = useNavigate();
  const { workspace } = useAuth();

  if (
    item.requiredFlags &&
    !item.requiredFlags.every((flag) => flags[flag].enabled)
  ) {
    return null;
  }

  if (item.requiredScopes && !checkScopes(...item.requiredScopes)) {
    return null;
  }

  const itemPath = `/w/${workspace?.id}/${item.path}`;

  const isSelected = () => itemPath === currentPath || currentPath.includes('/' + item.path + '/')

  return (
    <>
      <ListItemButton
        key={itemPath}
        onClick={() => { navigate(itemPath) }}
        sx={{
          py: 0.7,
          pl: depth * 2,
          mx: 1,
          borderRadius: '8px',
          backgroundColor: isSelected() ? green[150] : green[850],
          '&:hover': {
            backgroundColor: isSelected() ? green[150] : green[850],
            color: 'inherit',
            boxShadow: 'none',
          },
          '& svg': { color: `${isSelected() ? green[850] : green[150]} !important` }
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 35,
            visibility: item.icon ? 'visible' : 'hidden'
          }}
        >
          {item.icon}
        </ListItemIcon>
        <ListItemText
          sx={{ color: isSelected() ? green[850] : white }}
          primary={t(item.transKey)}
        />
      </ListItemButton >
      <List component="div" sx={{ py: 0 }}>
        {item.children?.map((child) => (
          <MenuItem
            key={child.path}
            item={child}
            currentPath={currentPath}
            depth={depth + 1}
          />
        ))}
      </List>
    </>
  );
};
