import { ApolloClient, InMemoryCache } from '@apollo/client';
import { RestLink } from 'apollo-link-rest';

// Set `RestLink` with your endpoint
const restLink = new RestLink({
  uri: 'api/auth/',
  headers: {
    'Content-Type': 'application/json',
  },
});

// Setup your client
export const authClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: restLink,
  defaultOptions: { query: { fetchPolicy: 'no-cache' } },
});
