import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { t } from 'i18next';
import { memo } from 'react';

interface Props {
  editing?: boolean;
}

export const Header = memo(({ editing }: Props) => {
  return (
    <TableHead>
      <TableRow>
        <TableCell sx={{ minWidth: editing ? 150 : undefined }}>
          {t('jobFamiliesPage.table.jobFamilyCode')}
        </TableCell>
        <TableCell sx={{ minWidth: editing ? 180 : undefined }}>
          {t('jobFamiliesPage.table.jobFunction')}
        </TableCell>
        <TableCell sx={{ minWidth: editing ? 180 : undefined }}>
          {t('jobFamiliesPage.table.jobFamilyName')}
        </TableCell>
        <TableCell sx={{ minWidth: editing ? 80 : undefined }} align="center">
          {t('jobFamiliesPage.table.ignoreGeodiff')}
        </TableCell>
        <TableCell sx={{ minWidth: editing ? 300 : undefined }} align="center">
          {t('jobFamiliesPage.table.compBandGroup')}
        </TableCell>
        <TableCell sx={{ minWidth: editing ? 180 : undefined }} align="center">
          {t('jobFamiliesPage.table.rangeWidthGroup')}
        </TableCell>
        <TableCell sx={{ minWidth: editing ? 180 : undefined }} align="center">
          {t('jobFamiliesPage.table.bonusStructure')}
        </TableCell>
        <TableCell
          sx={{
            minWidth: editing ? 200 : undefined,
          }}
          align="center"
        >
          {t('jobFamiliesPage.table.equityBandGroup')}
        </TableCell>
        <TableCell sx={{ minWidth: editing ? 180 : undefined }} align="center">
          {t('jobFamiliesPage.table.compBandMultiplier')}
        </TableCell>
        <TableCell sx={{ minWidth: editing ? 300 : undefined }} align="center">
          {t('jobFamiliesPage.table.executiveMatch')}
        </TableCell>
        <TableCell sx={{ minWidth: editing ? 300 : undefined }} align="center">
          {t('jobFamiliesPage.table.managementMatch')}
        </TableCell>
        <TableCell sx={{ minWidth: editing ? 300 : undefined }} align="center">
          {t('jobFamiliesPage.table.professionalMatch')}
        </TableCell>
        <TableCell sx={{ minWidth: editing ? 300 : undefined }} align="center">
          {t('jobFamiliesPage.table.technicalMatch')}
        </TableCell>
        <TableCell sx={{ minWidth: editing ? 300 : undefined }} align="center">
          {t('jobFamiliesPage.table.supportMatch')}
        </TableCell>
        {editing && <TableCell align="center" />}
      </TableRow>
    </TableHead>
  );
});
