import { Divider, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

import { GoogleLogin } from '@react-oauth/google';
import { t } from 'i18next';
import { useCallback, useEffect, useState } from 'react';
import ifEnter from '../../lib/ifEnter';
import { useAuth } from '../../providers/auth';
import { useGoogleLogin } from './useGoogleLogin';
import { usePasswordLogin } from './usePasswordLogin';

export default function LoginPage() {
  const [params] = useSearchParams();
  const { user, workspace, onLogIn } = useAuth();
  const [loginWithPassword] = usePasswordLogin();
  const [loginWithGoogle] = useGoogleLogin();
  const navigate = useNavigate();
  const [email, setEmail] = useState(params.get('email') || '');
  const [password, setPassword] = useState(params.get('password') || '');

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (!user && !loading && params.get('email') && params.get('password')) {
      onPasswordLogin();
    }
  }, []);

  useEffect(() => {
    if (workspace) {
      navigate(`/w/${workspace.id}/location-lookup`, { replace: true });
    }
  }, [workspace]);

  const onPasswordLogin = () => {
    setLoading(true);
    return loginWithPassword({ email, password })
      .then((response) => {
        setError(false);
        return onLogIn(response?.data?.login.access_token);
      })
      .catch((error) => {
        setError(true);
      })
      .finally(() => setLoading(false));
  };

  const onGoogleLogin = useCallback(
    (googleTokenLoginRequest: { credential?: string; clientId?: string }) => {
      if (
        !googleTokenLoginRequest.credential ||
        !googleTokenLoginRequest.clientId
      ) {
        setError(true);
        return;
      }

      setLoading(true);
      loginWithGoogle(googleTokenLoginRequest)
        .then((response) => {
          setError(false);
          return onLogIn(response?.data?.loginWithGoogle.access_token);
        })
        .catch((error) => {
          setError(true);
        })
        .finally(() => setLoading(false));
    },
    []
  );

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 12,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5" sx={{ mt: 2 }}>
          {t('loginPage.title')}
        </Typography>
        <Stack spacing={1} sx={{ my: 3 }}>
          <Box>
            <GoogleLogin
              size="large"
              onSuccess={onGoogleLogin}
              width={500}
              logo_alignment="center"
              useOneTap={false}
            />
          </Box>
          <Divider sx={{ py: 1, px: 15 }}>
            <Typography color="text.secondary">or</Typography>
          </Divider>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label={t('global.email')}
            name="email"
            autoComplete="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onKeyUp={ifEnter(onPasswordLogin)}
            disabled={loading}
            error={error}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label={t('global.auth.password')}
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyUp={ifEnter(onPasswordLogin)}
            autoComplete="current-password"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    size="small"
                    onClick={() =>
                      navigate(
                        `/login/forgot-password?${createSearchParams({
                          email,
                        }).toString()}`
                      )
                    }
                  >
                    forgot password?
                  </Button>
                </InputAdornment>
              ),
            }}
            disabled={loading}
            error={error}
          />
          <Button
            onClick={onPasswordLogin}
            fullWidth
            variant="contained"
            size="large"
            disabled={loading}
          >
            {t('global.auth.login')}
          </Button>

          <Divider sx={{ py: 2 }} />

          <Stack sx={{ textAlign: 'center' }} spacing={1}>
            <Typography color="text.secondary" sx={{ pt: 4 }}>
              {t('loginPage.noAccount')}
            </Typography>
            <Button onClick={() => navigate('/signup')} variant="outlined">
              {t('global.auth.signup')}
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Container>
  );
}
