import {
  useGlobalLevelsQuery,
  useJobLevelsQuery,
} from '@alamere/generated-graphql-types';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { t } from 'i18next';
import { orderBy } from 'lodash';
import { memo, useMemo } from 'react';
import EquityBandsHeader from './EquityBandsHeaderRow';
import EquityBandsRow from './EquityBandsRow';
import { OnGlobalLevelChangeFunction } from './RsuOptionsMix';
import { OnBandChangeFunction, OnGroupChangeFunction } from './types';
import { useScopes } from '../../../hooks/useScopes';
import { useAuth } from '../../../providers/auth';
import { Scope } from '@alamere/core';

export interface EquityBandsTableProps {
  editing: boolean;
  onGroupChange: OnGroupChangeFunction;
  onBandChange: OnBandChangeFunction;
  onGlobalLevelChange: OnGlobalLevelChangeFunction;
}

export default memo(function EquityBandsTable({
  editing,
  onGroupChange,
  onBandChange,
  onGlobalLevelChange,
}: EquityBandsTableProps) {
  const { checkScopes } = useScopes();
  const { workspaceMembership } = useAuth();
  const { data: globalLevelData, loading: loadingGlobalLevelData } =
    useGlobalLevelsQuery();
  const { data: jobLevelsData } = useJobLevelsQuery({
    variables: {
      jobLevelsGetRequest: {
        onlyVisible: true,
      },
    },
  });
  const visibleGlobalLevels = useMemo(
    () =>
      globalLevelData?.globalLevels.filter((level) =>
        jobLevelsData?.jobLevels.some(
          (jobLevel) => jobLevel?.level === level.level
        )
      ),
    [globalLevelData, jobLevelsData]
  );
  const orderedGlobalLevels = orderBy(
    visibleGlobalLevels ?? [],
    ['level'],
    ['desc']
  );

  return (
    <TableContainer component={Paper} sx={{ width: 'fit-content' }}>
      <Table
        sx={(theme) => ({
          tableLayout: 'auto',
          '& .highlighted-column': {
            backgroundColor: theme.palette.background.secondary,
          },
        })}
        size="small"
        aria-label={t('equityBandsPage.table.name')}
      >
        <EquityBandsHeader editing={editing} onGroupChange={onGroupChange} />
        <TableBody>
          {(
            checkScopes(Scope.SEE_ALL_LEVELS_DATA)
              ? orderedGlobalLevels
              : orderedGlobalLevels
                .filter((level) =>
                  level.level < (workspaceMembership?.globalLevelLevel as number)))
            .map((globalLevel, index) => (
            <EquityBandsRow
              key={globalLevel.level}
              globalLevel={globalLevel}
              previousGlobalLevel={
                index < orderedGlobalLevels.length - 1
                  ? orderedGlobalLevels[index + 1]
                  : undefined
              }
              editing={editing}
              onBandChange={onBandChange}
              onGlobalLevelChange={onGlobalLevelChange}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
});
