import DarkModeRoundedIcon from '@mui/icons-material/DarkModeRounded';
import LightModeRoundedIcon from '@mui/icons-material/LightModeRounded';
import MeetingRoomRoundedIcon from '@mui/icons-material/MeetingRoomRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import {
  Avatar,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useScopes } from '../../hooks/useScopes';
import { useAuth } from '../../providers/auth';
import { ColorModeContext } from '../theme';
import { Scope } from '@alamere/core';

export default function AvatarMenu() {
  const navigate = useNavigate();
  const colorMode = React.useContext(ColorModeContext);
  const { user, workspace, onLogOut } = useAuth();
  const { checkScopes } = useScopes();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLLabelElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onLogout = () => {
    handleClose();
    onLogOut();
  };
  const onSettingsClick = () => {
    navigate(`/w/${workspace?.id}/settings`);
    handleClose();
  };

  const userInitials = [user?.firstName[0], user?.lastName[0]].join('');

  return (
    <div>
      <IconButton
        color="primary"
        aria-label="Open menu"
        aria-haspopup="true"
        component="label"
        id="avatar-button"
        aria-controls={open ? 'avatar-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <Avatar
          sx={{
            width: 32,
            height: 32,
            filter: 'drop-shadow(2px 2px 2px rgb(0 0 0 / 0.2))',
          }}
          alt={user?.fullName}
          src={!!user?.avatarUrl ? user?.avatarUrl : undefined}
        >
          {user?.avatarUrl ? null : !!userInitials ? (
            userInitials
          ) : (
            <PersonRoundedIcon />
          )}
        </Avatar>
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'avatar-button',
        }}
      >
        <List component="div" disablePadding>
          <ListItem>
            <ListItemIcon sx={{ minWidth: 35 }}>
              <PersonRoundedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={user?.fullName} secondary={user?.email} />
          </ListItem>
        </List>
        <Divider sx={{ mb: 2 }} />

        {checkScopes(Scope.EDIT_WORKSPACE) && (
          <MenuItem onClick={onSettingsClick}>
            <ListItemIcon>
              <SettingsRoundedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Settings</ListItemText>
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            colorMode.toggleColorMode();
            handleClose();
          }}
        >
          <ListItemIcon>
            {colorMode.activeMode === 'light' ? (
              <DarkModeRoundedIcon fontSize="small" />
            ) : (
              <LightModeRoundedIcon fontSize="small" />
            )}
          </ListItemIcon>
          <ListItemText>
            {colorMode.activeMode === 'light' ? 'Dark' : 'Light'} mode
          </ListItemText>
        </MenuItem>

        <Divider sx={{ mb: 2 }} />

        <MenuItem onClick={onLogout}>
          <ListItemIcon>
            <MeetingRoomRoundedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Logout</ListItemText>
        </MenuItem>
      </Menu>
    </div>
  );
}
