import { JobGroup } from '@alamere/core';
import { TableRow } from '@mui/material';
import { groupBy } from 'lodash';
import { memo, useMemo } from 'react';
import {
  GlobalLevelFragment,
  JobFragment,
  JobLevelFragment,
} from '../../../graphql/_generated/generated-graphql-types';
import EditableGlobalLevelCell from '../EditableGlobalLevelCell';
import Cell from './Cell';
import { OnItemChangeFunction } from './JobDefinitionsPage.page';

interface Props {
  globalLevel: GlobalLevelFragment;
  jobLevels: JobLevelFragment[];
  jobs: JobFragment[];
  editing: boolean;
  orderedJobGroups: JobGroup[];
  jobFamilyId: number;
  onItemChange: OnItemChangeFunction;
}
export default memo(
  ({
    globalLevel,
    jobLevels,
    jobs,
    orderedJobGroups,
    editing,
    jobFamilyId,
    onItemChange,
  }: Props) => {
    const jobsByGroup = useMemo(
      () => groupBy(jobs, (job) => job?.jobLevel.group),
      [jobs]
    );

    if (
      !editing &&
      !Object.values(jobsByGroup).some((job) => job !== undefined)
    ) {
      return null;
    }

    return (
      <TableRow>
        <EditableGlobalLevelCell
          level={globalLevel.level}
          globalLevel={globalLevel}
          editing={false}
          onChange={() => {}}
        />
        {orderedJobGroups.map((group) => {
          const jobLevel = jobLevels.find(
            (jobLevel) => jobLevel?.group === group
          );
          return (
            <Cell
              jobs={jobsByGroup[group]}
              jobLevel={jobLevel}
              jobFamilyId={jobFamilyId}
              jobGroup={group}
              editing={editing}
              key={group}
              onItemChange={onItemChange}
            />
          );
        })}
      </TableRow>
    );
  }
);
