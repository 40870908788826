import { Button, Typography} from '@mui/material';
import { useNavigate } from 'react-router-dom';

export function Logo({ color }: { color?: string }) {
  const navigate = useNavigate();
  return (
    <Button
      onClick={() => navigate('/')}
      variant="text"
      color="inherit"
      disableRipple
    >
      <Typography component="h1" variant="title" fontWeight={900} pl={1}>Alamere</Typography>
    </Button>
  );
}
