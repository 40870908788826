import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';
import { ListItemButton, ListSubheader, MenuItem } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../providers/auth';

export interface HqMenuProps {
  currentPath: string;
}

interface MenuItem {
  path: string;
  label: string;
  icon: React.ReactNode;
}

const mainMenuItems: MenuItem[] = [
  {
    path: `/hq/w`,
    label: 'Workspaces',
    icon: <BusinessRoundedIcon />,
  },
];

export function HqSidebarMenu({ currentPath }: HqMenuProps) {
  const { workspace } = useAuth();
  const navigate = useNavigate();

  const items = mainMenuItems.map((item: MenuItem) => {
    return (
      <ListItemButton
        key={item.path}
        onClick={() => navigate(item.path)}
        selected={item.path === currentPath}
      >
        <ListItemIcon sx={{ minWidth: 35 }}>{item.icon}</ListItemIcon>
        <ListItemText primary={item.label} />
      </ListItemButton>
    );
  });

  return (
    <>
      <ListSubheader>HQ</ListSubheader>
      {items}
    </>
  );
}
