import {
  GlobalLevelFragment,
  useEquityBandGroupsQuery,
} from '@alamere/generated-graphql-types';
import { TableCell, TableRow } from '@mui/material';
import { orderBy } from 'lodash';
import { memo } from 'react';
import GlobalLevelCell from '../../../components/GlobalLevelCell';
import EquityBandsCells from './EquityBandsCells';
import RsuOptionsMix, { OnGlobalLevelChangeFunction } from './RsuOptionsMix';
import { OnBandChangeFunction } from './types';

export interface EquityBandsRowProps {
  globalLevel: GlobalLevelFragment;
  editing: boolean;
  previousGlobalLevel?: GlobalLevelFragment;
  onBandChange: OnBandChangeFunction;
  onGlobalLevelChange: OnGlobalLevelChangeFunction;
}

export default memo(function EquityBandsRow({
  globalLevel,
  editing,
  previousGlobalLevel,
  onBandChange,
  onGlobalLevelChange,
}: EquityBandsRowProps) {
  const { data: equityBandGroups, loading: loadingEquityBandGroupsData } =
    useEquityBandGroupsQuery();

  const groups = orderBy(
    equityBandGroups?.equityBandGroups ?? [],
    ['id'],
    ['asc']
  );

  return (
    <TableRow>
      <GlobalLevelCell globalLevel={globalLevel} />

      <TableCell align="center" className="highlighted-column">
        <RsuOptionsMix
          globalLevel={globalLevel}
          editing={editing}
          onGlobalLevelChange={onGlobalLevelChange}
        />
      </TableCell>
      {groups.map((group, index) => (
        <EquityBandsCells
          key={group.id}
          group={group}
          globalLevel={globalLevel}
          previousGlobalLevel={previousGlobalLevel}
          editing={editing}
          loading={loadingEquityBandGroupsData}
          isHighlighted={index % 2 !== 0}
          onBandChange={onBandChange}
        />
      ))}
    </TableRow>
  );
});
