import { gql } from '@apollo/client';
import { useCallback } from 'react';
import { authClient } from '../../api/authClient';

const loginWithGoogleGql = gql`
  fragment GooglePayload on REST {
    access_token: String
  }
  query LoginWithGoogle($input: Payload!) {
    loginWithGoogle(input: $input) @rest(path: "login/google", method: "POST") {
      access_token
    }
  }
`;

export function useGoogleLogin() {
  const execute = useCallback(
    (googleTokenLoginRequest: { credential?: string; clientId?: string }) => {
      return authClient.query({
        query: loginWithGoogleGql,
        variables: {
          input: googleTokenLoginRequest,
        },
      });
    },
    []
  );
  return [execute];
}
