import { CountryCode, Tier } from '@alamere/core';
import { Stack } from '@mui/material';
import { useMemo, useState } from 'react';
import { t } from 'i18next';
import {
  EquityGlobalMappingFragment, useBonusStructureGroupsQuery, useCompBandGroupsQuery, useEquityBandGroupsQuery,
  useJobFamiliesQuery, useJobLevelsQuery, useJobsQuery, useRangeWidthGroupsQuery, JobFamilyFragment
} from '../../graphql/_generated/generated-graphql-types';
import JobFamilyPicker from '../../components/JobFamilyPicker';
import { MultiSelect, SelectOption } from '../../components/MultiSelect';
import { TierPicker } from '../../components/TierPicker';
import { Table } from './Table';

export default function CompRangesPage() {
  const [jobFamily, setJobFamily] = useState<JobFamilyFragment | null>(null);
  const [levels, setLevels] = useState<SelectOption[]>([]);
  const [tier, setTier] = useState<Tier | null>(null);
  const [equityGlobalMapping, setEquityGlobalMapping] =
    useState<EquityGlobalMappingFragment | null>({ countryCode: CountryCode.US, id: 1, percent: 2, useGeoDiff: true });
  const { data: jobFamiliesData } = useJobFamiliesQuery();
  const { data: jobLevelsData } = useJobLevelsQuery({
    variables: {
      jobLevelsGetRequest: {
        onlyVisible: false,
      },
    },
  });
  const { data: jobsData, loading: loadingJobs } = useJobsQuery({
    variables: {
      jobGetRequest: {
        jobFamilyIds: jobFamily ? [jobFamily.id] : [],
      },
    },
  });
  const { data: rangeWidthGroupsData} = useRangeWidthGroupsQuery();
  const { data: compBandGroupsData} = useCompBandGroupsQuery();
  const { data: groupsData } = useBonusStructureGroupsQuery();
  const { data: equityBandGroupsData, loading: loadingEquityBandGroups } =
    useEquityBandGroupsQuery();

  const combinedJobFamilyData = useMemo(() => {
    if (!jobFamiliesData || !rangeWidthGroupsData || !compBandGroupsData || !groupsData || !equityBandGroupsData || !jobFamily || levels.length === 0) {
      return [];
    }

    return jobFamiliesData.jobFamilies
      .filter(family => family.id === jobFamily.id)
      .map(jobFamily => {
        const rangeWidthGroup = rangeWidthGroupsData.rangeWidthGroups.find(
          group => group.id === jobFamily.rangeWidthGroup?.id
        );

        const compBandGroup = compBandGroupsData.compBandsGroups.find(
          group => group.id === jobFamily.compBandGroup?.id
        );

        const bonusStructureGroup = groupsData.bonusStructureGroups.find(
          group => group.id === jobFamily.bonusStructureGroup?.id
        );

        const equityBandGroup = equityBandGroupsData.equityBandGroups.find(
          group => group.id === jobFamily.equityBandGroup?.id
        );

        const filteredRangeWidths = rangeWidthGroup?.rangeWidths.filter(rw =>
          levels.some(level => level.value === rw.level)
        ) || [];

        const filteredCompBands = compBandGroup?.compBands.filter(cb =>
          levels.some(level => level.value === cb.globalLevelLevel)
        ) || [];

        const filteredBonusStructures = bonusStructureGroup?.bonusStructures.filter(bs =>
          levels.some(level => level.value === bs.globalLevelLevel)
        ) || [];

        const filteredEquityBands = equityBandGroup?.equityBands.filter(eb =>
          levels.some(level => level.value === eb.globalLevelLevel)
        ) || [];

        if (!rangeWidthGroup || !compBandGroup || !bonusStructureGroup || !equityBandGroup ||
          filteredRangeWidths.length === 0 || filteredCompBands.length === 0 ||
          filteredBonusStructures.length === 0 || filteredEquityBands.length === 0) {
          return null;
        }

        return {
          ...jobFamily,
          rangeWidthGroup: {
            ...rangeWidthGroup,
            rangeWidths: filteredRangeWidths
          },
          compBandGroup: {
            ...compBandGroup,
            compBands: filteredCompBands
          },
          bonusStructureGroup: {
            ...bonusStructureGroup,
            bonusStructures: filteredBonusStructures
          },
          equityBandGroup: {
            ...equityBandGroup,
            equityBands: filteredEquityBands
          }
        };
      })
      .filter(Boolean);

  }, [jobFamiliesData, rangeWidthGroupsData, compBandGroupsData, groupsData, equityBandGroupsData, jobFamily, levels]);

  const levelOptions = useMemo(() => {
    if (!jobsData?.jobs) return [];

    const levelMap = new Map();

    jobsData.jobs.forEach(job => {
      const level = job.jobLevel.level;
      const title = job.title;

      if (levelMap.has(level)) {
        levelMap.get(level).titles.push(title);
      } else {
        levelMap.set(level, {
          level,
          titles: [title],
          name: job.jobLevel.name
        });
      }
    });

    return Array.from(levelMap.values())
      .map(({ level }) => ({
        title: `Level ${level}`,
        value: level
      }))
      .sort((a, b) => b.value - a.value);
  }, [jobsData]);

  const handleLevelChange = (levels: SelectOption[]) => {
    setLevels(levels);
  };

  const handleJobFamilyChange = (jobFamily: JobFamilyFragment | null) => {
    setJobFamily(jobFamily);
    setLevels([]);
  };

  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={2}>
        <JobFamilyPicker onChange={handleJobFamilyChange} sx={{minWidth: 285}} />
        <MultiSelect
          disabled={!jobFamily}
          label={t('compRangesPage.select.level')}
          onChange={handleLevelChange}
          options={levelOptions}
          sx={{minWidth: 285}}
          levels={levels}
        />
        <TierPicker
          countryCode={CountryCode.US}
          useGeoDiff={equityGlobalMapping?.useGeoDiff ?? false}
          onChange={setTier}
          sx={{ width: 150 }}
        />
      </Stack>
      <Table
        jobFamily={jobFamily ? jobFamily.id : null}
        levels={levels.flatMap((level) => level.value)}
        combinedJobFamilyData={combinedJobFamilyData}
        jobsData={jobsData}
        loadingJobs={loadingJobs}
        tier={tier}
      />
    </Stack>
  );
}
