import { Helmet } from 'react-helmet-async';

export interface TitleProps {
  value: string;
  children: React.ReactNode;
}

export default function Title({ value, children }: TitleProps) {
  return (
    <>
      <Helmet>
        <title>{value} | Alamere Compensation</title>
      </Helmet>
      {children}
    </>
  );
}
