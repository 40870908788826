import capitalize from 'lodash/capitalize';

export const parseInput = (
  input: string,
  addTrailingLine: boolean = true
): string[] => {
  const enteredZips = new Set();
  if (input.length < 5) {
    return [restrictToFiveDigits(input)];
  }
  const lines = input.split(/[\r?\n\,\s]/);
  
  for (let i = 0; i < lines.length; i++) {
    lines[i] = restrictToFiveDigits(lines[i]);
    if (lines[i] === '' || enteredZips.has(lines[i])) {
      lines.splice(i, 1);
    }
    enteredZips.add(lines[i]);
  }
  if (addTrailingLine && lines[lines.length - 1].length === 5) {
    lines.push('');
  }
  return lines;
};
const restrictToFiveDigits = (input: string) => {
  input = input
    .trim()
    .replace(/[^0-9]/g, '')
    .slice(0, 5);
  return input;
};

export const capitalizeCity = (city: string): string => {
  return city.split(' ').map(capitalize).join(' ');
}

export const formatNumber = (number: number | undefined) => {
  return number !== undefined ? number.toLocaleString('en') : '';
};
