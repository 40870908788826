import { GlobalLevelFragment } from '@alamere/generated-graphql-types';
import { TableCell } from '@mui/material';

export interface GlobalLevelCellProps {
  globalLevel: GlobalLevelFragment;
}

export default function GlobalLevelCell({ globalLevel }: GlobalLevelCellProps) {
  return <TableCell align="center">{globalLevel.name}</TableCell>;
}
