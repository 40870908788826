// made with https://materialpalettes.com/

export const green = {
  '50': '#dff2ef',
  '100': '#3f6c5140', // green[850] at 25%
  '150': '#d1dad4',
  '200': '#7cc9bd',
  '250': '#294314',
  '300': '#46b3a3',
  '400': '#3F6C51',
  '500': '#62a87c',
  '600': '#007561',
  '700': '#006553',
  '800': '#004a38',
  '850': '#3F6C51',
  '900': '#152420',
};
export const yellow = {
  '50': '#faf1e0',
  '100': '#f2dbb1',
  '200': '#eac37f',
  '300': '#e2ac4d',
  '400': '#de9b2b',
  '500': '#d98c10',
  '600': '#d5810c',
  '700': '#cf7307',
  '800': '#c86504',
  '900': '#be4f02',
};
export const blue = {
  '50': '#e7f2fb',
  '100': '#c6def7',
  '200': '#a5cbf1',
  '300': '#89b6ea',
  '400': '#77a6e5',
  '500': '#6c98df',
  '600': '#658ad1',
  '700': '#5b78be',
  '800': '#5368ab',
  '900': '#454b8a',
};
export const red = {
  '50': '#faeaee',
  '100': '#f4cad2',
  '200': '#df959c',
  '300': '#d06e77',
  '400': '#d94d59',
  '500': '#dd3b43',
  '600': '#ce3341',
  '700': '#bc2a3a',
  '800': '#82141f',
  '900': '#52040d',
};
export const black = {
  main: '#000',
  '50': '#222',
  '100': '#222',
  '200': '#151515',
  '300': '#151515',
  '400': '#111',
  '500': '#050505',
  '600': '#050505',
  '700': '#050505',
  '800': '#000',
  '900': '#000',
};

export const white = '#fff';
