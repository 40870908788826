import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../providers/auth';

export function useWorkspaceNavigate() {
  const navigate = useNavigate();
  const { workspace } = useAuth();

  return useCallback(
    (path: string) => {
      if (!workspace) return;
      if (path.startsWith('/')) path = path.slice(1);
      navigate(`/w/${workspace.id}/${path}`);
    },
    [navigate, workspace]
  );
}
