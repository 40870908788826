import {
  AlamereLocationId,
  AlamereLocations,
  TierManager,
} from '@alamere/core';
import { Box, Typography } from '@mui/material';

export function Exclusions({
  id,
  tierManager,
}: {
  id: AlamereLocationId;
  tierManager: TierManager;
}) {
  const exclusions = tierManager.getExclusions(id);

  if (exclusions.length === 0) return null;

  return (
    <Box lineHeight={1} sx={{ mt: 0.5 }}>
      <Typography variant="caption" lineHeight={1}>
        {`Excludes areas that are part of the following metro${
          exclusions.length > 1 ? 's' : ''
        }:`}
      </Typography>

      <ul style={{ paddingLeft: 18, marginTop: 4, paddingTop: 0 }}>
        {exclusions.map((id) => (
          <li key={id} style={{ padding: 0, margin: 3 }}>
            {AlamereLocations[id].label} ({tierManager.getTier(id).label})
          </li>
        ))}
      </ul>
    </Box>
  );
}
