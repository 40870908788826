import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { t } from 'i18next';

const Footer = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        paddingY: 6,
        backgroundColor: '#021C2F',
        color: '#fff',
      }}
    >
      <Container sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box display="flex" gap={1} alignItems="center">
          <Typography variant="body1">
            © {new Date().getFullYear()} Alamere, Inc.
          </Typography>
          <Link
            href="https://www.alamere.io/terms"
            sx={{
              color: '#fff',
              textDecoration: 'none',
              fontSize: '0.875rem',
              borderLeft: '1px solid #fff',
              paddingLeft: '0.5em',
            }}
          >
            {t('homePage.footer.termsOfService')}
          </Link>
          <Link
            href="https://www.alamere.io/privacy"
            sx={{
              color: '#fff',

              textDecoration: 'none',
              fontSize: '0.875rem',
              borderLeft: '1px solid #fff',
              paddingLeft: '0.5em',
            }}
          >
            {t('homePage.footer.privacyPolicy')}
          </Link>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
