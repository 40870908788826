import { JobGroup } from '@alamere/core';
import {
  JobFamilyFragment,
  RadfordJobFamilyFragment,
} from '@alamere/generated-graphql-types';
import { TableRow } from '@mui/material';
import { memo, useState } from 'react';
import { BonusStructureGroup } from './cells/BonusStructureGroup';
import { Code } from './cells/Code';
import { Delete } from './cells/Delete';
import { DynamicParent } from './cells/DynamicParent';
import { EquityBandGroup } from './cells/EquityBandGroup';
import { IgnoreGeoDiff } from './cells/IgnoreGeoDiff';
import { JobFunction } from './cells/JobFunction';
import { Name } from './cells/Name';
import { RadfordMatch } from './cells/RadfordMatch';
import { RangeWidthGroup } from './cells/RangeWidthGroup';
import { OnItemChangeFunction } from './types';
import { CompBandGroup } from './cells/CompBandGroup';

interface Props {
  editing: boolean;
  jobFamily: JobFamilyFragment;
  onItemChange: OnItemChangeFunction;
  familiesByJobGroup: Record<JobGroup, RadfordJobFamilyFragment[]>;
  loadingRadford: boolean;
}
export const Row = memo(
  ({
    editing,
    jobFamily,
    familiesByJobGroup,
    loadingRadford,
    onItemChange,
  }: Props) => {
    const [hovered, setHovered] = useState(false);

    return (
      <TableRow
        hover
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <Code
          editing={editing}
          jobFamily={jobFamily}
          onItemChange={onItemChange}
        />
        <JobFunction
          editing={editing}
          jobFamily={jobFamily}
          onItemChange={onItemChange}
        />
        <Name
          editing={editing}
          jobFamily={jobFamily}
          onItemChange={onItemChange}
        />
        <IgnoreGeoDiff
          editing={editing}
          jobFamily={jobFamily}
          onItemChange={onItemChange}
        />
        <CompBandGroup
          editing={editing}
          jobFamily={jobFamily}
          onItemChange={onItemChange}
        />
        <RangeWidthGroup
          editing={editing}
          jobFamily={jobFamily}
          onItemChange={onItemChange}
        />

        <BonusStructureGroup
          editing={editing}
          jobFamily={jobFamily}
          onItemChange={onItemChange}
        />
        <EquityBandGroup
          editing={editing}
          jobFamily={jobFamily}
          onItemChange={onItemChange}
        />
        <DynamicParent editing={editing} jobFamily={jobFamily} onItemChange={onItemChange}/>

        <RadfordMatch
          loading={loadingRadford}
          editing={editing}
          jobFamily={jobFamily}
          onItemChange={onItemChange}
          jobGroup={JobGroup.Executive}
          families={familiesByJobGroup[JobGroup.Executive]}
        />
        <RadfordMatch
          loading={loadingRadford}
          editing={editing}
          jobFamily={jobFamily}
          onItemChange={onItemChange}
          jobGroup={JobGroup.Management}
          families={familiesByJobGroup[JobGroup.Management]}
        />
        <RadfordMatch
          loading={loadingRadford}
          editing={editing}
          jobFamily={jobFamily}
          onItemChange={onItemChange}
          jobGroup={JobGroup.Professional}
          families={familiesByJobGroup[JobGroup.Professional]}
        />
        <RadfordMatch
          loading={loadingRadford}
          editing={editing}
          jobFamily={jobFamily}
          onItemChange={onItemChange}
          jobGroup={JobGroup.TechnicalScientific}
          families={familiesByJobGroup[JobGroup.TechnicalScientific]}
        />
        <RadfordMatch
          loading={loadingRadford}
          editing={editing}
          jobFamily={jobFamily}
          onItemChange={onItemChange}
          jobGroup={JobGroup.Support}
          families={familiesByJobGroup[JobGroup.Support]}
        />
        <Delete
          editing={editing}
          jobFamily={jobFamily}
          hovered={hovered}
          onItemChange={onItemChange}
        />
      </TableRow>
    );
  }
);
