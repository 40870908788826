import {
  SettingProperty,
  SettingType,
  useEquityBandGroupsQuery,
} from '@alamere/generated-graphql-types';
import { Skeleton } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { t } from 'i18next';
import { orderBy } from 'lodash';
import { memo } from 'react';
import { useSetting } from '../../../hooks/useSetting';
import EquityBandsHeaderCell from './EquityBandsHeaderCell';
import { OnGroupChangeFunction } from './types';

export interface EquityBandsHeaderRowProps {
  editing: boolean;
  onGroupChange: OnGroupChangeFunction;
}

type RequirementKey = 'showMinMax' | 'showDeltas';

const EQUITY_BANDS_COLUMNS: {
  transKey: string;
  getWidth: (editing: boolean) => { minWidth: number };
  requires: RequirementKey[];
}[] = [
  {
    transKey: 'equityPage.bands.min',
    getWidth: (editing: boolean) => ({ minWidth: editing ? 130 : 80 }),
    requires: ['showMinMax'],
  },
  {
    transKey: 'equityPage.bands.mid',
    getWidth: (editing: boolean) => ({ minWidth: editing ? 130 : 80 }),
    requires: [],
  },
  {
    transKey: 'equityPage.bands.max',
    getWidth: (editing: boolean) => ({ minWidth: editing ? 130 : 80 }),
    requires: ['showMinMax'],
  },
  {
    transKey: 'equityPage.bands.delta',
    getWidth: (editing: boolean) => ({ minWidth: 40 }),
    requires: ['showDeltas'],
  },
];

export const getEquityBandColumns = (
  config: Record<RequirementKey, boolean>
) => {
  const requirements = Object.entries(config).map(([k, v]) => {
    if (v) return k;
  });

  return EQUITY_BANDS_COLUMNS.filter((col) => {
    return col.requires.every((req) => requirements.includes(req));
  });
};

export default memo(function EquityBandsHeaderRow({
  editing,
  onGroupChange,
}: EquityBandsHeaderRowProps) {
  const { value: showMinMax } = useSetting(
    SettingProperty.EQUITY_BANDS_PAGE_SHOW_MIN_MAX,
    SettingType.WORKSPACE_USER
  );
  const { value: showDeltas } = useSetting(
    SettingProperty.EQUITY_BANDS_PAGE_SHOW_DELTA_FROM_PREVIOUS_BAND,
    SettingType.WORKSPACE_USER
  );
  const { data: equityBandGroups, loading } = useEquityBandGroupsQuery();

  const groups = orderBy(
    equityBandGroups?.equityBandGroups ?? [],
    ['id'],
    ['asc']
  );

  if (groups.length === 0) {
    return null;
  }

  if (loading) {
    return (
      <TableHead>
        <TableRow>
          <TableCell /* intentionally empty */ />
          <TableCell className="highlighted-column" /* intentionally empty */ />
          <TableCell colSpan={showMinMax ? 3 : 1}>
            <Skeleton />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('global.globalLevel')}</TableCell>
          {getEquityBandColumns({ showMinMax, showDeltas }).map(
            (col, index) => (
              <TableCell
                key={index}
                align="center"
                sx={col.getWidth(editing)}
                className="highlighted-column"
              >
                {t(col.transKey)}
              </TableCell>
            )
          )}
        </TableRow>
      </TableHead>
    );
  }

  return (
    <TableHead>
      <TableRow>
        <TableCell /* intentionally empty */ />
        <TableCell className="highlighted-column" /* intentionally empty */ />

        {groups.map((group, index) => {
          return (
            <EquityBandsHeaderCell
              key={group.id}
              group={group}
              editing={editing}
              colSpan={getEquityBandColumns({ showDeltas, showMinMax }).length}
              isHighlighted={index % 2 !== 0}
              onGroupChange={onGroupChange}
            />
          );
        })}
      </TableRow>
      <TableRow>
        <TableCell align="center">{t('global.globalLevel')}</TableCell>
        <TableCell align="center" className="highlighted-column">
          {t('equityPage.bands.rsuOptionsMix')}
        </TableCell>

        {groups.map((group, groupIndex) => {
          return getEquityBandColumns({ showDeltas, showMinMax }).map(
            (col, index) => (
              <TableCell
                key={`${group}:${index}`}
                align="center"
                sx={col.getWidth(editing)}
                className={groupIndex % 2 !== 0 ? 'highlighted-column' : ''}
              >
                {t(col.transKey)}
              </TableCell>
            )
          );
        })}
      </TableRow>
    </TableHead>
  );
});
