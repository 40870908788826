import { isBlessedUser } from '@alamere/core';
import { useCreateWorkspaceMutation } from '@alamere/generated-graphql-types';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {
  Box,
  Button,
  Collapse,
  List,
  ListItemButton,
  Paper,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import TextField from '@mui/material/TextField';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../providers/auth';
import { green } from '../colors';

export function WorkspacePicker() {
  const navigate = useNavigate();
  const { user, workspace, memberships, onChangeWorkspace } = useAuth();
  const [createWorkspace] = useCreateWorkspaceMutation();

  const [expanded, setExpanded] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [newWorkspaceName, setNewWorkspaceName] = useState('');

  const onToggle = () => {
    setExpanded(!expanded);
  };

  const onCreateWorkspace = () => {
    createWorkspace({
      variables: {
        createWorkspaceInput: {
          name: newWorkspaceName,
        },
      },
    }).then((res) => {
      if (res.data?.createWorkspace.id) {
        onChangeWorkspace(res.data?.createWorkspace.id);
        navigate(0);
      }
    });
    setModalOpen(false);
  };

  const hasMultipleWorkspaces = memberships && memberships.length > 1;
  const isBlessed = user && isBlessedUser(user.email);
  const shouldShowDropdown = hasMultipleWorkspaces || isBlessed;

  const paperStyles = {
    p: 0.8,
    m: 2,
    mt: 0,
    borderRadius: '8px',
    border: `1px solid ${green[150]}`,
    background: green[250],
    color: green[150],
    fontSize: '14px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  };

  if (!shouldShowDropdown) {
    return <Paper sx={paperStyles}>{workspace && workspace.name}</Paper>;
  }

  return (
    <Paper
      sx={(theme) => ({
        m: 2,
        mb: 4,
      })}
      elevation={2}
    >
      <ListItemButton onClick={onToggle}>
        <ListItemText primary={workspace && workspace.name} />
        {shouldShowDropdown && (expanded ? <ExpandLess /> : <ExpandMore />)}
      </ListItemButton>
      {shouldShowDropdown && (
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <List subheader={<li />}>
            {isBlessed && (
              <>
                <Button
                  sx={{ mx: 2, mb: 1 }}
                  variant="contained"
                  size="small"
                  startIcon={<AddRoundedIcon />}
                  onClick={() => setModalOpen(true)}
                >
                  new workspace
                </Button>
                <Dialog
                  open={modalOpen}
                  onClose={() => {
                    setModalOpen(false);
                    setNewWorkspaceName('');
                  }}
                >
                  <DialogTitle>Create New Workspace</DialogTitle>
                  <DialogContent>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="workspace-name"
                      label="Workspace Name"
                      type="text"
                      fullWidth
                      variant="standard"
                      value={newWorkspaceName}
                      onChange={(e) => setNewWorkspaceName(e.target.value)}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => {
                        setModalOpen(false);
                        setNewWorkspaceName('');
                      }}
                    >
                      Cancel
                    </Button>
                    <Button onClick={onCreateWorkspace}>Create</Button>
                  </DialogActions>
                </Dialog>
              </>
            )}
            {hasMultipleWorkspaces && (
              <ListSubheader>Change workspace</ListSubheader>
            )}
            <Box sx={{ maxHeight: 220, overflow: 'auto' }}>
              {memberships?.flatMap((membership) => {
                if (membership.workspace.id === workspace?.id) return [];
                return (
                  <ListItemButton
                    key={membership.workspace.id}
                    sx={{ pl: 4 }}
                    onClick={(e) => {
                      onToggle();
                      onChangeWorkspace(membership.workspace.id);
                    }}
                  >
                    <ListItemText primary={membership.workspace.name} />
                  </ListItemButton>
                );
              })}
            </Box>
          </List>
        </Collapse>
      )}
    </Paper>
  );
}
