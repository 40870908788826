import {
  ZipcodeResponse,
  useZipcodeSearchLazyQuery,
} from '@alamere/generated-graphql-types';
import { Box, Paper, TextField } from '@mui/material';
import { useState } from 'react';
import amplitude, { AmplitudeEventType } from '../../vendor/amplitude';

export type SearchProps = {
  onUpdate: (zipcodeResponse: ZipcodeResponse | undefined) => void;
};

export default function Search({ onUpdate }: SearchProps) {
  const [zipCode, setZipCode] = useState<string>('');
  const [search] = useZipcodeSearchLazyQuery();

  const onChange = (newZipcode: string) => {
    if (newZipcode.length > 5) {
      setZipCode(newZipcode.slice(0, 5));
    } else {
      setZipCode(newZipcode);
      onUpdate(undefined);
    }

    if (newZipcode.length === 5) {
      search({
        variables: { zipcodeSearchRequest: { zipcode: newZipcode } },
      }).then((res) => {
        onUpdate(res.data?.searchZipcode as ZipcodeResponse);
        amplitude.track(AmplitudeEventType.SearchZipcode);
      });
    }
  };

  return (
    <Box p={2}>
      <Paper elevation={3}>
        <TextField
          name="zipcode"
          label="Search by ZIP Code"
          variant="outlined"
          fullWidth
          value={zipCode}
          onChange={(e) => onChange(e.target.value)}
        />
      </Paper>
    </Box>
  );
}
