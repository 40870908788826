import { Scope } from '@alamere/core';
import {
  CompBandFragment,
  CompBandSaveRequest,
  GlobalLevelFragment,
  useCompBandGroupDeleteMutation,
  useCompBandGroupsQuery,
  useCompBandGroupsSaveMutation,
  useCompBandSaveMutation,
  useGlobalLevelsQuery,
  useJobLevelsQuery
} from '@alamere/generated-graphql-types';
import AddIcon from '@mui/icons-material/Add';
import { CircularProgress, IconButton, Stack, Typography } from '@mui/material';
import { t } from 'i18next';
import { cloneDeep, orderBy } from 'lodash';
import { lazy, Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { EditButtons } from '../../components/EditButtons';
import { useScopes } from '../../hooks/useScopes';
import {
  CompBandGroup,
  CompBandGroupUpdates,
  CompBandUpdates,
  OnCompBandChangeFunction,
  OnCompBandGroupChangeFunction,
  OnCompBandGroupDeleteFunction,
  RowData
} from './types';
import { useAuth } from '../../providers/auth';

export function CompBandsPage() {
  const { checkScopes } = useScopes();
  const { workspaceMembership } = useAuth();
  const { data: globalLevelData, loading: loadingGlobalLevel } =
    useGlobalLevelsQuery();
  const {
    data: compBandGroupsData,
    refetch: refetchCompBandGroups,
    loading: loadingCompBandGroups,
  } = useCompBandGroupsQuery();
  const { data: jobLevelsData } = useJobLevelsQuery({
    variables: {
      jobLevelsGetRequest: {
        onlyVisible: true,
      },
    },
  });
  const visibleGlobalLevels = useMemo(
    () =>
      globalLevelData?.globalLevels.filter((level) =>
        jobLevelsData?.jobLevels.some(
          (jobLevel) => jobLevel?.level === level.level
        )
      ),
    [globalLevelData, jobLevelsData]
  );

  const [saveCompBands, { loading: savingCompBands }] =
    useCompBandSaveMutation();
  const [saveCompBandGroups, { loading: savingCompBandGroup }] =
    useCompBandGroupsSaveMutation();
  const [deleteCompBandGroup, { loading: deletingCompBandGroup }] =
    useCompBandGroupDeleteMutation();

  const [globalLevels, setGlobalLevels] = useState<GlobalLevelFragment[]>([]);
  const [rows, setRows] = useState<RowData[]>([]);
  const [compBandGroups, setCompBandGroups] = useState<CompBandGroup[]>([]);
  const [updates, setUpdates] = useState<CompBandUpdates>({});
  const [compBandGroupUpdates, setCompBandGroupUpdates] =
    useState<CompBandGroupUpdates>({});
  const [editing, setEditing] = useState<boolean>(false);
  const [widthUpdateErrors, setWidthUpdateErrors] = useState<string[]>([]);

  useEffect(() => {
    if (visibleGlobalLevels && compBandGroupsData?.compBandsGroups) {
      const orderedGlobalLevels = orderBy(
        visibleGlobalLevels,
        ['level'],
        ['desc']
      );
      const sortedGroups = orderBy(
        compBandGroupsData.compBandsGroups,
        ['id'],
        ['asc']
      );
      const rows = (
        checkScopes(Scope.SEE_ALL_LEVELS_DATA)
          ? orderedGlobalLevels
          : orderedGlobalLevels
            .filter((level) =>
              level.level < (workspaceMembership?.globalLevelLevel as number)))
        .map((globalLevel) => {
        return {
          globalLevel,
          compBands: sortedGroups.map((compBandGroup) => {
            return compBandGroup.compBands.find(
              (compBand) => compBand.globalLevelLevel === globalLevel.level
            ) as CompBandFragment;
          }),
        };
      });

      setGlobalLevels(orderedGlobalLevels);
      setCompBandGroups(sortedGroups);
      setRows(rows);
      setUpdates({});
      setCompBandGroupUpdates({});
    }
  }, [visibleGlobalLevels, compBandGroupsData]);

  const handleCompBandChange = useCallback<OnCompBandChangeFunction>(
    ({ compBand }) => {
      setUpdates((prevUpdates) => ({
        ...prevUpdates,
        [compBand.globalLevelLevel]: {
          ...prevUpdates[compBand.globalLevelLevel],
          [compBand.compBandGroupId]: compBand,
        },
      }));
    },
    []
  );

  const handleDeleteCompBandGroup =
    useCallback<OnCompBandGroupDeleteFunction>((id) => {
      deleteCompBandGroup({ variables: { compBandGroupDeleteId: id } });
      refetchCompBandGroups();
    }, []);

  const validate = () => {
    const widthErrors: string[] = [];

    Object.values(updates).forEach((levelUpdates) => {
      Object.values(levelUpdates).forEach((update: any) => {
        if (
          update.percentVariable !== 0 &&
          update.absoluteVariable !== 0
        ) {
          widthErrors.push(
            `Percent and absolute variables cannot be both set for level ${update.globalLevelLevel}.`
          );
        }
      });
    });

    setWidthUpdateErrors(widthErrors);
    return widthErrors.length === 0;
  };

  const handleSave = async () => {
    const isValid = validate();

    if (isValid) {
      const widthUpdates = Object.values(updates)
        .flatMap((levelUpdates) =>
          Object.values(levelUpdates) as CompBandSaveRequest[]
        )
      const groupUpdates = Object.values(compBandGroupUpdates)

      try {
        if (widthUpdates.length > 0) {
          await saveCompBands({
            variables: {
              compBandSaveRequest: {
                items: widthUpdates,
              },
            },
          });
        }
        if (groupUpdates.length > 0) {
          await saveCompBandGroups({
            variables: {
              compBandGroupSaveManyRequest: {
                items: groupUpdates,
              },
            },
          });
        }

        await refetchCompBandGroups();
      } catch (error) {
        console.error('Error saving comp bands or groups:', error);
      }

      setEditing(false);
    }
  };

  const handleCreateNewGroup = async () => {
    await saveCompBandGroups({
      variables: {
        compBandGroupSaveManyRequest: {
          items: [{ name: t('compBandsPage.newGroupName') }],
        },
      },
    });
    await refetchCompBandGroups();
  };

  const handleCompBandGroupChange =
    useCallback<OnCompBandGroupChangeFunction>((compBandGroup) => {
      setCompBandGroupUpdates((updates) => {
        const newUpdates = cloneDeep(updates);
        newUpdates[compBandGroup.id] = compBandGroup;
        return newUpdates;
      });
    }, []);

  const LazyCompBandsTable = lazy(() => import('./CompBandsTable'));
  const tableComponent = useMemo(() => {
    return (
      <LazyCompBandsTable
        globalLevel={globalLevels}
        rows={rows}
        compBandGroups={compBandGroups}
        editing={editing}
        onCompBandChange={handleCompBandChange}
        onCompBandGroupChange={handleCompBandGroupChange}
        onCompBandGroupDelete={handleDeleteCompBandGroup}
      />
    );
  }, [globalLevels, rows, compBandGroups, editing]);

  const noData = useMemo(
    () => rows.every((row) => row.compBands.length === 0),
    [rows]
  );

  if (loadingGlobalLevel || loadingCompBandGroups) {
    return <CircularProgress />;
  }

  return (
    <>
      {checkScopes(Scope.COMP_BANDS_EDIT) && (
        <EditButtons
          onEdit={() => setEditing(true)}
          onSave={() => handleSave()}
          onCancel={() => setEditing(false)}
          sx={{ mb: 2 }}
        />
      )}
      <Suspense fallback={<CircularProgress />}>
        {widthUpdateErrors.length > 0 && (
          <Typography color="error">
            {widthUpdateErrors.map((error, index) => (
              <div key={index}>{error}</div>
            ))}
          </Typography>
        )}
        {noData && !loadingCompBandGroups && !editing ? (
          <Typography>{t('compBandsPage.noData')}</Typography>
        ) : (
          <Stack direction="row" spacing={1} sx={{ mt: 2 }}>
            {tableComponent}
            {editing && (
              <IconButton
                aria-label={t('compBandsPage.createNewGroup')}
                sx={{ height: 'fit-content' }}
                title={t('compBandsPage.createNewGroup')}
                onClick={handleCreateNewGroup}
              >
                <AddIcon />
              </IconButton>
            )}
          </Stack>
        )}
      </Suspense>
    </>
  );
}
