import { EquityValueType } from './equity';

export enum SettingProperty {
  // WARNING:
  // Only add new items. DO NOT DELETE EXISTING.
  // Used as pg column.
  PREFERRED_STOCK_PRICE = 'PREFERRED_STOCK_PRICE',
  STRIKE_PRICE = 'STRIKE_PRICE',
  STOCK_UNIT = 'STOCK_UNIT',
  EQUITY_BANDS_PAGE_SHOW_ALTERNATE_EQUITY_UNIT = 'EQUITY_BANDS_PAGE_SHOW_ALTERNATE_EQUITY_UNIT',
  EQUITY_BANDS_PAGE_SHOW_MIN_MAX = 'EQUITY_BANDS_PAGE_SHOW_MIN_MAX',
  EQUITY_BANDS_PAGE_SHOW_DELTA_FROM_PREVIOUS_BAND = 'EQUITY_BANDS_PAGE_SHOW_DELTA_FROM_PREVIOUS_BAND',
}

export interface SettingKey<T extends SettingProperty> {
  property: T;
  type: SettingType;
}

export enum SettingType {
  // WARNING:
  // Only add new items. DO NOT DELETE EXISTING.
  // Used as pg column.
  WORKSPACE = 'WORKSPACE',
  WORKSPACE_USER = 'WORKSPACE_USER',
  USER = 'USER',
}

export type SettingConfigType<T> = {
  property: SettingProperty;
} & (T extends EquityValueType
  ? {
      defaultValue: EquityValueType;
      parse: (value: string) => EquityValueType;
    }
  : T extends number
  ? {
      defaultValue: number;
      parse: (value: string) => number;
    }
  : T extends boolean
  ? {
      defaultValue: boolean;
      parse: (value: string) => boolean;
    }
  : T extends string
  ? {
      defaultValue: string;
      parse: (value: string) => string;
    }
  : never);

export type SettingPropertyTypes = {
  [SettingProperty.STOCK_UNIT]: {
    type: EquityValueType;
  };
  [SettingProperty.PREFERRED_STOCK_PRICE]: {
    type: number;
  };
  [SettingProperty.STRIKE_PRICE]: {
    type: number;
  };
  [SettingProperty.EQUITY_BANDS_PAGE_SHOW_ALTERNATE_EQUITY_UNIT]: {
    type: boolean;
  };
  [SettingProperty.EQUITY_BANDS_PAGE_SHOW_MIN_MAX]: {
    type: boolean;
  };
  [SettingProperty.EQUITY_BANDS_PAGE_SHOW_DELTA_FROM_PREVIOUS_BAND]: {
    type: boolean;
  };
};

export type GetSettingType<T extends SettingProperty> =
  SettingPropertyTypes[T]['type'];

export const SettingConfigs: {
  [key in SettingProperty]: SettingConfigType<GetSettingType<key>>;
} = {
  [SettingProperty.STOCK_UNIT]: {
    property: SettingProperty.STOCK_UNIT,
    defaultValue: EquityValueType.USD,
    parse: (value: string) =>
      EquityValueType[value as keyof typeof EquityValueType] as EquityValueType,
  },
  [SettingProperty.PREFERRED_STOCK_PRICE]: {
    property: SettingProperty.PREFERRED_STOCK_PRICE,
    defaultValue: 0,
    parse: (value: string) => parseFloat(value),
  },
  [SettingProperty.STRIKE_PRICE]: {
    property: SettingProperty.STRIKE_PRICE,
    defaultValue: 0,
    parse: (value: string) => parseFloat(value),
  },
  [SettingProperty.EQUITY_BANDS_PAGE_SHOW_ALTERNATE_EQUITY_UNIT]: {
    property: SettingProperty.EQUITY_BANDS_PAGE_SHOW_ALTERNATE_EQUITY_UNIT,
    defaultValue: false,
    parse: (value: string) => JSON.parse(value),
  },
  [SettingProperty.EQUITY_BANDS_PAGE_SHOW_MIN_MAX]: {
    property: SettingProperty.EQUITY_BANDS_PAGE_SHOW_MIN_MAX,
    defaultValue: false,
    parse: (value: string) => JSON.parse(value),
  },
  [SettingProperty.EQUITY_BANDS_PAGE_SHOW_DELTA_FROM_PREVIOUS_BAND]: {
    property: SettingProperty.EQUITY_BANDS_PAGE_SHOW_DELTA_FROM_PREVIOUS_BAND,
    defaultValue: false,
    parse: (value: string) => JSON.parse(value),
  },
};
