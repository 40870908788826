import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SxProps,
  OutlinedInput,
  Checkbox,
  ListItemText,
  SelectChangeEvent
} from '@mui/material';
import { useEffect, useState } from 'react';

export interface Props {
  label: string
  options?: SelectOption[];
  onChange: (options: SelectOption[]) => void;
  sx?: SxProps;
  disabled?: boolean;
  levels?: SelectOption[];
}

export interface SelectOption {
  title: string
  value: number
}

export function MultiSelect({
  label,
  options: optionsFromProps,
  onChange,
  sx,
  disabled,
  levels,
}: Props) {
  const [selected, setSelected] = useState<SelectOption[]>([]);
  const options = optionsFromProps ?? []

  useEffect(() => {
    if (levels) {
      setSelected(levels)
    }
  }, [levels]);
  const handleChange = (event: SelectChangeEvent<number[]>) => {
    const values = event.target.value as number[];
    const selectedOptions = options.filter(option =>
      values.includes(option.value)
    );
    setSelected(selectedOptions);
    onChange(selectedOptions);
  };

  return (
    <FormControl sx={sx} size="small">
      <InputLabel id="multi-select-label">
        {label}
      </InputLabel>
      <Select
        labelId="multi-select-label"
        multiple
        disabled={disabled}
        value={selected.map(option => option.value)}
        onChange={handleChange}
        input={<OutlinedInput label={label} />}
        renderValue={(selected) =>
          (selected as number[])
            .map(value => options.find(option => option.value === value)?.title)
            .join(', ')
        }
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            <Checkbox checked={selected.some(item => item.value === option.value)} />
            <ListItemText primary={option.title} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
