import { concat } from 'lodash';
import { arrayToTree } from 'performant-array-to-tree';
export interface AlamereLocationIF {
  id: AlamereLocationId;
  label: string;
  lat?: number;
  long?: number;
  type: LocationType;
  parentId: AlamereLocationId | null;
  exceptions?: AlamereLocationId[];
}

export type AlamereIdToTier = Record<AlamereLocationId, Tier>;

export type Tier = {
  percentile: number;
  tierNumber: string;
  label?: string;
};
export type TierConfigParsed = {
  [key: string]: {
    percentile: number;
    label?: string;
    alamereLocationIds: AlamereLocationId[];
  };
};

export type AlamereLocationWithChildren = {
  children: AlamereLocationWithChildren[];
  id: AlamereLocationId;
  parentId: AlamereLocationId | null;
};

export type NestedTierConfig = {
  [key: string]: {
    percentile: number;
    alamereLocationsWithChildren: AlamereLocationWithChildren[];
  };
};

export class TierManager {
  _alamereIdToTier: AlamereIdToTier;
  _tierConfig: TierConfigParsed;
  _nestedTierConfig: NestedTierConfig;
  constructor(private readonly tierConfig: TierConfigParsed) {
    this._tierConfig = TierManager._addNewLocations(
      tierConfig,
      TierManager._getAlamereIdToTier(tierConfig)
    );
    this._nestedTierConfig = TierManager._getNestedTierConfig(this._tierConfig);
    this._alamereIdToTier = TierManager._getAlamereIdToTier(this._tierConfig);
  }

  getTier(alamereId: AlamereLocationId): Tier {
    const knownId = this._alamereIdToTier[alamereId];
    if (knownId) return knownId;
    return this._alamereIdToTier[AlamereLocations['AllOtherLocations'].id];
  }

  getAlltiers(): Tier[] {
    return Object.keys(this._tierConfig).map((tierNumber) => {
      return {
        percentile: this._tierConfig[tierNumber].percentile,
        tierNumber,
        label: this._tierConfig[tierNumber].label,
      };
    });
  }

  getTierConfig() {
    return this._tierConfig;
  }

  getNestedTierConfig() {
    return this._nestedTierConfig;
  }

  getNestedTier(tierNumber: string) {
    return this._nestedTierConfig[tierNumber];
  }

  getResolvedLocationId(
    id: AlamereLocationId,
    tierNumber: string
  ): AlamereLocationId {
    throw new Error("Not implemented");
  }

  getMostSpecificLocationId(
    locationId: AlamereLocationId,
    state: string
  ): AlamereLocationId {
    if (AlamereLocations[locationId].type === LocationType.METRO) {
      return locationId;
    }

    if (AlamereLocations[locationId].type === LocationType.STATE) {
      // States in the same tier as All Other Locations get displayed as "All Other Locations".
      if (this.isInAllOtherLocationsTier(locationId)) {
        return AlamereLocations.AllOtherLocations.id;
      }
      return locationId;
    }

    // If the location ID is "All Other Locations" but the state is 
    // in a different tier, return the state location ID.
    const stateLocationId = AlamereLocationId[state as AlamereLocationId];
    if (!stateLocationId) {
      return locationId;
    }

    if (this.isInAllOtherLocationsTier(stateLocationId)) {
      return locationId;
    }

    return stateLocationId;
  }

  static getDirectChildrenIds(
    parentId: AlamereLocationId
  ): AlamereLocationId[] {
    return (Object.keys(AlamereLocations) as AlamereLocationId[]).filter(
      (location) => {
        return AlamereLocations[location].parentId === parentId;
      }
    );
  }

  findChildrenInTier(
    parentId: AlamereLocationId,
    tierNumber: string
  ): AlamereLocationId[] {
    const childrenInTier = this._tierConfig[
      tierNumber
    ].alamereLocationIds.reduce((acc, childId) => {
      if (AlamereLocations[childId].parentId === parentId) acc.push(childId);
      return acc;
    }, [] as AlamereLocationId[]);

    if (childrenInTier.length === 0) return childrenInTier;

    return childrenInTier.concat(
      childrenInTier.flatMap((id) => {
        return this.findChildrenInTier(id, tierNumber);
      })
    );
  }

  findChildrenNotInParentTier(
    parentId: AlamereLocationId
  ): AlamereLocationId[] {
    const parentTier = this.getTier(parentId).tierNumber;
    return TierManager.getDirectChildrenIds(parentId).filter((childId) => {
      return this.getTier(childId).tierNumber !== parentTier;
    });
  }

  getExclusionsText(id: AlamereLocationId) {
    const exclusions = this.getExclusions(id)
      .map((id) => `${AlamereLocations[id].label} (${this.getTier(id).label})`)
      .sort();

    if (exclusions.length === 0) return undefined;

    return `Excluding areas that are part of the following metro${
      exclusions.length > 1 ? 's' : ''
    }: ${exclusions.join(', ')}`;
  }

  getExclusions(id: AlamereLocationId): AlamereLocationId[] {
    return concat(
      this.findChildrenNotInParentTier(id) || [],
      AlamereLocations[id].exceptions || []
    );
  }

  tierContainsAllOtherLocations(tierNumber: string) {
    return this._alamereIdToTier.AllOtherLocations.tierNumber === tierNumber;
  }

  isInAllOtherLocationsTier(alamereId: AlamereLocationId) {
    return (
      this.getTier(AlamereLocations['AllOtherLocations'].id).tierNumber ===
      this.getTier(alamereId).tierNumber
    );
  }

  private static _findAncestor(
    id: AlamereLocationId,
    idsInTier: AlamereLocationId[]
  ): AlamereLocationId | null {
    const parentId = AlamereLocations[id].parentId;

    if (parentId === null) return null;
    const isParentInTier = parentId && idsInTier.includes(parentId);

    return isParentInTier
      ? parentId
      : TierManager._findAncestor(parentId, idsInTier);
  }

  private static _getNestedTierConfig(tierConfig: TierConfigParsed) {
    return Object.keys(tierConfig).reduce((acc, tierNumber) => {
      const preparedObjects = tierConfig[tierNumber].alamereLocationIds
        .sort((a, b) => a.localeCompare(b))
        .map((alamereId) => {
          const parentId = AlamereLocations[alamereId].parentId;
          return {
            id: alamereId,
            parentId: TierManager._findAncestor(
              alamereId,
              tierConfig[tierNumber].alamereLocationIds
            ),
          };
        });

      const alamereLocationsWithChildren = arrayToTree(preparedObjects, {
        dataField: null,
      }) as AlamereLocationWithChildren[];

      acc[tierNumber] = {
        percentile: tierConfig[tierNumber].percentile,
        alamereLocationsWithChildren,
      };

      return acc;
    }, {} as NestedTierConfig);
  }

  private static _getAlamereIdToTier(parsedConfig: TierConfigParsed) {
    return Object.keys(parsedConfig).reduce((acc, tierNumber) => {
      parsedConfig[tierNumber].alamereLocationIds.forEach((id) => {
        acc[id] = {
          tierNumber,
          percentile: parsedConfig[tierNumber].percentile,
          label: parsedConfig[tierNumber].label || `Tier ${tierNumber}`,
        };
      });
      return acc;
    }, {} as AlamereIdToTier);
  }

  private static _addNewLocations(
    tierConfig: TierConfigParsed,
    alamereIdToTier: AlamereIdToTier
  ) {
    const newTierConfig = { ...tierConfig };
    const newLocations = (
      Object.keys(AlamereLocations) as AlamereLocationId[]
    ).filter((id) => !alamereIdToTier[id]);

    newLocations.forEach((id) => {
      const parentId = TierManager._getParentLocationId(id, alamereIdToTier);

      newTierConfig[
        alamereIdToTier[parentId].tierNumber
      ].alamereLocationIds.push(id);
    });

    return newTierConfig;
  }

  private static _getParentLocationId(
    id: AlamereLocationId,
    alamereIdToTier: AlamereIdToTier
  ) {
    let parentId = AlamereLocations[id].parentId;
    let i = 0;
    while (parentId && !alamereIdToTier[parentId]) {
      parentId = AlamereLocations[parentId].parentId;
      i++;
      if (i > 100) throw new Error('Infinite loop');
    }
    return parentId || AlamereLocations['AllOtherLocations'].id;
  }
}

export enum LocationType {
  METRO = 'METRO',
  STATE = 'STATE',
  ALL_OTHER = 'ALL_OTHER',
}

export enum AlamereLocationId {
  AllOtherLocations = 'AllOtherLocations',
  Atlanta = 'Atlanta',
  BostonProvidence = 'BostonProvidence',
  Charlotte = 'Charlotte',
  Chicago = 'Chicago',
  Cleveland = 'Cleveland',
  DallasFortWorth = 'DallasFortWorth',
  Denver = 'Denver',
  Detroit = 'Detroit',
  Houston = 'Houston',
  LasVegas = 'LasVegas',
  GreaterLosAngeles = 'GreaterLosAngeles',
  MiamiFortLauderdale = 'MiamiFortLauderdale',
  MinneapolisStPaul = 'MinneapolisStPaul',
  NewYorkTriState = 'NewYorkTriState',
  Orlando = 'Orlando',
  Philadelphia = 'Philadelphia',
  Phoenix = 'Phoenix',
  Portland = 'Portland',
  Raleigh = 'Raleigh',
  Sacramento = 'Sacramento',
  SaltLakeCity = 'SaltLakeCity',
  Seattle = 'Seattle',
  SfBayArea = 'SfBayArea',
  StLouis = 'StLouis',
  WashingtonDcBaltimore = 'WashingtonDcBaltimore',
  SanDiego = 'SanDiego',
  Austin = 'Austin',
  AL = 'AL',
  AK = 'AK',
  AZ = 'AZ',
  AR = 'AR',
  CA = 'CA',
  CO = 'CO',
  CT = 'CT',
  DC = 'DC',
  DE = 'DE',
  FL = 'FL',
  GA = 'GA',
  HI = 'HI',
  ID = 'ID',
  IL = 'IL',
  IA = 'IA',
  IN = 'IN',
  KS = 'KS',
  KY = 'KY',
  LA = 'LA',
  ME = 'ME',
  MD = 'MD',
  MA = 'MA',
  MI = 'MI',
  MN = 'MN',
  MS = 'MS',
  MO = 'MO',
  MT = 'MT',
  NE = 'NE',
  NV = 'NV',
  NH = 'NH',
  NJ = 'NJ',
  NM = 'NM',
  NY = 'NY',
  NC = 'NC',
  ND = 'ND',
  OH = 'OH',
  OK = 'OK',
  OR = 'OR',
  PA = 'PA',
  RI = 'RI',
  SC = 'SC',
  SD = 'SD',
  TN = 'TN',
  TX = 'TX',
  UT = 'UT',
  VT = 'VT',
  VA = 'VA',
  WA = 'WA',
  WV = 'WV',
  WI = 'WI',
  WY = 'WY',
}

export const AlamereLocations: Record<AlamereLocationId, AlamereLocationIF> = {
  AllOtherLocations: {
    id: AlamereLocationId.AllOtherLocations,
    label: 'All Other Locations',
    type: LocationType.ALL_OTHER,
    parentId: null,
  },
  Atlanta: {
    id: AlamereLocationId.Atlanta,
    label: 'Atlanta',
    lat: 33.7499286681945,
    long: -84.38374020585461,
    type: LocationType.METRO,
    parentId: AlamereLocationId.GA,
  },
  BostonProvidence: {
    id: AlamereLocationId.BostonProvidence,
    label: 'Boston-Providence',
    lat: 42.361944753697635,
    long: -71.05748828551702,
    type: LocationType.METRO,
    parentId: AlamereLocationId.MA,
  },
  Charlotte: {
    id: AlamereLocationId.Charlotte,
    label: 'Charlotte',
    lat: 35.2270869,
    long: -80.8431267,
    type: LocationType.METRO,
    parentId: AlamereLocationId.NC,
  },
  Chicago: {
    id: AlamereLocationId.Chicago,
    label: 'Chicago',
    lat: 41.8755616,
    long: -87.6244212,
    type: LocationType.METRO,
    parentId: AlamereLocationId.IL,
  },
  Cleveland: {
    id: AlamereLocationId.Cleveland,
    label: 'Cleveland',
    lat: 41.5051613,
    long: -81.6934446,
    type: LocationType.METRO,
    parentId: AlamereLocationId.OH,
  },
  DallasFortWorth: {
    id: AlamereLocationId.DallasFortWorth,
    label: 'Dallas-Fort Worth',
    lat: 32.7762719,
    long: -96.7968559,
    type: LocationType.METRO,
    parentId: AlamereLocationId.TX,
  },
  Denver: {
    id: AlamereLocationId.Denver,
    label: 'Denver',
    lat: 39.7392364,
    long: -104.9848623,
    type: LocationType.METRO,
    parentId: AlamereLocationId.CO,
  },
  Detroit: {
    id: AlamereLocationId.Detroit,
    label: 'Detroit',
    lat: 42.3315509,
    long: -83.0466403,
    type: LocationType.METRO,
    parentId: AlamereLocationId.MI,
  },
  Houston: {
    id: AlamereLocationId.Houston,
    label: 'Houston',
    lat: 29.7589382,
    long: -95.3676974,
    type: LocationType.METRO,
    parentId: AlamereLocationId.TX,
  },
  LasVegas: {
    id: AlamereLocationId.LasVegas,
    label: 'Las Vegas',
    lat: 36.1672559,
    long: -115.1485163,
    type: LocationType.METRO,
    parentId: AlamereLocationId.NV,
  },
  GreaterLosAngeles: {
    id: AlamereLocationId.GreaterLosAngeles,
    label: 'Greater Los Angeles',
    lat: 34.0536909,
    long: -118.2427666,
    type: LocationType.METRO,
    parentId: AlamereLocationId.CA,
  },
  MiamiFortLauderdale: {
    id: AlamereLocationId.MiamiFortLauderdale,
    label: 'Miami-Fort Lauderdale',
    lat: 25.7742658,
    long: -80.1936589,
    type: LocationType.METRO,
    parentId: AlamereLocationId.FL,
  },
  MinneapolisStPaul: {
    id: AlamereLocationId.MinneapolisStPaul,
    label: 'Minneapolis-St. Paul',
    lat: 44.9772995,
    long: -93.2654692,
    type: LocationType.METRO,
    parentId: AlamereLocationId.MN,
    exceptions: [],
  },
  NewYorkTriState: {
    id: AlamereLocationId.NewYorkTriState,
    label: 'New York Tri-State',
    lat: 40.7127281,
    long: -74.0060152,
    type: LocationType.METRO,
    parentId: AlamereLocationId.NY,
  },
  Orlando: {
    id: AlamereLocationId.Orlando,
    label: 'Orlando',
    lat: 28.5421109,
    long: -81.3790304,
    type: LocationType.METRO,
    parentId: AlamereLocationId.FL,
  },
  Philadelphia: {
    id: AlamereLocationId.Philadelphia,
    label: 'Philadelphia',
    lat: 39.9527237,
    long: -75.1635262,
    type: LocationType.METRO,
    parentId: AlamereLocationId.PA,
  },
  Phoenix: {
    id: AlamereLocationId.Phoenix,
    label: 'Phoenix',
    lat: 33.4484367,
    long: -112.0741417,
    type: LocationType.METRO,
    parentId: AlamereLocationId.AZ,
  },
  Portland: {
    id: AlamereLocationId.Portland,
    label: 'Portland (OR)',
    lat: 45.5202471,
    long: -122.6741949,
    type: LocationType.METRO,
    parentId: AlamereLocationId.OR,
  },
  Raleigh: {
    id: AlamereLocationId.Raleigh,
    label: 'Raleigh',
    lat: 35.7803977,
    long: -78.6390989,
    type: LocationType.METRO,
    parentId: AlamereLocationId.NC,
  },
  Sacramento: {
    id: AlamereLocationId.Sacramento,
    label: 'Sacramento',
    lat: 38.5810606,
    long: -121.493895,
    type: LocationType.METRO,
    parentId: AlamereLocationId.CA,
  },
  SaltLakeCity: {
    id: AlamereLocationId.SaltLakeCity,
    label: 'Salt Lake City',
    lat: 40.7596198,
    long: -111.8867975,
    type: LocationType.METRO,
    parentId: AlamereLocationId.UT,
  },
  Seattle: {
    id: AlamereLocationId.Seattle,
    label: 'Seattle',
    lat: 47.6038321,
    long: -122.3300624,
    type: LocationType.METRO,
    parentId: AlamereLocationId.WA,
  },
  SfBayArea: {
    id: AlamereLocationId.SfBayArea,
    label: 'SF Bay Area',
    lat: 37.7790262,
    long: -122.419906,
    type: LocationType.METRO,
    parentId: AlamereLocationId.CA,
  },
  StLouis: {
    id: AlamereLocationId.StLouis,
    label: 'St. Louis',
    lat: 38.6272733,
    long: -90.1978889,
    type: LocationType.METRO,
    parentId: AlamereLocationId.MO,
  },
  WashingtonDcBaltimore: {
    id: AlamereLocationId.WashingtonDcBaltimore,
    label: 'Washington DC-Baltimore',
    lat: 38.9071923,
    long: -77.0368707,
    type: LocationType.METRO,
    parentId: AlamereLocationId.AllOtherLocations,
  },
  SanDiego: {
    id: AlamereLocationId.SanDiego,
    label: 'San Diego',
    lat: 32.715738,
    long: -117.1610838,
    type: LocationType.METRO,
    parentId: AlamereLocationId.CA,
  },
  Austin: {
    id: AlamereLocationId.Austin,
    label: 'Austin',
    lat: 30.267153,
    long: -97.7430608,
    type: LocationType.METRO,
    parentId: AlamereLocationId.TX,
  },
  AL: {
    id: AlamereLocationId.AL,
    label: 'Alabama',
    type: LocationType.STATE,
    parentId: AlamereLocationId.AllOtherLocations,
    lat: 32.3182314,
    long: -86.9022979,
  },
  AK: {
    id: AlamereLocationId.AK,
    label: 'Alaska',
    type: LocationType.STATE,
    parentId: AlamereLocationId.AllOtherLocations,
    lat: 64.2008413,
    long: -149.4936733,
  },
  AZ: {
    id: AlamereLocationId.AZ,
    label: 'Arizona',
    type: LocationType.STATE,
    parentId: AlamereLocationId.AllOtherLocations,
    lat: 34.0489281,
    long: -111.0937311,
  },
  AR: {
    id: AlamereLocationId.AR,
    label: 'Arkansas',
    type: LocationType.STATE,
    parentId: AlamereLocationId.AllOtherLocations,
    lat: 34.79981,
    long: -92.199199,
  },
  CA: {
    id: AlamereLocationId.CA,
    label: 'California',
    type: LocationType.STATE,
    parentId: AlamereLocationId.AllOtherLocations,
    lat: 36.778261,
    long: -119.4179324,
  },
  CO: {
    id: AlamereLocationId.CO,
    label: 'Colorado',
    type: LocationType.STATE,
    parentId: AlamereLocationId.AllOtherLocations,
    lat: 39.5500507,
    long: -105.7820674,
  },
  CT: {
    id: AlamereLocationId.CT,
    label: 'Connecticut',
    type: LocationType.STATE,
    parentId: AlamereLocationId.AllOtherLocations,
    lat: 41.6032207,
    long: -73.087749,
    exceptions: [
      AlamereLocationId.NewYorkTriState,
      AlamereLocationId.BostonProvidence,
    ],
  },
  DC: {
    id: AlamereLocationId.DC,
    label: 'District of Columbia',
    type: LocationType.STATE,
    parentId: AlamereLocationId.AllOtherLocations,
    lat: 38.9071923,
    long: -77.0368707,
    exceptions: [AlamereLocationId.WashingtonDcBaltimore],
  },
  DE: {
    id: AlamereLocationId.DE,
    label: 'Delaware',
    type: LocationType.STATE,
    parentId: AlamereLocationId.AllOtherLocations,
    lat: 38.9108325,
    long: -75.5276699,
    exceptions: [AlamereLocationId.Philadelphia],
  },
  FL: {
    id: AlamereLocationId.FL,
    label: 'Florida',
    type: LocationType.STATE,
    parentId: AlamereLocationId.AllOtherLocations,
    lat: 27.6648274,
    long: -81.5157535,
  },
  GA: {
    id: AlamereLocationId.GA,
    label: 'Georgia',
    type: LocationType.STATE,
    parentId: AlamereLocationId.AllOtherLocations,
    lat: 32.1574351,
    long: -82.907123,
  },
  HI: {
    id: AlamereLocationId.HI,
    label: 'Hawaii',
    type: LocationType.STATE,
    parentId: AlamereLocationId.AllOtherLocations,
    lat: 19.8986828,
    long: -155.6658575,
  },
  ID: {
    id: AlamereLocationId.ID,
    label: 'Idaho',
    type: LocationType.STATE,
    parentId: AlamereLocationId.AllOtherLocations,
    lat: 44.0682019,
    long: -114.7420408,
  },
  IL: {
    id: AlamereLocationId.IL,
    label: 'Illinois',
    type: LocationType.STATE,
    parentId: AlamereLocationId.AllOtherLocations,
    lat: 40.6331249,
    long: -89.3985283,
    exceptions: [AlamereLocationId.StLouis],
  },
  IN: {
    id: AlamereLocationId.IN,
    label: 'Indiana',
    type: LocationType.STATE,
    parentId: AlamereLocationId.AllOtherLocations,
    lat: 40.5512165,
    long: -85.6023643,
    exceptions: [AlamereLocationId.Chicago],
  },
  IA: {
    id: AlamereLocationId.IA,
    label: 'Iowa',
    type: LocationType.STATE,
    parentId: AlamereLocationId.AllOtherLocations,
    lat: 41.8780025,
    long: -93.097702,
  },
  KS: {
    id: AlamereLocationId.KS,
    label: 'Kansas',
    type: LocationType.STATE,
    parentId: AlamereLocationId.AllOtherLocations,
    lat: 39.011902,
    long: -98.4842465,
  },
  KY: {
    id: AlamereLocationId.KY,
    label: 'Kentucky',
    type: LocationType.STATE,
    parentId: AlamereLocationId.AllOtherLocations,
    lat: 37.8393332,
    long: -84.2700179,
  },
  LA: {
    id: AlamereLocationId.LA,
    label: 'Louisiana',
    type: LocationType.STATE,
    parentId: AlamereLocationId.AllOtherLocations,
    lat: 30.9842977,
    long: -91.9623327,
  },
  ME: {
    id: AlamereLocationId.ME,
    label: 'Maine',
    type: LocationType.STATE,
    parentId: AlamereLocationId.AllOtherLocations,
    lat: 45.253783,
    long: -69.4454689,
  },
  MD: {
    id: AlamereLocationId.MD,
    label: 'Maryland',
    type: LocationType.STATE,
    parentId: AlamereLocationId.AllOtherLocations,
    lat: 39.0457549,
    long: -76.6412712,
    exceptions: [
      AlamereLocationId.WashingtonDcBaltimore,
      AlamereLocationId.Philadelphia,
    ],
  },
  MA: {
    id: AlamereLocationId.MA,
    label: 'Massachusetts',
    type: LocationType.STATE,
    parentId: AlamereLocationId.AllOtherLocations,
    lat: 42.4072107,
    long: -71.3824374,
  },
  MI: {
    id: AlamereLocationId.MI,
    label: 'Michigan',
    type: LocationType.STATE,
    parentId: AlamereLocationId.AllOtherLocations,
    lat: 44.3148443,
    long: -85.6023643,
  },
  MN: {
    id: AlamereLocationId.MN,
    label: 'Minnesota',
    type: LocationType.STATE,
    parentId: AlamereLocationId.AllOtherLocations,
    lat: 46.729553,
    long: -94.6858998,
  },
  MS: {
    id: AlamereLocationId.MS,
    label: 'Mississippi',
    type: LocationType.STATE,
    parentId: AlamereLocationId.AllOtherLocations,
    lat: 32.3546679,
    long: -89.3985283,
  },
  MO: {
    id: AlamereLocationId.MO,
    label: 'Missouri',
    type: LocationType.STATE,
    parentId: AlamereLocationId.AllOtherLocations,
    lat: 37.9642529,
    long: -91.8318334,
  },
  MT: {
    id: AlamereLocationId.MT,
    label: 'Montana',
    type: LocationType.STATE,
    parentId: AlamereLocationId.AllOtherLocations,
    lat: 46.8796822,
    long: -110.3625658,
  },
  NE: {
    id: AlamereLocationId.NE,
    label: 'Nebraska',
    type: LocationType.STATE,
    parentId: AlamereLocationId.AllOtherLocations,
    lat: 41.4925374,
    long: -99.9018131,
  },
  NV: {
    id: AlamereLocationId.NV,
    label: 'Nevada',
    type: LocationType.STATE,
    parentId: AlamereLocationId.AllOtherLocations,
    lat: 38.8026097,
    long: -116.419389,
  },
  NH: {
    id: AlamereLocationId.NH,
    label: 'New Hampshire',
    type: LocationType.STATE,
    parentId: AlamereLocationId.AllOtherLocations,
    lat: 43.1938516,
    long: -71.5723953,
    exceptions: [AlamereLocationId.BostonProvidence],
  },
  NJ: {
    id: AlamereLocationId.NJ,
    label: 'New Jersey',
    type: LocationType.STATE,
    parentId: AlamereLocationId.AllOtherLocations,
    lat: 40.0583238,
    long: -74.4056612,
    exceptions: [
      AlamereLocationId.NewYorkTriState,
      AlamereLocationId.Philadelphia,
    ],
  },
  NM: {
    id: AlamereLocationId.NM,
    label: 'New Mexico',
    type: LocationType.STATE,
    parentId: AlamereLocationId.AllOtherLocations,
    lat: 34.9727305,
    long: -105.0323635,
  },
  NY: {
    id: AlamereLocationId.NY,
    label: 'New York State',
    type: LocationType.STATE,
    parentId: AlamereLocationId.AllOtherLocations,
    lat: 43.2994285,
    long: -74.2179326,
  },
  NC: {
    id: AlamereLocationId.NC,
    label: 'North Carolina',
    type: LocationType.STATE,
    parentId: AlamereLocationId.AllOtherLocations,
    lat: 35.7595731,
    long: -79.0192997,
  },
  ND: {
    id: AlamereLocationId.ND,
    label: 'North Dakota',
    type: LocationType.STATE,
    parentId: AlamereLocationId.AllOtherLocations,
    lat: 47.5514926,
    long: -101.0020119,
  },
  OH: {
    id: AlamereLocationId.OH,
    label: 'Ohio',
    type: LocationType.STATE,
    parentId: AlamereLocationId.AllOtherLocations,
    lat: 40.4172871,
    long: -82.907123,
  },
  OK: {
    id: AlamereLocationId.OK,
    label: 'Oklahoma State',
    type: LocationType.STATE,
    parentId: AlamereLocationId.AllOtherLocations,
    lat: 35.0077519,
    long: -97.092877,
    exceptions: [AlamereLocationId.DallasFortWorth],
  },
  OR: {
    id: AlamereLocationId.OR,
    label: 'Oregon',
    type: LocationType.STATE,
    parentId: AlamereLocationId.AllOtherLocations,
    lat: 43.8041334,
    long: -120.5542012,
  },
  PA: {
    id: AlamereLocationId.PA,
    label: 'Pennsylvania',
    type: LocationType.STATE,
    parentId: AlamereLocationId.AllOtherLocations,
    lat: 41.2033216,
    long: -77.1945247,
    exceptions: [
      AlamereLocationId.NewYorkTriState,
      AlamereLocationId.WashingtonDcBaltimore,
    ],
  },
  RI: {
    id: AlamereLocationId.RI,
    label: 'Rhode Island',
    type: LocationType.STATE,
    parentId: AlamereLocationId.AllOtherLocations,
    lat: 41.5800945,
    long: -71.4774291,
    exceptions: [AlamereLocationId.BostonProvidence],
  },
  SC: {
    id: AlamereLocationId.SC,
    label: 'South Carolina',
    type: LocationType.STATE,
    parentId: AlamereLocationId.AllOtherLocations,
    lat: 33.836081,
    long: -81.1637245,
    exceptions: [AlamereLocationId.Charlotte],
  },
  SD: {
    id: AlamereLocationId.SD,
    label: 'South Dakota',
    type: LocationType.STATE,
    parentId: AlamereLocationId.AllOtherLocations,
    lat: 43.9695148,
    long: -99.9018131,
  },
  TN: {
    id: AlamereLocationId.TN,
    label: 'Tennessee',
    type: LocationType.STATE,
    parentId: AlamereLocationId.AllOtherLocations,
    lat: 35.5174913,
    long: -86.5804473,
  },
  TX: {
    id: AlamereLocationId.TX,
    label: 'Texas',
    type: LocationType.STATE,
    parentId: AlamereLocationId.AllOtherLocations,
    lat: 31.9685988,
    long: -99.9018131,
  },
  UT: {
    id: AlamereLocationId.UT,
    label: 'Utah',
    type: LocationType.STATE,
    parentId: AlamereLocationId.AllOtherLocations,
    lat: 39.3209801,
    long: -111.0937311,
  },
  VT: {
    id: AlamereLocationId.VT,
    label: 'Vermont',
    type: LocationType.STATE,
    parentId: AlamereLocationId.AllOtherLocations,
    lat: 44.5588028,
    long: -72.5778415,
  },
  VA: {
    id: AlamereLocationId.VA,
    label: 'Virginia',
    type: LocationType.STATE,
    parentId: AlamereLocationId.AllOtherLocations,
    lat: 37.4315734,
    long: -78.6568942,
    exceptions: [AlamereLocationId.WashingtonDcBaltimore],
  },
  WA: {
    id: AlamereLocationId.WA,
    label: 'Washington',
    type: LocationType.STATE,
    parentId: AlamereLocationId.AllOtherLocations,
    lat: 47.7510741,
    long: -120.7401385,
    exceptions: [AlamereLocationId.Portland],
  },
  WV: {
    id: AlamereLocationId.WV,
    label: 'West Virginia',
    type: LocationType.STATE,
    parentId: AlamereLocationId.AllOtherLocations,
    lat: 38.5976262,
    long: -80.4549026,
    exceptions: [AlamereLocationId.WashingtonDcBaltimore],
  },
  WI: {
    id: AlamereLocationId.WI,
    label: 'Wisconsin',
    type: LocationType.STATE,
    parentId: AlamereLocationId.AllOtherLocations,
    lat: 43.7844397,
    long: -88.7878678,
    exceptions: [
      AlamereLocationId.MinneapolisStPaul,
      AlamereLocationId.Chicago,
    ],
  },
  WY: {
    id: AlamereLocationId.WY,
    label: 'Wyoming',
    type: LocationType.STATE,
    parentId: AlamereLocationId.AllOtherLocations,
    lat: 43.0759678,
    long: -107.2902839,
  },
};

export const DEFAULT_TIER_CONFIG: TierConfigParsed = {
  1: {
    percentile: 100,
    label: 'Tier 1',
    alamereLocationIds: [
      AlamereLocations['NewYorkTriState'].id,
      AlamereLocations['SfBayArea'].id,
    ],
  },
  2: {
    percentile: 90,
    label: 'Tier 2',
    alamereLocationIds: [
      AlamereLocations['BostonProvidence'].id,
      AlamereLocations['Chicago'].id,
      AlamereLocations['Denver'].id,
      AlamereLocations['GreaterLosAngeles'].id,
      AlamereLocations['Sacramento'].id,
      AlamereLocations['Seattle'].id,
      AlamereLocations['WashingtonDcBaltimore'].id,
      AlamereLocations['NY'].id,
      AlamereLocations['CA'].id,
      AlamereLocations['SanDiego'].id,
      AlamereLocations['Austin'].id,
    ],
  },
  3: {
    percentile: 80,
    label: 'Tier 3',
    alamereLocationIds: [
      AlamereLocations['AllOtherLocations'].id,
      AlamereLocations['Atlanta'].id,
      AlamereLocations['Charlotte'].id,
      AlamereLocations['Cleveland'].id,
      AlamereLocations['DallasFortWorth'].id,
      AlamereLocations['Detroit'].id,
      AlamereLocations['Houston'].id,
      AlamereLocations['LasVegas'].id,
      AlamereLocations['MiamiFortLauderdale'].id,
      AlamereLocations['MinneapolisStPaul'].id,
      AlamereLocations['Orlando'].id,
      AlamereLocations['Philadelphia'].id,
      AlamereLocations['Phoenix'].id,
      AlamereLocations['Portland'].id,
      AlamereLocations['Raleigh'].id,
      AlamereLocations['SaltLakeCity'].id,
      AlamereLocations['StLouis'].id,
      AlamereLocations['AL'].id,
      AlamereLocations['AK'].id,
      AlamereLocations['AZ'].id,
      AlamereLocations['AR'].id,
      AlamereLocations['CO'].id,
      AlamereLocations['CT'].id,
      AlamereLocations['DC'].id,
      AlamereLocations['DE'].id,
      AlamereLocations['FL'].id,
      AlamereLocations['GA'].id,
      AlamereLocations['HI'].id,
      AlamereLocations['ID'].id,
      AlamereLocations['IL'].id,
      AlamereLocations['IN'].id,
      AlamereLocations['IA'].id,
      AlamereLocations['KS'].id,
      AlamereLocations['KY'].id,
      AlamereLocations['LA'].id,
      AlamereLocations['ME'].id,
      AlamereLocations['MD'].id,
      AlamereLocations['MA'].id,
      AlamereLocations['MI'].id,
      AlamereLocations['MN'].id,
      AlamereLocations['MS'].id,
      AlamereLocations['MO'].id,
      AlamereLocations['MT'].id,
      AlamereLocations['NE'].id,
      AlamereLocations['NV'].id,
      AlamereLocations['NH'].id,
      AlamereLocations['NJ'].id,
      AlamereLocations['NM'].id,
      AlamereLocations['NC'].id,
      AlamereLocations['ND'].id,
      AlamereLocations['OH'].id,
      AlamereLocations['OK'].id,
      AlamereLocations['OR'].id,
      AlamereLocations['PA'].id,
      AlamereLocations['RI'].id,
      AlamereLocations['SC'].id,
      AlamereLocations['SD'].id,
      AlamereLocations['TN'].id,
      AlamereLocations['TX'].id,
      AlamereLocations['UT'].id,
      AlamereLocations['VT'].id,
      AlamereLocations['VA'].id,
      AlamereLocations['WA'].id,
      AlamereLocations['WV'].id,
      AlamereLocations['WI'].id,
      AlamereLocations['WY'].id,
    ],
  },
};
