import { Scope } from '@alamere/core';
import {
  JobFamiliesDocument,
  JobFamilyFragment,
  JobFamilySaveRequest,
  useJobFamiliesQuery,
  useJobFamiliesSaveMutation,
} from '@alamere/generated-graphql-types';
import { useApolloClient } from '@apollo/client';
import AddRounded from '@mui/icons-material/AddRounded';
import { Button, Stack } from '@mui/material';
import { t } from 'i18next';
import { uniq } from 'lodash';
import { useCallback, useState } from 'react';
import { EditButtons } from '../../components/EditButtons';
import { NewGroup } from './NewGroup';
import { Table } from './Table';
import { OnItemChangeFunction } from './types';

export default function JobFamiliesPage() {
  const client = useApolloClient();

  const [editing, setEditing] = useState(false);

  const [itemUpdates, setItemUpdates] = useState<
    Record<string, JobFamilySaveRequest>
  >({});

  const [jobFamilyIdsToDelete, setJobFamilyIdsToDelete] = useState<number[]>(
    []
  );

  const { data: jobFamiliesData } = useJobFamiliesQuery();

  const [saveItems] = useJobFamiliesSaveMutation();

  const handleSave = async () => {
    if (
      Object.values(itemUpdates).length > 0 ||
      jobFamilyIdsToDelete.length > 0
    ) {
      await saveItems({
        variables: {
          request: {
            items: Object.values(itemUpdates),
            jobFamilyIdsToDelete,
          },
        },
        refetchQueries: [JobFamiliesDocument],
      });
    }
    setItemUpdates({});
    setJobFamilyIdsToDelete([]);
    setEditing(false);
  };

  const handleCancel = () => {
    client.refetchQueries({
      include: [JobFamiliesDocument],
    });
    setItemUpdates({});
    setJobFamilyIdsToDelete([]);
    setEditing(false);
  };

  const handleItemChange: OnItemChangeFunction = useCallback(
    ({ newItem, jobFamilyIdToDelete }) => {
      setItemUpdates((prev) => {
        if (!newItem?.id) {
          return prev;
        }
        return {
          ...prev,
          [newItem.id]: {
            id: newItem.id,
            name: newItem.name,
            code: newItem.code,
            ignoreGeoDiff: newItem.ignoreGeoDiff,
            bonusStructureGroupId: newItem.bonusStructureGroup?.id || null,
            equityBandGroupId: newItem.equityBandGroup?.id || null,
            jobFunctionId: newItem.jobFunction?.id || null,
            rangeWidthGroupId: newItem.rangeWidthGroup?.id || null,
            compBandGroupId: newItem.compBandGroup?.id || null,
            radfordExecutiveMatchCode: newItem.radfordExecutiveMatchCode,
            radfordManagementMatchCode: newItem.radfordManagementMatchCode,
            radfordProfessionalMatchCode: newItem.radfordProfessionalMatchCode,
            radfordScientificMatchCode: newItem.radfordScientificMatchCode,
            radfordSupportMatchCode: newItem.radfordSupportMatchCode,
            compBandMultiplier: newItem.compBandMultiplier || null,
          },
        };
      });

      setJobFamilyIdsToDelete((prev) => {
        if (!jobFamilyIdToDelete) {
          return prev;
        }
        const newIds = [...prev];
        newIds.push(jobFamilyIdToDelete);
        return uniq(newIds);
      });
    },
    [setItemUpdates, setJobFamilyIdsToDelete]
  );

  const handleCreateNewFamily = async () => {
    const nextCode = getNextCode(jobFamiliesData?.jobFamilies ?? []);
    await saveItems({
      variables: {
        request: {
          items: [
            {
              code: nextCode,
              name: t('jobFamiliesPage.newFamilyName'),
            },
          ],
        },
      },
      update: (cache, { data }) => {
        const savedJobFamilies = data?.jobFamiliesSave ?? [];
        const existingJobFamilies =
          cache.readQuery<{ jobFamilies: JobFamilyFragment[] }>({
            query: JobFamiliesDocument,
          })?.jobFamilies ?? [];
        const newJobFamilies = [...existingJobFamilies, ...savedJobFamilies];
        cache.writeQuery({
          query: JobFamiliesDocument,
          data: { jobFamilies: newJobFamilies },
        });
      },
    });
  };

  return (
    <Stack spacing={2} mb={2}>
      <EditButtons
        requiredScopes={[Scope.RANGE_WIDTHS_EDIT]}
        onEdit={() => setEditing(true)}
        onSave={handleSave}
        onCancel={handleCancel}
      />
      <Stack direction="row" spacing={1} sx={{ mt: 2 }}>
        <Table editing={editing} onItemChange={handleItemChange} />
        <NewGroup editing={editing} />
      </Stack>
      {editing && (
        <Button
          sx={{ width: 'fit-content' }}
          variant="outlined"
          startIcon={<AddRounded />}
          onClick={handleCreateNewFamily}
        >
          {t('jobFamiliesPage.addNewFamily')}
        </Button>
      )}
    </Stack>
  );
}

function getNextCode(jobFamilies: JobFamilyFragment[]) {
  const newCode = jobFamilies.reduce(
    (acc, fam) => {
      const match = fam.code?.match(/(^[A-Z\.\-]+)(\d+$)/);

      if (!match) {
        return acc;
      }

      const letters = match[1];
      const numbers = parseInt(match[2]);
      const oldNumbers = parseInt(acc.val);

      const newPrefix = oldNumbers > numbers ? acc.prefix : letters;
      const newNumber = oldNumbers > numbers ? oldNumbers + 1 : numbers + 1;
      const numberLength =
        oldNumbers > numbers ? acc.val.length : match[2].length;
      const formattedNumber = String(newNumber).padStart(numberLength, '0');

      return { prefix: newPrefix, val: formattedNumber };
    },
    { prefix: 'JF', val: '00001' }
  );
  return newCode.prefix + newCode.val;
}
