import {
  InvitationStatus,
  InvitationWithCustomRoleFragment,
  MembershipWithCustomRoleFragment,
  UpdateWorkspaceRequest,
  useDeleteInvitationMutation,
  useDeleteMembershipMutation,
  useInviteUserMutation,
  useRequestFeatureAccessMutation,
  UserRole, useUpdateMembershipLevelMutation,
  useUpdateMembershipMutation,
  useUpdateWorkspaceMutation,
  useWorkspaceMembershipsAndInvitationsLazyQuery
} from '@alamere/generated-graphql-types';
import DeleteIcon from '@mui/icons-material/Delete';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Alert,
  Box,
  Button,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Stack,
  Switch,
  TextField,
  Typography
} from '@mui/material';
import { t } from 'i18next';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import AlertDialog from '../../components/AlertDialog';
import ifEnter from '../../lib/ifEnter';
import { useAuth } from '../../providers/auth';
import { CustomizableRole, RolePicker } from './RolePicker';
import { JobLevelPicker } from './JobLevelPicker';

export default function WorkspaceSettingsGeneral() {
  const { user, workspace, refetch } = useAuth();
  const [inviteUserRole, setInviteUserRole] = useState<CustomizableRole>({
    userRole: UserRole.MEMBER,
  });
  const [inviteByDomainRole, setInviteByDomainRole] =
    useState<CustomizableRole>({
      userRole: workspace?.inviteByDomainRole || UserRole.MEMBER,
      customRole: workspace?.inviteByDomainCustomRole,
    });
  const [email, setEmail] = useState<string>('');
  const [workspaceFormData, setWorkspaceFormData] =
    useState<UpdateWorkspaceRequest>({
      name: workspace?.name || '',
      domain: workspace?.domain || null,
      enableInviteByDomain: workspace?.enableInviteByDomain || false,
      inviteByDomainRole: workspace?.inviteByDomainRole || UserRole.MEMBER,
      inviteByDomainCustomRoleId: workspace?.inviteByDomainCustomRole?.id,
    });
  const [membershipToDelete, setMembershipToDelete] =
    useState<MembershipWithCustomRoleFragment | null>(null);
  const [invitationToDelete, setInvitationToDelete] =
    useState<InvitationWithCustomRoleFragment | null>(null);

  const [fetchWorkspaceMembershipsAndInvitations, { data }] =
    useWorkspaceMembershipsAndInvitationsLazyQuery();
  const [inviteUser, { loading: loadingInvite, error }] =
    useInviteUserMutation();
  const [deleteInvitation] = useDeleteInvitationMutation();
  const [updateMembership] = useUpdateMembershipMutation();
  const [updateMembershipLevel] = useUpdateMembershipLevelMutation();
  const [deleteMembership] = useDeleteMembershipMutation();
  const [updateWorkspace] = useUpdateWorkspaceMutation();
  const [requestFeatureAccess] = useRequestFeatureAccessMutation();

  useEffect(() => {
    if (workspace?.id) {
      fetchWorkspaceMembershipsAndInvitations({
        fetchPolicy: 'network-only',
      });
    }
  }, [workspace?.id]);

  useEffect(() => {
    setWorkspaceFormData({
      name: workspace?.name || '',
      domain: workspace?.domain || null,
      enableInviteByDomain: workspace?.enableInviteByDomain || false,
      inviteByDomainRole: workspace?.inviteByDomainRole || UserRole.MEMBER,
      inviteByDomainCustomRoleId: workspace?.inviteByDomainCustomRole?.id,
    });
    setInviteByDomainRole({
      userRole: workspace?.inviteByDomainRole || UserRole.MEMBER,
      customRole: workspace?.inviteByDomainCustomRole,
    });
  }, [workspace]);

  const onInviteUser = async () => {
    await inviteUser({
      variables: {
        inviteUserRequest: {
          email,
          role: inviteUserRole.userRole,
          customRoleId: inviteUserRole.customRole?.id,
        },
      },
    });
    setEmail('');
    await fetchWorkspaceMembershipsAndInvitations({
      fetchPolicy: 'network-only',
    });
  };

  const onDeleteInvitation = async (
    invitation: InvitationWithCustomRoleFragment | null
  ) => {
    if (invitation === null) return;
    await deleteInvitation({
      variables: { deleteInvitationRequest: { invitationId: invitation.id } },
    }).then(() => {
      setInvitationToDelete(null);
      fetchWorkspaceMembershipsAndInvitations({
        fetchPolicy: 'network-only',
      });
    });
  };

  const onDeleteMembership = async (
    membership: MembershipWithCustomRoleFragment | null
  ) => {
    if (membership === null) return;
    if (membership.user.id === user?.id) {
      enqueueSnackbar('Cannot delete yourself', { variant: 'warning' });
      return;
    }

    await deleteMembership({
      variables: { deleteMembershipRequest: { id: membership.id } },
    }).then(() => setMembershipToDelete(null));
    fetchWorkspaceMembershipsAndInvitations({
      fetchPolicy: 'network-only',
    });
  };

  const onUpdateLevel = (
    membership: MembershipWithCustomRoleFragment,
    level: number
  ) => {
    if (membership.user.id === user?.id) {
      enqueueSnackbar('Cannot change your own level', { variant: 'warning' });
      return;
    }

    updateMembershipLevel({
      variables: {
        updateMembershipLevelRequest: {
          id: membership.id,
          level,
        },
      },
    }).then(() => {
      fetchWorkspaceMembershipsAndInvitations({
        fetchPolicy: 'network-only',
      }).then(() => enqueueSnackbar('Level updated', { variant: 'success' }));
    });
  };

  const onUpdateRole = (
    membership: MembershipWithCustomRoleFragment,
    { userRole, customRole }: CustomizableRole
  ) => {
    if (membership.user.id === user?.id) {
      enqueueSnackbar('Cannot change your own role', { variant: 'warning' });
      return;
    }

    updateMembership({
      variables: {
        updateMembershipRequest: {
          id: membership.id,
          role: userRole,
          customRoleId: customRole?.id,
        },
      },
    }).then(() => {
      fetchWorkspaceMembershipsAndInvitations({
        fetchPolicy: 'network-only',
      }).then(() => enqueueSnackbar('Role updated', { variant: 'success' }));
    });
  };

  const onUpdateWorkspace = (
    updateWorkspaceRequest: UpdateWorkspaceRequest
  ) => {
    return updateWorkspace({
      variables: {
        updateWorkspaceRequest,
      },
    })
      .then(refetch)
  };
  const onEnableInviteByDomain = () => {
    const newFormData = {
      ...workspaceFormData,
      enableInviteByDomain: !workspaceFormData.enableInviteByDomain,
      inviteByDomainRole: workspaceFormData.inviteByDomainRole,
    };
    setWorkspaceFormData(newFormData);
    onUpdateWorkspace(newFormData);
  };

  const onRequestInviteByDomainAccess = () => {
    requestFeatureAccess({
      variables: { request: { feature: 'Invite by domain' } },
    });
    enqueueSnackbar(t('workspaceSettingsPage.inviteByDomain.requestSent'), {
      variant: 'success',
    });
  };

  const invitations = data?.workspace.invitations.filter(
    (invitation) => invitation.status === InvitationStatus.INVITED
  );
  const memberships = data?.workspace.memberships;

  const inviteDisabled =
    invitations?.some((i) => i.email === email) ||
    memberships?.some((m) => m.user.email === email) ||
    !email ||
    !email.includes('@') ||
    !email.includes('.');

  if (!workspace) {
    return null;
  }

  return (
    <Box sx={{ ml: 0 }}>
      <AlertDialog
        color="error"
        onSubmit={() => onDeleteMembership(membershipToDelete)}
        onCancel={() => setMembershipToDelete(null)}
        open={!!membershipToDelete}
        title={`Delete ${[
          membershipToDelete?.user.firstName,
          membershipToDelete?.user.lastName,
        ].join(' ')}?`}
        body={`You are about to delete user "${[
          membershipToDelete?.user.firstName,
          membershipToDelete?.user.lastName,
        ].join(' ')}" (${
          membershipToDelete?.user.email
        }) from this workspace. This action cannot be undone. Are you sure?`}
      />
      <AlertDialog
        color="error"
        onSubmit={() => onDeleteInvitation(invitationToDelete)}
        onCancel={() => setInvitationToDelete(null)}
        open={!!invitationToDelete}
        title={`Delete invite?`}
        body={`You are about to delete the invitation for email ${invitationToDelete?.email}. This action cannot be undone. Are you sure?`}
      />
      <Stack spacing={2}>
        <Typography variant="h5" sx={{ pt: 2 }}>
          Invite users to your workspace
        </Typography>
        <Paper sx={{ p: 2 }}>
          <Grid container spacing={2} sx={{ pl: 0 }}>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                sx={{ flex: 2 }}
                label="Email"
                type="email"
                autoComplete="email"
                size="small"
                autoFocus
                required
                error={!!error}
                value={email}
                onChange={(e) =>
                  setEmail(e.target.value.trim().toLocaleLowerCase())
                }
                onKeyUp={(e) => !inviteDisabled && ifEnter(onInviteUser)(e)}
              />
            </Grid>
            <Grid item md={3} xs={6}>
              <RolePicker
                FormControlProps={{ fullWidth: true }}
                value={inviteUserRole}
                onChange={setInviteUserRole}
              />
            </Grid>
            <Grid item md={3} xs={6}>
              <LoadingButton
                fullWidth
                variant="contained"
                disabled={inviteDisabled}
                onClick={onInviteUser}
                loading={loadingInvite}
              >
                Invite <SendRoundedIcon sx={{ marginLeft: 1 }} />
              </LoadingButton>
            </Grid>
          </Grid>
          <Stack spacing={2} sx={{ width: 'fit-content' }}>
            {invitations && invitations.length > 0 && (
              <Typography variant="title" sx={{ marginTop: 5 }}>
                Pending Invites
              </Typography>
            )}

            {invitations &&
              invitations.map((invitation) => {
                return (
                  <Grid
                    key={invitation.id}
                    container
                    columnSpacing={2}
                    rowSpacing={{ xs: 1, md: 0 }}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item>
                      <Typography>{invitation.email}</Typography>
                    </Grid>
                    <Grid item>
                      <RolePicker
                        value={{
                          userRole: invitation.role,
                          customRole: invitation.customRole,
                        }}
                        disabled
                        SelectProps={{ sx: { width: 180 } }}
                      />
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => setInvitationToDelete(invitation)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                );
              })}
          </Stack>
          <Typography variant="h6" sx={{ mt: 5, mb: 1 }}>
            {t('workspaceSettingsPage.inviteByDomain.title')}
          </Typography>
          {workspace.domain ? (
            <FormControlLabel
              sx={{ mx: 0, mb: 1 }}
              control={
                <Switch
                  checked={!!workspaceFormData.enableInviteByDomain}
                  color="primary"
                  onChange={onEnableInviteByDomain}
                  sx={{ mr: 2 }}
                />
              }
              label={
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    flexWrap: 'wrap',
                  }}
                >
                  {t('workspaceSettingsPage.inviteByDomain.enabled', {
                    domain: workspace.domain,
                  })}
                  <RolePicker
                    onChange={async (role) => {
                      const newFormData: UpdateWorkspaceRequest = {
                        ...workspaceFormData,
                        inviteByDomainRole: role.userRole,
                        inviteByDomainCustomRoleId: role.customRole?.id,
                      };
                      setInviteByDomainRole(role);
                      setWorkspaceFormData(newFormData);
                      await onUpdateWorkspace(newFormData);
                      enqueueSnackbar('Default role updated', {
                        variant: 'success',
                      });
                    }}
                    disabled={!workspaceFormData.enableInviteByDomain}
                    value={inviteByDomainRole}
                    SelectProps={{ sx: { width: 180 } }}
                  />
                </Box>
              }
              labelPlacement="end"
            />
          ) : (
            <Alert
              severity="info"
              sx={{ width: 'fit-content' }}
              action={
                <Button
                  color="inherit"
                  size="small"
                  onClick={onRequestInviteByDomainAccess}
                >
                  {t('workspaceSettingsPage.inviteByDomain.requestAccess')}
                </Button>
              }
            >
              {t('workspaceSettingsPage.inviteByDomain.noDomain')}
            </Alert>
          )}
        </Paper>

        <Typography variant="h5" sx={{ pt: 2 }}>
          Workspace Members
        </Typography>
        <Paper sx={{ p: 2 }}>
          <Stack spacing={2}>
            {memberships &&
              memberships
                .filter((m) => m.role !== UserRole.JITA_WRITE)
                .sort((m1, m2) =>
                  m1.user.id === user?.id
                    ? -1
                    : 0 || m2.user.id === user?.id
                    ? 1
                    : 0 ||
                      m1.user.firstName.localeCompare(m2.user.firstName) ||
                      m1.user.lastName.localeCompare(m2.user.lastName) ||
                      m1.user.email.localeCompare(m2.user.email)
                )
                .map((m) => {
                  return (
                    <Grid
                      key={m.id}
                      container
                      rowSpacing={{ xs: 1, md: 1 }}
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Grid item xs={12} sm={12} md={12} lg={4}>
                        <Typography>
                          {[m.user.firstName, m.user.lastName].join(' ')}
                        </Typography>
                        <Typography variant="caption">
                          {m.user.email}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={4}>
                        <RolePicker
                          onChange={(role) => onUpdateRole(m, role)}
                          disabled={m.user.id === user?.id}
                          value={{ userRole: m.role, customRole: m.customRole }}
                          SelectProps={{ sx: { width: 180 } }}
                        />
                        {m.user.id !== user?.id && (
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            disabled={m.user.id === user?.id}
                            onClick={() => setMembershipToDelete(m)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={4}>
                        {m.role !== UserRole.ADMIN && (
                          <JobLevelPicker
                            value={m.globalLevelLevel === null || m.globalLevelLevel === undefined
                              ? undefined
                              : {label: `Level ${m.globalLevelLevel}`, value: m.globalLevelLevel}
                            }
                            SelectProps={{ sx: { width: 180 } }}
                            onChange={(level) => onUpdateLevel(m, level)}
                          />
                        )}
                      </Grid>
                      <Grid item xs={12} md={12}>
                        {m.user.id === user?.id && memberships.length > 1 && (
                          <Divider sx={{ mt: 2, mb: 1 }} />
                        )}
                      </Grid>
                    </Grid>
                  );
                })}
          </Stack>
        </Paper>
      </Stack>
    </Box>
  );
}
