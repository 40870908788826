import {
  PermissionSets,
  Scope,
  Scopes,
  USER_ROLES_WITH_METADATA,
  UserRoleMetadata,
} from '@alamere/core';
import {
  CustomRoleFragment,
  useGetCustomRolesQuery,
} from '@alamere/generated-graphql-types';
import ModeEditRoundedIcon from '@mui/icons-material/ModeEditRounded';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import { Box, Button, Chip, Drawer, Stack, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { t } from 'i18next';
import { useState } from 'react';
import { CustomRoleDetail } from './CustomRoleDetail';
import { DefaultRoleDetail } from './DefaultRoleDetail';

export function WorkspacePermissions() {
  const { data, loading, refetch } = useGetCustomRolesQuery();
  const [hovered, setHovered] = useState<number | string>();

  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [selectedCustomRole, setSelectedCustomRole] = useState<
    CustomRoleFragment | undefined
  >();

  const [selectedDefaultRole, setSelectedDefaultRole] = useState<
    UserRoleMetadata | undefined
  >();

  const handleCustomRoleClick = (customRole: CustomRoleFragment) => () => {
    setDrawerOpen(true);
    setSelectedCustomRole(customRole);
  };
  const handleDefaultRoleClick = (defaultRole: UserRoleMetadata) => () => {
    setDrawerOpen(true);
    setSelectedDefaultRole(defaultRole);
  };

  const handleCreateRole = () => {
    setDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
    setSelectedCustomRole(undefined);
    setSelectedDefaultRole(undefined);
  };

  const handleCustomRoleComplete = async () => {
    setDrawerOpen(false);
    setSelectedCustomRole(undefined);
    await refetch();
  };

  const handleCustomRoleCancel = () => {
    setDrawerOpen(false);
    setSelectedCustomRole(undefined);
  };

  return (
    <Box>
      <Drawer open={drawerOpen} anchor="right" onClose={handleCloseDrawer}>
        {!selectedDefaultRole && (
          <CustomRoleDetail
            customRole={selectedCustomRole}
            onComplete={handleCustomRoleComplete}
            onCancel={handleCustomRoleCancel}
          />
        )}
        {selectedDefaultRole && (
          <DefaultRoleDetail
            role={selectedDefaultRole}
            onCancel={handleCloseDrawer}
          />
        )}
      </Drawer>
      <Stack gap={5}>
        <Box>
          <Stack
            direction="row"
            sx={{
              justifyContent: 'space-between',
              alignItems: 'flex-end',
              mb: 1,
            }}
          >
            <Typography variant="h5">Default Roles</Typography>
          </Stack>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Access</TableCell>
                  <TableCell width={60}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(Object.values(USER_ROLES_WITH_METADATA) as UserRoleMetadata[])
                  .filter((role) => !role.isInternal)
                  .map((role) => (
                    <TableRow
                      key={role.name}
                      onClick={handleDefaultRoleClick(role)}
                      onMouseEnter={() => setHovered(role.id)}
                      onMouseLeave={() => setHovered(undefined)}
                      hover
                      sx={{
                        cursor: 'pointer',
                      }}
                    >
                      <TableCell>{role.name}</TableCell>
                      <TableCell>
                        {renderScopes(Array.from(PermissionSets[role.id]))}
                      </TableCell>
                      <TableCell align="right" sx={{ py: 0 }}>
                        {hovered === role.id && <VisibilityRoundedIcon />}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box>
          <Stack
            direction="row"
            sx={{
              justifyContent: 'space-between',
              alignItems: 'flex-end',
              mb: 1,
            }}
          >
            <Typography variant="h5">Custom Roles</Typography>
            <Button variant="contained" onClick={handleCreateRole}>
              Create New Custom Role
            </Button>
          </Stack>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Access</TableCell>
                  <TableCell align="right">Users with Role</TableCell>
                  <TableCell width={60}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.getCustomRoles.map((customRole) => (
                  <TableRow
                    key={customRole.name}
                    onClick={handleCustomRoleClick(customRole)}
                    onMouseEnter={() => setHovered(customRole.id)}
                    onMouseLeave={() => setHovered(undefined)}
                    hover
                    sx={{
                      cursor: 'pointer',
                    }}
                  >
                    <TableCell>{customRole.name}</TableCell>
                    <TableCell>{renderScopes(customRole.scopes)}</TableCell>
                    <TableCell align="right">{customRole.userCount}</TableCell>
                    <TableCell align="right" sx={{ py: 0 }}>
                      {hovered === customRole.id && <ModeEditRoundedIcon />}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Stack>
    </Box>
  );
}

function renderScopes(scopes: Scope[]) {
  const MAX_SCOPES = 3;
  const firstThree = scopes
    .slice(0, MAX_SCOPES)
    .map((scope) => (
      <Chip key={scope} label={t(Scopes[scope].label)} sx={{ m: 0.4 }} />
    ));
  const rest =
    scopes.length > MAX_SCOPES ? ` (+ ${scopes.length - MAX_SCOPES} more)` : '';
  return (
    <Box alignItems="center">
      {firstThree}
      {rest}
    </Box>
  );
}
