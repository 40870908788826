import {
  DEFAULT_TIER_CONFIG,
  Scope,
  TierConfigParsed,
  TierManager,
} from '@alamere/core';
import {
  ZipcodeResponse,
  useSaveTierConfigurationMutation,
  useTierConfigLazyQuery,
} from '@alamere/generated-graphql-types';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded';
import ModeEditRoundedIcon from '@mui/icons-material/ModeEditRounded';
import RestartAltRoundedIcon from '@mui/icons-material/RestartAltRounded';
import {
  Box,
  Button,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  TextField,
} from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useScopes } from '../../hooks/useScopes';
import { useAuth } from '../../providers/auth';
import amplitude, { AmplitudeEventType } from '../../vendor/amplitude';
import Configuration from './Configuration';
import Map from './Map';
import Results from './Results';
import Search from './Search';
export default function LocationCalculatorPage() {
  const { workspace } = useAuth();
  const [fetchTierConfig, { data, loading }] = useTierConfigLazyQuery();
  const [saveTierConfig] = useSaveTierConfigurationMutation();
  const { checkScopes } = useScopes();

  const [tierManager, setTierManager] = useState<TierManager | undefined>();

  const [zipcodeResponse, setZipcodeResponse] = useState<
    ZipcodeResponse | undefined
  >();
  const [tierNotes, setTierNotes] = useState<string>('');
  const [editing, setEditing] = useState<boolean>(false);

  useEffect(() => {
    if (workspace && workspace.id && !data) {
      fetchTierConfig();
    }
  }, [workspace]);

  useEffect(() => {
    if (!loading && data?.tierConfig.tierConfigJson) {
      setTierManager(
        new TierManager(
          JSON.parse(data.tierConfig.tierConfigJson) as TierConfigParsed
        )
      );
    }

    if (data) {
      setTierNotes(data.tierConfig.notes || '');
    }
  }, [data]);

  if (!workspace) {
    return null;
  }

  const onConfigReset = () => {
    setTierManager(new TierManager(DEFAULT_TIER_CONFIG));
  };

  const onConfigChange = (newTierConfig: TierConfigParsed) => {
    setTierManager(new TierManager(newTierConfig));
  };

  const onConfigSave = () => {
    saveTierConfig({
      variables: {
        tierConfigJson: JSON.stringify(tierManager?.getTierConfig()),
        notes: tierNotes,
      },
    }).then(() => {
      enqueueSnackbar('Configuration saved', {
        variant: 'success',
      });
      fetchTierConfig({
        fetchPolicy: 'network-only',
      });
      amplitude.track(AmplitudeEventType.EditGeoTierConfiguration);
    });
  };

  if (!checkScopes(Scope.VIEW_LOCATION_CALCULATOR) || !tierManager) {
    return null;
  }

  const renderEditButtons = () => {
    return (
      <Box marginTop={3} marginBottom={-1}>
        {editing ? (
          <>
            <Button
              variant="text"
              startIcon={<RestartAltRoundedIcon />}
              onClick={() => {
                onConfigReset();
              }}
            >
              Reset Configurations
            </Button>
            <Button
              variant="text"
              startIcon={<CancelRoundedIcon />}
              onClick={() => {
                setEditing(false);
                data?.tierConfig.tierConfigJson &&
                  setTierManager(
                    new TierManager(
                      JSON.parse(
                        data.tierConfig.tierConfigJson
                      ) as TierConfigParsed
                    )
                  );
                setTierNotes(data?.tierConfig.notes || '');
              }}
            >
              Cancel
            </Button>
            <Button
              variant="text"
              startIcon={<CheckRoundedIcon />}
              onClick={() => {
                setEditing(false);
                onConfigSave();
              }}
            >
              Accept
            </Button>
          </>
        ) : (
          <Button
            variant="text"
            startIcon={<ModeEditRoundedIcon />}
            onClick={() => setEditing(true)}
          >
            Edit
          </Button>
        )}
      </Box>
    );
  };

  return (
    <Box>
      <Grid container spacing={1} sx={{ alignItems: 'stretch' }}>
        <Grid item xs={12} md={5} lg={4}>
          <Paper sx={{ minWidth: 275, height: '100%' }}>
            <Search onUpdate={(res) => setZipcodeResponse(res)} />
            <Results
              city={zipcodeResponse?.city}
              state={zipcodeResponse?.state}
              alamereLocationId={
                zipcodeResponse?.alamereLocationId &&
                tierManager.getMostSpecificLocationId(
                  zipcodeResponse?.alamereLocationId,
                  zipcodeResponse?.state
                )
              }
              tier={
                zipcodeResponse?.alamereLocationId
                  ? tierManager.getTier(
                      tierManager.getMostSpecificLocationId(
                        zipcodeResponse?.alamereLocationId,
                        zipcodeResponse?.state
                      )
                    )
                  : undefined
              }
            />
            {editing ? (
              <Box p={2}>
                <TextField
                  fullWidth
                  label="Notes"
                  multiline
                  rows={4}
                  onChange={(e) => setTierNotes(e.target.value)}
                  value={tierNotes}
                />
              </Box>
            ) : (
              tierNotes &&
              checkScopes(Scope.VIEW_LOCATION_CALCULATOR_NOTES) && (
                <List sx={{ marginTop: -2 }}>
                  <ListItem sx={{ alignItems: 'baseline' }}>
                    <ListItemIcon sx={{ top: 10, position: 'relative' }}>
                      <DescriptionRoundedIcon />
                    </ListItemIcon>
                    <ListItemText
                      secondary={tierNotes}
                      primary="Notes"
                      sx={{
                        whiteSpace: 'pre-wrap',
                      }}
                    />
                  </ListItem>
                </List>
              )
            )}
          </Paper>
        </Grid>
        <Grid item xs={12} md={7} lg={8}>
          <Map zipcodeResponse={zipcodeResponse} tierManager={tierManager} />
        </Grid>
      </Grid>
      {checkScopes(Scope.EDIT_LOCATION_CALCULATOR) && renderEditButtons()}

      {loading || !tierManager ? null : (
        <Configuration
          onConfigChange={onConfigChange}
          tierManager={tierManager!}
          editing={editing}
        />
      )}
    </Box>
  );
}
