import { EnvironmentType } from './environment.type';

export const env: EnvironmentType = {
  env: 'qa',
  flagsmith: {
    environmentId: 'iWen2g8LMM2fPJyPvEjEca',
    cacheFlags: false,
    cacheTTL: 0,
  },
};
