import { COUNTRIES_BY_CODE, CountryCode } from '@alamere/core';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  Autocomplete,
  Checkbox,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { t } from 'i18next';
import { useState } from 'react';
import {
  EquityGlobalMappingFragment,
  EquityGlobalMappingSaveRequest,
} from '../../../graphql/_generated/generated-graphql-types';

export interface RowData {
  mapping: EquityGlobalMappingFragment | EquityGlobalMappingSaveRequest;
  edited: boolean;
  deleted?: boolean;
}

export interface RowProps {
  row: RowData;
  onChange: (row: RowData, index: number) => void;
  index: number;
  editing: boolean;
}

export default function Row({
  row: { mapping, edited, deleted },
  onChange,
  index,
  editing,
}: RowProps) {
  const [hovered, setHovered] = useState(false);
  return (
    <TableRow
      sx={(theme) => ({
        borderLeft: edited ? `4px solid ${theme.palette.primary.main}` : 'none',
      })}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <TableCell component="th" scope="row">
        {editing ? (
          <Autocomplete
            disablePortal
            disableClearable
            options={Object.values(COUNTRIES_BY_CODE)}
            getOptionLabel={(option) => option.name || ''}
            value={
              mapping.countryCode && COUNTRIES_BY_CODE[mapping.countryCode]
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                sx={{ color: 'red' }}
                size="small"
              />
            )}
            onChange={(e, value) => {
              onChange(
                {
                  mapping: {
                    ...mapping,
                    countryCode: value?.alpha2 as CountryCode,
                  },
                  edited: true,
                  deleted: false,
                },
                index
              );
            }}
          />
        ) : (
          <Typography>{COUNTRIES_BY_CODE[mapping.countryCode].name}</Typography>
        )}
      </TableCell>
      <TableCell align="center">
        {editing ? (
          <TextField
            variant="outlined"
            inputProps={{ sx: { textAlign: 'right' } }}
            size="small"
            value={mapping.percent}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            onChange={(e) => {
              onChange(
                {
                  mapping: {
                    ...mapping,
                    percent: parseFloat(e.target.value) || 0,
                  },
                  edited: true,
                  deleted: false,
                },
                index
              );
            }}
          />
        ) : (
          <Typography>{mapping.percent}%</Typography>
        )}
      </TableCell>
      <TableCell align="center">
        {mapping.countryCode === CountryCode.US ? (
          <Checkbox
            size="small"
            checked={mapping.useGeoDiff}
            disabled={!editing}
            onChange={(e) =>
              onChange(
                {
                  mapping: {
                    ...mapping,
                    useGeoDiff: e.target.checked,
                  },
                  edited: true,
                  deleted: false,
                },
                index
              )
            }
          />
        ) : (
          <Tooltip
            componentsProps={{ tooltip: { sx: { maxWidth: 350 } } }}
            title={t('equityPage.globalMappings.table.geoDiffNotAvailable')}
          >
            <IconButton size="small">
              <InfoOutlinedIcon fontSize="small" color="disabled" />
            </IconButton>
          </Tooltip>
        )}
      </TableCell>
      {editing && (
        <TableCell align="right" sx={{ py: 0 }}>
          {hovered && (
            <IconButton
              onClick={() =>
                onChange(
                  {
                    mapping,
                    edited,
                    deleted: !deleted,
                  },
                  index
                )
              }
            >
              <DeleteRoundedIcon />
            </IconButton>
          )}
        </TableCell>
      )}
    </TableRow>
  );
}
