import { UserRole } from '@alamere/core';
import {
  Membership,
  User,
  Workspace,
  useCurrentUserLazyQuery,
} from '@alamere/generated-graphql-types';
import { useApolloClient } from '@apollo/client';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { IconButton } from '@mui/material';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { ACCESS_TOKEN, WORKSPACE_ID } from '../lib/constants';
import amplitude from '../vendor/amplitude';
import { useIdentifyFlagsmithUser } from '../vendor/flagsmith';

const WORKSPACE_PATH_MATCH_REGEX = /^\/w\/[0-9]+\//i;

interface AuthContextValue {
  loading: boolean;
  user?: User;
  workspace?: Workspace;
  workspaceMembership?: Membership;
  memberships?: Membership[];
  membershipByWorkspaceId?: Record<number, Membership>;
  onLogIn: (accessToken: string) => void;
  onLogOut: () => void;
  onReset: () => void;
  onChangeWorkspace: (workspaceId: number) => void;
  refetch: () => void;
}

const AuthContext = createContext<AuthContextValue | undefined>({
  loading: false,
  user: undefined,
  workspace: undefined,
  workspaceMembership: undefined,
  memberships: undefined,
  membershipByWorkspaceId: undefined,
  onLogIn: () => undefined,
  onLogOut: () => undefined,
  onReset: () => undefined,
  onChangeWorkspace: () => undefined,
  refetch: () => undefined,
});

export function AuthProvider({ children }: { children: ReactNode }) {
  const navigate = useNavigate();
  const client = useApolloClient();
  const { workspaceId: workspaceIdFromParams } = useParams();
  const { pathname } = useLocation();
  const [fetchCurrentUser, { loading, refetch: refetchCurrentUser }] =
    useCurrentUserLazyQuery({
      fetchPolicy: 'network-only',
    });
  const [user, setUser] = useState<User | undefined>(undefined);
  const workspace = useMemo(
    () => (user ? resolveWorkspace(workspaceIdFromParams, user) : undefined),
    [user, workspaceIdFromParams, client]
  );
  const membershipByWorkspaceId = useMemo(
    () =>
      user?.memberships.reduce((acc, membership) => {
        acc[membership.workspace.id] = membership;
        return acc;
      }, {} as Record<number, Membership>),
    [user]
  );
  useIdentifyFlagsmithUser(user, workspace);

  useEffect(() => {
    if (
      !loading &&
      !!user &&
      !user.hasVerifiedEmail &&
      pathname !== '/verify-email'
    ) {
      navigate({
        pathname: '/verify-email',
        search: createSearchParams({ email: user.email }).toString(),
      });
    }
  }, [user, loading, user?.hasVerifiedEmail, pathname]);

  useEffect(() => {
    if (
      workspaceIdFromParams &&
      workspaceIdFromParams !== localStorage.getItem(WORKSPACE_ID)
    ) {
      localStorage.setItem(WORKSPACE_ID, workspaceIdFromParams);
    }
  }, [pathname]);

  useEffect(() => {
    if (getToken()) {
      fetchCurrentUser().then((res) => {
        setUser(res?.data ? (res.data?.currentUser as User) : undefined);
      });
    } else {
      setUser(undefined);
    }
  }, [getToken()]);

  // Navigate to valid workspaceId
  useEffect(() => {
    if (
      workspaceIdFromParams &&
      workspace &&
      workspace.id !== parseInt(workspaceIdFromParams)
    ) {
      navigate(
        pathname.replace(WORKSPACE_PATH_MATCH_REGEX, `/w/${workspace.id}/`),
        {
          replace: true,
        }
      );
    }
  }, [workspace?.id]);

  const onLogIn = useCallback(
    (accessToken: string) => {
      saveToken(accessToken);
      return fetchCurrentUser().then((res) => {
        setUser(res.data ? (res.data?.currentUser as User) : undefined);
      });
    },
    [fetchCurrentUser]
  );

  const onReset = useCallback(() => {
    deleteToken();
    setUser(undefined);
    amplitude.reset();
    client.resetStore();
  }, []);

  const onLogOut = useCallback(async () => {
    onReset();
    navigate(0);
  }, [navigate, getToken(), user, client]);

  const refetch = useCallback(() => {
    return refetchCurrentUser().then((res) => {
      setUser(res.data ? (res.data?.currentUser as User) : undefined);
    });
  }, [refetchCurrentUser]);

  const onChangeWorkspace = useCallback(
    async (newWorkspaceId: number) => {
      localStorage.setItem(WORKSPACE_ID, newWorkspaceId.toString());
      await client.resetStore();

      const newPath = pathname.replace(
        WORKSPACE_PATH_MATCH_REGEX,
        `/w/${newWorkspaceId}/`
      );
      navigate(newPath !== pathname ? newPath : `/w/${newWorkspaceId}/`);
    },
    [navigate, client, pathname]
  );

  const workspaceMembership = workspace
    ? membershipByWorkspaceId?.[workspace?.id]
    : undefined;

  useEffect(() => {
    if (
      workspaceMembership &&
      workspaceMembership?.role === UserRole.JITA_WRITE
    ) {
      enqueueSnackbar('You are logged in with JITA. Use good judgement.', {
        variant: 'warning',
        preventDuplicate: true,
        autoHideDuration: null,
        anchorOrigin: { vertical: 'top', horizontal: 'center' },
        action: (key) => (
          <IconButton
            color="primary"
            aria-label="dismiss notification"
            component="label"
            onClick={() => {
              closeSnackbar(key);
            }}
          >
            <CloseRoundedIcon />
          </IconButton>
        ),
      });
    }
  }, [workspaceMembership]);

  const authContextValue: AuthContextValue = useMemo(
    () => ({
      loading,
      user,
      workspace,
      memberships: user?.memberships,
      membershipByWorkspaceId,
      workspaceMembership,
      onChangeWorkspace,
      onLogIn,
      onLogOut,
      onReset,
      refetch,
    }),
    [
      loading,
      user,
      workspace,
      workspaceIdFromParams,
      workspaceMembership,
      onChangeWorkspace,
      onLogIn,
      onLogOut,
      onReset,
      refetch,
    ]
  );

  if (loading) {
    return null;
  }

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth(): AuthContextValue {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}

function resolveWorkspace(
  workspaceIdFromParams: string | undefined,
  user: User
): Workspace | undefined {
  const cachedWorkspaceId = localStorage.getItem(WORKSPACE_ID);

  if (
    workspaceIdFromParams &&
    findValidWorkspace(workspaceIdFromParams, user)
  ) {
    localStorage.setItem(WORKSPACE_ID, workspaceIdFromParams);
    return findValidWorkspace(workspaceIdFromParams, user);
  }

  if (cachedWorkspaceId && findValidWorkspace(cachedWorkspaceId, user)) {
    return findValidWorkspace(cachedWorkspaceId, user);
  }

  if (user.memberships.length > 0) {
    const workspace = user.memberships[0].workspace;
    localStorage.setItem(WORKSPACE_ID, workspace.id.toString());
    return workspace;
  }

  return undefined;
}

function findValidWorkspace(
  workspaceId: string | undefined,
  user: User
): Workspace | undefined {
  if (!workspaceId) {
    return undefined;
  }
  return user.memberships.find((m) => m.workspace.id === parseInt(workspaceId))
    ?.workspace;
}

export function saveToken(access_token: string) {
  localStorage.setItem(ACCESS_TOKEN, access_token);
}

export function getToken() {
  return localStorage.getItem(ACCESS_TOKEN);
}

export function deleteToken() {
  localStorage.removeItem(ACCESS_TOKEN);
}
