import {
  useRequestJitaMutation,
  useSearchWorkspacesQuery,
} from '@alamere/generated-graphql-types';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputAdornment,
  Paper,
  TextField,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import _ from 'lodash';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../providers/auth';

export function HqWorkspacesPage() {
  const navigate = useNavigate();
  const { onChangeWorkspace } = useAuth();
  const [openDialog, setOpenDialog] = useState(false);
  const [jitaDurationHrs, setJitaDurationHrs] = useState('4');
  const [jitaReason, setJitaReason] = useState('');
  const [jitaWorkspaceId, setJitaWorkspaceId] = useState<number | undefined>();
  const [requestJita] = useRequestJitaMutation();

  const [query, setQuery] = useState('');
  const { data, fetchMore, loading } = useSearchWorkspacesQuery({
    variables: {
      searchWorkspacesPagedRequest: { query: '', first: 10 },
    },
    notifyOnNetworkStatusChange: true,
  });
  const [hovered, setHovered] = useState<number>();

  const onSearch = _.debounce(
    ({ query, after }: { query: string; after?: number | null }) => {
      fetchMore({
        variables: {
          searchWorkspacesPagedRequest: {
            query,
            first: 10,
            after,
          },
        },
        updateQuery: (prev, { fetchMoreResult: next }) => {
          if (!next) return prev;
          if (next && after)
            return {
              ...prev,
              searchWorkspaces: {
                edges: [
                  ...prev.searchWorkspaces.edges,
                  ...next.searchWorkspaces.edges,
                ],
                pageInfo: next.searchWorkspaces.pageInfo,
              },
            };
          return next;
        },
      });
    },
    500
  );

  const reasonSize = jitaReason ? jitaReason.trim().split(' ').length : 0;

  const onRequestJita = async () => {
    if (!jitaWorkspaceId) return;
    await requestJita({
      variables: {
        jitaRequest: {
          workspaceId: jitaWorkspaceId,
          expiresAt: Date.now() + parseFloat(jitaDurationHrs) * 1000 * 60 * 60,
          reason: jitaReason,
        },
      },
    });
    setOpenDialog(false);
    await onChangeWorkspace(jitaWorkspaceId);
  };

  const edges = data?.searchWorkspaces.edges;
  const pageInfo = data?.searchWorkspaces.pageInfo;

  return (
    <Paper sx={{ p: 3 }}>
      <Paper sx={{ mb: 2, width: 'fit-content' }}>
        <TextField
          label="Search workspaces"
          onChange={(e) => {
            setQuery(e.target.value || '');
            onSearch({ query: e.target.value || '' });
          }}
          value={query}
        />
      </Paper>
      {edges && edges.length > 0 ? (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>ID</TableCell>
                <TableCell>Domain</TableCell>
                <TableCell>Created At</TableCell>
                <TableCell align="right" width={150}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {edges?.map(({ node: workspace }) => {
                return (
                  <TableRow
                    key={workspace.id}
                    onMouseEnter={() => setHovered(workspace.id)}
                    onMouseLeave={() => setHovered(undefined)}
                    hover
                  >
                    <TableCell>{workspace.name}</TableCell>
                    <TableCell>{workspace.id}</TableCell>
                    <TableCell>{workspace.domain}</TableCell>
                    <TableCell>
                      {new Date(workspace.createdAt).toLocaleDateString()}
                    </TableCell>
                    <TableCell sx={{ py: 0 }} align="right">
                      {hovered === workspace.id && (
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={() => {
                            setJitaWorkspaceId(workspace.id);
                            setOpenDialog(true);
                          }}
                        >
                          Request JITA
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box>No Results</Box>
      )}
      {pageInfo?.hasNextPage && (
        <Box
          sx={{
            mt: 1,
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Button
            onClick={() =>
              onSearch({
                query,
                after: pageInfo?.endCursor || null,
              })
            }
            disabled={loading}
          >
            fetch more
          </Button>
        </Box>
      )}
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        maxWidth="xs"
      >
        <DialogTitle>Request JITA</DialogTitle>
        <DialogContent sx={{ gap: 3 }}>
          <DialogContentText>
            With great power comes great responsibility
          </DialogContentText>
          <FormControl
            fullWidth
            margin="normal"
            variant="outlined"
            sx={{ gap: 3 }}
          >
            <TextField
              disabled
              label="Workspace ID"
              value={jitaWorkspaceId}
              onChange={(e) => setJitaReason(e.target.value)}
            />
            <TextField
              label="Duration"
              type="number"
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start" sx={{ ml: 2 }}>
                    {parseFloat(jitaDurationHrs) === 1 ? 'hour' : 'hours'}
                  </InputAdornment>
                ),
              }}
              value={jitaDurationHrs}
              onChange={(e) => setJitaDurationHrs(e.target.value)}
            />
            <FormControl>
              <TextField
                autoFocus
                required
                label="Reason"
                fullWidth
                multiline
                value={jitaReason}
                onChange={(e) => setJitaReason(e.target.value)}
              />
              <FormHelperText>
                Provide an explanation of why you need JITA access and whether
                you received permission from the workspace owner.
              </FormHelperText>
            </FormControl>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button onClick={() => onRequestJita()}>Request JITA</Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
}
