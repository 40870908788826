import { gql } from '@apollo/client';
import MarkEmailReadRoundedIcon from '@mui/icons-material/MarkEmailReadRounded';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import { LinearProgress, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { authClient } from '../../api/authClient';
import ifEnter from '../../lib/ifEnter';
import { useAuth } from '../../providers/auth';

const sendEmailVerification = gql`
  fragment EmailVerificationPayload on REST {
    email: String
    code: String
  }
  query SendEmailVerification($input: EmailVerificationPayload!) {
    sendEmailVerification(input: $input)
      @rest(path: "verify-email", method: "POST") {
      void
    }
  }
`;

export default function VerifyEmailPage() {
  const [params] = useSearchParams();
  const { user, refetch } = useAuth();
  const [email, setEmail] = useState(params.get('email') || '');
  const [code, setCode] = useState(params.get('code') || '');
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [verificationLoading, setVerificationLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (user?.hasVerifiedEmail) {
      navigate('/');
    }
  }, [user]);

  const handleSubmit = () => {
    return authClient
      .query({
        query: sendEmailVerification,
        variables: {
          input: {
            email,
            code,
          },
        },
      })
      .then(() => {
        setIsEmailSent(true);
      })
      .catch((e) => {
        setIsEmailSent(false);
      });
  };

  useEffect(() => {
    if (!user?.hasVerifiedEmail && code) {
      setVerificationLoading(true);
      handleSubmit()
        .then(refetch)
        .catch((e) => {
          setVerificationLoading(false);
          setCode('');
          enqueueSnackbar(
            "Could not verify your email. We'll try sending you another verification link via email.",
            {
              variant: 'error',
              preventDuplicate: true,
            }
          );
        });
    }
  }, [code]);

  const shouldRenderEmailVerificationForm =
    !code && !isEmailSent && !verificationLoading;

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {verificationLoading && (
          <Box>
            <Typography>Verifying...</Typography>
            <LinearProgress />
          </Box>
        )}
        {shouldRenderEmailVerificationForm && (
          <>
            <MarkEmailReadRoundedIcon />
            <Typography component="h1" variant="h5">
              Verify your email
            </Typography>
            <Typography my={2}>
              For your security, we need to verify your email
            </Typography>
            <Box sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onKeyUp={ifEnter(handleSubmit)}
                autoFocus
              />
              <Button
                fullWidth
                variant="contained"
                size="large"
                onClick={handleSubmit}
                sx={{ mt: 3, mb: 2 }}
              >
                Send me a verification link
              </Button>
            </Box>
          </>
        )}
        {isEmailSent && (
          <Stack spacing={2} sx={{ alignItems: 'center' }}>
            <SendRoundedIcon />
            <Typography component="h1" variant="h5">
              Verification email sent!
            </Typography>
            <Typography>Now, check your email.</Typography>
            <Typography>You may close this page.</Typography>
          </Stack>
        )}
      </Box>
    </Container>
  );
}
