import { Box, Container, Tab, Tabs } from '@mui/material';
import { useMemo } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

const MenuItems = [
  { href: 'general', label: 'Workspace' },
  { href: 'users', label: 'Users' },
  { href: 'permissions', label: 'Roles' },
];

export default function WorkspaceSettingsPage() {
  const navigate = useNavigate();

  const { pathname } = useLocation();
  const pathRoot = useMemo(
    () => pathname.split('/').slice(0, -1).join('/'),
    [pathname]
  );
  const currentTabPath = useMemo(() => pathname.split('/').pop(), [pathname]);
  const currentTabIndex = useMemo(
    () => MenuItems.findIndex((item) => item.href === currentTabPath),
    [currentTabPath]
  );

  return (
    <Container sx={{ ml: 0 }}>
      <Box sx={{ width: '100%', mb: 5 }}>
        <Tabs value={currentTabIndex} aria-label="workspace tabs navigation">
          {MenuItems.map((item, index) => (
            <Tab
              key={index}
              component="a"
              label={item.label}
              onClick={() => navigate([pathRoot, item.href].join('/'))}
            />
          ))}
        </Tabs>
      </Box>
      <Outlet />
    </Container>
  );
}
