import { gql } from '@apollo/client';
import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { authClient } from '../../api/authClient';
import ifEnter from '../../lib/ifEnter';

const requestPasswordResetEmail = gql`
  fragment Payload on REST {
    email: String
    password: String
  }
  query RequestPasswordResetEmail($input: Payload!) {
    sendPasswordResetEmail(input: $input)
      @rest(path: "email-password-reset", method: "POST") {
      void
    }
  }
`;

export default function FortogPasswordPage() {
  const [params] = useSearchParams();
  const [email, setEmail] = useState(params.get('email') || '');
  const [isEmailSent, setIsEmailSent] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = () => {
    return authClient
      .query({
        query: requestPasswordResetEmail,
        variables: {
          input: {
            email: email,
          },
        },
      })
      .then((response) => {
        setIsEmailSent(true);
      })
      .catch((error) => {});
  };

  const renderResetForm = () => {
    return (
      <>
        <Typography component="h1" variant="h5">
          Forgot your password?
        </Typography>
        <Box sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onKeyUp={ifEnter(handleSubmit)}
            autoFocus
          />
          <Button
            fullWidth
            variant="contained"
            size="large"
            onClick={handleSubmit}
            sx={{ mt: 3, mb: 2 }}
          >
            Send me a reset link
          </Button>
          <Grid container direction="column" alignItems="center">
            <Grid item>
              <Button onClick={() => navigate('/login')} variant="text">
                {'Remember your password? Log In'}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </>
    );
  };

  const renderEmailSent = () => {
    return (
      <Stack spacing={2} sx={{ alignItems: 'center' }}>
        <Typography component="h1" variant="h5">
          Recovery email sent!
        </Typography>
        <Typography>Now, check your email.</Typography>
        <Typography variant="body2">
          or{' '}
          <Button onClick={() => navigate('/login')} variant="text">
            {'Remember your password? Log In'}
          </Button>
        </Typography>
      </Stack>
    );
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 12,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {!isEmailSent ? renderResetForm() : renderEmailSent()}
      </Box>
    </Container>
  );
}
