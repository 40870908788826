import {
  JobGroup,
  JobLevelSaveRequest,
  SurveyCode,
  SurveyProvider,
} from '@alamere/generated-graphql-types';

export const radfordDefaultLevels: JobLevelSaveRequest[] = [
  {
    surveyCode: SurveyCode.E6,
    group: JobGroup.Executive,
    level: 19,
    name: 'CEO',
    code: 'E6',
    isVisible: true,
    surveyProvider: SurveyProvider.Radford,
  },
  {
    surveyCode: SurveyCode.E5,
    group: JobGroup.Executive,
    level: 18,
    name: 'C-Suite',
    code: 'E5',
    isVisible: true,
    surveyProvider: SurveyProvider.Radford,
  },
  {
    surveyCode: SurveyCode.E4,
    group: JobGroup.Executive,
    level: 17,
    name: 'SVP II',
    code: 'E4',
    isVisible: true,
    surveyProvider: SurveyProvider.Radford,
  },
  {
    surveyCode: SurveyCode.E3,
    group: JobGroup.Executive,
    level: 16,
    name: 'SVP',
    code: 'E3',
    isVisible: true,
    surveyProvider: SurveyProvider.Radford,
  },
  {
    surveyCode: SurveyCode.E2,
    group: JobGroup.Executive,
    level: 15,
    name: 'VP II',
    code: 'E2',
    isVisible: true,
    surveyProvider: SurveyProvider.Radford,
  },
  {
    surveyCode: SurveyCode.E1,
    group: JobGroup.Executive,
    level: 14,
    name: 'VP',
    code: 'E1',
    isVisible: true,
    surveyProvider: SurveyProvider.Radford,
  },

  {
    surveyCode: SurveyCode.M6,
    group: JobGroup.Management,
    level: 13,
    name: 'Senior Director',
    code: 'M6',
    isVisible: true,
    surveyProvider: SurveyProvider.Radford,
  },
  {
    surveyCode: SurveyCode.M5,
    group: JobGroup.Management,
    level: 12,
    name: 'Director',
    code: 'M5',
    isVisible: true,
    surveyProvider: SurveyProvider.Radford,
  },
  {
    surveyCode: SurveyCode.M4,
    group: JobGroup.Management,
    level: 11,
    name: 'Senior Manager / Associate Director',
    code: 'M4',
    isVisible: true,
    surveyProvider: SurveyProvider.Radford,
  },
  {
    surveyCode: SurveyCode.M3,
    group: JobGroup.Management,
    level: 10,
    name: 'Manager',
    code: 'M3',
    isVisible: true,
    surveyProvider: SurveyProvider.Radford,
  },
  {
    surveyCode: SurveyCode.M2,
    group: JobGroup.Management,
    level: 9,
    name: 'Senior Supervisor',
    code: 'M2',
    isVisible: true,
    surveyProvider: SurveyProvider.Radford,
  },
  {
    surveyCode: SurveyCode.M1,
    group: JobGroup.Management,
    level: 7,
    name: 'Supervisor',
    code: 'M1',
    isVisible: true,
    surveyProvider: SurveyProvider.Radford,
  },
  {
    surveyCode: SurveyCode.P7,
    group: JobGroup.Professional,
    level: 13,
    name: 'Distinguished',
    code: 'P7',
    isVisible: true,
    surveyProvider: SurveyProvider.Radford,
  },
  {
    surveyCode: SurveyCode.P6,
    group: JobGroup.Professional,
    level: 12,
    name: 'Principal / Consultant',
    code: 'P6',
    isVisible: true,
    surveyProvider: SurveyProvider.Radford,
  },
  {
    surveyCode: SurveyCode.P5,
    group: JobGroup.Professional,
    level: 11,
    name: 'Expert',
    code: 'P5',
    isVisible: true,
    surveyProvider: SurveyProvider.Radford,
  },
  {
    surveyCode: SurveyCode.P4,
    group: JobGroup.Professional,
    level: 10,
    name: 'Advanced',
    code: 'P4',
    isVisible: true,
    surveyProvider: SurveyProvider.Radford,
  },
  {
    surveyCode: SurveyCode.P3,
    group: JobGroup.Professional,
    level: 9,
    name: 'Career',
    code: 'P3',
    isVisible: true,
    surveyProvider: SurveyProvider.Radford,
  },
  {
    surveyCode: SurveyCode.P2,
    group: JobGroup.Professional,
    level: 7,
    name: 'Developing',
    code: 'P2',
    isVisible: true,
    surveyProvider: SurveyProvider.Radford,
  },
  {
    surveyCode: SurveyCode.P1,
    group: JobGroup.Professional,

    level: 6,
    name: 'Entry',
    code: 'P1',
    isVisible: true,
    surveyProvider: SurveyProvider.Radford,
  },
  {
    surveyCode: SurveyCode.T7,
    group: JobGroup.TechnicalScientific,
    level: 14,
    name: 'Fellow / Distinguished Fellow',
    code: 'T7',
    isVisible: true,
    surveyProvider: SurveyProvider.Radford,
  },
  {
    surveyCode: SurveyCode.T6,
    group: JobGroup.TechnicalScientific,
    level: 13,
    name: 'Senior Principal',
    code: 'T6',
    isVisible: true,
    surveyProvider: SurveyProvider.Radford,
  },
  {
    surveyCode: SurveyCode.T5,
    group: JobGroup.TechnicalScientific,
    level: 12,
    name: 'Principal',
    code: 'T5',
    isVisible: true,
    surveyProvider: SurveyProvider.Radford,
  },
  {
    surveyCode: SurveyCode.T4,
    group: JobGroup.TechnicalScientific,
    level: 11,
    name: 'Advanced',
    code: 'T4',
    isVisible: true,
    surveyProvider: SurveyProvider.Radford,
  },
  {
    surveyCode: SurveyCode.T3,
    group: JobGroup.TechnicalScientific,
    level: 10,
    name: 'Career',
    code: 'T3',
    isVisible: true,
    surveyProvider: SurveyProvider.Radford,
  },
  {
    surveyCode: SurveyCode.T2,
    group: JobGroup.TechnicalScientific,
    level: 9,
    name: 'Developing',
    code: 'T2',
    isVisible: true,
    surveyProvider: SurveyProvider.Radford,
  },
  {
    surveyCode: SurveyCode.T1,
    group: JobGroup.TechnicalScientific,
    level: 8,
    name: 'Entry',
    code: 'T1',
    isVisible: true,
    surveyProvider: SurveyProvider.Radford,
  },
  {
    surveyCode: SurveyCode.S5,
    group: JobGroup.Support,
    level: 6,
    name: 'Specialist',
    code: 'S5',
    isVisible: true,
    surveyProvider: SurveyProvider.Radford,
  },
  {
    surveyCode: SurveyCode.S4,
    group: JobGroup.Support,
    level: 5,
    name: 'Highly Skilled',
    code: 'S4',
    isVisible: true,
    surveyProvider: SurveyProvider.Radford,
  },
  {
    surveyCode: SurveyCode.S3,
    group: JobGroup.Support,
    level: 4,
    name: 'Senior',
    code: 'S3',
    isVisible: true,
    surveyProvider: SurveyProvider.Radford,
  },
  {
    surveyCode: SurveyCode.S2,
    group: JobGroup.Support,
    level: 3,
    name: 'Intermediate',
    code: 'S2',
    isVisible: true,
    surveyProvider: SurveyProvider.Radford,
  },
  {
    surveyCode: SurveyCode.S1,
    group: JobGroup.Support,
    level: 2,
    name: 'Entry',
    code: 'S1',
    isVisible: true,
    surveyProvider: SurveyProvider.Radford,
  },
  {
    surveyCode: SurveyCode.Intern,
    group: JobGroup.Support,
    level: 0,
    name: 'Intern',
    code: 'Intern',
    isVisible: true,
    surveyProvider: SurveyProvider.Radford,
  },
];
