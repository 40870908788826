import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { Avatar, IconButton, Menu } from '@mui/material';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Toolbar from '@mui/material/Toolbar';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Help } from '../components/Help';
import { Logo } from '../components/Logo';
import { getToken, useAuth } from '../providers/auth';
import { useIdentifyAmplitudeUser } from '../vendor/amplitude';
import AvatarMenu from './menus/AvatarMenu';
import HqMenu from './menus/HqMenu';
import JitaMenu from './menus/JitaMenu';
import { MainMenu } from './menus/Menu';

const drawerWidth = 240;

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

function AuthedLayout() {
  const { loading, user } = useAuth();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    if (!loading && !user && !getToken()) {
      navigate('/login', { replace: true });
    }
  }, [loading, user]);

  useIdentifyAmplitudeUser();

  if (loading || !user || !user.hasVerifiedEmail) return null;

  return (
    <Box sx={{ display: 'flex' }}>
      {/* Menus */}
      <MenusContainer>
        <List component="nav">
          <Box>
            <MainMenu currentPath={pathname} />
          </Box>
        </List>
      </MenusContainer>

      {/* Main content */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
        }}
      >
        {/*  Use Toolbar to push content down */}
        <Toolbar />

        <Box sx={{ mx: 2 }}>
          <Outlet />
        </Box>
      </Box>
      <Help />
    </Box>
  );
}

function MenusContainer({ children }: { children: React.ReactNode }) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {/* Desktop Sidebar */}
      <Drawer
        sx={{ display: { xs: 'none', md: 'block' } }}
        variant="permanent"
        open={true}
      >
        <Toolbar
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            px: [1],
          }}
        >
          <Logo />
        </Toolbar>
        {children}
      </Drawer>

      {/* Mobile logo */}
      <Box
        sx={{
          position: 'absolute',
          left: 10,
          top: 3,
          zIndex: 10,
          gap: 1,
          alignItems: 'center',
          display: { xs: 'flex', md: 'none' },
        }}
      >
        <Logo />
      </Box>

      <Box
        sx={{
          display: 'flex',
          position: 'absolute',
          right: 10,
          top: 3,
          zIndex: 10,
          gap: { xs: 0, md: 1 },
          alignItems: 'center',
        }}
      >
        {/* Desktop + Mobile Top Menu */}
        <JitaMenu />
        <HqMenu />
        <AvatarMenu />

        {/* Mobile "sidebar" Menu */}
        <IconButton
          aria-label="Main Menu"
          id="menu-button"
          onClick={handleClick}
          sx={{ display: { xs: 'flex', md: 'none' } }}
        >
          <Avatar
            sx={{
              width: 32,
              height: 32,
              filter: 'drop-shadow(2px 2px 2px rgb(0 0 0 / 0.2))',
            }}
          >
            <MenuRoundedIcon />
          </Avatar>
        </IconButton>
        <Menu
          id="main-menu-mobile"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          sx={{ width: '100%' }}
          MenuListProps={{
            'aria-labelledby': 'menu-button',
          }}
          PaperProps={{ sx: { width: '100%' } }}
        >
          {children}
        </Menu>
      </Box>
    </>
  );
}

export default AuthedLayout;
