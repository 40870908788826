import { Chip, Stack, TableCell, Typography } from '@mui/material';
import { memo, useState } from 'react';
import {
  JobFragment,
  JobGroup,
  JobLevelFragment,
} from '../../../graphql/_generated/generated-graphql-types';
import CellItem from './CellItem';
import { OnItemChangeFunction } from './JobDefinitionsPage.page';
import NewJobButton from './NewJobButton';

interface Props {
  jobs: JobFragment[];
  jobFamilyId: number;
  jobGroup: JobGroup;
  jobLevel?: JobLevelFragment;
  editing: boolean;
  onItemChange: OnItemChangeFunction;
}
export default memo(
  ({ jobs, editing, jobLevel, jobFamilyId, onItemChange }: Props) => {
    const [hovered, setHovered] = useState<boolean>(false);

    return (
      <TableCell
        width="fit-content"
        sx={{ maxWidth: editing ? 330 : 300 }}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        {jobLevel && (
          <Stack direction="row" gap={1} alignItems="center">
            {(jobs || editing) && (
              <>
                <Chip size="small" label={jobLevel.code} />
                {jobs && (
                  <Stack>
                    <Stack gap={0.5}>
                      {jobs?.map((job) => (
                        <CellItem
                          job={job}
                          editing={editing}
                          key={job.id}
                          onItemChange={onItemChange}
                        />
                      ))}
                    </Stack>
                    <Typography
                      sx={{ mt: editing ? 0.8 : 0, lineHeight: 1 }}
                      variant="caption"
                      color="text.secondary"
                    >
                      {jobLevel.name}
                    </Typography>
                  </Stack>
                )}
              </>
            )}
            {editing && (
              <NewJobButton
                visible={hovered}
                jobLevel={jobLevel}
                jobFamilyId={jobFamilyId}
                editing={editing}
                onItemChange={onItemChange}
              />
            )}
          </Stack>
        )}
      </TableCell>
    );
  }
);
