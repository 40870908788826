import { useSubmitFeedbackMutation } from '@alamere/generated-graphql-types';
import QuestionMarkRoundedIcon from '@mui/icons-material/QuestionMarkRounded';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import {
  Button,
  Fab,
  FormControl,
  FormLabel,
  Paper,
  TextField,
  Tooltip,
} from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';
import { NpsRating } from './NpsRating';

interface SubmitFeedbackVariables {
  message: string;
  rating: number;
}

export function Help() {
  const [open, setOpen] = useState(false);
  const [submitFeedback] = useSubmitFeedbackMutation();

  const handleSubmit = async ({ message, rating }: SubmitFeedbackVariables) => {
    await submitFeedback({
      variables: {
        feedbackRequest: {
          message,
          rating,
          currentRoute: window.location.href,
        },
      },
    });
    enqueueSnackbar('Thank you for your feedback!', { variant: 'success' });
    setOpen(false);
  };

  return (
    <>
      <Tooltip
        title={<FeedbackForm onSubmit={handleSubmit} />}
        open={open}
        onOpen={() => {}}
        onClose={() => {}}
        componentsProps={{
          tooltip: { sx: { backgroundColor: 'transparent', minWidth: 350 } },
        }}
      >
        <Fab
          sx={{
            position: 'absolute',
            bottom: 20,
            right: 20,
          }}
          size="small"
          onClick={() => setOpen(!open)}
        >
          <QuestionMarkRoundedIcon color="disabled" />
        </Fab>
      </Tooltip>
    </>
  );
}

function FeedbackForm({
  onSubmit,
}: {
  onSubmit: ({ message, rating }: SubmitFeedbackVariables) => void;
}) {
  const [message, setMessage] = useState('');
  const [rating, setRating] = useState(2);

  const handleSubmit = async () => {
    await onSubmit({ message, rating });
    setMessage('');
    setRating(3);
  };

  return (
    <Paper sx={{ p: 2 }}>
      <FormControl fullWidth margin="normal" variant="outlined" sx={{ gap: 2 }}>
        <FormLabel>Share Feedback / Ask a Question</FormLabel>
        <NpsRating onChange={(value) => setRating(value)} value={rating} />
        <TextField
          label="Your message"
          multiline
          rows={4}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        <Button
          variant="contained"
          endIcon={<SendRoundedIcon />}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </FormControl>
    </Paper>
  );
}
