import {
  JobFamilyFragment,
  JobFamilyFragmentDoc,
  JobFamilySaveRequest,
} from '@alamere/generated-graphql-types';
import { useApolloClient } from '@apollo/client';
import { Checkbox, TableCell } from '@mui/material';
import { memo } from 'react';
import { OnItemChangeFunction } from '../types';

interface Props {
  editing: boolean;
  jobFamily: JobFamilyFragment;
  onItemChange: OnItemChangeFunction;
}
export const IgnoreGeoDiff = memo(
  ({ editing, jobFamily, onItemChange }: Props) => {
    const { cache } = useApolloClient();

    const handleChange = (value: boolean) => {
      const data = { ...jobFamily, ignoreGeoDiff: value };

      cache.writeFragment<JobFamilySaveRequest>({
        id: `JobFamily:${jobFamily.id}`,
        fragmentName: 'JobFamily',
        fragment: JobFamilyFragmentDoc,
        data,
      });

      onItemChange({ newItem: data });
    };

    return (
      <TableCell align="center">
        <Checkbox
          size="small"
          checked={jobFamily.ignoreGeoDiff}
          disabled={!editing}
          onChange={(e) => handleChange(e.target.checked)}
        />
      </TableCell>
    );
  }
);
