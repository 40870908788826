import { Flag } from './flags';
import { UserRole } from './user-roles';

export interface ScopeMetadata {
  id: Scope;
  label: string;
  group: string;
  requires?: Scope[];
  isDependencyTo?: Scope[];
  flags?: Flag[];
}

export enum Scope {
  // DO NOT REMOVE ITEMS - USED IN DB
  EDIT_WORKSPACE = 'EDIT_WORKSPACE',
  VIEW_LOCATION_CALCULATOR = 'VIEW_LOCATION_CALCULATOR',
  VIEW_LOCATION_CALCULATOR_NOTES = 'VIEW_LOCATION_CALCULATOR_NOTES',
  EDIT_LOCATION_CALCULATOR = 'EDIT_LOCATION_CALCULATOR',
  EQUITY_BANDS_VIEW = 'EQUITY_BANDS_VIEW',
  EQUITY_BANDS_EDIT = 'EQUITY_BANDS_EDIT',
  JOB_ARCHITECTURE_VIEW = 'JOB_ARCHITECTURE_VIEW',
  JOB_ARCHITECTURE_EDIT = 'JOB_ARCHITECTURE_EDIT',
  JOB_DEFINITIONS_VIEW = 'JOB_DEFINITIONS_VIEW',
  JOB_DEFINITIONS_EDIT = 'JOB_DEFINITIONS_EDIT',
  RANGE_WIDTHS_VIEW = 'RANGE_WIDTHS_VIEW',
  RANGE_WIDTHS_EDIT = 'RANGE_WIDTHS_EDIT',
  JOB_FAMILIES_VIEW = 'JOB_FAMILIES_VIEW',
  JOB_FAMILIES_EDIT = 'JOB_FAMILIES_EDIT',
  JOB_FUNCTIONS_VIEW = 'JOB_FUNCTIONS_VIEW',
  JOB_FUNCTIONS_EDIT = 'JOB_FUNCTIONS_EDIT',
  BONUS_STRUCTURES_VIEW = 'BONUS_STRUCTURES_VIEW',
  BONUS_STRUCTURES_EDIT = 'BONUS_STRUCTURES_EDIT',
  COMP_BANDS_VIEW = 'COMP_BANDS_VIEW',
  COMP_BANDS_EDIT = 'COMP_BANDS_EDIT',
  SET_USERS_JOB_LEVEL = 'SET_USERS_JOB_LEVEL',
  SEE_ALL_LEVELS_DATA = 'SEE_ALL_LEVELS_DATA'
}

const ALL_SCOPES = new Set(Object.values(Scope));

export const Scopes: Record<Scope, ScopeMetadata> = {
  EDIT_WORKSPACE: {
    id: Scope.EDIT_WORKSPACE,
    label: 'scopes.workspaceManagement.editWorkspace.title',
    group: 'scopes.workspaceManagement.title',
  },
  SET_USERS_JOB_LEVEL: {
    id: Scope.SET_USERS_JOB_LEVEL,
    label: 'scopes.usersJobLevel.title',
    group: 'scopes.usersJobLevel.title',
  },
  SEE_ALL_LEVELS_DATA: {
    id: Scope.SEE_ALL_LEVELS_DATA,
    label: 'scopes.allLevelData.title',
    group: 'scopes.allLevelData.title',
  },
  VIEW_LOCATION_CALCULATOR: {
    id: Scope.VIEW_LOCATION_CALCULATOR,
    label: 'scopes.geoTiers.viewGeoTiers.title',
    group: 'scopes.geoTiers.title',
    isDependencyTo: [
      Scope.EDIT_LOCATION_CALCULATOR,
      Scope.VIEW_LOCATION_CALCULATOR_NOTES,
    ],
  },
  VIEW_LOCATION_CALCULATOR_NOTES: {
    id: Scope.VIEW_LOCATION_CALCULATOR_NOTES,
    label: 'scopes.geoTiers.viewGeoTiersNotes.title',
    group: 'scopes.geoTiers.title',
    requires: [Scope.VIEW_LOCATION_CALCULATOR],
  },
  EDIT_LOCATION_CALCULATOR: {
    id: Scope.EDIT_LOCATION_CALCULATOR,
    label: 'scopes.geoTiers.editGeoTiers.title',
    group: 'scopes.geoTiers.title',
    requires: [Scope.VIEW_LOCATION_CALCULATOR],
  },
  JOB_ARCHITECTURE_VIEW: {
    id: Scope.JOB_ARCHITECTURE_VIEW,
    label: 'scopes.jobCatalog.jobArchitectureView.title',
    group: 'scopes.jobCatalog.title',
    isDependencyTo: [Scope.JOB_ARCHITECTURE_EDIT],
    flags: [Flag.JOB_CATALOG],
  },
  JOB_ARCHITECTURE_EDIT: {
    id: Scope.JOB_ARCHITECTURE_EDIT,
    label: 'scopes.jobCatalog.jobArchitectureEdit.title',
    group: 'scopes.jobCatalog.title',
    requires: [Scope.JOB_ARCHITECTURE_VIEW],
    flags: [Flag.JOB_CATALOG],
  },
  JOB_DEFINITIONS_VIEW: {
    id: Scope.JOB_DEFINITIONS_VIEW,
    label: 'scopes.jobCatalog.jobDefinitionsView.title',
    group: 'scopes.jobCatalog.title',
    isDependencyTo: [Scope.JOB_DEFINITIONS_EDIT],
    flags: [Flag.JOB_CATALOG],
  },
  JOB_DEFINITIONS_EDIT: {
    id: Scope.JOB_DEFINITIONS_EDIT,
    label: 'scopes.jobCatalog.jobDefinitionsEdit.title',
    group: 'scopes.jobCatalog.title',
    requires: [Scope.JOB_DEFINITIONS_VIEW],
    flags: [Flag.JOB_CATALOG],
  },
  EQUITY_BANDS_VIEW: {
    id: Scope.EQUITY_BANDS_VIEW,
    label: 'scopes.jobCatalog.equityBandsView.title',
    group: 'scopes.jobCatalog.title',
    isDependencyTo: [Scope.EQUITY_BANDS_EDIT],
    flags: [Flag.JOB_CATALOG],
  },
  EQUITY_BANDS_EDIT: {
    id: Scope.EQUITY_BANDS_EDIT,
    label: 'scopes.jobCatalog.equityBandsEdit.title',
    group: 'scopes.jobCatalog.title',
    requires: [Scope.EQUITY_BANDS_VIEW],
    flags: [Flag.JOB_CATALOG],
  },
  RANGE_WIDTHS_VIEW: {
    id: Scope.RANGE_WIDTHS_VIEW,
    label: 'scopes.jobCatalog.rangeWidthsView.title',
    group: 'scopes.jobCatalog.title',
    isDependencyTo: [Scope.RANGE_WIDTHS_EDIT],
    flags: [Flag.JOB_CATALOG],
  },
  RANGE_WIDTHS_EDIT: {
    id: Scope.RANGE_WIDTHS_EDIT,
    label: 'scopes.jobCatalog.rangeWidthsEdit.title',
    group: 'scopes.jobCatalog.title',
    requires: [Scope.RANGE_WIDTHS_VIEW],
    flags: [Flag.JOB_CATALOG],
  },
  JOB_FAMILIES_VIEW: {
    id: Scope.JOB_FAMILIES_VIEW,
    label: 'scopes.jobCatalog.jobFamiliesView.title',
    group: 'scopes.jobCatalog.title',
    isDependencyTo: [Scope.JOB_FAMILIES_EDIT],
    flags: [Flag.JOB_CATALOG],
  },
  JOB_FAMILIES_EDIT: {
    id: Scope.JOB_FAMILIES_EDIT,
    label: 'scopes.jobCatalog.jobFamiliesEdit.title',
    group: 'scopes.jobCatalog.title',
    requires: [Scope.JOB_FAMILIES_VIEW],
    flags: [Flag.JOB_CATALOG],
  },
  JOB_FUNCTIONS_VIEW: {
    id: Scope.JOB_FUNCTIONS_VIEW,
    label: 'scopes.jobCatalog.jobFunctionsView.title',
    group: 'scopes.jobCatalog.title',
    isDependencyTo: [Scope.JOB_FUNCTIONS_EDIT],
    flags: [Flag.JOB_CATALOG],
  },
  JOB_FUNCTIONS_EDIT: {
    id: Scope.JOB_FUNCTIONS_EDIT,
    label: 'scopes.jobCatalog.jobFunctionsEdit.title',
    group: 'scopes.jobCatalog.title',
    requires: [Scope.JOB_FUNCTIONS_VIEW],
    flags: [Flag.JOB_CATALOG],
  },
  BONUS_STRUCTURES_VIEW: {
    id: Scope.BONUS_STRUCTURES_VIEW,
    label: 'scopes.jobCatalog.bonusStructuresView.title',
    group: 'scopes.jobCatalog.title',
    isDependencyTo: [Scope.BONUS_STRUCTURES_EDIT],
    flags: [Flag.JOB_CATALOG],
  },
  BONUS_STRUCTURES_EDIT: {
    id: Scope.BONUS_STRUCTURES_EDIT,
    label: 'scopes.jobCatalog.bonusSctructuresEdit.title',
    group: 'scopes.jobCatalog.title',
    requires: [Scope.BONUS_STRUCTURES_VIEW],
    flags: [Flag.JOB_CATALOG],
  },
  COMP_BANDS_VIEW: {
    id: Scope.COMP_BANDS_VIEW,
    label: 'scopes.jobCatalog.compBandsView.title',
    group: 'scopes.jobCatalog.title',
    isDependencyTo: [Scope.COMP_BANDS_EDIT],
    flags: [Flag.JOB_CATALOG],
  },
  COMP_BANDS_EDIT: {
    id: Scope.COMP_BANDS_EDIT,
    label: 'scopes.jobCatalog.compBandsEdit.title',
    group: 'scopes.jobCatalog.title',
    requires: [Scope.COMP_BANDS_VIEW],
    flags: [Flag.JOB_CATALOG],
  },
};

export const ScopesByGroup: Record<string, ScopeMetadata[]> = Object.keys(
  Scopes
).reduce((acc, scopeId) => {
  const scope = Scopes[scopeId as keyof typeof Scopes];
  if (!acc[scope.group]) {
    acc[scope.group] = [];
  }
  acc[scope.group].push(scope);
  return acc;
}, {} as Record<string, ScopeMetadata[]>);

export const PermissionSets: Record<UserRole, Set<Scope>> = {
  [UserRole.ADMIN]: ALL_SCOPES,
  [UserRole.JITA_WRITE]: ALL_SCOPES,
  [UserRole.MEMBER]: new Set([Scope.VIEW_LOCATION_CALCULATOR]),
  [UserRole.CUSTOM]: new Set(),
};

export function canAccessAllScopes(
  scopes: Scope[],
  membership: { role: UserRole; customRole?: { scopes: Scope[] } | null }
) {
  if (!scopes || scopes.length === 0) {
    return true;
  }

  for (const scope of scopes) {
    if (membership.role === UserRole.CUSTOM) {
      if (
        !membership.customRole ||
        !membership.customRole.scopes.includes(scope)
      ) {
        return false;
      }
    } else {
      if (!PermissionSets[membership.role].has(scope)) {
        return false;
      }
    }
  }

  return true;
}
