import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import { Box } from '@mui/material';
import Rating, { IconContainerProps } from '@mui/material/Rating';
import { styled } from '@mui/material/styles';
import { SyntheticEvent } from 'react';

const StyledRating = styled(Rating)(({ theme }) => ({
  '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
    color: theme.palette.action.disabled,
  },
}));

const customIcons: {
  [index: string]: {
    icon: React.ReactElement;
    label: string;
  };
} = {
  1: {
    icon: <SentimentVeryDissatisfiedIcon color="error" />,
    label: 'Dissatisfied',
  },

  2: {
    icon: <SentimentSatisfiedIcon color="warning" />,
    label: 'Neutral',
  },

  3: {
    icon: <SentimentVerySatisfiedIcon color="success" />,
    label: 'Satisfied',
  },
};

function IconContainer(props: IconContainerProps) {
  const { value, ...other } = props;
  return <span {...other}>{customIcons[value].icon}</span>;
}

export interface NpsRatingProps {
  onChange: (newValue: number) => void;
  value: number;
}
export function NpsRating({ onChange, value }: NpsRatingProps) {
  const handleChange = (event: SyntheticEvent, newValue: number | null) => {
    if (newValue !== null) {
      onChange(newValue);
    }
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <StyledRating
        name="nps-rating"
        value={value}
        IconContainerComponent={IconContainer}
        getLabelText={(value: number) => customIcons[value].label}
        highlightSelectedOnly
        onChange={handleChange}
        max={3}
      />
      <Box ml={1}>{customIcons[value]?.label}</Box>
    </Box>
  );
}
