import { AlamereLocationId, AlamereLocations, Tier } from '@alamere/core';
import ApartmentRoundedIcon from '@mui/icons-material/ApartmentRounded';
import AttachMoneyRoundedIcon from '@mui/icons-material/AttachMoneyRounded';
import EqualizerRoundedIcon from '@mui/icons-material/EqualizerRounded';
import MoneyOffCsredRoundedIcon from '@mui/icons-material/MoneyOffCsredRounded';
import PercentRoundedIcon from '@mui/icons-material/PercentRounded';
import ShareLocationRoundedIcon from '@mui/icons-material/ShareLocationRounded';
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import capitalize from 'lodash/capitalize';
export type ResultsProps = {
  tier?: Tier;
  city?: string;
  state?: string;
  alamereLocationId?: AlamereLocationId;
};

export default function Results({
  city,
  state,
  alamereLocationId,
  tier,
}: ResultsProps) {
  const discount = tier?.percentile ? 100 - tier?.percentile : undefined;

  return (
    <List>
      <ListItem>
        <ListItemIcon>
          <ShareLocationRoundedIcon />
        </ListItemIcon>
        <ListItemText
          primary={
            (alamereLocationId && AlamereLocations[alamereLocationId].label) ||
            '--'
          }
          secondary="Location"
        />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <ApartmentRoundedIcon />
        </ListItemIcon>
        <ListItemText
          primary={
            city || state
              ? [city?.split(' ').map(capitalize).join(' '), state].join(' ')
              : '--'
          }
          secondary="City/State"
        />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <EqualizerRoundedIcon />
        </ListItemIcon>
        <ListItemText
          primary={tier ? tier.label || `Tier ${tier.tierNumber}` : '--'}
          secondary="Tier"
        />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          {!discount || discount > 0 ? (
            <AttachMoneyRoundedIcon />
          ) : (
            <MoneyOffCsredRoundedIcon />
          )}
        </ListItemIcon>
        <ListItemText
          style={{ fontSize: 20 }}
          primary={discount !== undefined ? `${-discount}%` : '--'}
          secondary={discount && discount < 0 ? 'Premium' : 'Discount'}
        />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <PercentRoundedIcon />
        </ListItemIcon>
        <ListItemText
          primary={tier ? tier.percentile.toFixed(0).toString() + '%' : '--'}
          secondary="Percent"
        />
      </ListItem>
    </List>
  );
}
